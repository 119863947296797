import { FC, useEffect, useState } from "react";
import { PolicyNarrativeBlob } from "../../../../dtos/policy-narrative-blob";
import { ProgramNarrativePage } from "../../../../dtos/program-narrative-page";
import { useApiGet, useApiPost } from "../../../../hooks/useApi";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { Button, Loading, Modal, RichEditor } from "../../../TrueUI";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import {
  DialogConfirmationConfigurationProps,
  PolicyQuoteInsuredAndActiveSetterProps,
} from "../PolicyQuoteForm/PolicyQuoteTypes";
import {
  updatePolicyQuote,
  updatePolicyQuoteInformation,
} from "../updatesPolicyQuoteFunctions";
import Note from "./Note";
import {
  getPolicyNarrativeListFromConfiguration,
  collapseAll,
  collapseItem,
  deletedNoteValues,
  disableOrEnableDeleteButton,
  updatePolicyNarrativeNote,
} from "./UnderwriterNotesUtils";
import { PolicyBlob } from "../../../../dtos/policy-blob";
import { QuotePolicySectionEnum } from "../../../../dtos/quote-policy-section-enum";
import { validatePreviousSections } from "../PolicyQuoteForm/PolicyQuoteValidationUtils";
import style from "./UnderwriterNotes.module.css";
import DialogConfirmation from "../../../TrueUI/Dialogs/DialogConfirmation";
import { getPolicyQuote } from "../PolicyQuoteForm/PolicyQuoteUtils";
import {
  areObjectsEqual,
  mergeObjectsAttributes,
} from "../../../../utilities/objectFunctions";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../../../GlobalAtoms";
import {
  getCurrentDateByTimeZone,
  getDateFromUtcToUserTimeZone,
} from "../../../../utilities/dateTimeZoneFunctions";
import {
  FormattingDateAndTime,
  getUniversalTime,
} from "../../../../utilities/dateFunctions";

type PolicyUnderWritingNotes = PolicyQuoteInsuredAndActiveSetterProps & {
  isModalOpen?: boolean;
  setIsModalOpen?: (isOpen: boolean) => void;
};
const UnderwriterNotes: FC<PolicyUnderWritingNotes> = ({
  insuredId,
  tabKey,
  //quoteStatus,
  setActiveSection,
  isModalOpen = false,
  setIsModalOpen,
}) => {
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const localOptions = useRecoilValue(globalOptions);
  const shortName = localOptions?.shortName;
  const currentDate = getCurrentDateByTimeZone(
    localOptions?.timeZoneID ?? null
  );
  const defaultTxt = `${FormattingDateAndTime(currentDate)} ${shortName} \n`;
  const [readyToSave, setReadyToSave] = useState<boolean>(false);
  const [underwriterNotesUI, setUnderwriterNotesUI] = useState<
    PolicyNarrativeBlob[] | null
  >();
  const [currentNote, setCurrentNote] = useState<PolicyNarrativeBlob | null>(
    null
  );
  const [dialogConfirmationConfiguration, setDialogConfirmationConfiguration] =
    useState<DialogConfirmationConfigurationProps>({
      isOpen: false,
      text: "",
      option: "save-one",
    });

  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );

  const { responseGet, dispatchGet } = useApiGet<ProgramNarrativePage>(
    "api/ProgramNarrative/GetUnderwriterNotesPage"
  );

  const { responsePost, dispatchPost } = useApiPost<PolicyBlob>(
    `api/QuotePolicy/SaveQuoteJSON?insuredId=${insuredId}&section=${QuotePolicySectionEnum.UNDERWRITING_NOTES}`,
    getAtom()?.policyQuoteInformation?.policyQuote
  );

  const isAllExpanded = underwriterNotesUI?.every((x) => x.expanded) ?? false;

  const isNotLastUnderwriterNote = (noteIndex: number) =>
    (underwriterNotesUI?.length ?? 0) - 1 != noteIndex;

  const saveUnderwriterNotes = () => {
    const atomValue = getAtom();
    const newAtomValueForPolicyQuote = updatePolicyQuote(
      atomValue,
      "narratives",
      underwriterNotesUI
    );
    setAtom(newAtomValueForPolicyQuote);
    setReadyToSave(true);
  };

  const showNoButton = () => {
    return dialogConfirmationConfiguration.option !== "save-all"
      ? (close) => {
          setDialogConfirmationConfiguration({
            ...dialogConfirmationConfiguration,
            isOpen: close,
          });
        }
      : undefined;
  };

  const checkPendingChangesToSave = () => {
    const underwriterNote =
      underwriterNotesUI?.find(
        (note) => note.narrativeID === currentNote?.narrativeID
      ) ?? {};
    if (!areObjectsEqual(currentNote, underwriterNote)) {
      setDialogConfirmationConfiguration({
        isOpen: true,
        text: "Are you sure you want to close before saving the changes?",
        option: "save-one",
      });
    } else {
      setCurrentNote(null);
    }
  };

  const collapseAllEvent = () => {
    const totalOfExpandedNotes =
      underwriterNotesUI?.filter((x) => x.expanded)?.length ?? 0;
    const collapsedNotes = collapseAll(
      underwriterNotesUI ?? [],
      totalOfExpandedNotes === 0
    );
    setUnderwriterNotesUI(collapsedNotes);
  };

  const updateExpandedFieldItem = (value: boolean, noteIndex: number) => {
    const updatedNotes = collapseItem(
      underwriterNotesUI ?? [],
      noteIndex,
      value
    );
    setUnderwriterNotesUI(updatedNotes);
  };

  const ModalNoteContent = () => (
    <RichEditor
      disableCollapse
      value={currentNote?.formattedNarrativeText}
      contentStyle={{ height: 300 }}
      onChangeRawValue={(value) =>
        setCurrentNote({ ...currentNote, formattedNarrativeText: value })
      }
    />
  );
  const ModalContentList = () => (
    <div className={style.underwriter_notes_container}>
      <div className={style.button_container}>
        <Button
          variantStyle={"outlined"}
          onClick={() => {
            collapseAllEvent();
          }}
        >
          {`${isAllExpanded ? "COLLAPSE" : "EXPAND"} ALL`}
        </Button>
      </div>
      <div className={style.style_box_container}>
        {underwriterNotesUI ? (
          <div>
            {underwriterNotesUI.map((note, noteIndex) => (
              <Note
                key={noteIndex}
                tabKey={tabKey}
                noteIndex={noteIndex}
                note={note}
                editEvent={(note) => {
                  const value =
                    note?.formattedNarrativeText !== null &&
                    note?.formattedNarrativeText !== ""
                      ? note?.formattedNarrativeText
                      : defaultTxt;

                  setCurrentNote({
                    ...note,
                    formattedNarrativeText: value,
                  });
                  setDialogConfirmationConfiguration({
                    isOpen: false,
                    text: "",
                    option: "save-one",
                  });
                }}
                isCollapsed={(value) => {
                  updateExpandedFieldItem(value, noteIndex);
                }}
                collapseBottomMargin={isNotLastUnderwriterNote(noteIndex)}
              />
            ))}
          </div>
        ) : (
          <Loading isLoading={true} />
        )}
      </div>
    </div>
  );

  const saveEditedNote = (isDeleted) => {
    const newEditedNote = isDeleted
      ? { ...deletedNoteValues, narrativeID: currentNote?.narrativeID }
      : {
          narrativeID: currentNote?.narrativeID,
          formattedNarrativeText: currentNote?.formattedNarrativeText ?? "",
          updateBy: shortName,
          updateOn: getDateFromUtcToUserTimeZone(
            getUniversalTime(),
            localOptions?.timeZoneID ?? null
          ),
        };

    const newUpdatedNote = updatePolicyNarrativeNote(
      underwriterNotesUI ?? [],
      newEditedNote
    );
    setUnderwriterNotesUI?.(newUpdatedNote);
    setCurrentNote(null);
  };

  const saveEvent = () => {
    if (conditionHasValue(currentNote)) {
      saveEditedNote(false);
    } else {
      saveUnderwriterNotes();
    }
  };

  const deleteFunction = () => {
    setDialogConfirmationConfiguration({
      isOpen: true,
      text: "Are you sure you want to delete this content?",
      option: "delete",
    });
  };

  const cancelEvent = () => {
    if (conditionHasValue(currentNote)) {
      checkPendingChangesToSave();
    } else {
      setIsModalOpen?.(false);
    }
  };
  const deleteDisabled = conditionHasValue(currentNote)
    ? disableOrEnableDeleteButton(currentNote)
    : undefined;

  const deleteEvent = conditionHasValue(currentNote)
    ? deleteFunction
    : undefined;

  useEffect(() => {
    if (readyToSave === true) {
      dispatchPost();
    }
  }, [readyToSave]);

  useEffect(() => {
    if (responsePost.requestInstanceSuccessful) {
      const atomValue = getAtom();
      const newAtomValue = updatePolicyQuoteInformation(
        atomValue,
        "policyQuote",
        responsePost.axiosResponse?.data
      );
      setAtom(newAtomValue);
      setReadyToSave(false);
      setDialogConfirmationConfiguration({
        isOpen: true,
        text: "Underwriting notes saved",
        option: "save-all",
      });
    }
  }, [responsePost]);

  useEffect(() => {
    if (isModalOpen === true) {
      const atomValue = getAtom();
      const activeSection =
        atomValue?.policyQuoteInformation?.activeSection ??
        QuotePolicySectionEnum.INFORMATION;
      const isValid = validatePreviousSections(atomValue, activeSection);
      if (isValid) {
        dispatchGet();
      } else {
        const newAtomValue = updatePolicyQuoteInformation(
          atomValue,
          "activeSection",
          QuotePolicySectionEnum.INFORMATION
        );
        setAtom(newAtomValue);
        setActiveSection?.(QuotePolicySectionEnum.INFORMATION);
      }
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (responseGet?.axiosResponse?.data && !responseGet?.isLoading) {
      const atomValue = getAtom();
      const policyQuote = getPolicyQuote(atomValue);
      const policyNarratives = policyQuote?.narratives ?? [];
      const newPolicyNarrativeList = getPolicyNarrativeListFromConfiguration(
        responseGet?.axiosResponse?.data.programNarrativeConfiguration ?? []
      );
      const mergedNarratives = Object.values(
        mergeObjectsAttributes(newPolicyNarrativeList, policyNarratives)
      ) as PolicyNarrativeBlob[];
      setUnderwriterNotesUI(mergedNarratives);
    }
  }, [responseGet]);

  return (
    <Modal
      id="underwriterNoteModal"
      title={
        conditionHasValue(currentNote)
          ? currentNote?.narrativeDescription ?? ""
          : "Underwriting Notes"
      }
      size="md"
      open={isModalOpen}
      cancelEvent={cancelEvent}
      saveEvent={saveEvent}
      deleteDisabled={deleteDisabled}
      deleteEvent={deleteEvent}
      saveOverrideLabel={conditionHasValue(currentNote) ? "Save" : "Save Only"}
      showCloseButton
      showCancelTextButton
    >
      {currentNote ? ModalNoteContent() : ModalContentList()}
      <DialogConfirmation
        id="underwriterNoteDialogConfirmation"
        open={dialogConfirmationConfiguration?.isOpen}
        dialogDescriptionText={dialogConfirmationConfiguration?.text}
        optionYesOverrideLabel="OK"
        onOptionNoEvent={
          conditionHasValue(currentNote) ? showNoButton() : undefined
        }
        optionNoOverrideLabel={
          conditionHasValue(currentNote) ? "Cancel" : undefined
        }
        onOptionYesEvent={(close) => {
          if (conditionHasValue(currentNote)) {
            if (dialogConfirmationConfiguration.option === "save-one") {
              setCurrentNote(null);
            } else if (dialogConfirmationConfiguration.option === "delete") {
              saveEditedNote(true);
              setCurrentNote(null);
            }
          }
          setDialogConfirmationConfiguration({
            ...dialogConfirmationConfiguration,
            isOpen: close,
          });
          if (dialogConfirmationConfiguration.option === "save-all") {
            setIsModalOpen?.(false);
          }
        }}
      />
    </Modal>
  );
};

export default UnderwriterNotes;
