import { FC, useEffect, useState } from "react";
import { useApiGet } from "../../../../../../../../hooks";
import { PaymentEOBDto } from "../../../../../../../../dtos/payment-eob-dto";
import { isAPITotallyComplete } from "../../../../../../../../utilities/apiFunctions";
import Row, {
  rowWithNoMarginNorGutter,
} from "../../../../../../../TrueUI/Grids/Row";
import { Col, FontBold } from "../../../../../../../TrueUI";
import { colWithNoMarginNorGutter } from "../../../../../../../TrueUI/Grids/Col";
import RowDiagnosisCode from "./RowDiagnosisCode";
import { FormDiagnosisProps } from "../TypesPaymentBillDetails";
import { useRecoilState } from "recoil";
import { ClaimFinancialPaymentBillDetailsAtom } from "../../ClaimFinancialAtoms";

const FormDiagnosis: FC<FormDiagnosisProps> = ({}) => {
  const [paymentBillDetailsAtom, setPaymentBillDetailsAtom] = useRecoilState(
    ClaimFinancialPaymentBillDetailsAtom
  );

  const [paymentEOB, setPaymentEOB] = useState<PaymentEOBDto | null>(null);

  const { responseGet, dispatchGet } = useApiGet<PaymentEOBDto>(
    `api/PaymentEOB/GetPaymentEOBWithDiagnosisCodesByPaymentId?paymentId=${
      paymentBillDetailsAtom?.paymentData?.paymentId ?? 0
    }`
  );

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setPaymentEOB(responseGet?.responseData ?? null);
    }
  }, [responseGet]);

  useEffect(() => {
    const paymentEOBForAtom: any = paymentEOB;
    if ((paymentEOBForAtom?.eobId ?? 0) !== 0) {
      setPaymentBillDetailsAtom({
        ...paymentBillDetailsAtom,
        diagnosisCode1: getDiagnosisCodeForRow(1),
        diagnosisCode2: getDiagnosisCodeForRow(2),
        diagnosisCode3: getDiagnosisCodeForRow(3),
        diagnosisCode4: getDiagnosisCodeForRow(4),
      });
    }
  }, [paymentEOB]);

  const rows = { A: 1, B: 2, C: 3, D: 4 };

  const getDiagnosisCode = (paymentEOB, rowValue) => {
    const diagnosisCode = paymentEOB?.diagnosisCodes?.find((diagnosisCode) => {
      switch (rowValue) {
        case 1:
          return (
            (diagnosisCode?.diagnosisCodeId ?? 0) ===
            (paymentEOB?.diagnosisCodeId1 ?? -1)
          );
        case 2:
          return (
            (diagnosisCode?.diagnosisCodeId ?? 0) ===
            (paymentEOB?.diagnosisCodeId2 ?? -1)
          );
        case 3:
          return (
            (diagnosisCode?.diagnosisCodeId ?? 0) ===
            (paymentEOB?.diagnosisCodeId3 ?? -1)
          );
        case 4:
          return (
            (diagnosisCode?.diagnosisCodeId ?? 0) ===
            (paymentEOB?.diagnosisCodeId4 ?? -1)
          );
        default:
          return false;
      }
    });
    return diagnosisCode;
  };
  const getDiagnosisCodeFromAtom = (rowValue) => {
    switch (rowValue) {
      case 1:
        return paymentBillDetailsAtom?.diagnosisCode1;
      case 2:
        return paymentBillDetailsAtom?.diagnosisCode2;
      case 3:
        return paymentBillDetailsAtom?.diagnosisCode3;
      case 4:
        return paymentBillDetailsAtom?.diagnosisCode4;
      default:
        return null;
    }
  };
  const getDiagnosisCodeForRow = (value) => {
    return (
      getDiagnosisCodeFromAtom(value) ?? getDiagnosisCode(paymentEOB, value)
    );
  };
  return (
    <>
      {Object.entries(rows).map(([key, value]) => (
        <Row {...rowWithNoMarginNorGutter} numberOfColumns={24} key={key}>
          <Col
            breakpoints={{ xs: 2, sm: 2, md: 2, lg: 1, xl: 1 }}
            verticalAlignSelf="center"
          >
            <FontBold>{key}</FontBold>
          </Col>
          <Col
            breakpoints={{ xs: 22, sm: 22, md: 22, lg: 23, xl: 23 }}
            {...colWithNoMarginNorGutter}
          >
            <RowDiagnosisCode
              savedDiagnosisCode={getDiagnosisCodeForRow(value)}
              key={value}
              rowValue={value}
            />
          </Col>
        </Row>
      ))}
    </>
  );
};

export default FormDiagnosis;
