import { FC, useEffect, useState } from "react";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { groupArrayByKey } from "../../../../utilities/arrayFunctions";
import {
  getJSONDataWithoutConfigurations,
  getPolicyQuoteEndorsements,
} from "../PolicyQuoteForm/PolicyQuoteUtils";
import {
  Caption,
  Font,
  FontBold,
  Loading,
  SmallTitle,
  Switch,
} from "../../../TrueUI";
import { PolicyQuoteEndorsementOptionalFormsProps } from "../PolicyQuoteForm/PolicyQuoteTypes";
import { ProgramEndorsementBlob } from "../../../../dtos/program-endorsement-blob";
import { FormattingDate } from "../../../../utilities/dateFunctions";
import { useFormRequest } from "../../../../hooks/useFileStorage";
import { FormTypeEnum } from "../../../../dtos/form-type-enum";
import Link from "../../../TrueUI/Typography/Link";
import { isEmptyValue } from "../../../../utilities/conditionalSupportFunctions";
import DialogConfirmation from "../../../TrueUI/Dialogs/DialogConfirmation";
import style from "./EndorsementForms.module.css";

const OptionalForms: FC<PolicyQuoteEndorsementOptionalFormsProps> = ({
  forms,
  tabKey,
  setSelectedOptionalForms,
}) => {
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const [localForms, setLocalForms] = useState<any>();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const { getAtom } = useAtomFamily(GlobalInsuredAtomFamily(insuredIdAtomKey));

  const setOptionalLocalForms = (
    checked: boolean,
    endorsementForm: ProgramEndorsementBlob
  ) => {
    const updatedEndorsementForms = localForms[
      endorsementForm?.stateCode ?? ""
    ]?.map((form: ProgramEndorsementBlob) => {
      return form.endorsementFormID === endorsementForm.endorsementFormID
        ? { ...form, checked: checked }
        : form;
    });
    setLocalForms({
      ...localForms,
      [endorsementForm?.stateCode ?? ""]: updatedEndorsementForms,
    });
  };

  const setOptionalFormsFromAtom = (
    atomEndorsements?: ProgramEndorsementBlob[] | null
  ) => {
    const groupedEndorsements = groupArrayByKey(
      atomEndorsements ?? [],
      "stateCode"
    );
    Object.keys(groupedEndorsements).map((key) => {
      const updatedEndorsementForms = forms?.[key]?.map(
        (form: ProgramEndorsementBlob) => {
          const endorsementFound = groupedEndorsements?.[key]?.find(
            (endorsement) =>
              endorsement.endorsementFormID === form.endorsementFormID
          );

          return endorsementFound
            ? { ...form, checked: endorsementFound.checked }
            : form;
        }
      );
      if (updatedEndorsementForms)
        setLocalForms((prev) => ({
          ...prev,
          [key]: updatedEndorsementForms,
        }));
    });
  };

  const checkedAllFormsInState = (state: string) => {
    const formsLength = localForms[state]?.length ?? 0;
    const formsCheckedLength =
      localForms[state]?.filter((form) => form.checked)?.length ?? 0;

    setLocalForms({
      ...localForms,
      [state]: localForms[state].map((form) => ({
        ...form,
        checked: formsLength !== formsCheckedLength,
      })),
    });
  };

  const { sendMergeFormRequest } = useFormRequest();

  const printCheckedForm = (form: ProgramEndorsementBlob) => {
    const policyMergeFields = getAtom()?.policyQuoteInformation?.policyQuote;
    if (
      policyMergeFields !== undefined &&
      policyMergeFields !== null &&
      !isEmptyValue(form.templateName)
    ) {
      sendMergeFormRequest({
        formType: FormTypeEnum.POLICY_FORM,
        policyMergeFields: getJSONDataWithoutConfigurations(
          getAtom()?.policyQuoteInformation
        ),
        templateNameWithExtensionList: [form.templateName ?? ""],
        customFileName: `${
          policyMergeFields.insured?.insuredName ?? ""
        } endorsement form ${form.formNumber ?? ""}`,
      });
    }
    if (isEmptyValue(form.templateName)) {
      setDialogOpen(true);
    }
  };

  useEffect(() => {
    setLocalForms(forms);
    const atomValue = getAtom();
    const atomEndorsements = getPolicyQuoteEndorsements(atomValue);
    if (
      atomEndorsements &&
      atomEndorsements !== null &&
      atomEndorsements?.length > 0
    ) {
      setOptionalFormsFromAtom(atomEndorsements);
    }
  }, [forms]);

  useEffect(() => {
    if (localForms) {
      const endorsementForms = Object.values(
        localForms
      ).flat() as ProgramEndorsementBlob[];
      const checkedForms = endorsementForms.filter(
        (form) => form?.checked ?? false
      );
      setSelectedOptionalForms(checkedForms);
    }
  }, [localForms]);

  return localForms ? (
    <div>
      <div style={{ padding: "5px" }}>
        <Font>OPTIONAL FORMS</Font>
      </div>
      {localForms &&
        Object.keys(localForms)
          .sort()
          .map((key) => (
            <div key={`optional-state-container-${key}`}>
              <div className={style.state_container}>
                <div
                  className={style.state_header_container}
                  onClick={() => checkedAllFormsInState(key)}
                >
                  <FontBold>
                    {localForms?.[key]?.[0]?.stateName.toUpperCase()}
                  </FontBold>
                </div>
                {localForms?.[key]?.map((form: ProgramEndorsementBlob) => (
                  <div
                    key={`optional-form-${form.stateCode}-${form.endorsementFormID}`}
                    className={`optional_endorsement_form_row ${style.endorsement_form_row_information_container}`}
                  >
                    <div className={style.switch_link_container}>
                      <Switch
                        control="checkbox"
                        isChecked={form.checked}
                        onChangeIsChecked={(checked) =>
                          setOptionalLocalForms(checked, form)
                        }
                        primaryLabelColor
                        labelFontType={"BODY"}
                      />
                      <Link
                        linkFontType="BODY"
                        displayValue={`${form.formNumber}`}
                        onClick={() => printCheckedForm(form)}
                      />
                    </div>
                    <SmallTitle>
                      {form.formName}
                      <Caption>({FormattingDate(form.formDate)})</Caption>
                    </SmallTitle>
                  </div>
                ))}
              </div>
            </div>
          ))}
      <DialogConfirmation
        id="id-no-template-message"
        dialogDescriptionText="No print template found."
        optionYesOverrideLabel="OK"
        open={dialogOpen}
        onOptionYesEvent={setDialogOpen}
        onCloseEvent={setDialogOpen}
      />
    </div>
  ) : (
    <Loading isLoading={true} />
  );
};

export default OptionalForms;
