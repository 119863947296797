import { FC } from "react";
import style from "./DocumentsStyles.module.css";
import { DocumentPacketsProps } from "./DocumentsTypes";
import { Font, Switch } from "../../../../TrueUI";

const DocumentPackets: FC<DocumentPacketsProps> = ({
  programPackets,
  selectedPackets,
  onChangePacketSelected,
}) => {
  const getCheckedValueForPacket = (programPacketId: number) =>
    selectedPackets.find(
      (selectedPacket) => selectedPacket.packetId === programPacketId
    ) !== undefined;

  return (
    <div
      id="document-packets-container-id"
      className={style.document_divider_section_container}
    >
      <div className={style.divider_section_title_container}>
        <Font whiteSpace="normal" textAlign="start">
          SELECT A GROUP OF DOCUMENTS BY SELECTING A PACKET BELOW
        </Font>
      </div>
      {programPackets.map((programPacket, index) => (
        <div key={`program-packet-container-${programPacket.packetId}`}>
          <Switch
            id={`program-packet-${programPacket.packetId}`}
            key={`program-packet-${programPacket.packetId}`}
            tabIndex={index}
            control="checkbox"
            label={programPacket.packetName}
            labelPlacement={"end"}
            labelFontType={"BODY"}
            isChecked={getCheckedValueForPacket(programPacket.packetId)}
            onChangeIsChecked={(checked) =>
              onChangePacketSelected(programPacket, checked)
            }
          />
        </div>
      ))}
    </div>
  );
};

export default DocumentPackets;
