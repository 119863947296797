import { FC, useEffect, useState } from "react";
import { BaseTable2Properties } from "../../../../../../../TrueUI/Tables/BaseTable2/TableProperties";
import { useBaseTable } from "../../../../../../../../hooks/useBaseTable";
import { BaseTable2 } from "../../../../../../../TrueUI";
import { TableProceduresServicesSuppliesProps } from "../TypesPaymentBillDetails";
import { IconButton } from "@mui/material";
import { Remove } from "@mui/icons-material";
import { toUpperCaseKeys } from "../../../../../../../../utilities/objectFunctions";
import { useRecoilState } from "recoil";
import { ClaimFinancialPaymentBillDetailsAtom } from "../../ClaimFinancialAtoms";
import { getColumnIndexesByColumnNames } from "../../../../../../../BaseGrid/SupportFunctions/baseGridFunctions";
import { FormattingDate } from "../../../../../../../../utilities/dateFunctions";
import { currencyFormat } from "../../../../../../../../utilities/currencyFunctions";

const TableWrapperProceduresServicesSupplies: FC<
  TableProceduresServicesSuppliesProps
> = ({ tableData }) => {
  const diagnosisCodes = {
    1: "A",
    2: "B",
    3: "C",
    4: "D",
  };

  const [paymentBillDetailsAtom, setPaymentBillDetailsAtom] = useRecoilState(
    ClaimFinancialPaymentBillDetailsAtom
  );
  const [totals, setTotals] = useState<{
    totalCharge: number | null;
    totalPaid: number | null;
    thereAreSavedRows: boolean;
  }>({
    totalCharge: null,
    totalPaid: null,
    thereAreSavedRows: false,
  });

  const [paymentEobDetailIdToDelete, setPaymentEobDetailIdToDelete] = useState<
    number | null
  >(null);
  const tableName = "table-procedures-services-supplies";

  useEffect(() => {
    if (
      totals.thereAreSavedRows === true ||
      ((paymentBillDetailsAtom?.paymentBillDetails?.length ?? 0) > 0 &&
        totals?.totalCharge !== null &&
        totals?.totalCharge !== null)
    ) {
      setPaymentBillDetailsAtom({
        ...paymentBillDetailsAtom,
        ...totals,
      });
      setTotals({
        totalCharge: null,
        totalPaid: null,
        thereAreSavedRows: false,
      });
    }
  }, [totals]);

  useEffect(() => {
    if (paymentEobDetailIdToDelete !== null) {
      setPaymentBillDetailsAtom({
        ...paymentBillDetailsAtom,
        paymentBillDetailsToDelete: [
          ...(paymentBillDetailsAtom?.paymentBillDetailsToDelete ?? []),
          paymentEobDetailIdToDelete,
        ],
      });
      setPaymentEobDetailIdToDelete(null);
    }
  }, [paymentEobDetailIdToDelete]);

  const compareTableRowWithAtomRows = (rowHydratedData, rowInAtom) => {
    const individualBillDetails = rowInAtom?.individualBillDetails;
    const paymentServiceFeeSchedule = rowInAtom?.paymentServiceFeeSchedule;
    const serviceFromDateAreEqual =
      rowHydratedData?.serviceFromDate ===
      FormattingDate(individualBillDetails?.serviceFromDate);
    const serviceToDateAreEqual =
      rowHydratedData?.serviceToDate ===
      FormattingDate(individualBillDetails?.serviceToDate);
    const serviceCodeAreEqual =
      rowHydratedData?.serviceCode ===
      individualBillDetails?.serviceCode?.toString();
    const diagnosisCodeAreEqual =
      rowHydratedData?.diagnosisCode ===
      diagnosisCodes[individualBillDetails?.diagnosisCodeId?.toString() ?? ""];
    const shortDescriptionAreEqual =
      rowHydratedData?.shortDescription ===
      paymentServiceFeeSchedule?.shortDescription;
    const amountBilledAreEqual =
      rowHydratedData?.amountBilled ===
      currencyFormat(individualBillDetails?.amountBilled, false, 2, true);
    const amountAllowedAreEqual =
      rowHydratedData?.amountAllowed ===
      currencyFormat(individualBillDetails?.amountAllowed, false, 2, true);
    const amountPaidAreEqual =
      rowHydratedData?.amountPaid ===
      currencyFormat(individualBillDetails?.amountPaid, false, 2, true);
    const quantityAreEqual =
      rowHydratedData?.quantity ===
      currencyFormat(individualBillDetails?.quantity, false, 2, true);
    const eOBCodeAreEqual =
      rowHydratedData?.eOBCode === individualBillDetails?.eOBCode?.toString();

    const isRowFound =
      serviceFromDateAreEqual &&
      serviceToDateAreEqual &&
      serviceCodeAreEqual &&
      diagnosisCodeAreEqual &&
      shortDescriptionAreEqual &&
      amountBilledAreEqual &&
      amountAllowedAreEqual &&
      amountPaidAreEqual &&
      quantityAreEqual &&
      eOBCodeAreEqual;

    return isRowFound;
  };
  const removeRowFromAtom = (rowData) => {
    if ((rowData?.PaymentDetailId ?? "0") === "0") {
      const rowKeys = (tableData?.columns ?? [])
        .map((column: any) => column?.fieldName ?? "")
        .filter(
          (key) => ["PaymentDetailId", "PaymentId"].includes(key) === false
        );

      const rowHydratedData = rowKeys.reduce((acc, key) => {
        const lowercaseKey = key.charAt(0).toLowerCase() + key.slice(1);
        acc[lowercaseKey] = rowData?.[key] ?? "";
        return acc;
      }, {});

      const rowFound = {
        isFound: false,
      };

      const newRowsInAtom: any = { newPaymentBillDetails: [] };

      (paymentBillDetailsAtom?.paymentBillDetails ?? []).forEach(
        (rowInAtom) => {
          const isRowFound = compareTableRowWithAtomRows(
            rowHydratedData,
            rowInAtom
          );
          if (isRowFound === true) {
            if (rowFound.isFound === true) {
              newRowsInAtom.newPaymentBillDetails = [
                ...newRowsInAtom.newPaymentBillDetails,
                rowInAtom,
              ];
            }
            rowFound.isFound = true;
          } else {
            newRowsInAtom.newPaymentBillDetails = [
              ...newRowsInAtom.newPaymentBillDetails,
              rowInAtom,
            ];
          }
        }
      );
      setPaymentBillDetailsAtom({
        ...paymentBillDetailsAtom,
        paymentBillDetails: newRowsInAtom.newPaymentBillDetails,
        isDeletingPaymentBillDetails: true,
      });
    }
  };

  const RemoveItemLink = (rowData?: any) => {
    return (
      <IconButton
        aria-label="save"
        size="small"
        onClick={() => {
          removeRowFromAtom(rowData);

          tableInstanceMethods?.methods?.deleteRow({
            rowKey: rowData?._row_key ?? "",
            hydratedData: {
              ...rowData,
              ...toUpperCaseKeys(rowData),
            },
            deleteType: "hidden",
          });

          setPaymentEobDetailIdToDelete(
            parseInt(rowData?.PaymentDetailId ?? "0")
          );
        }}
      >
        <Remove fontSize={"medium"} />
      </IconButton>
    );
  };

  const tableConfiguration: BaseTable2Properties = {
    name: tableName,
    columnsAndData: tableData,
    columnOptions: [
      { fieldName: "ServiceFromDate", width: 8 },
      { fieldName: "ServiceToDate", width: 8 },
      { fieldName: "DiagnosisCode", width: 8 },
      { fieldName: "ServiceCode", width: 23 },
      { fieldName: "AmountBilled", width: 11, align: "right" },
      { fieldName: "AmountAllowed", width: 11, align: "right" },
      { fieldName: "Quantity", width: 7 },
      { fieldName: "AmountPaid", width: 14, align: "right" },
      {
        fieldName: "EOBCode",
        width: 7,
      },
      {
        fieldName: "OPTIONS",
        width: 3,
      },
    ],
    toolbarOptions: {
      hideToolbar: true,
    },
    advancedOptions: {
      disableOrderBy: true,
      paginate: false,
      tableStyle: {
        height: "100%",
      },
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: (actionOptions) =>
          RemoveItemLink?.(actionOptions.row) ?? null,
      },
      isEditMode: false,
    },
    events: {
      onDataChange: (data, columns) => {
        getTotals(data, columns);
      },
    },
  };

  const getTotals = (data: any, columns: any) => {
    const paidIndex = getColumnIndexesByColumnNames(
      ["AmountPaid"],
      columns
    )?.[0];
    const billedIndex = getColumnIndexesByColumnNames(
      ["AmountBilled"],
      columns
    )?.[0];
    const quantityIndex = getColumnIndexesByColumnNames(
      ["Quantity"],
      columns
    )?.[0];

    const getTotalCharge = (amountBilled: string, quantity: string) => {
      return (
        parseFloat(amountBilled?.replaceAll(",", "") ?? "0") *
        parseFloat(quantity?.replaceAll(",", "") ?? "0")
      );
    };

    const totalCharge = data.reduce(
      (accumulator, row) =>
        accumulator + getTotalCharge(row[billedIndex], row[quantityIndex]),
      0
    );

    const totalPaid = data.reduce(
      (accumulator, row) =>
        accumulator + parseFloat(row?.[paidIndex]?.replaceAll(",", "") ?? "0"),
      0
    );

    setTotals({
      totalCharge,
      totalPaid,
      thereAreSavedRows: (data?.length ?? 0) > 0,
    });
  };

  const { tableMethods } = useBaseTable(tableConfiguration);
  const tableInstanceMethods = tableMethods[tableName];

  return (
    <>
      <BaseTable2 name={tableName} />
    </>
  );
};

export default TableWrapperProceduresServicesSupplies;
