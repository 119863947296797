import { FC, useState } from "react";
import { Button, Col, Row } from "../../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../../TrueUI/Grids/Row";
import { RibbonButtonsPolicyWaiverProps } from "../TypesPolicyWaivers";
import DialogConfirmation from "../../../../TrueUI/Dialogs/DialogConfirmation";
import { StatusEnums } from "../../../../../dtos/status-enums";
import style from "./ModalWaiverStyles.module.css";
import { colWithNoMarginNorGutter } from "../../../../TrueUI/Grids/Col";
import { CustomJSONTypeEnum } from "../../../../../dtos/custom-json-type-enum";
import { useSetRecoilState } from "recoil";
import { customJSONPrintManagerAtom } from "../../../../CustomJSONPrint/CustomJSONPrintManagerAtoms";
import CustomJSONPrintManager from "../../../../CustomJSONPrint/CustomJSONPrintManager";

const RibbonButtonsPolicyWaiver: FC<RibbonButtonsPolicyWaiverProps> = ({
  policyWaiverComp,
  setPolicyWaiverComp,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const isAddMode = policyWaiverComp?.policyWaiver?.waiverId === 0;
  const isPrintEnabled =
    policyWaiverComp?.policyWaiver?.status === StatusEnums.ACTIVE && !isAddMode;

  const setCustomJSONPrintManagerAtom = useSetRecoilState(
    customJSONPrintManagerAtom
  );

  const onClickPrint = () => {
    setCustomJSONPrintManagerAtom({
      customJSONType: CustomJSONTypeEnum.WAIVER,
      sourceIDToBuildJSON_1: policyWaiverComp?.policyWaiver?.waiverId ?? 0,
      configurationRequest: {
        templateNameWithExtensionList: [
          policyWaiverComp?.configurationToPrint?.templateName ?? "",
        ],
        customFileName: `Waiver ${policyWaiverComp?.policyWaiver?.waiverId}`,
      },
    });
  };

  return (
    <Row {...rowWithNoMarginNorGutter} allowWrap>
      <Col
        breakpoints={{ md: 12, lg: 12, xl: 12 }}
        horizontalAlign="flex-start"
        className={style.modal_waiver_ribbon_buttons_container}
      >
        <Button
          isDisabled={!isPrintEnabled}
          onClick={onClickPrint}
          variantStyle={"outlined"}
        >
          PRINT
        </Button>
        <Button
          onClick={() => {
            setPolicyWaiverComp({
              ...policyWaiverComp,
              policyWaiver: {
                ...policyWaiverComp?.policyWaiver,
                status: StatusEnums.ACTIVE,
                issueDate: new Date(),
                waiverId: 0,
              },
            });
          }}
          isDisabled={isAddMode}
          variantStyle={"outlined"}
        >
          RE-ISSUE
        </Button>
        <Button
          isDisabled={isAddMode}
          onClick={() => {
            setIsDialogOpen(true);
          }}
          variantStyle={"outlined"}
        >
          DELETE
        </Button>
      </Col>

      <Col
        breakpoints={{ md: 12, lg: 12, xl: 12 }}
        {...colWithNoMarginNorGutter}
      >
        <DialogConfirmation
          open={isDialogOpen ?? false}
          dialogDescriptionText="Are you sure you want to delete this waiver?"
          onOptionYesEvent={() => {
            setPolicyWaiverComp({
              ...policyWaiverComp,
              policyWaiver: {
                ...policyWaiverComp?.policyWaiver,
                status: StatusEnums.DELETED,
              },
              dispatchSave: true,
            });

            setIsDialogOpen(false);
          }}
          onOptionNoEvent={() => setIsDialogOpen(false)}
        />
      </Col>
      <CustomJSONPrintManager />
    </Row>
  );
};

export default RibbonButtonsPolicyWaiver;
