import {
  ColumnOptionsProperties,
  ComputeForCellParameters,
  ConditionForCellResponse,
} from "../../../TrueUI/Tables/BaseTable2/TableProperties";

import { EditedPayrollReportDetailsDto } from "../../../../dtos/edited-payroll-report-details-dto";
import { toUpperCaseKeys } from "../../../../utilities/objectFunctions";
import { Theme } from "../../../../media/themeTypes";
import {
  formatToCurrency,
  unFormatLocalString,
} from "../../../../utilities/stringFunctions";
import { BaseTableColumn } from "../../../../dtos/base-table-column";
import { BaseTableInputType } from "../../../../dtos/base-table-input-type";
import { PayrollReportDetailRowDto } from "../../../../dtos/payroll-report-detail-row-dto";
import { PayrollReportDetailInfoPage } from "../../../../dtos/payroll-report-detail-info-page";

export type PayrollReportDetailModalConfig = {
  isOpen: boolean;
  title: string;
  insuredId: number;
  payrollReportId: number;
};

const onPayrollChange = () => {
  return {
    triggerComputeFieldNames: ["Amount"],
  } as ConditionForCellResponse;
};

const updateAmountByPayroll = (options: ComputeForCellParameters<any>) => {
  const payroll = parseFloat(options?.row?.NetPayroll ?? "0");
  const modifiedRate = parseFloat(options?.row?.ModifiedRate ?? "0");
  if (isNaN(payroll) || isNaN(modifiedRate)) {
    return {
      value: "-",
    };
  } else {
    return { value: formatToCurrency((payroll / 100) * modifiedRate) };
  }
};

export const detailsTableColumnOptions: ColumnOptionsProperties<any>[] = [
  {
    fieldName: "Amount",
    align: "right",
    decimalScale: 2,
    computeOnChange: {
      conditionForCell: [(options) => updateAmountByPayroll(options)],
    },
    width: 10,
  },
  {
    fieldName: "NetPayroll",
    align: "right",
    decimalScale: 2,
    computeOnChange: {
      conditionForCell: [() => onPayrollChange()],
    },
    width: 10,
  },
];

export const getEditedPayrollReportDetail = (data: string[][]) => {
  return data.map((row: any) => {
    const EditedRowData = {
      payrollReportDetailId: parseInt(row?.PayrollReportDetailId),
      numEE: parseInt(row?.NumEE?.replace(",", "")),
      netPayroll: parseFloat(row?.NetPayroll?.replace(",", "")),
      amount: unFormatLocalString(row?.Amount?.replace(",", "")),
      baseTableRowKey: row?.baseTableRowKey,
      isBaseTableRowDeleted: row?.isBaseTableRowDeleted,
    };
    return EditedRowData;
  });
};

export const getEditedPayrollReportDetailUpperCaseKeys = (
  data: EditedPayrollReportDetailsDto[]
) => {
  return data?.map((editedRow) => toUpperCaseKeys(editedRow));
};

export const errorMessageStyle = (theme: Theme) => ({
  color: theme?.DANGER,
  fontSize: theme?.SMALL_TITLE?.SIZE,
  letterSpacing: "0.03333em",
  textAlign: "left",
  margin: 0,
  display: "block",
});

export type CustomFooterComponentPayrollReportDetailsProps = {
  rows: any;
  PayrollReportDetailInfo: Partial<PayrollReportDetailInfoPage> | null;
};

export const getDetailsColumns = (payrollReportInfo, requiredFlagChecked) => {
  return payrollReportInfo?.detailsColumns?.map((column: BaseTableColumn) => {
    if (!requiredFlagChecked === true && column.fieldName === "NumEE")
      return { ...column, type: BaseTableInputType.NUMBER };
    if (!requiredFlagChecked === true && column.fieldName === "NetPayroll")
      return { ...column, type: BaseTableInputType.CURRENCY };

    return { ...column, type: BaseTableInputType.READONLY };
  });
};
const lowercaseFirstLetter = (inputString) => {
  if (inputString.length === 0) {
    return ""; // Return an empty string if the input is empty
  }

  const firstLetterLowercase = inputString.charAt(0).toLowerCase();
  const restOfString = inputString.slice(1);

  return firstLetterLowercase + restOfString;
};

const getRowValueByColumnKey = (row, columns, columnKey) => {
  const index = columns?.findIndex((column) => column.fieldName === columnKey);
  return row[index ?? -1];
};

const updateDetailsRow = (row, columns, columnsToUpdate, newValues) => {
  const rowObject = { row: [...row] };
  columnsToUpdate.forEach((columnKey) => {
    const index = columns?.findIndex(
      (column) => column.fieldName === columnKey
    );

    rowObject.row[index ?? -1] = formatToCurrency(
      newValues[lowercaseFirstLetter(columnKey)]
    );
  });
  return rowObject.row;
};

export const getDataForDetailRow = (
  data: string[][],
  groupData: any,
  editedRowData?: Partial<EditedPayrollReportDetailsDto> | null,
  columns?: BaseTableColumn[]
) => {
  const groupNameId = groupData.NameId;
  const dataNameIndex = columns?.findIndex(
    (column) => column.fieldName === "NameId"
  );

  const detailRows = data.filter((row) => {
    const rowNameIdIndex =
      row[dataNameIndex ?? -1] === "" ? "-1" : row[dataNameIndex ?? -1];
    return rowNameIdIndex === groupNameId;
  });

  const editedRowsObject = editedRowData?.detailsRows?.reduce((obj, item) => {
    obj[item.payrollReportDetailId] = item;
    return obj;
  }, {});
  return detailRows.map((row) => {
    const detailId = getRowValueByColumnKey(
      row,
      columns,
      "PayrollReportDetailId"
    );
    return editedRowsObject?.[detailId] === undefined
      ? row
      : updateDetailsRow(
          row,
          columns,
          ["NumEE", "NetPayroll", "Amount"],
          editedRowsObject?.[detailId]
        );
  });
};

export const getDetailRowsFromTemp = (
  detailsRowsTemp: PayrollReportDetailRowDto[],
  existingArray: PayrollReportDetailRowDto[]
) => {
  if (existingArray?.length === 0) return detailsRowsTemp;

  detailsRowsTemp.forEach((editedRowDetail) => {
    // Check if object with the same id already exists in the array
    const existingObjIndex = existingArray.findIndex(
      (obj) =>
        obj.payrollReportDetailId === editedRowDetail.payrollReportDetailId
    );

    if (existingObjIndex !== -1) {
      // Object with the same id exists, update it
      existingArray[existingObjIndex] = {
        ...existingArray[existingObjIndex],
        ...editedRowDetail,
      };
    } else {
      // Object doesn't exist, add it to the array
      existingArray.push(editedRowDetail);
    }
  });
  return existingArray;
};
