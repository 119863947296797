import { useState, useEffect, useCallback } from "react";

export function useHasScroll(elementRef) {
  const [scrollState, setScrollState] = useState({
    vertical: false,
    horizontal: false,
    hasScroll: false,
  });

  const checkScroll = useCallback(() => {
    const element = elementRef.current;
    if (!element) return;

    const vertical = element.scrollHeight > element.clientHeight;
    const horizontal = element.scrollWidth > element.clientWidth;

    setScrollState({
      vertical,
      horizontal,
      hasScroll: vertical || horizontal,
    });
  }, [elementRef]);

  useEffect(() => {
    checkScroll();

    window.addEventListener("resize", checkScroll);

    const resizeObserver = new ResizeObserver(checkScroll);
    if (elementRef.current) {
      resizeObserver.observe(elementRef.current);
    }

    return () => {
      window.removeEventListener("resize", checkScroll);
      resizeObserver.disconnect();
    };
  }, [checkScroll]);

  return scrollState;
}
