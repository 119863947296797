import { FC, useEffect, useState } from "react";
import { InsuredInformationContactDto } from "../../../../dtos/insured-information-contact-dto";
import { StatusEnums } from "../../../../dtos/status-enums";
import { BaseTableInputType } from "../../../../dtos/base-table-input-type";
import { useBaseGrid } from "../../../BaseGrid/Hooks/useBaseGrid";
import BaseGrid from "../../../BaseGrid/BaseGrid";
import {
  BaseGridComputeForCellParameters,
  BaseGridConditionForCellResponse,
  BaseGridProperties,
} from "../../../BaseGrid/BaseGridProperties";
import BaseGridDropDownFilter from "../../../BaseGrid/BaseGridCommonFilters/BaseGridDropDownFilter";
import { usePermissions } from "../../../../hooks";
import { PermissionsEnums } from "../../../../dtos/permissions-enums";
import { OptionsContextActionParameterProperties } from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import IconButton from "../../../TrueUI/Buttons/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { ModalConfigurationInsuredContactsProps } from "./TypesInsuredContacts";
import ModalInsuredContacts from "./ModalInsuredContacts";
import { BaseGridInternalColumnTypeEnum } from "../../../../dtos/base-grid-internal-column-type-enum";

const tableName = "insured_information_contacts_table";

const INSURED_INFORMATION_API_PATH = "api/insuredinformation";

type InsuredInformationContactsType = {
  insuredId: number;
};

const InsuredInformationContacts: FC<InsuredInformationContactsType> = ({
  insuredId,
}) => {
  const [modalConfiguration, setModalConfiguration] =
    useState<ModalConfigurationInsuredContactsProps>({
      open: false,
      contact: null,
      refreshTable: false,
      action: "add",
      insuredId,
      showInactivePrimaryContactConfirmation: false,
      showDeletePrimaryContactConfirmation: false,
    });
  // on init contact status
  const onContactStatusInit = (
    options: BaseGridComputeForCellParameters<InsuredInformationContactDto>
  ): BaseGridConditionForCellResponse | void => {
    if (options.castedRow?.PrimaryContact === true) {
      // if this is the primary contact, the status gets disabled
      return {
        value: options?.currentValue,
        typeId: BaseTableInputType.READONLY_SELECT,
      };
    } else
      return {
        value: options?.currentValue,
      };
  };

  //on init primary contact
  const onPrimaryContactInit = (
    options: BaseGridComputeForCellParameters<InsuredInformationContactDto>
  ): BaseGridConditionForCellResponse | void => {
    if (options.castedRow?.ContactStatus === StatusEnums.INACTIVE) {
      // if the contact is inactive, the primary checkbox gets disabled
      return {
        value: options?.currentValue,
        typeId: BaseTableInputType.READONLY_CHECKBOX,
      };
    } else {
      return {
        value: options?.currentValue,
      };
    }
  };
  const hasPermission = usePermissions([
    PermissionsEnums.UNDERWRITING_QUOTE,
    PermissionsEnums.UNDERWRITTER,
    PermissionsEnums.PROGRAM_ADMIN,
  ]).hasPermission;

  useEffect(() => {
    if (modalConfiguration?.refreshTable === true) {
      manuallyReloadParameters();
      setModalConfiguration({ ...modalConfiguration, refreshTable: false });
    }
  }, [modalConfiguration?.refreshTable]);

  const getContactObjectFromRow = (row: any) => {
    return {
      insuredId: row?.InsuredId,
      tenantId: row?.TenantId,
      contactId: row?.ContactId,
      insuredContactId: row?.InsuredContactId,
      contactFirstName: row?.ContactFirstName,
      contactLastName: row?.ContactLastName,
      contactMiddle: row?.ContactMiddle,
      contactTitle: row?.ContactTitle,
      contactStatus: row?.ContactStatus,
      primaryContact: row?.PrimaryContact,
      primaryPhone: row?.PrimaryPhone,
      primaryPhoneAdd: row?.PrimaryPhoneAdd,
      secondaryPhone: row?.SecondaryPhone,
      secondaryPhoneAdd: row?.SecondaryPhoneAdd,
      fax: row?.Fax,
      email: row?.Email,
      contactTag: row?.ContactTag,
    };
  };

  const editIcon = (
    actionOptions: OptionsContextActionParameterProperties<any>
  ) => (
    <IconButton
      aria-label="expand row"
      onClick={() =>
        setModalConfiguration({
          ...modalConfiguration,
          open: true,
          contact: getContactObjectFromRow(actionOptions.row),
          refreshTable: false,
          action: "edit",
        })
      }
    >
      <EditIcon fontSize="small" />
    </IconButton>
  );

  const tableConfiguration: BaseGridProperties = {
    name: tableName,
    getURL: `${INSURED_INFORMATION_API_PATH}/GetInsuredInformationContacts?insuredId=${insuredId}`,
    filterOptions: [
      (options) =>
        BaseGridDropDownFilter({
          options,
          id: "status-filter",
          columnNames: ["ContactStatus"],
          defaultValue: StatusEnums.ACTIVE.toString(),
        }),
    ],
    useManuallyReloadParameters: true,
    columnOptions: [
      {
        fieldName: "ContactLastName",
        width: "15",
      },
      {
        fieldName: "ContactFirstName",
        width: "15",
      },
      {
        fieldName: "ContactTitle",
        width: "15",
      },
      {
        fieldName: "Email",
        width: "25",
      },
      {
        fieldName: "ContactTag",
        width: "25",
      },
      {
        fieldName: "PrimaryPhone",
        width: "15",
      },
      {
        fieldName: "ContactStatus",
        width: "8",
        computeOnInit: (options) => onContactStatusInit(options),
      },
      {
        fieldName: "PrimaryContact",
        width: "8",
        computeOnInit: (options) => onPrimaryContactInit(options),
      },
      {
        fieldName:
          BaseGridInternalColumnTypeEnum[
            BaseGridInternalColumnTypeEnum.OPTIONS
          ],
        width: 3,
      },
    ],
    advancedOptions: {
      overrideColumnDefaultValues: [
        {
          columnFieldName: "InsuredId",
          propName: "defaultValue",
          value: insuredId,
        },
      ],
      optionsColumnConfiguration: {
        isHidden: !hasPermission,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: editIcon,
      },
    },
    toolbarOptions: {
      showSortFilter: false,
      showImportButton: false,
      showAddButton: hasPermission,
      showEditButton: false,
      addButtonText: "Add Contact",
    },
    events: {
      addEventOverrideCallback: () => {
        setModalConfiguration({
          ...modalConfiguration,
          open: true,
          contact: {
            contactId: 0,
            tenantId: 0,
            insuredId: insuredId,
            insuredContactId: 0,
            contactStatus: StatusEnums.ACTIVE,
          },
          refreshTable: false,
          action: "add",
        });
      },
    },
  };

  const { manuallyReloadParameters } = useBaseGrid(tableConfiguration);
  return (
    <>
      <BaseGrid name={tableName} />
      <ModalInsuredContacts
        modalConfiguration={modalConfiguration}
        setModalConfiguration={setModalConfiguration}
      ></ModalInsuredContacts>
    </>
  );
};

export default InsuredInformationContacts;
