import { FC, useEffect, useState } from "react";
import { Box } from "@mui/material";
import {
  Col,
  FontBold,
  Input,
  InputAddress,
  InputDate,
  InputPhone,
  InputTax,
  Row,
  Select,
} from "../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { colWithNoMarginNorGutter } from "../../../TrueUI/Grids/Col";
import { insuredInformationInputAddressStyles } from "./InsuredInformationStyles";
import { SelectOptions } from "../../../../dtos/select-options";
import {
  getProgramsAsOptions,
  getTaxIdType,
  onChangeInsuredTaxId,
  onChangeProgram,
} from "./InsuredInformationUtils";
import { PolicyProgramBlob } from "../../../../dtos/policy-program-blob";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { PolicyBlob } from "../../../../dtos/policy-blob";
import { acord130FormProperties } from "../../../../GlobalAtoms";
import { PolicyInsuredBlob } from "../../../../dtos/policy-insured-blob";
import {
  conditionHasValue,
  isEmptyValue,
} from "../../../../utilities/conditionalSupportFunctions";
import { NewSubmissionFormUIProps } from "../../../Dashboard/QuickActions/ModalNewSubmission";
import { phoneMask } from "../../../../utilities/stringFunctions";
import { StatusEnums } from "../../../../dtos/status-enums";
import { PolicyAssignmentBlob } from "../../../../dtos/policy-assignment-blob";

type insuredInfoInsuredProps = {
  selectedProgram: number;
  programs: PolicyProgramBlob[];
  underwriterOptions: SelectOptions[];
  businessTypeOptions: SelectOptions[];
  assignmentTypeOptions: SelectOptions[];
  assigneeTypeOptions: SelectOptions[];
  newSubmissionForm: NewSubmissionFormUIProps;
  errorDetails?: { [key: string]: any };
  userId: number;
};

const InsuredInformationInsured: FC<insuredInfoInsuredProps> = ({
  programs,
  selectedProgram,
  underwriterOptions,
  businessTypeOptions,
  assignmentTypeOptions,
  assigneeTypeOptions,
  newSubmissionForm,
  errorDetails,
  userId,
}) => {
  const readOnly = false;
  const [program, setProgram] = useState<PolicyProgramBlob | null>();
  const [insured, setInsured] = useState<PolicyInsuredBlob | null>();

  const { getAtom, setAtom } = useAtomFamily<PolicyBlob | null>(
    acord130FormProperties
  );

  const updateInsuredItem = (
    value: string,
    target: string,
    isPhone: boolean = false
  ) => {
    if (target === "underwriterId") {
      const atomValue = getAtom();
      const policyId = atomValue?.policyID ?? 0;
      const underwriter = underwriterOptions.find(
        (option) => option.intValue?.toString() === value
      );
      const underwriterType =
        underwriter?.iconName === "tell-a-friend" ? "Team" : "User";
      const assignment = atomValue?.assignment;
      const assigneeType = assigneeTypeOptions.find(
        (assignee) => assignee.displayName === underwriterType
      );
      const assignmentType = assignmentTypeOptions.find(
        (assignment) => assignment.displayName === "Policy"
      );
      const assignmentUpdated: PolicyAssignmentBlob = {
        assignmentId: assignment?.assignmentId ?? 0,
        assignmentType: assignmentType?.intValue,
        assignmentForeignId: policyId,
        assigneeType: assigneeType?.intValue,
        assigneeId: parseInt(value),
        assigneeRole: "Underwriter",
        assignmentStatus: StatusEnums.ACTIVE,
        primary: 1,
      };

      const newAtomValueAssignment = {
        ...atomValue,
        assignment: assignmentUpdated,
      } as PolicyBlob;

      setAtom(newAtomValueAssignment);
    }
    if (isPhone) {
      const NoFormatTarget = target + "NoFormat";
      setInsured({
        ...insured,
        [target]: phoneMask(value),
        [NoFormatTarget]: value,
      });
    } else {
      setInsured({
        ...insured,
        [target]: value,
      });
    }
  };

  useEffect(() => {
    const atomValue = getAtom();
    const assigneeByUserId = underwriterOptions.find(
      (underwiter) => underwiter.intValue === userId
    );
    onChangeProgram(selectedProgram, programs, setProgram);
    if (!isEmptyValue(atomValue?.insured?.insuredID)) {
      setInsured(atomValue?.insured);
    } else {
      setInsured({
        insuredName: newSubmissionForm.companyName,
        legalName: newSubmissionForm.companyName,
        taxID: newSubmissionForm.taxId,
        taxIDType: newSubmissionForm.taxIdType,
        underwriterID: assigneeByUserId?.intValue,
      });
    }
  }, []);

  useEffect(() => {
    const atomValue = getAtom();
    if (conditionHasValue(program) && conditionHasValue(insured)) {
      setAtom({
        ...atomValue,
        program: program,
        insured: insured,
      });
      if (
        isEmptyValue(insured?.addresses) &&
        !isEmptyValue(atomValue?.insured?.addresses)
      ) {
        setInsured((prev) => ({
          ...prev,
          addresses: atomValue?.insured?.addresses,
        }));
      }
      if (
        isEmptyValue(insured?.contacts) &&
        !isEmptyValue(atomValue?.insured?.contacts)
      ) {
        setInsured((prev) => ({
          ...prev,
          contacts: atomValue?.insured?.contacts,
        }));
      }
      if (
        isEmptyValue(insured?.officers) &&
        !isEmptyValue(atomValue?.insured?.officers)
      ) {
        setInsured((prev) => ({
          ...prev,
          officers: atomValue?.insured?.officers,
        }));
      }
      if (
        isEmptyValue(insured?.names) &&
        !isEmptyValue(atomValue?.insured?.names)
      ) {
        setInsured((prev) => ({
          ...prev,
          names: atomValue?.insured?.names,
        }));
      }
    }
  }, [program, insured]);

  return (
    <Row {...rowWithNoMarginNorGutter} rowDirection="column">
      <Col
        {...colWithNoMarginNorGutter}
        horizontalAlign="flex-start"
        verticalGutter="10px"
      >
        <FontBold>INSURED</FontBold>
      </Col>
      <Col {...colWithNoMarginNorGutter} horizontalAlign="flex-start">
        <Row {...rowWithNoMarginNorGutter} horizontalAlign="flex-start">
          <Col
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 5, lg: 5, xl: 5 }}
            horizontalAlign="flex-start"
          >
            <Select
              tabIndex={5}
              id="id-program-input"
              name="name-program-input"
              label="Program"
              labelFontType="BOLD_CAPTION"
              inputFontType="BODY"
              variant="filled"
              value={program?.programID}
              options={getProgramsAsOptions(programs)}
              onChange={(value) => onChangeProgram(value, programs, setProgram)}
              readOnly={false}
              firstOptionAsDefault={false}
              optionsMaxHeight="200px"
              disabled
            />
          </Col>
          <Col
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 3, lg: 3, xl: 3 }}
            horizontalAlign="flex-start"
            horizontalGutter="25px"
          >
            <Select
              tabIndex={6}
              id="id-underwriter-input"
              name="name-underwriter-input"
              label="Underwriter"
              labelFontType="BOLD_CAPTION"
              inputFontType="BODY"
              variant="filled"
              value={insured?.underwriterID ?? ""}
              options={underwriterOptions}
              onChange={(value) => updateInsuredItem(value, "underwriterId")}
              readOnly={readOnly}
              firstOptionAsDefault={false}
              optionsMaxHeight="200px"
              errorMessage={errorDetails?.insured?.underwriterId}
            />
          </Col>
        </Row>
      </Col>
      <Col
        {...colWithNoMarginNorGutter}
        horizontalAlign="flex-start"
        verticalGutter="10px"
      >
        <Row {...rowWithNoMarginNorGutter} horizontalAlign="flex-start">
          <Col
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 5, lg: 5, xl: 5 }}
            horizontalAlign="flex-start"
          >
            <Input
              tabIndex={7}
              id="id-insured-name-input"
              name="name-insured-name-input"
              label="Insured Name"
              labelFontType="BOLD_CAPTION"
              inputFontType="BODY"
              variant="filled"
              value={insured?.insuredName ?? ""}
              onChangeRawValue={(value) =>
                updateInsuredItem(value, "insuredName")
              }
              maxLength={300}
              readOnly={readOnly}
              errorMessage={errorDetails?.insured?.insuredName}
            />
          </Col>
          <Col
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 5, lg: 5, xl: 5 }}
            horizontalAlign="flex-start"
            horizontalGutter="25px"
          >
            <Input
              tabIndex={8}
              id="id-dba-input"
              name="name-dba-input"
              label="DBA"
              labelFontType="BOLD_CAPTION"
              inputFontType="BODY"
              variant="filled"
              value={insured?.dba ?? ""}
              onChangeRawValue={(value) => updateInsuredItem(value, "dba")}
              maxLength={300}
              readOnly={readOnly}
              errorMessage={errorDetails?.insured?.dba}
            />
          </Col>
        </Row>
      </Col>
      <Col
        {...colWithNoMarginNorGutter}
        breakpoints={{ md: 5, lg: 5, xl: 5 }}
        horizontalAlign="flex-start"
      >
        <Input
          tabIndex={9}
          id="id-legal-name-input"
          name="name-legal-name-input"
          label="Legal Name"
          labelFontType="BOLD_CAPTION"
          inputFontType="BODY"
          variant="filled"
          value={insured?.legalName ?? ""}
          onChangeRawValue={(value) => updateInsuredItem(value, "legalName")}
          maxLength={300}
          readOnly={readOnly}
          errorMessage={errorDetails?.insured?.legalName}
        />
      </Col>
      <Col
        {...colWithNoMarginNorGutter}
        breakpoints={{ md: 5, lg: 5, xl: 5 }}
        horizontalAlign="flex-start"
        verticalGutter="10px"
      >
        <Row
          {...rowWithNoMarginNorGutter}
          numberOfColumns={23}
          horizontalAlign="space-between"
        >
          <Col
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 11, lg: 11, xl: 11 }}
            horizontalAlign="flex-start"
          >
            <InputPhone
              tabIndex={10}
              id="id-phone-number-input"
              name="name-phone-number-input"
              phoneLabel="Phone Number"
              labelFontType="BOLD_CAPTION"
              inputFontType="BODY"
              variant="filled"
              phoneValue={insured?.phoneNoFormat ?? ""}
              extValue={insured?.phoneAdd ?? ""}
              onChangePhoneValue={(value) =>
                updateInsuredItem(value, "phone", true)
              }
              onChangeExtensionValue={(value) =>
                updateInsuredItem(value, "phoneAdd")
              }
              errorMessagePhone={errorDetails?.insured?.phone}
              hasExtension={true}
            />
          </Col>
          <Col
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 11, lg: 11, xl: 11 }}
            horizontalAlign="flex-start"
          >
            <InputPhone
              tabIndex={11}
              id="id-fax-number-input"
              name="name-fax-number-input"
              phoneLabel="Fax Number"
              labelFontType="BOLD_CAPTION"
              inputFontType="BODY"
              variant="filled"
              phoneValue={insured?.faxNoFormat ?? ""}
              onChangePhoneValue={(value) =>
                updateInsuredItem(value, "fax", true)
              }
              errorMessagePhone={errorDetails?.insured?.fax}
            />
          </Col>
        </Row>
      </Col>
      <Col
        {...colWithNoMarginNorGutter}
        breakpoints={{ md: 5, lg: 5, xl: 5 }}
        horizontalAlign="flex-start"
      >
        <Box sx={insuredInformationInputAddressStyles()}>
          <InputAddress
            tabIndex={12}
            id="id-mailing-address-input"
            name="name-mailing-address-input"
            label="Mailing Address"
            readOnly={readOnly}
            showSingleLabel={false}
            labelFontType="BOLD_CAPTION"
            inputFontType="BODY"
            variant="filled"
            initValueAddress1={insured?.mailingAddress1 ?? ""}
            initValueAddress2={insured?.mailingAddress2 ?? ""}
            initValueCity={insured?.mailingCity ?? ""}
            initValueStateCode={insured?.mailingState ?? ""}
            initValuePostCode={insured?.mailingPostalCode ?? ""}
            onChangeValueAddress1={(value) =>
              updateInsuredItem(value, "mailingAddress1")
            }
            onChangeValueAddress2={(value) =>
              updateInsuredItem(value, "mailingAddress2")
            }
            onChangeValueCity={(value) =>
              updateInsuredItem(value, "mailingCity")
            }
            onChangeStateCode={(value) => {
              if (!isEmptyValue(value))
                updateInsuredItem(value, "mailingState");
            }}
            onChangeValuePostCode={(value) =>
              updateInsuredItem(value, "mailingPostalCode")
            }
            address1ErrorMessage={errorDetails?.insured?.mailingAddress1}
            address2ErrorMessage={errorDetails?.insured?.mailingAddress2}
            cityErrorMessage={errorDetails?.insured?.mailingCity}
            postCodeErrorMessage={errorDetails?.insured?.mailingPostalCode}
            isFirstStateCodeAsDefault={false}
          />
        </Box>
      </Col>
      <Col
        {...colWithNoMarginNorGutter}
        horizontalAlign="flex-start"
        verticalGutter="10px"
      >
        <Row {...rowWithNoMarginNorGutter} horizontalAlign="flex-start">
          <Col
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 5, lg: 5, xl: 5 }}
            horizontalAlign="flex-start"
          >
            <Row
              {...rowWithNoMarginNorGutter}
              numberOfColumns={23}
              horizontalAlign="space-between"
            >
              <Col
                {...colWithNoMarginNorGutter}
                breakpoints={{ md: 11, lg: 11, xl: 11 }}
                horizontalAlign="flex-start"
              >
                <InputDate
                  tabIndex={18}
                  id="id-date-opened-input"
                  name="name-date-opened-input"
                  label="Date Opened"
                  labelFontType="BOLD_CAPTION"
                  inputFontType="BODY"
                  variant="filled"
                  value={insured?.dateBusinessStarted}
                  onChangeRawValue={(value) =>
                    updateInsuredItem(value, "dateBusinessStarted")
                  }
                  readOnly={readOnly}
                />
              </Col>
              <Col
                {...colWithNoMarginNorGutter}
                breakpoints={{ md: 11, lg: 11, xl: 11 }}
                horizontalAlign="flex-start"
              >
                <Input
                  tabIndex={19}
                  id="id-sic-input"
                  name="name-sic-input"
                  label="SIC"
                  labelFontType="BOLD_CAPTION"
                  inputFontType="BODY"
                  type="number"
                  thousandSeparator={false}
                  variant="filled"
                  value={insured?.sic ?? ""}
                  onChangeRawValue={(value) =>
                    updateInsuredItem(value?.toString(), "sic")
                  }
                  maxLength={5}
                  decimalScale={0}
                  readOnly={readOnly}
                  errorMessage={errorDetails?.insured?.sic}
                />
              </Col>
            </Row>
          </Col>
          <Col
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 2, lg: 2, xl: 2 }}
            horizontalAlign="flex-start"
            horizontalGutter="25px"
          >
            <Input
              tabIndex={20}
              id="id-naics-input"
              name="name-naics-input"
              type="number"
              label="NAICS"
              labelFontType="BOLD_CAPTION"
              inputFontType="BODY"
              variant="filled"
              thousandSeparator={false}
              value={insured?.naics ?? ""}
              onChangeRawValue={(value) =>
                updateInsuredItem(value?.toString(), "naics")
              }
              maxLength={7}
              decimalScale={0}
              readOnly={readOnly}
              errorMessage={errorDetails?.insured?.naics}
            />
          </Col>
        </Row>
      </Col>
      <Col
        {...colWithNoMarginNorGutter}
        horizontalAlign="flex-start"
        verticalGutter="10px"
      >
        <Row {...rowWithNoMarginNorGutter} horizontalAlign="flex-start">
          <Col
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 5, lg: 5, xl: 5 }}
            horizontalAlign="flex-start"
          >
            <Input
              tabIndex={21}
              id="id-website-url-input"
              name="name-website-url-input"
              label="Website URL"
              labelFontType="BOLD_CAPTION"
              inputFontType="BODY"
              variant="filled"
              value={insured?.websiteURL ?? ""}
              onChangeRawValue={(value) =>
                updateInsuredItem(value, "websiteURL")
              }
              maxLength={100}
              readOnly={readOnly}
              errorMessage={errorDetails?.insured?.websiteURL}
            />
          </Col>
          <Col
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 5, lg: 5, xl: 5 }}
            horizontalAlign="flex-start"
            horizontalGutter="25px"
          >
            <Input
              tabIndex={22}
              id="id-email-address-input"
              name="name-email-address-input"
              label="Email Address"
              labelFontType="BOLD_CAPTION"
              inputFontType="BODY"
              variant="filled"
              type="email"
              value={insured?.emailAddress ?? ""}
              onChangeRawValue={(value) =>
                updateInsuredItem(value, "emailAddress")
              }
              maxLength={100}
              readOnly={readOnly}
              errorMessage={errorDetails?.insured?.emailAddress}
            />
          </Col>
        </Row>
      </Col>
      <Col
        {...colWithNoMarginNorGutter}
        horizontalAlign="flex-start"
        verticalGutter="10px"
      >
        <Row {...rowWithNoMarginNorGutter} horizontalAlign="flex-start">
          <Col
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 5, lg: 5, xl: 5 }}
            horizontalAlign="flex-start"
          >
            <Row
              {...rowWithNoMarginNorGutter}
              numberOfColumns={23}
              horizontalAlign="space-between"
            >
              <Col
                {...colWithNoMarginNorGutter}
                breakpoints={{ md: 11, lg: 11, xl: 11 }}
                horizontalAlign="flex-start"
              >
                <Select
                  tabIndex={23}
                  id="id-business-type-input"
                  name="name-business-type-input"
                  label="Business Type"
                  labelFontType="BOLD_CAPTION"
                  inputFontType="BODY"
                  variant="filled"
                  value={insured?.businessType}
                  options={businessTypeOptions}
                  onChange={(value) => updateInsuredItem(value, "businessType")}
                  readOnly={readOnly}
                  firstOptionAsDefault={false}
                  optionsMaxHeight="150px"
                  errorMessage={errorDetails?.insured?.businessType}
                />
              </Col>
              <Col
                {...colWithNoMarginNorGutter}
                breakpoints={{ md: 11, lg: 11, xl: 11 }}
                horizontalAlign="flex-start"
              >
                {!isEmptyValue(insured) && (
                  <InputTax
                    tabIndex={24}
                    id="id-tax-id-input"
                    name="name-tax-id-input"
                    label="Tax ID"
                    labelFontType="BOLD_CAPTION"
                    inputFontType="BODY"
                    variant="filled"
                    value={insured?.taxID ?? ""}
                    onChangeRaw={({ value, type }) =>
                      onChangeInsuredTaxId(value, type, insured, setInsured)
                    }
                    readOnly={readOnly}
                    initialType={getTaxIdType(insured?.taxIDType)}
                    errorMessage={errorDetails?.insured?.taxID}
                  />
                )}
              </Col>
            </Row>
          </Col>
          <Col
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 5, lg: 5, xl: 5 }}
            horizontalAlign="flex-start"
            horizontalGutter="25px"
          >
            <Row
              {...rowWithNoMarginNorGutter}
              numberOfColumns={23}
              horizontalAlign="space-between"
            >
              <Col
                {...colWithNoMarginNorGutter}
                breakpoints={{ md: 11, lg: 11, xl: 11 }}
                horizontalAlign="flex-start"
              >
                <Input
                  tabIndex={25}
                  id="id-risk-id-input"
                  name="name-risk-id-input"
                  type="riskid"
                  label="Risk ID"
                  labelFontType="BOLD_CAPTION"
                  inputFontType="BODY"
                  variant="filled"
                  value={insured?.riskID ?? ""}
                  onChangeRawValue={(value) =>
                    updateInsuredItem(value?.toString(), "riskID")
                  }
                  maxLength={20}
                  readOnly={readOnly}
                  errorMessage={errorDetails?.insured?.riskID}
                />
              </Col>
              <Col
                {...colWithNoMarginNorGutter}
                breakpoints={{ md: 11, lg: 11, xl: 11 }}
                horizontalAlign="flex-start"
              >
                <Input
                  tabIndex={26}
                  id="id-state-id-input"
                  name="name-state-id-input"
                  type="number"
                  label="State ID"
                  labelFontType="BOLD_CAPTION"
                  inputFontType="BODY"
                  variant="filled"
                  thousandSeparator={false}
                  value={insured?.stateID ?? ""}
                  onChangeRawValue={(value) =>
                    updateInsuredItem(value?.toString(), "stateID")
                  }
                  maxLength={12}
                  decimalScale={0}
                  readOnly={readOnly}
                  errorMessage={errorDetails?.insured?.stateID}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default InsuredInformationInsured;
