import { CancelReasonDto } from "../../../../dtos/cancel-reason-dto";
import { InsertedCancellationDto } from "../../../../dtos/inserted-cancellation-dto";
import { CancellationDataDto } from "../../../../dtos/cancellation-data-dto";
import { PolicyDatesDto } from "../../../../dtos/policy-dates-dto";
import { SelectOptions } from "../../../../dtos/select-options";

export type ModalAddCancellationProps = {
  cancellationModalConfig: CancellationModalProps;
  setCancellationModalConfig: (value: CancellationModalProps) => void;
  updateRowIntoTable: (
    action: "add" | "update" | "delete",
    value: InsertedCancellationDto | null
  ) => void;
};

export type ModalAddCancellationContentProps = {
  insuredId: number;
  cancellationId: null | number;
  onSaveCancellationEvent: (value: string) => void;
  onCancelEvent: (value: boolean) => void;
  onDeleteEvent: () => void;
  onReinstateOrRescindEvent: () => void;
  errorMessages?: any;
  cancellationData?: CancellationDataDto | Partial<CancellationDataDto> | null;
  setCancellationData?: (
    value?: CancellationDataDto | Partial<CancellationDataDto>
  ) => void;
};
export type PolicyCancellationTableProps = {
  insuredId: number;
};

export type CancellationModalProps = {
  insuredId: number;
  cancellationId: number;
  openCancellationModal: boolean;
  action: "add" | "update" | "delete";
  rowData?: any;
  type: "insured" | "billing";
};

export const SAVE_AS_CANCELLED_ACTION = "save-as-cancelled";
export const SAVE_ONLY_ACTION = "save-only";

export const defaultCancellationModalProps: CancellationModalProps = {
  insuredId: 0,
  cancellationId: 0,
  openCancellationModal: false,
  action: "add",
  type: "insured",
};

export const getSplitButtonOptions = (
  onSaveCancellationEvent: (option: string) => void
) => {
  return [
    {
      key: 1,
      option: "SAVE AS CANCELLED",
      dropdownText: "Save as Cancelled",
      action: () => onSaveCancellationEvent(SAVE_AS_CANCELLED_ACTION),
    },
    {
      key: 2,
      option: "SAVE ONLY",
      dropdownText: "Save Only",
      action: () => onSaveCancellationEvent(SAVE_ONLY_ACTION),
    },
  ];
};

export const getPolicyEffectiveDayByPolicyId = (
  newPolicyId: number,
  policyDates: PolicyDatesDto[]
) => {
  const item = policyDates.find((x) => x.policyId === newPolicyId);
  return item?.effectiveDate;
};

export const getPolicyExpirationDayByPolicyId = (
  newPolicyId: number,
  policyDates: PolicyDatesDto[]
) => {
  const item = policyDates.find((x) => x.policyId === newPolicyId);
  return item?.expirationDate;
};

export const getPolicyNumberByPolicyId = (
  newPolicyId: number,
  policyList: SelectOptions[]
) => {
  const item = policyList.find((x) => x.intValue === newPolicyId);
  return item?.displayName;
};

export const getPolicyDatesByPolicyNumber = (
  policyEffectiveDate?: string | null,
  policyExpirationDate?: string | null
) => {
  return policyEffectiveDate !== null && policyEffectiveDate !== undefined
    ? `${policyEffectiveDate} - ${policyExpirationDate}`
    : "";
};

export const showFieldByReasonCancelCode = (
  cancelReasons: CancelReasonDto[],
  selectedCancelReasonId?: number | null
) => {
  if (selectedCancelReasonId === null || selectedCancelReasonId === undefined)
    return false;
  const cancelCodeByReasonId = cancelReasons.find(
    (x) => x.cancelReasonId === selectedCancelReasonId
  )?.cancelCode;
  const nonpaymentOfPremiumCancelCode = "05";
  return cancelCodeByReasonId === nonpaymentOfPremiumCancelCode;
};

export const getPropertyByCancelReasonId = (
  selectedCancelReasonId: number,
  cancelReasons: CancelReasonDto[],
  propertyName: string
) =>
  cancelReasons.find((x) => x.cancelReasonId === selectedCancelReasonId)?.[
    propertyName
  ];
