import { FC, useState } from "react";
import { DocumentsSplitterProps } from "./DocumentsTypes";
import { Splitter, SplitterOnChangeEvent } from "@progress/kendo-react-layout";
import style from "./DocumentsStyles.module.css";
import { ProgramPolicyDocumentDto } from "../../../../../dtos/program-policy-document-dto";
import { ProgramPacketDto } from "../../../../../dtos/program-packet-dto";
import DocumentPackets from "./DocumentPackets";
import DocumentSelectedPackets from "./DocumentSelectedPackets";
import DocumentsIndividual from "./DocumentsIndividual";

const DocumentsSplitter: FC<DocumentsSplitterProps> = ({
  tabKey,
  policyArea,
  documentsUI,
  setDocumentsUI,
}) => {
  const [panes, setPanes] = useState<Array<any>>([
    { size: "25%", min: "15%", max: "30%" },
    { min: "40%", max: "70%" },
    { size: "30%", min: "20%", max: "40%" },
  ]);

  const onChangeSplitterPanes = (event: SplitterOnChangeEvent) => {
    setPanes(event.newState);
  };

  const getSelectedPacketsUpdatedByPacketChanged = (
    programPacket: ProgramPacketDto,
    checked: boolean
  ) => {
    if (checked === false) {
      return documentsUI.selectedPackets.filter(
        (selectedPacket) => selectedPacket.packetId !== programPacket.packetId
      );
    } else {
      return [...documentsUI.selectedPackets, programPacket];
    }
  };

  const getSelectedPacketDocumentsUpdatedByPacketChange = (
    selectedPackets: ProgramPacketDto[]
  ) => {
    const selectedPacketIds = selectedPackets.flatMap(
      (selectedPacket) => selectedPacket.packetId
    );
    const packetDocumentsFiltered =
      documentsUI.allDocuments?.packetDocuments.filter((packetDocument) =>
        selectedPacketIds.includes(packetDocument.packetID)
      ) ?? [];

    const onlyDocuments = packetDocumentsFiltered.flatMap(
      (packetDocument) => packetDocument.programPolicyDocument
    );

    const noDuplicatedDocumentsMapped = new Map(
      onlyDocuments.map((programDocument) => [
        programDocument.programDocId,
        programDocument,
      ])
    );

    return [...noDuplicatedDocumentsMapped.values()];
  };

  const selectedIndividualDocumentsUpdatedByPacketChange = (
    selectedPacketDocumentsUpdated: ProgramPolicyDocumentDto[],
    checked: boolean
  ) => {
    if (checked === true) {
      return documentsUI.selectedDocuments.filter((selectedDocument) =>
        selectedPacketDocumentsUpdated.every(
          (selectedPacketDocument) =>
            selectedPacketDocument.programDocId !==
            selectedDocument.programDocId
        )
      );
    } else {
      return documentsUI.selectedDocuments;
    }
  };

  const onChangePacketSelected = (
    programPacketId: ProgramPacketDto,
    checked: boolean
  ) => {
    const selectedPacketsUpdated = getSelectedPacketsUpdatedByPacketChanged(
      programPacketId,
      checked
    );

    const selectedPacketDocumentsUpdated =
      getSelectedPacketDocumentsUpdatedByPacketChange(selectedPacketsUpdated);

    const selectedDocumentsUpdated =
      selectedIndividualDocumentsUpdatedByPacketChange(
        selectedPacketDocumentsUpdated,
        checked
      );

    setDocumentsUI({
      ...documentsUI,
      selectedPackets: selectedPacketsUpdated,
      selectedPacketDocuments: selectedPacketDocumentsUpdated,
      selectedDocuments: selectedDocumentsUpdated,
    });
  };

  const onChangeIndividualDocumentSelected = (
    programPolicyDocument: ProgramPolicyDocumentDto,
    checked: boolean
  ) => {
    if (checked === false) {
      const selectedDocumentsWithoutChangedDocument =
        documentsUI.selectedDocuments.filter(
          (selectedDocument) =>
            selectedDocument.programDocId !== programPolicyDocument.programDocId
        );

      setDocumentsUI({
        ...documentsUI,
        selectedDocuments: selectedDocumentsWithoutChangedDocument,
      });
    } else {
      const selectedDocumentsWithChangedDocument = [
        ...documentsUI.selectedDocuments,
        programPolicyDocument,
      ];

      setDocumentsUI({
        ...documentsUI,
        selectedDocuments: selectedDocumentsWithChangedDocument,
      });
    }
  };

  return (
    <Splitter
      className={style.documents_splitter}
      panes={panes}
      onChange={onChangeSplitterPanes}
    >
      <DocumentPackets
        programPackets={documentsUI.allDocuments?.packets ?? []}
        selectedPackets={documentsUI.selectedPackets}
        onChangePacketSelected={onChangePacketSelected}
      />
      <DocumentSelectedPackets
        tabKey={tabKey}
        policyArea={policyArea}
        selectedPacketDocuments={documentsUI.selectedPacketDocuments}
        customJSONToPrint={documentsUI.allDocuments?.customPolicyJSON ?? ""}
      />
      <DocumentsIndividual
        tabKey={tabKey}
        allProgramPolicyDocuments={
          documentsUI.allDocuments?.policyDocuments ?? []
        }
        policyArea={policyArea}
        selectedPacketDocuments={documentsUI.selectedPacketDocuments}
        selectedDocuments={documentsUI.selectedDocuments}
        onChangeIndividualDocumentSelected={onChangeIndividualDocumentSelected}
        customJSONToPrint={documentsUI.allDocuments?.customPolicyJSON ?? ""}
      />
    </Splitter>
  );
};
export default DocumentsSplitter;
