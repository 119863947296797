import { getColorWithOpacityHexaDecimal } from "../../../../media/themeConstants";
import { Theme } from "../../../../media/themeTypes";

export const endorsementFormsSplitterStyles = (theme: Theme) => {
  return {
    height: "100%",
    ".k-splitter": {
      height: "100%",
      backgroundColor: "transparent",
      borderColor: getColorWithOpacityHexaDecimal(theme.BORDER_COLOR, 50),
      ".k-splitbar": {
        backgroundColor: getColorWithOpacityHexaDecimal(theme.BORDER_COLOR, 50),
      },
      ".k-splitbar:focus": {
        background: theme.PRIMARY,
      },
    },
  };
};
