import { useEffect, useState } from "react";
import { RecoilState, useRecoilCallback, useRecoilValue } from "recoil";
import { BaseTableColumn } from "../../../../dtos/base-table-column";
import { useApiGet } from "../../../../hooks";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { tableInstanceAtomFamily2 } from "../TableAtoms";
import { NO_URL_PROVIDED_ERROR } from "../TableConstants";
import {
  addColumnIndexByArrayOrder,
  addInternalDataValuesToNewRows,
  appendCoreInternalColumns,
  debugShowInternalColumns,
  getSafeRowKey,
  OverridableInternalColumnValueProps,
  updateColumnHeaderSortOrder,
  updateColumnPropByName,
  updateInternalColumnValuesByConfiguration,
} from "../tableFunctions";
import { TableColumns, TableInstanceType2 } from "./TableProperties";
import { BaseGridSignatureEnum } from "../../../../dtos/base-grid-signature-enum";

const ProcessesIncomingData = (props) => {
  const [url, setURL] = useState<string | null>(null);
  const [columnsAndData, setColumnsAndData] = useState<{
    columns: BaseTableColumn[];
    data: string[][];
  } | null>(null);
  const [columnsAndGroups, setColumnsAndGroups] = useState<{
    columns: BaseTableColumn[];
    groups: any[];
  } | null>(null);
  const [reloadDynamicData, setReloadDynamicData] = useState(false);

  const tableInstance = useRecoilValue(
    tableInstanceAtomFamily2(props.uiid) as RecoilState<TableInstanceType2>
  );

  const { responseGet, dispatchGet } = useApiGet<any>(
    url ?? NO_URL_PROVIDED_ERROR
  );

  const setTableInstance = useRecoilCallback(
    ({ set }) =>
      (newValueTableInstance: TableInstanceType2) => {
        set(tableInstanceAtomFamily2(props.uiid), {
          ...tableInstance,
          ...newValueTableInstance,
          uiid: props.uiid,
        });
      },
    []
  );

  // TODO - this needs to be moved to tableFunctions
  const updateColumnsWithOverrodeValues = (columns: any[]): TableColumns => {
    if (
      tableInstance?.advancedOptions?.overrideColumnDefaultValues &&
      tableInstance?.advancedOptions?.overrideColumnDefaultValues?.length > 0
    ) {
      return updateColumnPropByName(
        tableInstance?.advancedOptions.overrideColumnDefaultValues,
        columns
      );
    }
    return columns;
  };

  const processColumnsAndData = (
    providedColumns: BaseTableColumn[],
    providedData: string[][]
  ): { columns: any[]; data: string[][] } | void => {
    const appendedInternalColumns = appendCoreInternalColumns(
      providedColumns,
      tableInstance?.advancedOptions?.optionsColumnConfiguration?.isHidden ??
        true,
      (tableInstance?.multiSelectOptions?.length ?? 0) === 0,
      tableInstance?.advancedOptions?.indicationColumnConfiguration?.isHidden ??
        true
    );

    const updatedTableColumnsIndexs = addColumnIndexByArrayOrder(
      appendedInternalColumns
    );
    const updatedTableColumns = updateColumnsWithOverrodeValues(
      updatedTableColumnsIndexs
    );

    const updatedColumnHeaderSortOrder =
      updateColumnHeaderSortOrder(updatedTableColumns);

    const addedInternalColumnValues =
      addInternalDataValuesToNewRows(providedData);

    const overridableConfiguration = {
      indicationType:
        tableInstance.advancedOptions?.indicationColumnConfiguration
          ?.indicationType,
      optionType:
        tableInstance.advancedOptions?.optionsColumnConfiguration?.optionType,
    } as OverridableInternalColumnValueProps;

    const updatedColumns = tableInstance._debug.showInternalColumnHeaders
      ? debugShowInternalColumns(updatedColumnHeaderSortOrder)
      : updatedColumnHeaderSortOrder;

    const updatedInternalOverridableColumnValues =
      updateInternalColumnValuesByConfiguration(
        updatedColumns,
        addedInternalColumnValues,
        overridableConfiguration,
        tableInstance.advancedOptions?.optionsColumnConfiguration
          ?.optionsStateCondition
      );

    const allRowKeys =
      updatedInternalOverridableColumnValues.map((d) => getSafeRowKey(d)) ?? [];

    if (
      responseGet.axiosResponse?.data.tableData.signature === "standard" ||
      tableInstance.tableType === "standard"
    ) {
      const newTableInstance: Partial<TableInstanceType2> = {
        ...tableInstance,

        columns: updatedColumns,
        data: updatedInternalOverridableColumnValues,
        allRowKeys: allRowKeys,
        isPreProcessingComplete: true,
        tableType: "standard",
      };

      setTableInstance(newTableInstance as TableInstanceType2);
    } else {
      return {
        columns: updatedColumns,
        data: updatedInternalOverridableColumnValues,
      };
    }
  };

  const groupProcessColumnsAndData = (columns: any[], groups: any[]) => {
    const appendedInternalColumns = appendCoreInternalColumns(
      columns,
      tableInstance?.advancedOptions?.optionsColumnConfiguration?.isHidden ??
        true,
      (tableInstance?.multiSelectOptions?.length ?? 0) === 0,
      tableInstance?.advancedOptions?.indicationColumnConfiguration?.isHidden ??
        true
    );

    const updatedTableColumnsIndexs = addColumnIndexByArrayOrder(
      appendedInternalColumns
    );

    const updatedTableColumns = updateColumnsWithOverrodeValues(
      updatedTableColumnsIndexs
    );

    const updatedColumnHeaderSortOrder =
      updateColumnHeaderSortOrder(updatedTableColumns);

    const updatedColumns = tableInstance._debug.showInternalColumnHeaders
      ? debugShowInternalColumns(updatedColumnHeaderSortOrder)
      : updatedColumnHeaderSortOrder;

    setTableInstance({
      ...tableInstance,
      columns: updatedColumns,
      groups: groups,
      isPreProcessingComplete: true,
      tableType: "multi",
    });
  };

  useEffect(() => {
    if (
      // conditionHasValue(url) &&
      url !== null &&
      url !== "" &&
      tableInstance.isPreProcessingComplete === false &&
      reloadDynamicData
    ) {
      dispatchGet(url ?? NO_URL_PROVIDED_ERROR);
      setReloadDynamicData(false);
    }
  }, [url, reloadDynamicData]);

  useEffect(() => {
    if (
      tableInstance.tableType === "multi" &&
      conditionHasValue(tableInstance.columnsAndGroups)
    ) {
      setColumnsAndGroups(tableInstance.columnsAndGroups ?? null);
    } else if (
      tableInstance?.columnsAndData?.data !== undefined &&
      tableInstance?.columnsAndData?.columns
    ) {
      setColumnsAndData(tableInstance?.columnsAndData ?? null);
    }
  }, []);

  useEffect(() => {
    if (columnsAndData?.data !== undefined && columnsAndData?.columns) {
      processColumnsAndData(
        columnsAndData?.columns ?? [],
        columnsAndData?.data ?? []
      );
    }
  }, [columnsAndData]);

  useEffect(() => {
    if (conditionHasValue(columnsAndGroups)) {
      groupProcessColumnsAndData(
        columnsAndGroups?.columns ?? [],
        columnsAndGroups?.groups ?? []
      );
    }
  }, [columnsAndGroups]);

  useEffect(() => {
    if (
      responseGet.axiosResponse !== null &&
      responseGet.axiosResponse?.data !== null
      // && tableInstance.data.length === 0
    ) {
      if (
        responseGet.axiosResponse?.data.tableData.signature ===
        BaseGridSignatureEnum.TABLE_SIGNATURE_STANDARD
      ) {
        processColumnsAndData(
          responseGet.axiosResponse?.data?.tableData?.columns ?? [],
          responseGet.axiosResponse?.data?.tableData?.data ?? []
        );
        tableInstance?.methods?.onRefresh?.(false);
      }
      if (
        responseGet.axiosResponse?.data.tableData.signature ===
        BaseGridSignatureEnum.TABLE_SIGNATURE_MULTI
      ) {
        groupProcessColumnsAndData(
          responseGet.axiosResponse?.data.tableData.columns,
          responseGet.axiosResponse?.data.tableData.groups
        );
      }
    }
  }, [responseGet]);

  useEffect(() => {
    if (
      conditionHasValue(tableInstance.getURL) &&
      tableInstance.getURL !== ""
    ) {
      setURL(tableInstance.getURL);
      setTableInstance({
        ...tableInstance,
        data: [],
        columns: [],
        allRowKeys: [],
        sortedAndFilteredData: [],
        isPreProcessingComplete: false,
      });
    }
  }, [tableInstance.getURL]);

  useEffect(() => {
    if (tableInstance.isPreProcessingComplete === false) {
      setReloadDynamicData(true);
    }
  }, [tableInstance.isPreProcessingComplete]);

  useEffect(() => {
    if (tableInstance._accessors?._refreshTable) {
      setURL(tableInstance.getURL);
      setTableInstance({
        ...tableInstance,
        getURL: "",
        data: [],
        columns: [],
        allRowKeys: [],
        sortedAndFilteredData: [],
        isPreProcessingComplete: false,
        _accessors: {
          _refreshTable: false,
        },
      });
    }
  }, [tableInstance._accessors?._refreshTable]);

  return null;
};

export default ProcessesIncomingData;
