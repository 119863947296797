import { useEffect, useState } from "react";
import { useApiGet } from "../../../hooks";
import { addAllOption } from "../../../utilities/selectFunctions";
import { INTERNAL_TABLE_COLUMNS_OBJ } from "../../TrueUI/Tables/TableConstants";
import IconButton from "../../TrueUI/Buttons/IconButton";
import OpenIcon from "@mui/icons-material/Launch";
import { useAtomFamily } from "../../../hooks/useAtomFamily";
import { globalOptions } from "../../../GlobalAtoms";
import { isAPITotallyComplete } from "../../../utilities/apiFunctions";
import { NoteCardConfigDto } from "../../../dtos/note-card-config-dto";
import {
  areDatesEquals,
  FormattingDate,
  GettingDateWithoutTime,
  subtractDays,
} from "../../../utilities/dateFunctions";
import { getQueryStringsURL } from "../../../utilities/URLUtilities_OBSOLETE";
import {
  DRAWER_NOTE_ID,
  DRAWER_TABS,
  OPEN_DRAWER,
} from "../../../utilities/queryStringsHash";
import { DrawerActionsEnums } from "../../Insured/InsuredDrawer/InsuredDrawerConstants";
import { useNavigate } from "react-router";
import BaseGridDropDownFilter from "../../BaseGrid/BaseGridCommonFilters/BaseGridDropDownFilter";
import { BaseGridProperties } from "../../BaseGrid/BaseGridProperties";
import { useBaseGrid } from "../../BaseGrid/Hooks/useBaseGrid";
import BaseGrid from "../../BaseGrid/BaseGrid";
import InternalInputDateFilter from "../../Billing/PaymentActivity/InternalInputDateFilter";
import BaseGridSearchFilter from "../../BaseGrid/BaseGridCommonFilters/BaseGridSearchFilter";

const NotesGrid = () => {
  const defaultToDate = new Date();
  const defaultFromDate = subtractDays(defaultToDate, 7);
  const formatDate = (date: Date) => FormattingDate(date, "YYYY-MM-DD");

  const [configuration, setConfiguration] =
    useState<NoteCardConfigDto | null>();

  const [dateFrom, setDateFrom] = useState<string>(formatDate(defaultFromDate));
  const [dateTo, setDateTo] = useState<string>(formatDate(defaultToDate));
  const { responseGet, dispatchGet } = useApiGet<NoteCardConfigDto>(
    "api/Dashboard/GetNotesConfiguration"
  );

  const { getAtom } = useAtomFamily(globalOptions);
  const currentUser = getAtom().userId;
  const [currentUserId, setCurrentUserId] = useState<string | null>(null);
  const [refreshTable, setRefreshTable] = useState<boolean>(false);
  const navigate = useNavigate();

  const name = "assigned_tasks_table";
  const getURL = `api/Dashboard/GetNotes?from=${dateFrom}&to=${dateTo}`;

  useEffect(() => {
    dispatchGet();
    setCurrentUserId(currentUser);
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setConfiguration(responseGet?.responseData ?? null);
      setRefreshTable(true);
    }
  }, [responseGet]);

  const actionsForRows = (rowData: any) => {
    return (
      <IconButton
        aria-label="expand row"
        onClick={() =>
          openEditNote(rowData.row?.InsuredOrClaimantLink, rowData.row?.NoteId)
        }
      >
        <OpenIcon fontSize="small" />
      </IconButton>
    );
  };

  const openEditNote = (noteUrl: string, noteId: number) => {
    const drawerHashes = getQueryStringsURL([
      {
        nameOfHash: OPEN_DRAWER,
        valueOfHash: 1,
      },
      {
        nameOfHash: DRAWER_TABS,
        valueOfHash: DrawerActionsEnums.NOTES,
      },
      {
        nameOfHash: DRAWER_NOTE_ID,
        valueOfHash: noteId,
      },
    ]);

    navigate(noteUrl + drawerHashes);
  };

  const tableConfiguration: BaseGridProperties = {
    name,
    getURL,
    useManuallyReloadParameters: true,
    toolbarOptions: {
      showAddButton: false,
      showEditButton: false,
      showSortFilter: false,
      showImportButton: false,
      showSaveButton: false,
      showExcelButton: true,
      showPDFButton: false,
    },
    filterOptions: [
      (options) =>
        InternalInputDateFilter({
          options,
          label: "From:",
          inputWidth: "210px",
          defaultValue: GettingDateWithoutTime(dateFrom),
          isStaticFilter: true,
          id: "fromDate",
          onChangeRawValue: (value) => {
            const isEqual = areDatesEquals(value, new Date(dateFrom));
            if (isEqual === false) {
              setDateFrom(formatDate(value));
            }
          },
        }),
      (options) =>
        InternalInputDateFilter({
          options,
          label: "To:",
          inputWidth: "210px",
          defaultValue: GettingDateWithoutTime(dateTo),
          isStaticFilter: true,
          id: "toDate",
          onChangeRawValue: (value) => {
            const isEqual = areDatesEquals(value, new Date(dateTo));
            if (isEqual === false) {
              setDateTo(formatDate(value));
            }
          },
        }),
      (options) =>
        BaseGridDropDownFilter({
          id: "notes_past_7_days_owner_filter",
          options: options,
          optionsOverride: addAllOption(configuration?.ownerUserOptions ?? []),
          columnNames: ["OwnerId"],
          labelText: "Owner:",
          defaultValue: currentUserId ?? "all",
          onChange: (value) => setCurrentUserId(value),
        }),
      (options) =>
        BaseGridSearchFilter({
          options,
          id: "note_content_table_filter_search",
          columnNames: ["Content"],
          columnDisplayNames: ["note content"],
        }),
    ],
    columnOptions: [
      {
        fieldName: INTERNAL_TABLE_COLUMNS_OBJ.INDICATION_COLUMN_FIELD_NAME,
        displayNameOverride: "Type",
      },
      { fieldName: "InsuredOrClaim", width: 15 },
      { fieldName: "Name", width: 10 },
      { fieldName: "Owner", width: 15 },
      { fieldName: "Subject", width: 40 },
      { fieldName: "Category", width: 10 },
      { fieldName: "DateAdded", width: 8 },
      { fieldName: "HasFile", width: 4 },
    ],
    advancedOptions: {
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: actionsForRows,
      },
      indicationColumnConfiguration: {
        conditionalIcon: (row) => {
          return row.NoteType;
        },
        isHidden: false,
      },
      disableSortOrder: true,
    },
  };

  useEffect(() => {
    if (refreshTable) {
      manuallyReloadParameters();
      setRefreshTable(false);
    }
  }, [refreshTable]);

  useEffect(() => {
    setRefreshTable(true);
  }, [dateFrom, dateTo]);

  const { manuallyReloadParameters } = useBaseGrid(tableConfiguration);
  return (
    <div id={"notes_grid_container"} style={{ height: "100%" }}>
      <BaseGrid name={name} />
    </div>
  );
};

export default NotesGrid;
