import BaseGrid from "../../../../../BaseGrid/BaseGrid";
import ClaimFinancialHeader from "./ClaimFinancialHeader";
import { SplitButton } from "../../../../../TrueUI";
import EditIcon from "@mui/icons-material/Edit";
import { FC, useEffect, useState } from "react";
import { useBaseGrid } from "../../../../../BaseGrid/Hooks/useBaseGrid";
import { PaymentTableDto } from "../../../../../../dtos/payment-table-dto";
import { ReserveTableDto } from "../../../../../../dtos/reserve-table-dto";
import { FinancialTypeEnum } from "../../../../../../dtos/financial-type-enum";
import { SelectOptions } from "../../../../../../dtos/select-options";
import { FILTER_ALL_RESULTS } from "../../../../../BaseGrid/BaseGridConstants";
import ModalVoidPayment from "./ModalVoidPayment";
import ModalClaimFinancial from "./ModalClaimFinancial";
import {
  DeletedPaymentProps,
  FinancialModalProps,
  PaymentTableRow,
} from "./typesAndConstants";
import { UpdatedPaymentRowData } from "./ClaimFinancialUtil";
import { PaymentDto } from "../../../../../../dtos/payment-dto";
import { ClaimLandingClaimantDto } from "../../../../../../dtos/claim-landing-claimant-dto";
import { useRecoilState } from "recoil";
import {
  ClaimFinancialDataAtom,
  ClaimFinancialGetAPIRequesterAtom,
  ReloadPaymentsAndReservesGridAtom,
} from "./ClaimFinancialAtoms";
import BaseGridSortFilter from "../../../../../BaseGrid/BaseGridCommonFilters/BaseGridSortFilter";
import BaseGridSearchFilter from "../../../../../BaseGrid/BaseGridCommonFilters/BaseGridSearchFilter";
import PaymentsAndReservesFilter from "./PaymentsAndReservesFilter";
import ModalClaimFinancialReserves, {
  UpdatedReserve,
} from "./ModalClaimFinancialReserves";
import { toUpperCaseKeys } from "../../../../../../utilities/objectFunctions";
import ModalRefundPayment from "./RefundComponents/ModalRefundPayment";
import { VoidedOrRefundModalOpenProps } from "./RefundComponents/TypesModalRefundPayment";
import PaymentAndReservesTypeOrStatusFilter from "./PaymentAndReservesTypeOrStatusFilter";
import { getDataIndexByColumnNameWithoutExternal } from "../../../../../TrueUI/Tables/tableFunctions";
import ModalMovePayment from "./MovePaymentComponents/ModalMovePayment";
import { OptionsContextActionParameterProperties } from "../../../../../TrueUI/Tables/BaseTable2/TableProperties";
import { Box, IconButton } from "@mui/material";
import { BaseGridSignatureEnum } from "../../../../../../dtos/base-grid-signature-enum";

const paymentsAndReservesGridName = "payments_and_reserves_grid";

type ModalConfigClaimFinancialReserves = {
  reserveId: number;
  isReserveModalOpen: boolean;
};

type PaymentsAndReservesGridProperties = {
  claimId?: number;
  tabKey: string;
  // financialData: ClaimFinancialDto | null;
  claimLandingClaimant?: ClaimLandingClaimantDto;
  hasClaimsManagementPermissions: boolean;
};
const PaymentsAndReservesGrid: FC<PaymentsAndReservesGridProperties> = ({
  claimId,
  tabKey,
  // financialData,
  claimLandingClaimant,
  hasClaimsManagementPermissions,
  // splitButtonOptions,
}) => {
  const [isVoidedRefundOrMoveModalConfig, setIsVoidedRefundOrMoveModalConfig] =
    useState<VoidedOrRefundModalOpenProps>({
      voided: false,
      refund: false,
      movePayment: false,
      movePaymentData: null,
      refundPaymentData: null,
      reserveTypes: [],
      refreshTable: true,
      paymentId: -1,
    });

  const [_newPaymentRowData, setNewPaymentRowData] = useState<
    PaymentDto[] | (Partial<PaymentDto> & PaymentTableRow)[] | null
  >(null);
  const [updatedPaymentRowData, setUpdatedPaymentRowData] = useState<
    UpdatedPaymentRowData | null | undefined
  >(null);
  const [_deletedPaymentRowData, setDeletedPaymentRowData] =
    useState<DeletedPaymentProps>();

  const [financialData, _setFinancialData] = useRecoilState(
    ClaimFinancialDataAtom
  );
  const [selectedGridRow, setSelectedGridRow] = useState<any>(null);
  const [currentDataAndColumnsResult, setCurrentDataAndColumnsResult] =
    useState<PaymentTableDto | ReserveTableDto | null>(null);
  const [
    currentDataAndColumnsResultFiltered,
    setCurrentDataAndColumnsResultFiltered,
  ] = useState<PaymentTableDto | ReserveTableDto | null>(null);
  const [currentColumnOptions, setCurrentColumnOptions] = useState<any[]>([
    { fieldName: "InvoiceDate", width: 7 },
    { fieldName: "PayeeName", width: 17 },
    {
      fieldName: "PaidAmount",
      width: 10,
      align: "right",
    },
    { fieldName: "PaymentStatus", width: 10 },
    { fieldName: "PaymentCategory", width: 12 },
    { fieldName: "PaymentDate", width: 7 },
    { fieldName: "ServiceDate", width: 7 },
    { fieldName: "ReferenceNo", width: 7 },
    { fieldName: "PaymentMethod", width: 6 },
    { fieldName: "UpdateBy", width: 15 },
  ]);
  const [currentFilterDropDownValues, setCurrentFilterDropDownValues] =
    useState<{
      filteredTypes?: SelectOptions[];
      filteredStatuses?: SelectOptions[];
    } | null>(null);
  const [activeFinancialType, setActiveFinancialType] =
    useState<FinancialTypeEnum>(FinancialTypeEnum.PAYMENT);
  const [getApiRequester, setGetApiRequester] = useRecoilState(
    ClaimFinancialGetAPIRequesterAtom
  );
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [reloadPaymentsAndReservesGrid, setReloadPaymentsAndReservesGrid] =
    useRecoilState(ReloadPaymentsAndReservesGridAtom);
  const [modalConfig, setModalConfig] =
    useState<ModalConfigClaimFinancialReserves>();
  const [selectedRowData, setSelectedRowData] = useState<any>(null);

  const [modalProps, setModalProps] = useState<
    FinancialModalProps | Partial<FinancialModalProps>
  >({
    claimId: claimId,
    open: false,
    title: "Modal title",
    refreshTable: true,
  });

  const [filterSelectedOption, setFilterSelectedOption] = useState({
    typeOrCategory: "all",
    status: "all",
  });
  const [_openModal, setOpenModal] = useState<boolean>(false);

  const [isGridConfigUpdated, setIsGridConfigUpdated] =
    useState<boolean>(false);

  const updateColumnsAndData = () => {
    if (FinancialTypeEnum.PAYMENT === activeFinancialType) {
      setCurrentDataAndColumnsResult(financialData?.paymentTable ?? null);
    } else {
      setCurrentDataAndColumnsResult(financialData?.reserveTable ?? null);
    }
  };

  const updateColumnOptions = () => {
    if (FinancialTypeEnum.PAYMENT === activeFinancialType) {
      setCurrentColumnOptions([
        { fieldName: "Flag", width: 3 },
        { fieldName: "PaymentDate", width: 7 },
        { fieldName: "PayeeName", width: 10 },
        { fieldName: "PaymentStatus", width: 7 },
        { fieldName: "Type", width: 8 },
        { fieldName: "PaymentCategory", width: 9 },
        { fieldName: "InvoiceDate", width: 7 },
        { fieldName: "ServiceDate", width: 8 },
        { fieldName: "ReferenceNo", width: 7 },
        { fieldName: "PaidFromDate", width: 8 },
        { fieldName: "PaidToDate", width: 7 },
        { fieldName: "Billed", width: 7, align: "right" },
        { fieldName: "PaidAmount", width: 7, align: "right" },
        { fieldName: "UpdateBy", width: 8 },
      ]);
    } else {
      setCurrentColumnOptions([
        { fieldName: "ReserveDate", width: 7 },
        {
          fieldName: "Amount",
          width: 12,
          align: "right",
        },
        { fieldName: "ReserveStatus", width: 12 },
        { fieldName: "Category", width: 12 },
        { fieldName: "Comments", width: 35 },
        { fieldName: "UpdateBy", width: 20 },
        { fieldName: "OPTIONS", width: 3 },
      ]);
    }
  };

  const updateDataResultSet = (financialType: FinancialTypeEnum) => {
    if (FinancialTypeEnum.PAYMENT === financialType) {
      setCurrentDataAndColumnsResult(financialData?.paymentTable ?? null);
    } else {
      setCurrentDataAndColumnsResult(financialData?.reserveTable ?? null);
    }

    setActiveFinancialType(financialType);
  };

  const updateCurrentFilterOptionsData = () => {
    const filteredTypes =
      (financialData?.quickSearchOption?.categoryOrType.map((x) => ({
        displayName: x.displayName,
        stringValue: x.displayName.toString(),
      })) as SelectOptions[]) ?? [];

    const filteredStatuses =
      (financialData?.quickSearchOption?.status
        .filter((x) => x.type === activeFinancialType)
        .map((x) => ({
          displayName: x.displayName,
          stringValue: x.displayName.toString(),
        })) as SelectOptions[]) ?? [];

    setCurrentFilterDropDownValues({
      ...currentFilterDropDownValues,
      filteredTypes: [
        {
          displayName: "All",
          stringValue: FILTER_ALL_RESULTS,
        } as SelectOptions,
      ].concat(filteredTypes),
      filteredStatuses: [
        {
          displayName: "All",
          stringValue: FILTER_ALL_RESULTS,
        } as SelectOptions,
      ].concat(filteredStatuses),
    });
  };

  const updateGridConfig = () => {
    updateColumnsAndData();
    updateColumnOptions();
    updateCurrentFilterOptionsData();
    setIsGridConfigUpdated(true);
  };

  const saveRowToTable = (
    action: "update" | "delete",
    newRowData?: UpdatedReserve
  ) => {
    if (newRowData === null || action === null) return;
    switch (action) {
      case "update":
        updateRow(newRowData);
        break;
      case "delete":
        deleteRow();
        break;
      default:
        break;
    }
  };

  const updateRow = (rowData) => {
    const updatedRow = {
      rowKey: selectedRowData.rowKey,
      hydratedData: {
        ...selectedRowData.hydratedData,
        ...toUpperCaseKeys(rowData),
      },
    };
    updateRowAndReload(updatedRow);
    setSelectedRowData(null);
  };

  const deleteRow = () => {
    if (selectedRowData !== undefined && selectedRowData !== null) {
      setGetApiRequester({
        ...getApiRequester,
        isRequested: true,
        isCompleted: false,
      });
    }
  };

  const splitButtonOptions = [
    {
      option: "add-payment",
      dropdownText: "Add Payment",
      action: () => {
        setModalProps({
          open: true,
          title: "Add Payment",
          option: "add-payment",
          refreshTable: true,
        });
      },
    },
    {
      option: "manual-payment",
      dropdownText: "Add Manual Payment",
      action: () => {
        setModalProps({
          open: true,
          title: "Add Manual Payment",
          option: "manual-payment",
          refreshTable: true,
        });
      },
    },
    {
      option: "add-recovery",
      dropdownText: "Add Recovery",
      action: () => {
        setModalProps({
          open: true,
          title: "Add Recovery",
          option: "add-recovery",
          refreshTable: true,
        });
      },
    },
  ];

  useEffect(() => {
    if (isGridConfigUpdated) {
      manuallyReloadParameters();
      setIsGridConfigUpdated(false);
    }
  }, [isGridConfigUpdated]);

  useEffect(() => {
    if (triggerUpdate) {
      updateDataResultSet(activeFinancialType);
      setTriggerUpdate(false);
    }
  }, [triggerUpdate]);

  useEffect(() => {
    if (
      activeFinancialType === FinancialTypeEnum.PAYMENT &&
      currentDataAndColumnsResult === null &&
      financialData !== null &&
      financialData.paymentTable !== null &&
      financialData.paymentTable?.tableData.data.length > 0
    ) {
      setCurrentDataAndColumnsResult(financialData.paymentTable);
    }

    if (
      activeFinancialType === FinancialTypeEnum.RESERVES &&
      currentDataAndColumnsResult === null &&
      financialData !== null &&
      financialData.reserveTable !== null &&
      financialData.reserveTable?.tableData.data.length > 0
    ) {
      setCurrentDataAndColumnsResult(financialData.reserveTable);
    }
  }, [financialData]);

  useEffect(() => {
    updateGridConfig();
  }, [activeFinancialType]);

  useEffect(() => {
    if (reloadPaymentsAndReservesGrid) {
      setReloadPaymentsAndReservesGrid(false);
    }
  }, [reloadPaymentsAndReservesGrid]);

  useEffect(() => {
    if (
      getApiRequester.isCompleted &&
      financialData !== null &&
      currentDataAndColumnsResult !== null
    ) {
      updateDataResultSet(activeFinancialType);
    }
  }, [financialData, getApiRequester]);

  useEffect(() => {
    if (
      currentDataAndColumnsResult !== null &&
      currentDataAndColumnsResult?.tableData !== null
    ) {
      setCurrentDataAndColumnsResultFiltered(currentDataAndColumnsResult);
    }
  }, [currentDataAndColumnsResult]);

  useEffect(() => {
    if (
      currentDataAndColumnsResultFiltered !== null &&
      currentDataAndColumnsResultFiltered?.tableData !== null
    ) {
      manuallyReloadParameters();
    }
  }, [currentDataAndColumnsResultFiltered]);

  useEffect(() => {
    if (
      currentDataAndColumnsResult !== null &&
      currentDataAndColumnsResult?.tableData !== null
    ) {
      const filteredTableData = filterDataByTypeAndStatus();
      setCurrentDataAndColumnsResultFiltered({
        tableData: {
          signature:
            currentDataAndColumnsResultFiltered?.tableData.signature ??
            BaseGridSignatureEnum.TABLE_SIGNATURE_STANDARD,
          columns: currentDataAndColumnsResultFiltered?.tableData.columns ?? [],
          data: filteredTableData ?? [],
        },
      });
    }
  }, [filterSelectedOption]);
  const getColumnIndexByFieldNameFromTable = (fieldName: string) =>
    getDataIndexByColumnNameWithoutExternal(
      currentDataAndColumnsResult?.tableData.columns ?? [],
      fieldName
    );

  const filterDataByTypeAndStatus = () => {
    const tableData = currentDataAndColumnsResult?.tableData.data;

    if (activeFinancialType === FinancialTypeEnum.PAYMENT) {
      const paymentTypeColumnIndex = getColumnIndexByFieldNameFromTable("Type");
      const paymentStatusColumnIndex =
        getColumnIndexByFieldNameFromTable("PaymentStatus");
      const filteredDataByPaymentType = tableData?.filter(
        (row) =>
          row[paymentTypeColumnIndex] === filterSelectedOption.typeOrCategory ||
          filterSelectedOption.typeOrCategory === "all"
      );
      const filteredDataByPaymentTypeAndStatus =
        filteredDataByPaymentType?.filter(
          (row) =>
            row[paymentStatusColumnIndex] === filterSelectedOption.status ||
            filterSelectedOption.status === "all"
        );
      return filteredDataByPaymentTypeAndStatus;
    } else if (activeFinancialType === FinancialTypeEnum.RESERVES) {
      const reserveCategoryColumnIndex =
        getColumnIndexByFieldNameFromTable("Category");
      const reserveStatusColumnIndex =
        getColumnIndexByFieldNameFromTable("ReserveStatus");

      const filteredDataByReserveCategory = tableData?.filter(
        (row) =>
          row[reserveCategoryColumnIndex] ===
            filterSelectedOption.typeOrCategory ||
          filterSelectedOption.typeOrCategory === "all"
      );
      const filteredDataByReserveCategoryAndStatus =
        filteredDataByReserveCategory?.filter(
          (row) =>
            row[reserveStatusColumnIndex] === filterSelectedOption.status ||
            filterSelectedOption.status === "all"
        );
      return filteredDataByReserveCategoryAndStatus;
    }
    return tableData;
  };

  useEffect(() => {
    if (updatedPaymentRowData !== undefined && updatedPaymentRowData !== null) {
      updateRowAndReload({
        hydratedData: updatedPaymentRowData,
        rowKey: updatedPaymentRowData?.rowKey ?? "NO_KEY_FOUND",
      });
    }
  }, [updatedPaymentRowData]);

  useEffect(() => {
    if (modalProps.open && selectedGridRow !== null) {
      setOpenModal(true);
    }
  }, [modalProps, selectedGridRow]);

  useEffect(() => {
    if (selectedGridRow !== null) {
      setModalProps({
        open: true,
        title: "View Payment",
        option: "view-payment",
        refreshTable: true,
      });
    }
  }, [selectedGridRow]);

  const actionsForRows = (
    actionOptions: OptionsContextActionParameterProperties<any>
  ) => {
    return (
      <Box display={"flex"} justifyContent={"flex-end"}>
        <IconButton
          disabled={!hasClaimsManagementPermissions}
          aria-label="expand row"
          size="small"
          onClick={() => {
            if (activeFinancialType === FinancialTypeEnum.PAYMENT) {
              setSelectedGridRow({ ...actionOptions?.row });
            } else {
              setSelectedRowData({
                rowKey: actionOptions.row?._row_key,
                hydratedData: actionOptions.row,
              });

              setModalConfig({
                reserveId: actionOptions.row.ReserveId,
                isReserveModalOpen: true,
              });
            }
          }}
        >
          <EditIcon fontSize="small" />
        </IconButton>
      </Box>
    );
  };

  const { updateRowAndReload, manuallyReloadParameters } = useBaseGrid({
    name: paymentsAndReservesGridName,
    useManuallyReloadParameters: true,
    columnsAndData: {
      columns: currentDataAndColumnsResultFiltered?.tableData?.columns ?? [],
      data: currentDataAndColumnsResultFiltered?.tableData?.data ?? [],
    },
    tableType: "standard",
    advancedOptions: {
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: actionsForRows,
      },
      indicationColumnConfiguration: {
        isHidden: activeFinancialType === FinancialTypeEnum.RESERVES,
        conditionalIcon: (row) => {
          if (row.IsRecurring === "1") {
            return "autorenew";
          } else {
            return "custom";
          }
        },
      },
    },

    columnOptions: currentColumnOptions,
    filterOptions: [
      (options) =>
        PaymentsAndReservesFilter({
          options,
          financialData,
          activeFinancialType,
          onChange: (value) => {
            setActiveFinancialType(value);
            setFilterSelectedOption({
              typeOrCategory: "all",
              status: "all",
            });
          },
        }),
      (options) =>
        BaseGridSortFilter({
          options,
          columnNames:
            activeFinancialType === FinancialTypeEnum.PAYMENT
              ? ["PaymentDate"]
              : ["ReserveDate"],
          labelText: "Sort:",
          defaultValue: "asc",
          id: "payments-order-filter",
        }),
      () =>
        PaymentAndReservesTypeOrStatusFilter({
          options: currentFilterDropDownValues?.filteredTypes ?? [],
          id: "type",
          labelText: "Type:",
          labelPosition: "start",
          value: filterSelectedOption.typeOrCategory,
          onChange: (value) => {
            setFilterSelectedOption({
              ...filterSelectedOption,
              typeOrCategory: value,
            });
          },
        }),
      () =>
        PaymentAndReservesTypeOrStatusFilter({
          id: "status",
          options: currentFilterDropDownValues?.filteredStatuses ?? [],
          labelText: "Status:",
          labelPosition: "start",
          value: filterSelectedOption.status,
          onChange: (value) => {
            setFilterSelectedOption({ ...filterSelectedOption, status: value });
          },
        }),

      (options) =>
        BaseGridSearchFilter({
          options,
          id: "payments_and_reservers_grid_search_filter",
          columnNames: [
            "PayeeTaxId",
            "PayeeName",
            "PaymentDate",
            "InvoiceDate",
            "ServiceDate",
            "PaidFromDate",
            "PaidToDate",
          ],
          columnDisplayNames: ["FEIN", "Name, ", " Date"],
          isHidden:
            activeFinancialType === FinancialTypeEnum.PAYMENT ? false : true,
        }),
    ],
    toolbarOptions: {
      showAddButton: false,
      showEditButton: false,
      showImportButton: false,
      showSaveButton: false,
      showSortFilter: false,
    },
    events: {
      onUpdateRow: () => {
        setGetApiRequester({
          ...getApiRequester,
          isRequested: true,
          isCompleted: false,
        });
      },
    },
  });

  useEffect(() => {
    if (!modalProps.open) {
      if (modalProps?.refreshTable === true) {
        setGetApiRequester({
          ...getApiRequester,
          isRequested: true,
          isCompleted: false,
        });
        setUpdateGridAfterModalClose(false);
      }
    }
    if (
      isVoidedRefundOrMoveModalConfig.voided === false &&
      !isVoidedRefundOrMoveModalConfig.refund === false
    ) {
      if (isVoidedRefundOrMoveModalConfig.refreshTable === true) {
        setGetApiRequester({
          ...getApiRequester,
          isRequested: true,
          isCompleted: false,
        });
        setUpdateGridAfterModalClose(false);
      }
    }
  }, [modalProps, isVoidedRefundOrMoveModalConfig]);

  useEffect(() => {
    if (updateGridAfterModalClose && getApiRequester.isCompleted) {
      updateDataResultSet(activeFinancialType);

      manuallyReloadParameters();

      setGetApiRequester({
        ...getApiRequester,
        isCompleted: false,
        isRequested: false,
      });
    }
  }, [getApiRequester.isCompleted]);

  const [updateGridAfterModalClose, setUpdateGridAfterModalClose] =
    useState<boolean>(false);

  useEffect(() => {
    if (!modalProps.open && modalProps.refreshTable === true) {
      setGetApiRequester({
        ...getApiRequester,
        isRequested: true,
        isCompleted: false,
      });
      setUpdateGridAfterModalClose(false);
    }
  }, [modalProps]);

  useEffect(() => {
    if (updateGridAfterModalClose && getApiRequester.isCompleted) {
      updateDataResultSet(activeFinancialType);

      setTimeout(() => {
        manuallyReloadParameters();
      }, 3000);
      setGetApiRequester({
        ...getApiRequester,
        isCompleted: false,
        isRequested: false,
      });
    }
  }, [getApiRequester.isCompleted]);

  return (
    <>
      <div style={{ height: "50%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "7px",
            marginBottom: "5px",
          }}
        >
          <div style={{ flexGrow: "1" }}>
            <ClaimFinancialHeader title="FINANCIAL HISTORY" />
          </div>
          <div>
            <SplitButton
              items={splitButtonOptions}
              withDropdownTextLabel
              disabled={!hasClaimsManagementPermissions}
            />
          </div>
        </div>
        <div style={{ flexGrow: "1", height: "90%" }}>
          <BaseGrid name={paymentsAndReservesGridName} />
        </div>
      </div>
      <div id={"financial_modals_container"}>
        <>
          {modalProps.open && (
            <ModalClaimFinancial
              claimId={claimId}
              modalProps={modalProps}
              closeModal={(isOpen, refreshTable = true) => {
                setModalProps({
                  ...modalProps,
                  open: isOpen,
                  refreshTable,
                });
                setSelectedGridRow(null);
              }}
              paymentId={selectedGridRow?.PaymentId ?? null}
              setNewPaymentRowData={setNewPaymentRowData}
              setUpdatedPaymentRowData={setUpdatedPaymentRowData}
              setDeletedPaymentRowData={setDeletedPaymentRowData}
              setIsVoidedRefundOrMoveModalConfig={
                setIsVoidedRefundOrMoveModalConfig
              }
              rowKey={selectedGridRow?._row_key_column ?? "NO_KEY_FOUND"}
              claimLandingClaimant={claimLandingClaimant}
              tabKey={tabKey ?? "NO_KEY_FOUND"}
              paymentSelectsOptions={
                financialData?.paymentOptions ?? (null as any)
              }
            />
          )}

          {modalProps.option === "view-payment" &&
            isVoidedRefundOrMoveModalConfig.voided && (
              <ModalVoidPayment
                claimId={claimId}
                paymentId={isVoidedRefundOrMoveModalConfig?.paymentId ?? null}
                setUpdatedPaymentRowData={setUpdatedPaymentRowData}
                rowKey={selectedGridRow?._row_key_column ?? "NO_KEY_FOUND"}
                setIsVoidedRefundOrMoveModalConfig={
                  setIsVoidedRefundOrMoveModalConfig
                }
                isVoidedRefundOrMoveModalConfig={
                  isVoidedRefundOrMoveModalConfig
                }
                setNewPaymentRowData={setNewPaymentRowData}
                tabKey={tabKey}
              />
            )}
          {modalProps.option === "view-payment" &&
            isVoidedRefundOrMoveModalConfig.refundPaymentData !== null && (
              <ModalRefundPayment
                claimId={claimId}
                setIsVoidedRefundOrMoveModalConfig={
                  setIsVoidedRefundOrMoveModalConfig
                }
                isVoidedRefundOrMoveModalConfig={
                  isVoidedRefundOrMoveModalConfig
                }
                reservesInformation={
                  financialData?.paymentOptions?.reservesInformation
                }
                tabKey={tabKey}
              />
            )}
          {isVoidedRefundOrMoveModalConfig?.movePayment === true && (
            <ModalMovePayment
              claimId={claimId}
              setIsVoidedRefundOrMoveModalConfig={
                setIsVoidedRefundOrMoveModalConfig
              }
              isVoidedRefundOrMoveModalConfig={isVoidedRefundOrMoveModalConfig}
            />
          )}
          <ModalClaimFinancialReserves
            reserveId={modalConfig?.reserveId ?? 0}
            claimId={claimId}
            isReserveModalOpen={modalConfig?.isReserveModalOpen ?? false}
            setReserveModalOpen={(value) => {
              setModalConfig({
                reserveId: 0,
                isReserveModalOpen: value,
              });
            }}
            setUpdatedReserve={saveRowToTable}
          />
        </>
      </div>
    </>
  );
};

export default PaymentsAndReservesGrid;
