import { FC } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { BaseTableCellSelectProperties } from "../../TrueUI/Tables/BaseTable2/TableProperties";
import { useRecoilValue } from "recoil";
import { hydrateDataSingle } from "../../TrueUI/Tables/tableFunctions";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";

import {
  AutorenewPayment,
  DiscFileIcon,
  DocFileIcon,
  JPGFileIcon,
  MailFileIcon,
  Mp3FileIcon,
  PDFFileIcon,
  PHPFileIcon,
  PPTFileIcon,
  TXTFileIcon,
  VideoFileIcon,
  WordFileIcon,
  XLSFileIcon,
  ZipFileIcon,
  ClaimIconTable,
  InsuredIconTable,
} from "../../TrueUI/Icons/CustomIcons";
import Link from "../../TrueUI/Typography/Link";
import { Font } from "../../TrueUI";
import { useGridInstance } from "../Hooks/useGridInstance";

interface BaseTableIndicationCellProperties
  extends BaseTableCellSelectProperties {}

// Just the common icons across the site
export type IndicationType =
  | "error"
  | "warning"
  | "success"
  | "information"
  | "in_edit"
  | "mp3"
  | "disc"
  | "video"
  | "jpg"
  | "zip"
  | "txt"
  | "doc"
  | "pdf"
  | "mail"
  | "php"
  | "ppt"
  | "xls"
  | "word"
  | "autorenew"
  | "none"
  | "insured"
  | "claim"
  | "custom";

export type InternalIndicationType =
  | IndicationType
  | JSX.Element
  | "conditional"
  | "none";

const IndicationCell: FC<BaseTableIndicationCellProperties> = ({
  uiid,
  value,
  row,
}) => {
  const { instanceSelector } = useGridInstance(uiid, "IndicationCell");
  const instance = useRecoilValue(instanceSelector());

  const indicationConfig =
    instance?.advancedOptions?.indicationColumnConfiguration;

  const indicationData = hydrateDataSingle(instance?.columns ?? [], row);

  const getType = (): InternalIndicationType => {
    if (conditionHasValue(indicationConfig?.conditionalIcon)) {
      const result =
        indicationConfig?.conditionalIcon?.(indicationData) ?? "none";
      const typeOfConditional = typeof result;
      return typeOfConditional === "string" ? result : "conditional";
    } else {
      return indicationConfig?.indicationType ?? "none";
    }
  };

  const getIndicationIconType = () => {
    switch (getType()) {
      case "error":
        return <ErrorOutlineIcon />;
      case "warning":
        return <ErrorOutlineIcon />;
      case "success":
        return <CheckCircleOutlineIcon />;
      case "information":
        return <HelpOutlineIcon />;
      case "in_edit":
        return <CreateOutlinedIcon />;
      case "mp3":
        return <Mp3FileIcon />;
      case "disc":
        return <DiscFileIcon />;
      case "video":
        return <VideoFileIcon />;
      case "jpg":
        return <JPGFileIcon />;
      case "zip":
        return <ZipFileIcon />;
      case "txt":
        return <TXTFileIcon />;
      case "doc":
        return <DocFileIcon />;
      case "pdf":
        return <PDFFileIcon />;
      case "mail":
        return <MailFileIcon />;
      case "php":
        return <PHPFileIcon />;
      case "ppt":
        return <PPTFileIcon />;
      case "xls":
        return <XLSFileIcon />;
      case "word":
        return <WordFileIcon />;
      case "autorenew":
        return <AutorenewPayment />;
      case "insured":
        return <InsuredIconTable />;
      case "claim":
        return <ClaimIconTable />;
      case "custom":
        return indicationConfig?.CustomIndicationComponent?.(indicationData);
      case "conditional":
        return indicationConfig?.conditionalIcon?.(indicationData);
      case "none":
        return "";
      default:
        return <div>&nbsp;</div>;
    }
  };

  const getValue = () => {
    if (indicationConfig?.isLink) {
      return <Link linkFontType="BODY" displayValue={value} />;
    }
    return <Font>{value === "none" ? "" : value}</Font>;
  };

  return (
    <div
      onClick={() => indicationConfig?.onClick?.(indicationData)}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        // marginLeft: "2px",
        // gap: "5px",
        marginTop: "5px",
        cursor: conditionHasValue(indicationConfig?.onClick)
          ? "pointer"
          : "initial",
        color: indicationConfig?.iconColor,
      }}
    >
      <div title={getType().toString().toUpperCase() ?? ""}>
        {getIndicationIconType()}
      </div>
      {getValue()}
    </div>
  );
};

export default IndicationCell;
