import { FC, useEffect } from "react";
import { PolicyWaiverFormProps } from "../TypesPolicyWaivers";
import {
  Col,
  FontBold,
  Input,
  InputAddress,
  InputDate,
  InputMemo,
  InputPhone,
  Row,
  Select,
  Switch,
} from "../../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../../TrueUI/Grids/Row";
import { selectConfiguration } from "../../../../../utilities/inputPropsConfigurationFunctions";

const FormPolicyWaiver: FC<PolicyWaiverFormProps> = ({
  policies,
  policyWaiver,
  setPolicyWaiver,
  errorDetails,
}) => {
  const isEditMode = policyWaiver?.waiverId !== 0;

  useEffect(() => {
    if ((policies?.length ?? 0) > 0 && (policyWaiver?.policyId ?? 0) === 0) {
      setPolicyWaiver({
        ...policyWaiver,
        policyId:
          policies?.find((policy) => policy.isDefault === true)?.intValue ??
          null,
      });
    }
  }, [policies]);
  return (
    <>
      <Row {...rowWithNoMarginNorGutter} allowWrap>
        <Col breakpoints={{ md: 6, lg: 6, xl: 6 }}>
          <Select
            {...selectConfiguration(
              "id-policy",
              "policyId",
              "Policy",
              isEditMode
            )}
            labelFontType="BOLD_CAPTION"
            options={policies ?? []}
            firstOptionAsDefault={false}
            optionsMaxHeight={"400px"}
            value={policyWaiver?.policyId ?? ""}
            onChange={(value) => {
              setPolicyWaiver({ ...policyWaiver, policyId: value });
            }}
            errorMessage={errorDetails?.policyId}
            tabIndex={1}
          />
        </Col>
        <Col breakpoints={{ md: 6, lg: 6, xl: 6 }}></Col>
        <Col breakpoints={{ md: 6, lg: 5, xl: 5 }}>
          <InputDate
            readOnly={isEditMode}
            id="policy-waiver-issue-date"
            name="policy-waiver-issue-date"
            label="Issue Date"
            value={policyWaiver?.issueDate}
            onChangeRawValue={(value) =>
              setPolicyWaiver({ ...policyWaiver, issueDate: value })
            }
            tabIndex={2}
          />
        </Col>
        <Col
          breakpoints={{ md: 6, lg: 7, xl: 7 }}
          verticalAlignSelf={"flex-end"}
        >
          <Switch
            id="policy-waiver-auto-renew"
            name="policy-waiver-auto-renew"
            label="Auto Renew?"
            isChecked={(policyWaiver?.autoRenew ?? 0) === 1 ? true : false}
            onChangeIsChecked={(isChecked) =>
              setPolicyWaiver({
                ...policyWaiver,
                autoRenew: isChecked === true ? 1 : 0,
              })
            }
            control={"checkbox"}
            labelFontType={"BODY"}
            tabIndex={3}
          />
        </Col>
      </Row>
      <FontBold fontType={"BOLD_TITLE"}>CERTIFICATE HOLDER</FontBold>
      <Row {...rowWithNoMarginNorGutter} allowWrap>
        <Col breakpoints={{ md: 12, lg: 12, xl: 12 }}>
          <Input
            id="policy-waiver-company"
            name="policy-waiver-company"
            label="Company"
            value={policyWaiver?.companyName}
            onChangeRawValue={(value) =>
              setPolicyWaiver({ ...policyWaiver, companyName: value })
            }
            maxLength={200}
            errorMessage={errorDetails?.companyName}
            tabIndex={4}
          />
        </Col>
        <Col breakpoints={{ md: 12, lg: 12, xl: 12 }}>
          <InputAddress
            id="policy-waiver-inputAddress"
            showAddress2={true}
            showSingleLabel={false}
            initValueAddress1={policyWaiver?.address ?? ""}
            initValueAddress2={policyWaiver?.address2 ?? ""}
            initValueCity={policyWaiver?.city ?? ""}
            initValueStateCode={policyWaiver?.state ?? ""}
            initValuePostCode={policyWaiver?.postalCode ?? ""}
            cityMaxLength={50}
            onChangeValueAddress1={(value) =>
              setPolicyWaiver({
                ...policyWaiver,
                address: value,
              })
            }
            onChangeValueAddress2={(value) =>
              setPolicyWaiver({
                ...policyWaiver,
                address2: value,
              })
            }
            onChangeValueCity={(value) =>
              setPolicyWaiver({
                ...policyWaiver,
                city: value,
              })
            }
            onChangeStateCode={(value) =>
              setPolicyWaiver({
                ...policyWaiver,
                state: value,
              })
            }
            onChangeValuePostCode={(value) =>
              setPolicyWaiver({
                ...policyWaiver,
                postalCode: value,
              })
            }
            labelFontType="BOLD_CAPTION"
            readOnly={false}
            address1ErrorMessage={errorDetails?.address}
            address2ErrorMessage={errorDetails?.address2}
            cityErrorMessage={errorDetails?.city}
            stateCodeErrorMessage={errorDetails?.state}
            postCodeErrorMessage={errorDetails?.postalCode}
            isFirstStateCodeAsDefault={false}
            tabIndex={5}
          />
        </Col>
        <Col breakpoints={{ md: 12, lg: 12, xl: 12 }}>
          <Input
            id="policy-waiver-contact-name"
            name="policy-waiver-contact-name"
            label="Contact Name"
            value={policyWaiver?.contactName ?? ""}
            onChangeRawValue={(value) =>
              setPolicyWaiver({ ...policyWaiver, contactName: value })
            }
            maxLength={100}
            errorMessage={errorDetails?.contactName}
            tabIndex={10}
          />
        </Col>
        <Col breakpoints={{ md: 12, lg: 12, xl: 12 }}>
          <InputPhone
            id="policy-waiver-phone"
            name="policy-waiver-phone"
            phoneLabel="Phone"
            phoneValue={policyWaiver?.phoneNumber ?? ""}
            readOnly={false}
            errorMessagePhone={errorDetails?.phoneNumber}
            onChangePhoneValue={(value) => {
              setPolicyWaiver({
                ...policyWaiver,
                phoneNumber: value,
              });
            }}
            tabIndex={11}
          />
        </Col>
        <Col breakpoints={{ md: 12, lg: 12, xl: 12 }}>
          <Input
            id="policy-waiver-job-name"
            name="policy-waiver-job-name"
            label="Job Name"
            value={policyWaiver?.jobName}
            onChangeRawValue={(value) =>
              setPolicyWaiver({ ...policyWaiver, jobName: value })
            }
            maxLength={200}
            errorMessage={errorDetails?.jobName}
            tabIndex={12}
          />
        </Col>
        <Col breakpoints={{ md: 12, lg: 12, xl: 12 }}>
          <Input
            id="policy-waiver-location"
            name="policy-waiver-locaition"
            label="Location"
            value={policyWaiver?.location}
            onChangeRawValue={(value) =>
              setPolicyWaiver({ ...policyWaiver, location: value })
            }
            maxLength={200}
            errorMessage={errorDetails?.location}
            tabIndex={13}
          />
        </Col>
        <Col breakpoints={{ md: 12, lg: 12, xl: 12 }}>
          <InputMemo
            id="policy-waiver-description"
            name="policy-waiver-description"
            label="Description"
            onChangeRawValue={(value) =>
              setPolicyWaiver({ ...policyWaiver, description: value })
            }
            value={policyWaiver?.description}
            maxLength={500}
            errorMessage={errorDetails?.description}
            tabIndex={14}
          />
        </Col>
      </Row>
    </>
  );
};
export default FormPolicyWaiver;
