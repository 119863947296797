import { useRecoilValue } from "recoil";
import { globalOptions } from "../../../GlobalAtoms";
import themes from "../../../media/TrueTheme";
import { OptionsContextActionParameterProperties } from "../../TrueUI/Tables/BaseTable2/TableProperties";
import { Box, IconButton } from "@mui/material";
import { Launch } from "@mui/icons-material";
import { useEffect, useState } from "react";
import BaseTableTitleDisplay from "../../TrueUI/Tables/BaseTableCommonFilters/BaseTableTitleDisplay";
import { useApiGet } from "../../../hooks";
import { isAPITotallyCompleteNoContentResponse } from "../../../utilities/apiFunctions";
import { FormattingDate, newDate } from "../../../utilities/dateFunctions";
import { currencyFormat } from "../../../utilities/currencyFunctions";
import { BaseGridProperties } from "../../BaseGrid/BaseGridProperties";
import BaseGrid from "../../BaseGrid/BaseGrid";
import { useBaseGrid } from "../../BaseGrid/Hooks/useBaseGrid";
import InternalInputDateFilter from "./InternalInputDateFilter";

const PaymentActivityTable = () => {
  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];
  const [refreshTable, setRefreshTable] = useState<boolean>(false);
  const [paymentDate, setPaymentDate] = useState<string>(
    FormattingDate(newDate())
  );
  const [totalReceived, setTotalReceived] = useState<string>();
  const { dispatchGet, responseGet } = useApiGet<number>(
    `api/PaymentActivity/GetTotalReceived?PaymentDate=${FormattingDate(
      new Date(paymentDate)
    )}`
  );

  useEffect(() => {
    setRefreshTable(true);
  }, [totalReceived]);

  useEffect(() => {
    if (refreshTable) {
      manuallyReloadParameters();
      setRefreshTable(false);
    }
  }, [refreshTable]);

  useEffect(() => {
    dispatchGet();
  }, [paymentDate]);

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responseGet)) {
      setTotalReceived(
        currencyFormat(responseGet?.axiosResponse?.data, false, 2, true)
      );
    }
  }, [responseGet]);

  const actionsForRows = (
    actionOptions: OptionsContextActionParameterProperties<any>
  ) => {
    return (
      <Box
        display={"flex"}
        justifyContent={"flex-end"}
        fontSize="16px"
        width={"inherit"}
      >
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => {
            console.log({ actionOptions });
          }}
        >
          <Launch fontSize="inherit" sx={{ color: theme?.TEXT_COLOR }} />
        </IconButton>
      </Box>
    );
  };

  const tableName = "payment_activity_table";
  const tableConfiguration: BaseGridProperties = {
    name: tableName,
    getURL: `api/PaymentActivity/GetPaymentActivityTable?paymentDate=${paymentDate}`,
    useManuallyReloadParameters: true,
    columnOptions: [
      { fieldName: "InsuredName", width: 45 },
      { fieldName: "PaymentType", width: 15 },
      { fieldName: "ReferenceNumber", width: 20 },
      { fieldName: "Amount", width: 20, align: "right" },
      { fieldName: "OPTIONS", width: 2 },
    ],
    toolbarOptions: {
      showAddButton: false,
      showEditButton: false,
      showExcelButton: true,
      showPDFButton: true,
      showImportButton: false,
      showSaveButton: false,
      showSortFilter: false,
    },
    filterOptions: [
      (options) =>
        InternalInputDateFilter({
          options: options,
          label: "Payment Date",
          labelPosition: "start",
          inputWidth: "320px",
          labelFontType: "BODY",
          defaultValue: new Date(paymentDate),
          id: "paymentDate",
          columnsNames: ["PaymentDate"],
          onChangeRawValue: (value) => {
            setPaymentDate(FormattingDate(value));
          },
        }),
      () =>
        BaseTableTitleDisplay({
          title: "Total Received:",
          fontType: "BODY",
          width: "200px",
          marginLeft: 20,
        }),
      () =>
        BaseTableTitleDisplay({
          title: totalReceived ?? "",
          fontType: "BOLD_BODY",
        }),
    ],
    advancedOptions: {
      optionsColumnConfiguration: {
        isHidden: true,
        optionType: "component",
        optionsStateCondition: (e) => {
          if (e.triggerState === "on_init") {
            if (e.hydratedRow.IsBold === "true") {
              return "none";
            } else {
              return "component";
            }
          }
          return e.currentOptionValue;
        },
      },
      optionsContextActions: {
        contextComponent: actionsForRows,
      },
      disableSortOrder: true,
    },
  };

  const { manuallyReloadParameters } = useBaseGrid(tableConfiguration);

  return (
    <div id={"payment_activity_container"}>
      <BaseGrid name={tableName} />
    </div>
  );
};

export default PaymentActivityTable;
