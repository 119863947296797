import { BaseGridInternalColumnTypeEnum } from "../../dtos/base-grid-internal-column-type-enum";
import { BaseTableColumn } from "../../dtos/base-table-column";
import { BaseTableInputType } from "../../dtos/base-table-input-type";
import { CellVariantEnums } from "../../dtos/cell-variant-enums";
import { DataTypeEnums } from "../../dtos/data-type-enums";

export const BaseGridInternalColumnDefaultConfiguration = {
  _rowKeyColumn: {
    // internal
    _columnIndex: 0,
    _visibleColumnIndex: null,
    _headerSortOrder: null,
    _isInternal: true,

    // external
    displayName:
      BaseGridInternalColumnTypeEnum[BaseGridInternalColumnTypeEnum.ROW_KEY],
    fieldName:
      BaseGridInternalColumnTypeEnum[BaseGridInternalColumnTypeEnum.ROW_KEY],
    cellVariant: CellVariantEnums.STANDARD,
    type: BaseTableInputType.READONLY,
    displayOptions: [],
    defaultValue: null, // investigate creating a default key using crypto.randomUIID()
    defaultValueType: DataTypeEnums.STRING,
    isHidden: true,
    alternateDisplayValues: [],
    isFilterable: false,
    isSortable: false,
  } as BaseTableColumn,
  // _defaultRowIndexColumn: {
  //   // internal
  //   _columnIndex: 1,
  //   _visibleColumnIndex: null,
  //   _headerSortOrder: null,
  //   _isInternal: true,

  //   // external
  //   displayName: DEFAULT_ROW_INDEX,
  //   fieldName: DEFAULT_ROW_INDEX,
  //   cellVariant: CellVariantEnums.STANDARD,
  //   type: BaseTableInputType.READONLY,
  //   displayOptions: [],
  //   defaultValue: null, // investigate creating a default key using crypto.randomUIID()
  //   defaultValueType: DataTypeEnums.STRING,
  //   isHidden: true,
  //   alternateDisplayValues: [],
  //   isFilterable: false,
  //   isSortable: false,
  // } as BaseTableColumn,
  _multiSelectionColumn: {
    // internal
    _columnIndex: 2,
    _visibleColumnIndex: null,
    _headerSortOrder: null,
    _isInternal: true,
    // external
    displayName: " ",
    fieldName:
      BaseGridInternalColumnTypeEnum[
        BaseGridInternalColumnTypeEnum.MULTI_ROW_SELECT
      ],
    cellVariant: CellVariantEnums.STANDARD,
    type: BaseTableInputType.MULTI_SELECTION,
    displayOptions: [],
    defaultValue: "none",
    defaultValueType: DataTypeEnums.STRING,
    isHidden: true,
    alternateDisplayValues: [],
    isFilterable: false,
    isSortable: false,
  } as BaseTableColumn,
  _indicationColumn: {
    // internal
    _columnIndex: 3,
    _visibleColumnIndex: null,
    _headerSortOrder: null,
    _isInternal: true,

    // external
    displayName: " ",
    fieldName:
      BaseGridInternalColumnTypeEnum[BaseGridInternalColumnTypeEnum.INDICATION],
    cellVariant: CellVariantEnums.STANDARD,
    type: BaseTableInputType.INDICATION,
    displayOptions: [],
    defaultValue: "none",
    defaultValueType: DataTypeEnums.STRING,
    isHidden: true,
    alternateDisplayValues: [],
    isFilterable: false,
    isSortable: false,
  } as BaseTableColumn,
  _optionsColumn: {
    // internal
    _columnIndex: 4,
    _visibleColumnIndex: null,
    _headerSortOrder: null,
    _isInternal: true,

    // external
    displayName: " ",
    fieldName:
      BaseGridInternalColumnTypeEnum[BaseGridInternalColumnTypeEnum.OPTIONS],
    cellVariant: CellVariantEnums.STANDARD,
    type: BaseTableInputType.OPTIONS,
    displayOptions: [],
    defaultValue: "none",
    defaultValueType: DataTypeEnums.STRING,
    isHidden: true,
    alternateDisplayValues: [],
    isFilterable: false,
    isSortable: false,
  } as BaseTableColumn,
  _isRowDeletedColumn: {
    // internal
    _columnIndex: 5,
    _visibleColumnIndex: null,
    _headerSortOrder: null,
    _isInternal: true,

    // external
    displayName:
      BaseGridInternalColumnTypeEnum[BaseGridInternalColumnTypeEnum.IS_DELETED],
    fieldName:
      BaseGridInternalColumnTypeEnum[BaseGridInternalColumnTypeEnum.IS_DELETED],
    cellVariant: CellVariantEnums.STANDARD,
    type: BaseTableInputType.READONLY,
    displayOptions: [],
    defaultValue: "false",
    defaultValueType: DataTypeEnums.BOOLEAN,
    isHidden: true,
    alternateDisplayValues: [],
    isFilterable: false,
    isSortable: false,
  } as BaseTableColumn,
  _isRowNewColumn: {
    // internal
    _columnIndex: 6,
    _visibleColumnIndex: null,
    _headerSortOrder: null,
    _isInternal: true,

    // external
    displayName:
      BaseGridInternalColumnTypeEnum[BaseGridInternalColumnTypeEnum.IS_NEW],
    fieldName:
      BaseGridInternalColumnTypeEnum[BaseGridInternalColumnTypeEnum.IS_NEW],
    cellVariant: CellVariantEnums.STANDARD,
    type: BaseTableInputType.READONLY,
    displayOptions: [],
    defaultValue: "false",
    defaultValueType: DataTypeEnums.BOOLEAN,
    isHidden: true,
    alternateDisplayValues: [],
    isFilterable: false,
    isSortable: false,
  } as BaseTableColumn,
  _isRowBold: {
    // internal
    _columnIndex: 7,
    _visibleColumnIndex: null,
    _headerSortOrder: null,
    _isInternal: true,

    // external
    displayName:
      BaseGridInternalColumnTypeEnum[BaseGridInternalColumnTypeEnum.IS_BOLD],
    fieldName:
      BaseGridInternalColumnTypeEnum[BaseGridInternalColumnTypeEnum.IS_BOLD],
    cellVariant: CellVariantEnums.STANDARD,
    type: BaseTableInputType.READONLY,
    displayOptions: [],
    defaultValue: "false",
    defaultValueType: DataTypeEnums.BOOLEAN,
    isHidden: true,
    alternateDisplayValues: [],
    isFilterable: false,
    isSortable: false,
  } as BaseTableColumn,
};
