import { FC, useEffect, useState } from "react";
import style from "./DocumentsStyles.module.css";
import {
  DocumentsCommandsProps,
  DocumentsCommandsUIProps,
} from "./DocumentsTypes";
import { INSURED_ATOM_KEY } from "../../../../../utilities/queryStringsHash";
import { GlobalInsuredAtomFamily } from "../../../InsuredAtoms";
import { useAtomFamily } from "../../../../../hooks/useAtomFamily";
import { useFormRequest } from "../../../../../hooks";
import { FormTypeEnum } from "../../../../../dtos/form-type-enum";
import { getJSONDataWithoutConfigurations } from "../../PolicyQuoteForm/PolicyQuoteUtils";
import { isEmptyValue } from "../../../../../utilities/conditionalSupportFunctions";
import { Button, Input, Switch } from "../../../../TrueUI";
import DialogConfirmation, {
  DialogConfirmationProps,
} from "../../../../TrueUI/Dialogs/DialogConfirmation";
import { isJsonString } from "../../../../../utilities/stringFunctions";
import { PolicyBlob } from "../../../../../dtos/policy-blob";
import { DocSourceEnum } from "../../../../../dtos/doc-source-enum";
import { TemplateNameAndSourceDto } from "../../../../../dtos/template-name-and-source-dto";
import {
  defaultDocumentsCommandsUI,
  ERROR_TEMPLATE_NAME,
  getActivityLogCategory,
  getDefaultFileName,
  getDocumentFileCategory,
  getTemplateNameAndSource,
} from "./DocumentsUtils";

const DocumentsCommands: FC<DocumentsCommandsProps> = ({
  tabKey,
  policyArea,
  selectedPackets,
  selectedPacketDocuments,
  selectedDocuments,
  customJSONToPrint,
}) => {
  const atomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom } = useAtomFamily(GlobalInsuredAtomFamily(atomKey));
  const [documentsCommandsUI, setDocumentsCommandsUI] =
    useState<DocumentsCommandsUIProps>(defaultDocumentsCommandsUI);
  const [dialogConfiguration, setDialogConfiguration] =
    useState<DialogConfirmationProps | null>(null);
  const { sendMergeFormRequest } = useFormRequest();

  const getEndorsementTemplateNames = (
    endorsementSelectedPacketDocuments,
    endorsementForms
  ): TemplateNameAndSourceDto[] => {
    if (endorsementSelectedPacketDocuments?.length > 0) {
      // Is expected just one packet should include the endorsementDocuments
      const formType =
        endorsementSelectedPacketDocuments?.[1]?.docSource ===
        DocSourceEnum.CUSTOM_JSON
          ? FormTypeEnum.CUSTOM_FORM
          : FormTypeEnum.POLICY_FORM;

      return (
        endorsementForms?.map((form) => ({
          templateName: form.templateName ?? ERROR_TEMPLATE_NAME,
          mergeFieldSource: formType,
        })) ?? []
      );
    }
    return [];
  };

  const getTemplateNamesToPrint = (endorsementForms) => {
    const nonEndorsementSelectedPacketDocuments =
      selectedPacketDocuments.filter((x) => x.fileId !== null);

    const endorsementSelectedPacketDocuments = selectedPacketDocuments.filter(
      (x) => x.fileId === null
    );

    const templateNamesWithDocSourceFromPacket =
      nonEndorsementSelectedPacketDocuments.map((selectedPacketDocument) =>
        getTemplateNameAndSource(selectedPacketDocument)
      );

    const endorsementTemplateNamesWithDocSourceFromPacket =
      getEndorsementTemplateNames(
        endorsementSelectedPacketDocuments,
        endorsementForms
      );

    const templateNamesWithDocSourceBySelectedDocument = selectedDocuments.map(
      (selectedPacketDocument) =>
        getTemplateNameAndSource(selectedPacketDocument)
    );

    const allTemplateNamesWithDocSource =
      templateNamesWithDocSourceFromPacket
        ?.concat(endorsementTemplateNamesWithDocSourceFromPacket ?? [])
        .concat(templateNamesWithDocSourceBySelectedDocument ?? []) ?? [];

    const hasInvalidTemplateNames = !allTemplateNamesWithDocSource.every(
      (templateNameAndDocSource) =>
        templateNameAndDocSource.templateName !== ERROR_TEMPLATE_NAME
    );

    return {
      hasInvalidTemplateNames,
      allTemplateWithDocSourceNames: allTemplateNamesWithDocSource,
    };
  };

  const evaluatePrintingConfigurations = (
    policyMergeFields?: PolicyBlob | null
  ) => {
    const { hasInvalidTemplateNames, allTemplateWithDocSourceNames } =
      getTemplateNamesToPrint(policyMergeFields?.endorsementForms);

    if (hasInvalidTemplateNames) {
      setDialogConfiguration({
        open: true,
        dialogDescriptionText: "No print template(s) found.",
      });

      return { isFullyConfigured: false, allTemplateWithDocSourceNames };
    }

    if (
      allTemplateWithDocSourceNames.find(
        (templateWithDocSource) =>
          templateWithDocSource.mergeFieldSource === FormTypeEnum.CUSTOM_FORM
      ) !== undefined &&
      customJSONToPrint === ""
    ) {
      setDialogConfiguration({
        open: true,
        dialogDescriptionText:
          "There are documents selected requesting the usage of the custom JSON for printing but there is no custom JSON set up for the current policy period.",
      });

      return { isFullyConfigured: false, allTemplateWithDocSourceNames };
    }

    return { isFullyConfigured: true, allTemplateWithDocSourceNames };
  };

  const printDocuments = (downloadOrMail: "download" | "mail") => {
    const policyMergeFields = getAtom()?.policyQuoteInformation?.policyQuote;
    if (policyMergeFields !== undefined && policyMergeFields !== null) {
      const { isFullyConfigured, allTemplateWithDocSourceNames } =
        evaluatePrintingConfigurations(policyMergeFields);

      if (isFullyConfigured) {
        const fileCategory = getDocumentFileCategory(policyArea);
        sendMergeFormRequest(
          {
            formType: FormTypeEnum.POLICY_FORM,
            policyMergeFields: getJSONDataWithoutConfigurations(
              getAtom()?.policyQuoteInformation
            ),
            customMergeFields: isJsonString(customJSONToPrint)
              ? JSON.parse(customJSONToPrint)
              : null,
            templateNameWithSpecificSourceMergeFieldsList:
              allTemplateWithDocSourceNames,
            customFileName: documentsCommandsUI.fileName,
            configurationToSaveFile: {
              saveFileToInsured: documentsCommandsUI.saveFile,
              fileCategory,
              insuredId: policyMergeFields.insured?.insuredID ?? 0,
              claimId: null,
              policyId: policyMergeFields.policyID ?? -1,
              activityLogCategory: getActivityLogCategory(policyArea),
              activityLogDescription: !isEmptyValue(
                policyMergeFields.policyNumber
              )
                ? `The ${fileCategory.toLocaleLowerCase()} for policy number ${
                    policyMergeFields.policyNumber
                  } were generated`
                : `The ${fileCategory.toLocaleLowerCase()} for policy ID ${
                    policyMergeFields.policyID
                  } were generated`,
            },
          },
          downloadOrMail,
          true
        );
      }
    }
  };

  useEffect(() => {
    const insuredName =
      getAtom()?.policyQuoteInformation?.policyQuote?.insured?.insuredName ??
      "";
    setDocumentsCommandsUI({
      ...documentsCommandsUI,
      fileName: getDefaultFileName(
        selectedPackets,
        selectedDocuments,
        insuredName,
        policyArea
      ),
    });
  }, [selectedPackets, selectedDocuments]);

  return (
    <div className={style.documents_commands_container}>
      <Switch
        id="save-file-id"
        control="checkbox"
        label="Save File"
        labelPlacement={"end"}
        labelFontType={"BODY"}
        isChecked={documentsCommandsUI.saveFile}
        onChangeIsChecked={(checked) =>
          setDocumentsCommandsUI({
            ...documentsCommandsUI,
            saveFile: checked,
          })
        }
      />
      <Input
        id="file-name-id"
        name="file-name"
        label="File Name"
        labelFontType={"BOLD_CAPTION"}
        labelPosition="start"
        variant="filled"
        fileNameValidation
        value={documentsCommandsUI.fileName}
        onChangeRawValue={(value) =>
          setDocumentsCommandsUI({
            ...documentsCommandsUI,
            fileName: value,
          })
        }
        inputWidth="70%"
      />
      <div className={style.documents_commands_buttons_container}>
        <Button
          id="doc-email"
          name="doc-email"
          sx={{ marginRight: "15px" }}
          onClick={() => printDocuments("mail")}
        >
          EMAIL
        </Button>
        <Button
          id="doc-print"
          name="doc-print"
          onClick={() => printDocuments("download")}
        >
          PRINT
        </Button>
      </div>
      <DialogConfirmation
        {...dialogConfiguration}
        id="id-dialog-confirmation-document-commands"
        optionYesOverrideLabel="OK"
        onOptionYesEvent={() => setDialogConfiguration(null)}
        onCloseEvent={() => setDialogConfiguration(null)}
      />
    </div>
  );
};

export default DocumentsCommands;
