import { FC } from "react";
import { Modal } from "../../../../../../TrueUI";
import { ModalPaymentBillDetailsWrapperProps } from "./TypesPaymentBillDetails";
import BillDetailsWrapper from "./BillDetailsWrapper";
import { useRecoilState } from "recoil";
import { ClaimFinancialPaymentBillDetailsAtom } from "../ClaimFinancialAtoms";

const ModalPaymentBillDetailsWrapper: FC<
  ModalPaymentBillDetailsWrapperProps
> = ({ isModalOpen, setIsModalOpen, payment, claimId }) => {
  const [paymentBillDetailsAtom, setPaymentBillDetailsAtom] = useRecoilState(
    ClaimFinancialPaymentBillDetailsAtom
  );

  return (
    <Modal
      id="payment-bill-details"
      title="Bill Details"
      open={isModalOpen}
      cancelEvent={() => {
        setIsModalOpen(false);
        setPaymentBillDetailsAtom({
          paymentData: paymentBillDetailsAtom?.paymentData,
        });
      }}
      saveEvent={() => {
        setIsModalOpen(false);
        setPaymentBillDetailsAtom({
          ...paymentBillDetailsAtom,
          isModalForBillDetailsOpen: false,
        });
      }}
      size={"xl"}
      cancelButtonWithConfirmation
      showCloseButton
      showCancelTextButton
      cancelButtonConfirmationText={
        "Are you sure you want to cancel? Your work will be lost."
      }
    >
      <BillDetailsWrapper payment={payment} claimId={claimId} />
    </Modal>
  );
};
export default ModalPaymentBillDetailsWrapper;
