import { FC, useEffect } from "react";
import { Col, Row, Select } from "../";
import { PostalCodeDto } from "../../../dtos/postal-code-dto";
import { SelectOptions } from "../../../dtos/select-options";
import { useApiGet } from "../../../hooks/useApi";
import { FontsType } from "../../../media/themeTypes";
import { KeysAsType } from "../../../types/KeysAsAType";
import { getStates } from "../../../utilities/staticDataFunctions";
import { rowWithNoMarginNorGutter } from "../Grids/Row";
import Input from "./Input";

type inputProps = {
  id?: string;
  name?: string;
  label?: string;
  labelAddress2?: string;
  labelCity?: string;
  labelState?: string;
  labelPostalCode?: string;
  className?: string;
  readOnly?: boolean;
  variant?: "filled" | "standard";
  initValueAddress1: string;
  initValueAddress2?: string;
  initValueCity: string;
  initValueStateCode: string;
  initValuePostCode: string;
  initValueCounty?: string;
  address1ErrorMessage?: string[] | null;
  address2ErrorMessage?: string[] | null;
  cityErrorMessage?: string[] | null;
  postCodeErrorMessage?: string[] | null;
  stateCodeErrorMessage?: string[] | null;
  cityMaxLength?: number;
  onChangeValueAddress1?: (e) => void;
  onChangeValueAddress2?: (e) => void;
  onChangeValueCity?: (e) => void;
  onChangeStateCode?: (e) => void;
  onChangeValuePostCode?: (e) => void;
  onChangeValueCounty?: (e) => void;
  permissions?: number[];
  showAddress2?: boolean;
  showSingleLabel?: boolean;
  showCounty?: boolean;
  showLabelCounty?: boolean;
  inputFontType?: KeysAsType<FontsType>;
  labelFontType?: KeysAsType<FontsType>;
  isFirstStateCodeAsDefault?: boolean;
  tabIndex?: number;
  isAutoComplete?: boolean;
};

const InputAddress: FC<inputProps> = ({
  id = "",
  name = "",
  label = "Address",
  labelAddress2 = "Address 2",
  labelCity = "City",
  labelState = "State",
  labelPostalCode = "Postal Code",
  readOnly = true,
  variant,
  className = "",
  initValueAddress1,
  initValueAddress2 = "",
  initValueCity,
  initValuePostCode,
  initValueStateCode,
  initValueCounty = "",
  address1ErrorMessage = null,
  address2ErrorMessage = null,
  cityErrorMessage = null,
  postCodeErrorMessage = null,
  stateCodeErrorMessage = null,
  cityMaxLength = 30,
  onChangeValueAddress1,
  onChangeValueAddress2,
  onChangeValueCity,
  onChangeStateCode,
  onChangeValuePostCode,
  onChangeValueCounty,
  permissions,
  showAddress2 = true,
  showSingleLabel = true,
  showCounty = false,
  showLabelCounty = false,
  inputFontType = "BODY",
  labelFontType = "BOLD_BODY",
  isFirstStateCodeAsDefault = true,
  tabIndex = 0,
  isAutoComplete = true,
}) => {
  const states: Partial<SelectOptions>[] = getStates().map((state) => ({
    displayName: state.abbreviation,
    stringValue: state.abbreviation,
  }));

  const { responseGet, dispatchGet } = useApiGet<PostalCodeDto>(
    `api/PostalCode/GetCountyByPostalCode?postalCode=${initValuePostCode}`
  );

  useEffect(() => {
    if (showCounty) {
      const postalCodeLengthAtLeast = 5;
      if (
        initValuePostCode?.length !== undefined &&
        initValuePostCode?.length >= postalCodeLengthAtLeast
      ) {
        if (!readOnly) {
          dispatchGet();
        }
      } else {
        onChangeValueCounty?.("");
      }
    }
  }, [initValuePostCode]);

  useEffect(() => {
    if (responseGet.requestInstanceSuccessful && !responseGet.isLoading) {
      onChangeValueCounty?.(responseGet.axiosResponse?.data?.county ?? "");
    }
  }, [responseGet]);

  return (
    <Row
      className="main-row-input-address"
      rowDirection="column"
      {...rowWithNoMarginNorGutter}
    >
      <Col>
        <Input
          tabIndex={tabIndex}
          id={`${id}-address1`}
          name={`${name}-address1`}
          className={`${className}-address`}
          errorMessage={address1ErrorMessage}
          label={showSingleLabel ? "" : label}
          inputFontType={inputFontType}
          labelFontType={labelFontType}
          maxLength={100}
          permissions={permissions}
          readOnly={readOnly}
          value={initValueAddress1}
          variant={variant}
          onChangeRawValue={onChangeValueAddress1 as any}
          isAutoComplete={isAutoComplete ?? true}
        />
      </Col>
      {showAddress2 && !readOnly ? (
        <Col>
          <Input
            tabIndex={tabIndex + 1}
            id={`${id}-address2`}
            name={`${name}-address2`}
            className={`${className}-address2`}
            errorMessage={address2ErrorMessage}
            label={labelAddress2}
            inputFontType={inputFontType}
            labelFontType={labelFontType}
            maxLength={100}
            permissions={permissions}
            readOnly={readOnly}
            value={initValueAddress2}
            variant={variant}
            onChangeRawValue={onChangeValueAddress2 as any}
            isAutoComplete={isAutoComplete ?? true}
          />
        </Col>
      ) : (
        showAddress2 &&
        initValueAddress2 !== "" && (
          <Col>
            <Input
              tabIndex={tabIndex + 1}
              id={`${id}-address2`}
              name={`${name}-address2`}
              className={`${className}-address2`}
              label={showSingleLabel ? "" : labelAddress2}
              inputFontType={inputFontType}
              labelFontType={labelFontType}
              permissions={permissions}
              readOnly={readOnly}
              value={initValueAddress2}
              variant={variant}
              isAutoComplete={isAutoComplete ?? true}
            />
          </Col>
        )
      )}

      {!readOnly || !showSingleLabel ? (
        <Row {...rowWithNoMarginNorGutter}>
          <Col
            breakpoints={{
              xs: 12,
              md: showCounty ? 3 : 6,
              lg: showCounty ? 3 : 6,
              xl: showCounty ? 3 : 6,
            }}
          >
            <Input
              tabIndex={tabIndex + 2}
              id={`${id}-city`}
              name={`${name}-city`}
              className={`${className}-city`}
              errorMessage={cityErrorMessage}
              label={showSingleLabel ? "" : labelCity}
              inputFontType={inputFontType}
              labelFontType={labelFontType}
              maxLength={cityMaxLength}
              permissions={permissions}
              readOnly={readOnly}
              value={initValueCity}
              variant={variant}
              onChangeRawValue={onChangeValueCity as any}
              isAutoComplete={isAutoComplete ?? true}
            />
          </Col>
          <Col breakpoints={{ xs: 12, md: 3, lg: 3, xl: 3 }}>
            <Select
              id={`input-address-${id}`}
              tabIndex={tabIndex + 3}
              name={`${name}-state-code`}
              inputWidth={"100%"}
              optionsMaxHeight="220px"
              errorMessage={stateCodeErrorMessage}
              label={showSingleLabel ? "" : labelState}
              inputFontType={inputFontType}
              labelFontType={labelFontType}
              options={states}
              readOnly={readOnly}
              value={initValueStateCode ?? ""}
              variant={variant}
              onChange={onChangeStateCode as any}
              firstOptionAsDefault={isFirstStateCodeAsDefault ?? true}
            />
          </Col>
          <Col breakpoints={{ xs: 12, md: 3, lg: 3, xl: 3 }}>
            <Input
              tabIndex={tabIndex + 4}
              id={`${id}-postal-code`}
              name={`${name}-postal-code`}
              className={`${className}-postal-code`}
              errorMessage={postCodeErrorMessage}
              inputFontType={inputFontType}
              labelFontType={labelFontType}
              label={showSingleLabel ? "" : labelPostalCode}
              maxLength={10}
              permissions={permissions}
              readOnly={readOnly}
              type={"postalCode"}
              value={initValuePostCode}
              variant={variant}
              onChangeRawValue={onChangeValuePostCode}
              isAutoComplete={isAutoComplete ?? true}
            />
          </Col>
          {showCounty && (
            <Col breakpoints={{ xs: 12, md: 3, xl: 3 }}>
              <Input
                tabIndex={tabIndex + 5}
                id={`${id}-county`}
                name={`${name}-county`}
                className={`${className}-county`}
                inputFontType={inputFontType}
                labelFontType={labelFontType}
                label={showLabelCounty ? "County" : ""}
                maxLength={100}
                readOnly
                sx={showLabelCounty ? {} : { mt: "24px" }}
                variant={variant}
                value={initValueCounty}
                isAutoComplete={isAutoComplete ?? true}
              />
            </Col>
          )}
        </Row>
      ) : (
        <Col>
          <Input
            tabIndex={tabIndex}
            id={`${id}-input-full-address-on-read-only`}
            name={`${name}-input-full-address-on-read-only`}
            className={`${className}-input-full-address-on-read-only`}
            label={""}
            inputFontType={inputFontType}
            labelFontType={labelFontType}
            permissions={permissions}
            readOnly
            value={`${initValueCity}, ${initValueStateCode} ${initValuePostCode}`}
            variant={variant}
            isAutoComplete={isAutoComplete ?? true}
          />
        </Col>
      )}
    </Row>
  );
};

export default InputAddress;
