import { FC, useEffect, useState } from "react";
import {
  Col,
  Font,
  Input,
  InputDate,
  Row,
  Select,
} from "../../../../../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../../../../../TrueUI/Grids/Row";
import { IconButton } from "@mui/material";
import { AddOutlined } from "@mui/icons-material";
import {
  inputConfiguration,
  selectConfiguration,
} from "../../../../../../../../utilities/inputPropsConfigurationFunctions";
import { useApiGet } from "../../../../../../../../hooks";
import { PaymentEOBDetailDto } from "../../../../../../../../dtos/payment-eob-detail-dto";
import { isAPITotallyComplete } from "../../../../../../../../utilities/apiFunctions";
import { ServiceFeeScheduleDto } from "../../../../../../../../dtos/service-fee-schedule-dto";
import DialogConfirmation from "../../../../../../../TrueUI/Dialogs/DialogConfirmation";
import { FormBillIndividualDetailsProps } from "../TypesPaymentBillDetails";
import { useRecoilState } from "recoil";
import { ClaimFinancialPaymentBillDetailsAtom } from "../../ClaimFinancialAtoms";
import style from "../PaymentBillDetails.module.css";
import { addEmptyOption } from "../../../../../../../../utilities/selectFunctions";
import { FormattingDate } from "../../../../../../../../utilities/dateFunctions";

const FormBillIndividualDetails: FC<FormBillIndividualDetailsProps> = ({
  claimId,
  payeeId,
  paymentId,
  payToClaimant,
}) => {
  const billIndividualDetailsDefaultValues = {
    quantity: 1,
  };
  const [paymentBillDetailsAtom, setPaymentBillDetailsAtom] = useRecoilState(
    ClaimFinancialPaymentBillDetailsAtom
  );
  const [errors, setError] = useState<any>(null);
  const [showAlert, setShowAlert] = useState(false);
  const [billIndividualDetails, setBillIndividualDetails] =
    useState<Partial<PaymentEOBDetailDto> | null>(
      billIndividualDetailsDefaultValues
    );
  const [serviceFeeSchedule, setServiceFeeSchedule] =
    useState<Partial<ServiceFeeScheduleDto> | null>(null);

  const { responseGet, dispatchGet } = useApiGet<ServiceFeeScheduleDto>(
    `api/ServiceFeeSchedule/GetServiceFeeScheduleByServiceCode?serviceCode=${
      billIndividualDetails?.serviceCode
    }&claimId=${claimId}&payeeId=${payeeId}&paymentId=${paymentId}&payToClaimant=${payToClaimant}&serviceFrom=${FormattingDate(
      billIndividualDetails?.serviceFromDate
    )}`
  );

  const getAllowedAmount = (
    serviceFeeSchedule: ServiceFeeScheduleDto | null
  ) => {
    const amountAllowed: { value: number } = {
      value:
        serviceFeeSchedule?.feeAmount ??
        billIndividualDetails?.amountBilled ??
        0,
    };
    if (
      (serviceFeeSchedule?.feePct ?? 0) > 0 &&
      (serviceFeeSchedule?.feeAmount ?? 0) === 0
    ) {
      const feePctPercentage = (serviceFeeSchedule?.feePct ?? 0) / 100;
      const charges =
        feePctPercentage * (billIndividualDetails?.amountBilled ?? 0);
      amountAllowed.value = charges;
    }
    setBillIndividualDetails({
      ...billIndividualDetails,
      amountAllowed: amountAllowed.value,
    });
  };

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setServiceFeeSchedule(responseGet?.responseData ?? null);
      if (
        (responseGet?.responseData?.serviceFeeScheduleID ?? 0) === 0 &&
        (billIndividualDetails?.serviceCode?.length ?? 0) > 0
      ) {
        setShowAlert(true);
        setBillIndividualDetails({
          ...billIndividualDetails,
          amountAllowed: billIndividualDetails?.amountBilled ?? 0,
        });
      } else {
        getAllowedAmount(responseGet?.responseData ?? null);
      }
    }
  }, [responseGet]);

  useEffect(() => {
    setBillIndividualDetails({
      ...billIndividualDetails,
      eOBCode: serviceFeeSchedule?.eobCode ?? "00",
    });
  }, [serviceFeeSchedule?.eobCode]);

  useEffect(() => {
    if (serviceFeeSchedule !== null) {
      dispatchGet();
    }
  }, [billIndividualDetails?.serviceFromDate]);

  const [payAmount, setPayAmount] = useState(
    (billIndividualDetails?.amountAllowed ?? 0) *
      (billIndividualDetails?.quantity ?? 0)
  );

  useEffect(() => {
    const amountAllowed =
      billIndividualDetails?.amountAllowed ??
      billIndividualDetails?.amountBilled ??
      0;
    setPayAmount(amountAllowed * (billIndividualDetails?.quantity ?? 0));
  }, [billIndividualDetails?.amountAllowed, billIndividualDetails?.quantity]);

  useEffect(() => {
    if ((serviceFeeSchedule?.serviceFeeScheduleID ?? 0) !== 0) {
      getAllowedAmount(serviceFeeSchedule);
    } else {
      setBillIndividualDetails({
        ...billIndividualDetails,
        amountAllowed: billIndividualDetails?.amountBilled ?? 0,
      });
    }
  }, [billIndividualDetails?.amountBilled]);

  useEffect(() => {
    if (billIndividualDetails?.serviceFromDate !== null) {
      setError(null);
      setBillIndividualDetails({
        ...billIndividualDetails,
        serviceToDate: billIndividualDetails?.serviceFromDate,
      });
    }
  }, [billIndividualDetails?.serviceFromDate]);

  const addBillDetailRow = () => {
    if (billIndividualDetails?.serviceFromDate == null) {
      setError({
        serviceFromDate: ["This field is required."],
      });
    } else {
      setPaymentBillDetailsAtom({
        ...paymentBillDetailsAtom,
        paymentBillDetails: [
          {
            individualBillDetails: {
              ...billIndividualDetails,
              amountPaid: payAmount,
            },

            paymentServiceFeeSchedule: serviceFeeSchedule,
          },
          ...(paymentBillDetailsAtom?.paymentBillDetails ?? []),
        ],
      });
      setBillIndividualDetails(billIndividualDetailsDefaultValues);
      setServiceFeeSchedule(null);
    }
  };

  const generateArray = (data) => {
    const mapping = [
      { displayName: "A", intValue: 1, key: "diagnosisCode1" },
      { displayName: "B", intValue: 2, key: "diagnosisCode2" },
      { displayName: "C", intValue: 3, key: "diagnosisCode3" },
      { displayName: "D", intValue: 4, key: "diagnosisCode4" },
    ];

    return mapping.filter(({ key }) => (data?.[key] ?? null) != null);
  };

  return (
    <>
      <Row
        {...rowWithNoMarginNorGutter}
        numberOfColumns={24}
        verticalAlign={"flex-end"}
      >
        <Col breakpoints={{ xs: 2, sm: 2, md: 2, lg: 3, xl: 3 }}>
          <InputDate
            id="id-service-from-date"
            name={"bill-individual-detail-service-from-date"}
            label="Service From"
            value={billIndividualDetails?.serviceFromDate}
            onChangeRawValue={(value) =>
              setBillIndividualDetails({
                ...billIndividualDetails,
                serviceFromDate: value,
              })
            }
            labelFontType={"BOLD_CAPTION"}
            errorMessage={errors?.serviceFromDate}
          />
        </Col>
        <Col breakpoints={{ xs: 2, sm: 2, md: 2, lg: 3, xl: 3 }}>
          <InputDate
            id="id-service-to-date"
            name={"bill-individual-detail-service-to-date"}
            label="Service To"
            value={
              billIndividualDetails?.serviceToDate ??
              billIndividualDetails?.serviceFromDate
            }
            onChangeRawValue={(value) =>
              setBillIndividualDetails({
                ...billIndividualDetails,
                serviceToDate: value,
              })
            }
            labelFontType={"BOLD_CAPTION"}
          />
        </Col>
        <Col
          breakpoints={{ xs: 2, sm: 2, md: 2, lg: 3, xl: 3 }}
          displayFlex={false}
        >
          <Input
            id="bill-individual-detail-cpt-hcpcs"
            name="bill-individual-detail-cpt-hcpcs"
            maxLength={7}
            label="CPT/HCPCS"
            value={billIndividualDetails?.serviceCode ?? ""}
            onChangeRawValue={(value) =>
              setBillIndividualDetails({
                ...billIndividualDetails,
                serviceCode: value,
              })
            }
            onBlur={() => dispatchGet()}
            readOnly={(billIndividualDetails?.serviceFromDate ?? null) === null}
          />
          {serviceFeeSchedule?.longDescription && (
            <Font fontType={"CAPTION"} opacity={70}>
              <span
                className={style.helper_for_service_code}
                title={serviceFeeSchedule?.longDescription}
              >
                {serviceFeeSchedule?.longDescription}
              </span>
            </Font>
          )}
        </Col>
        <Col breakpoints={{ xs: 2, sm: 2, md: 2, lg: 1, xl: 1 }}>
          <Select
            {...selectConfiguration(
              "bill-individual-detail-diagnosis-code",
              "bill-individual-detail-diagnosis-code",
              "Diag"
            )}
            options={addEmptyOption(generateArray(paymentBillDetailsAtom), "")}
            value={billIndividualDetails?.diagnosisCodeId}
            onChange={(value) =>
              setBillIndividualDetails({
                ...billIndividualDetails,
                diagnosisCodeId: value,
              })
            }
            labelFontType={"BOLD_CAPTION"}
          />
        </Col>
        <Col breakpoints={{ xs: 2, sm: 2, md: 2, lg: 4, xl: 4 }}>
          <Input
            {...inputConfiguration(
              "bill-individual-detail-charges",
              "name-charges",
              "Charges"
            )}
            maxNumericValue={999999999}
            type={"fixedCurrency"}
            prefix={""}
            align={"right"}
            value={billIndividualDetails?.amountBilled}
            labelFontType={"BOLD_CAPTION"}
            onChangeRawValue={(value) =>
              setBillIndividualDetails({
                ...billIndividualDetails,
                amountBilled: value,
              })
            }
          />
        </Col>
        <Col breakpoints={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}>
          <Input
            {...inputConfiguration(
              "bill-individual-detail-quantity",
              "name-quantity",
              "Qty"
            )}
            minNumericValue={0.5}
            maxNumericValue={1000}
            maxLength={6}
            type={"fixedCurrency"}
            prefix={""}
            align={"right"}
            value={billIndividualDetails?.quantity ?? 1}
            labelFontType={"BOLD_CAPTION"}
            onChangeRawValue={(value) =>
              setBillIndividualDetails({
                ...billIndividualDetails,
                quantity: value,
              })
            }
            thousandSeparator={false}
            decimalScale={1}
          />
        </Col>
        <Col breakpoints={{ xs: 2, sm: 2, md: 2, lg: 3, xl: 3 }}>
          <Input
            {...inputConfiguration(
              "bill-individual-detail-allowed-amount",
              "name-allowed-amount",
              "Allowed"
            )}
            maxNumericValue={999999999}
            type={"fixedCurrency"}
            prefix={""}
            align={"right"}
            value={
              billIndividualDetails?.amountAllowed ??
              billIndividualDetails?.amountBilled
            }
            labelFontType={"BOLD_CAPTION"}
            readOnly
          />
        </Col>
        <Col breakpoints={{ xs: 2, sm: 2, md: 2, lg: 3, xl: 3 }}>
          <Input
            {...inputConfiguration(
              "bill-individual-detail-pay-amount",
              "name-pay-amount",
              "Pay Amount"
            )}
            maxNumericValue={999999999}
            type={"fixedCurrency"}
            prefix={""}
            align={"right"}
            value={payAmount}
            labelFontType={"BOLD_CAPTION"}
            readOnly
          />
        </Col>
        <Col breakpoints={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}>
          <Input
            id="bill-individual-detail-eob"
            name="bill-individual-detail-eob"
            label="EOB"
            readOnly
            value={billIndividualDetails?.eOBCode ?? "00"}
          />
        </Col>
        <Col
          breakpoints={{ xs: 2, sm: 2, md: 2, lg: 1, xl: 1 }}
          verticalAlignSelf={"center"}
        >
          <IconButton
            id={`plus-bill-individual-detail-action`}
            className="plus-minus-icon-container"
            sx={{ padding: "5px" }}
            disabled={false}
            onClick={() => {
              addBillDetailRow();
            }}
            true-element={"option-plus-icon"}
            color={"default"}
          >
            <AddOutlined className="plus-action-button" fontSize="small" />
          </IconButton>
        </Col>
      </Row>
      <DialogConfirmation
        id="no-service-code-found"
        open={showAlert}
        dialogDescriptionText={
          "The procedure code you entered could not be found.  You can continue to enter a manual payment or retry the code."
        }
        optionYesOverrideLabel={"OK"}
        onOptionYesEvent={(close) => {
          setShowAlert(close);
        }}
      />
    </>
  );
};

export default FormBillIndividualDetails;
