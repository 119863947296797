import { FC, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { globalOptions } from "../../GlobalAtoms";
import themes from "../../media/TrueTheme";
import { Box } from "@mui/material";
import { billingStyles } from "./BillingStyles";
import { TabPanel, Tabs, Title } from "../TrueUI";
import { SelectOptions } from "../../dtos/select-options";
import { useApiGet } from "../../hooks/useApi";
import { ProgramDto } from "../../dtos/program-dto";
import { isAPITotallyComplete } from "../../utilities/apiFunctions";
import { TOP_LEVEL_BILLING_TABS } from "../../utilities/queryStringsHash";
import PayrollReportingTabContent from "./PayrollReporting/PayrollReportingTabContent";
import PaymentActivityTable from "./PaymentActivity/PaymentActivityTable";
import CancellationTable from "./Cancellation/CancellationTable";
import ScheduledTransactionsGrid from "./Scheduled/ScheduledTransactionsGrid";
import AchTabContentWrapper from "./ACH/AchTabContentWrapper";
import StatementsTabContent from "./Statements/StatementsTabContent";
import LateFeesTableWrapper from "./LateFees/LateFeesTableWrapper";
import CommissionsTabContentWrapper from "./Commissions/CommissionsTabContentWrapper";
import { SelectedAgencyCommissionAtom } from "./Commissions/CommissionsAtoms";
import BillingHeader from "./BillingHeader";

const BillingMain: FC<any> = () => {
  const [programInfo, setProgramInfo] = useState<ProgramDto>();
  const [selectedProgram, setSelectedProgram] = useState<number | null>(null);
  const [programOptions, setProgramOptions] = useState<
    Partial<SelectOptions>[]
  >([]);
  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];
  const {
    responseGet: responseGetProgramList,
    dispatchGet: dispatchGetProgramList,
  } = useApiGet<ProgramDto[]>("api/Program/GetProgramsByTenant");

  const setTransaction = useSetRecoilState(SelectedAgencyCommissionAtom);
  const clearSelectedCommission = () => {
    setTransaction(null);
  };

  const setProgramOptionsAndDefaultValue = (programList: ProgramDto[]) => {
    const options: Partial<SelectOptions>[] =
      programList.map((program) => ({
        intValue: program.programId,
        displayName: program.programName,
      })) ?? [];
    const currentProgram = programList.find(
      (x) => x.programId === localOptions.selectedProgram?.programId
    );

    setProgramOptions(options);
    setProgramInfo(currentProgram);
    setSelectedProgram(localOptions.selectedProgram?.programId ?? -1);
  };

  useEffect(() => {
    dispatchGetProgramList();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGetProgramList)) {
      setProgramOptionsAndDefaultValue(
        responseGetProgramList.responseData ?? []
      );
    }
  }, [responseGetProgramList]);

  return (
    <Box className="billing-wrapper" sx={billingStyles(theme)}>
      <BillingHeader
        programOptions={programOptions}
        selectedProgram={selectedProgram}
        setSelectedProgram={setSelectedProgram}
      />
      <Tabs
        name={TOP_LEVEL_BILLING_TABS}
        onSelect={() => {
          clearSelectedCommission();
        }}
      >
        <TabPanel title="Payment Activity" key={"payment-activity"}>
          <PaymentActivityTable />
        </TabPanel>
        <TabPanel title="Payroll Reporting" key={"payroll-reporting"}>
          <PayrollReportingTabContent programId={selectedProgram} />
        </TabPanel>
        <TabPanel title="Statements" key={"statements"}>
          <StatementsTabContent />
        </TabPanel>
        {programInfo?.hasAch && (
          <TabPanel title={"ACH"} key={"ach"}>
            <AchTabContentWrapper
              hasAch={programInfo?.hasAch ?? false}
              selectedProgramId={programInfo?.programId ?? 0}
              selectedProgramCode={programInfo?.programCode ?? ""}
            />
          </TabPanel>
        )}
        {programInfo?.hasScheduled && (
          <TabPanel title="Scheduled" key={"scheduled"}>
            <ScheduledTransactionsGrid programId={selectedProgram ?? 0} />
          </TabPanel>
        )}
        {programInfo?.hasCancellation && (
          <TabPanel title="Cancellation" key={"cancellation"}>
            <CancellationTable />
          </TabPanel>
        )}
        {programInfo?.hasLateFees && (
          <TabPanel title="Late Fees" key={"late-fees"}>
            <LateFeesTableWrapper />
          </TabPanel>
        )}
        {programInfo?.hasRefunds && (
          <TabPanel title="Refunds" key={"refunds"}>
            <>Placeholder for Refunds</>
          </TabPanel>
        )}
        {programInfo?.hasCommissions && (
          <TabPanel
            title={
              <div onClick={clearSelectedCommission}>
                <Title>Commissions</Title>
              </div>
            }
            key={"commissions"}
          >
            <CommissionsTabContentWrapper />
          </TabPanel>
        )}
      </Tabs>
    </Box>
  );
};

export default BillingMain;
