import { FC, useEffect, useState } from "react";
import { PolicyWaiversWrapperProps } from "./TypesPolicyWaivers";
import PolicyWaiversTable from "./PolicyWaiversTable";
import { useApiGet } from "../../../../hooks";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import { WaiverTableConfigOptionsDto } from "../../../../dtos/waiver-table-config-options-dto";
import { SelectOptions } from "../../../../dtos/select-options";

const PolicyWaiversWrapper: FC<PolicyWaiversWrapperProps> = ({ insuredId }) => {
  const [policies, setPolicies] = useState<Partial<SelectOptions>[]>([]);
  const [programAllowsNoChargeWaivers, setProgramAllowsNoChargeWaivers] =
    useState<boolean | null>(null);
  const { responseGet, dispatchGet } = useApiGet<WaiverTableConfigOptionsDto>(
    `api/PolicyWaiver/GetWaiverTableConfigOptions?insuredId=${insuredId}`
  );

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setProgramAllowsNoChargeWaivers(
        responseGet?.axiosResponse?.data.programAllowsNoChargeWaivers ?? false
      );
      setPolicies(responseGet.axiosResponse?.data.policySelectOptions ?? []);
    }
  }, [responseGet]);

  return (
    <div>
      {programAllowsNoChargeWaivers !== null && (
        <PolicyWaiversTable
          insuredId={insuredId}
          programAllowsNoChargeWaivers={programAllowsNoChargeWaivers}
          policies={policies}
        />
      )}
    </div>
  );
};

export default PolicyWaiversWrapper;
