import { FC, useEffect, useState } from "react";
import {
  Caption,
  Font,
  FontBold,
  Loading,
  SmallTitle,
  Switch,
} from "../../../TrueUI";
import { PolicyQuoteEndorsementFormsProps } from "../PolicyQuoteForm/PolicyQuoteTypes";
import { ProgramEndorsementBlob } from "../../../../dtos/program-endorsement-blob";
import { FormattingDate } from "../../../../utilities/dateFunctions";
import style from "./EndorsementForms.module.css";

const RequiredForms: FC<PolicyQuoteEndorsementFormsProps> = ({ forms }) => {
  const [localForms, setLocalForms] = useState<any>();

  useEffect(() => {
    setLocalForms(forms);
  }, [forms]);

  return localForms ? (
    <div>
      <div style={{ padding: "5px" }}>
        <Font>REQUIRED FORMS</Font>
      </div>
      {localForms &&
        Object.keys(localForms)
          .sort()
          .map((key) => (
            <div key={`optional-state-container-${key}`}>
              <div className={style.state_container}>
                <div className={style.state_header_container}>
                  <FontBold>
                    {localForms?.[key]?.[0]?.stateName.toUpperCase()}
                  </FontBold>
                </div>
                {localForms?.[key]?.map((form: ProgramEndorsementBlob) => (
                  <div
                    key={`required-form-${form.stateCode}-${form.endorsementFormID}`}
                    className={style.endorsement_form_row_information_container}
                  >
                    <Switch
                      className={"endorsement_form_switch_with_label"}
                      control="checkbox"
                      label={`${form.formNumber}`}
                      labelPlacement={"end"}
                      isChecked={true}
                      primaryLabelColor
                      readOnly
                      labelFontType={"BODY"}
                      inputWidth="150px"
                    />
                    <SmallTitle>
                      {form.formName}
                      <Caption>({FormattingDate(form.formDate)})</Caption>
                    </SmallTitle>
                  </div>
                ))}
              </div>
            </div>
          ))}
    </div>
  ) : (
    <Loading isLoading={true} />
  );
};

export default RequiredForms;
