import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { AgencyInformationPage } from "../../dtos/agency-information-page";
import { AgencyLocationModalAtom } from "./AgencyAtoms";
import { useApiGet } from "../../hooks";
import Information from "./Information";
import Appointments from "./AgencyAppointment/Appointments";
import Locations from "./Locations";
import AgencyContacts from "./AgencyContacts";
import Coverages from "./AgencyCoverage/Coverages";
import { StatusEnums } from "../../dtos/status-enums";
import style from "./AgencyInformation.module.css";
import { isAPITotallyComplete } from "../../utilities/apiFunctions";
import ModalAgencyLocation from "./AgencyLocation/ModalAgencyLocation";
import { getColumnIndexByColumnName } from "../TrueUI/Tables/tableFunctions";
import { BaseGridSignatureEnum } from "../../dtos/base-grid-signature-enum";

const defaultAgencyInformation: AgencyInformationPage = {
  agencyInformation: {
    agencyId: 0,
    agencyName: "",
    agencyCode: "",
    agencyScore: 0,
    address1: "",
    address2: "",
    city: "",
    state: "",
    postCode: "",
    taxId: "",
    taxIdType: "",
    businessType: "",
    phoneNumber: "",
    phoneAdd: "",
    faxNumber: "",
    dateOpened: new Date(),
    status: StatusEnums.ACTIVE,
    websiteURL: "",
    isRequired1099: false,
  },
  locationsTable: {
    signature: BaseGridSignatureEnum.TABLE_SIGNATURE_STANDARD,
    columns: [],
    data: [],
  },
};

const AgencyInformation: FC<any> = () => {
  const { agencyId } = useParams();
  const isNewAgency = agencyId === "new";
  const [agencyLocationModalValues, setAgencyLocationModalValues] =
    useRecoilState(AgencyLocationModalAtom);
  const [agencyInformationPage, setAgencyInformationPage] =
    useState<AgencyInformationPage | null>(defaultAgencyInformation);
  const { responseGet, dispatchGet } = useApiGet<AgencyInformationPage>(
    "api/agency/GetAgencyInformationPage?agencyId=" + agencyId
  );
  const [readyToRender, setReadyToRender] = useState<boolean>(false);

  const getAllLocationNumbers = () => {
    const locNumColumnIndex = getColumnIndexByColumnName(
      "AgencyLocationNumber",
      agencyInformationPage?.locationsTable?.columns ?? []
    );
    const allLocationNumbers = agencyInformationPage?.locationsTable?.data?.map(
      (row) => row[locNumColumnIndex]
    );
    return allLocationNumbers ?? [];
  };

  useEffect(() => {
    if (!isNewAgency) dispatchGet();
    else setReadyToRender(true);
  }, []);

  useEffect(() => {
    if (agencyLocationModalValues?.refreshTable) {
      if (!isNewAgency) dispatchGet();
      else {
        setReadyToRender(true);
        setAgencyLocationModalValues({
          ...agencyLocationModalValues,
          refreshTable: false,
        });
      }
    }
  }, [agencyLocationModalValues?.refreshTable]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setAgencyInformationPage(responseGet?.axiosResponse?.data || null);
      setReadyToRender(true);
      setAgencyLocationModalValues({
        ...agencyLocationModalValues,
        refreshTable: false,
      });
    }
  }, [responseGet]);

  return (
    <>
      {readyToRender && agencyInformationPage ? (
        <div className={style.agency_information_container}>
          <div className={style.top_section_container}>
            <div className={style.top_left_column_container}>
              <Information
                agencyInformationObj={agencyInformationPage.agencyInformation}
              />
            </div>
            <div className={style.right_column_container}>
              <div className={style.top_right_table_container}>
                <Appointments agencyId={agencyId} isNewAgency={isNewAgency} />
              </div>
              <div className={style.top_right_table_container}>
                <Coverages agencyId={agencyId} isNewAgency={isNewAgency} />
              </div>
            </div>
          </div>
          <div className={style.bottom_section_container}>
            <div className={style.bottom_left_column_container}>
              {!agencyLocationModalValues?.refreshTable && (
                <Locations
                  isNewAgency={isNewAgency}
                  locationsInformation={agencyInformationPage.locationsTable}
                />
              )}
            </div>
            <div className={style.right_column_container}>
              <AgencyContacts agencyId={agencyId} isNewAgency={isNewAgency} />
            </div>
          </div>
          <ModalAgencyLocation
            agencyId={agencyId ?? "-1"}
            agencyLocationModalValues={agencyLocationModalValues}
            setAgencyLocationModalValues={setAgencyLocationModalValues}
            allLocationNumbers={getAllLocationNumbers()}
          />
        </div>
      ) : (
        <div>"Loading"</div>
      )}
    </>
  );
};

export default AgencyInformation;
