export function currencyFormat(
  value?: number | null,
  hasSign?: boolean,
  decimals?: number,
  isFixedDecimalScale: boolean = false
) {
  if (value === 0 && isFixedDecimalScale === false) return hasSign ? "$0" : "0";
  else {
    return value || value === 0
      ? new Intl.NumberFormat("en-US", {
          style: hasSign ? "currency" : "decimal",
          currency: "USD",
          maximumFractionDigits: decimals ?? 0,
          minimumFractionDigits: decimals ?? 0,
        }).format(value)
      : "";
  }
}

export function decimalStringToFloat(formatted: string) {
  const removedDecimals = formatted.split(".")?.[0];
  return parseFloat(removedDecimals.replaceAll(",", ""));
}
export const getMaxNumericValueWithPrecision = (
  valueLength: number,
  precision: number
) => {
  return parseFloat(`${"9".repeat(valueLength)}.${"9".repeat(precision)}`);
};
