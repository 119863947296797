import { FC, useState } from "react";
import { BaseTable2 } from "../../../TrueUI";
import { useBaseTable } from "../../../../hooks/useBaseTable";
import { BaseTable2Properties } from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import ModalAddCancellation from "./ModalAddCancellation";
import { InsertedCancellationDto } from "../../../../dtos/inserted-cancellation-dto";
import { toUpperCaseKeys } from "../../../../utilities/objectFunctions";
import {
  CancellationModalProps,
  PolicyCancellationTableProps,
  defaultCancellationModalProps,
} from "./CancellationUtil";

const tableName = "insured_policy_cancellation_table";

const PolicyCancellationTable: FC<PolicyCancellationTableProps> = ({
  insuredId,
}) => {
  const [cancellationModalConfig, setCancellationModalConfig] =
    useState<CancellationModalProps>({
      ...defaultCancellationModalProps,
      insuredId: insuredId,
    });

  const updateRowIntoTable = (
    action: "add" | "update" | "delete",
    newCancellation?: InsertedCancellationDto | null
  ) => {
    if (action === "add" && newCancellation)
      addRowToTable(newCancellation ?? null);
    if (action === "update" && newCancellation)
      updateRow(newCancellation ?? null);
    if (action === "delete") deleteRow();
  };

  const addRowToTable = (
    newRowData?: InsertedCancellationDto | Partial<InsertedCancellationDto>
  ) => {
    const newRow = (rowPattern: any) => {
      return { ...rowPattern, ...toUpperCaseKeys(newRowData) };
    };
    getTableMethods?.methods?.addRow(newRow);
  };

  const updateRow = (
    updatedRowData?: InsertedCancellationDto | Partial<InsertedCancellationDto>
  ) => {
    const updatedRow = {
      rowKey: cancellationModalConfig.rowData._row_key,
      hydratedData: {
        ...cancellationModalConfig.rowData,
        ...toUpperCaseKeys(updatedRowData),
      },
    };
    getTableMethods?.methods?.updateRow(updatedRow);
  };

  const deleteRow = () => {
    getTableMethods?.methods?.deleteRow({
      rowKey: cancellationModalConfig.rowData._row_key,
      hydratedData: {
        ...cancellationModalConfig.rowData,
      },
      deleteType: "hidden",
    });
  };

  const CancellationModalLink = (rowData?: any) => {
    return (
      <IconButton
        aria-label="save"
        size="small"
        onClick={() => {
          setCancellationModalConfig({
            ...cancellationModalConfig,
            cancellationId: rowData.CancellationId,
            openCancellationModal: true,
            rowData: rowData,
            action: "update",
          });
        }}
      >
        <EditIcon fontSize="small" />
      </IconButton>
    );
  };

  const tableConfiguration: BaseTable2Properties = {
    name: tableName,
    getURL: `api/Cancellation/GetCancellationTableByInsured?insuredId=${insuredId}`,
    columnOptions: [
      { fieldName: "Policy", width: 15 },
      { fieldName: "Type", width: 10 },
      { fieldName: "Reason", width: 35 },
      { fieldName: "Status", width: 10 },
      { fieldName: "NotifyDate", width: 10 },
      { fieldName: "CancelDate", width: 10 },
      { fieldName: "LastActivity", width: 25 },
      { fieldName: "OPTIONS", width: 3 },
    ],
    toolbarOptions: {
      showEditButton: false,
      showSaveButton: false,
      showAddButton: true,
      showExcelButton: true,
      showImportButton: false,
      showPDFButton: true,
      showSortFilter: false,
      addButtonText: "New Cancellation",
    },
    events: {
      addEventOverrideCallback: () => {
        setCancellationModalConfig({
          ...cancellationModalConfig,
          cancellationId: 0,
          openCancellationModal: true,
          action: "add",
        });
      },
    },
    advancedOptions: {
      paginate: false,
      isEditMode: false,
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: (actionOptions) =>
          CancellationModalLink(actionOptions.row),
      },
    },
  };

  const { tableMethods } = useBaseTable([tableConfiguration]);
  const getTableMethods = tableMethods[tableName];

  return (
    <>
      <div id={"cancellation-table-wrapper"}>
        <BaseTable2 name={tableName} />
      </div>
      <ModalAddCancellation
        cancellationModalConfig={cancellationModalConfig}
        setCancellationModalConfig={setCancellationModalConfig}
        updateRowIntoTable={updateRowIntoTable}
      />
    </>
  );
};

export default PolicyCancellationTable;
