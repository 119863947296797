import { FC, useEffect, useState } from "react";
import { Col, Font, Row } from "../../../TrueUI";
import { colWithNoMarginNorGutter } from "../../../TrueUI/Grids/Col";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { BindInstructionPremiumBreakdownProp } from "../PolicyQuoteForm/PolicyQuoteTypes";
import {
  hasBilledDepositAmount,
  hasBilledMethodReporting,
} from "./BindInstructionUtils";
import { PolicyPaymentSchedulePage } from "../../../../dtos/policy-payment-schedule-page";
import { PolicyPaymentScheduleMultiTablePage } from "../../../../dtos/policy-payment-schedule-multi-table-page";
import { useApiPost } from "../../../../hooks";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import PaymentScheduleTable from "./PaymentScheduleTable";
import { isEmptyValue } from "../../../../utilities/conditionalSupportFunctions";
import { PolicyPaymentScheduleTableRequestDto } from "../../../../dtos/policy-payment-schedule-table-request-dto";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import {
  getPolicyQuote,
  getPolicyQuoteStates,
} from "../PolicyQuoteForm/PolicyQuoteUtils";
import {
  getMultiTableGroups,
  getScheduleTableAsInvoicesForRegularQuote,
} from "./PaymentScheduleUtils";
import "./PaymentSchedule.module.css";
import { PolicyBindInstructionsBlob } from "../../../../dtos/policy-bind-instructions-blob";
import { usePolicyQuoteTriggerComponent } from "../hooks/usePolicyQuoteTriggerComponent";

const PaymentSchedule: FC<BindInstructionPremiumBreakdownProp> = ({
  insuredId,
  tabKey,
  bindInstructionUI,
  tableURL,
  readOnly = false,
}) => {
  const isBillByLocation = bindInstructionUI?.billByLocation ?? false;
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );
  const [paymentScheduleUI, setPaymentScheduleUI] =
    useState<PolicyPaymentScheduleTableRequestDto>();
  const [paymentScheduleTable, setPaymentScheduleTable] = useState<
    | PolicyPaymentSchedulePage
    | PolicyPaymentScheduleMultiTablePage
    | null
    | undefined
  >(null);
  const [showTable, setShowTable] = useState<boolean>(true);
  const { setPolicyQuoteTriggers } = usePolicyQuoteTriggerComponent();

  const {
    responsePost: scheduleTableResponse,
    dispatchPost: scheduleTableDispatch,
  } = useApiPost<
    PolicyPaymentSchedulePage | PolicyPaymentScheduleMultiTablePage
  >(tableURL ?? "", paymentScheduleUI);

  const setPaymentScheduleParametersToRequestTable = () => {
    const atomValue = getAtom();
    const currentStates = getPolicyQuoteStates(
      atomValue?.policyQuoteInformation
    );
    setPaymentScheduleUI({
      installmentPaymentAmount:
        bindInstructionUI?.installmentPaymentAmount ?? 0,
      planName: bindInstructionUI?.payPlan?.planName ?? "",
      nonEarningAmount: bindInstructionUI?.nonEarningAmount ?? -0.1,
      billedDepositAmount: bindInstructionUI?.billedDepositAmount ?? 0,
      numberOfPayments: bindInstructionUI?.numberOfPayments ?? 0,
      billedPremiumAmount: bindInstructionUI?.billedPremium ?? 0,
      billedFeesAmount: bindInstructionUI?.billedFees ?? 0,
      dueOnDay: bindInstructionUI?.dueOnDay ?? 0,
      effectiveDate: bindInstructionUI?.effectiveDate ?? new Date(),
      states: currentStates,
      depositRate: bindInstructionUI?.depositRate ?? 0,
    });
  };

  const updatePaymentScheduleTableDueDate = (newData: string[][]) => {
    setPaymentScheduleTable({
      ...paymentScheduleTable,
      tableData: {
        ...paymentScheduleTable?.tableData,
        data: newData,
      },
    } as PolicyPaymentSchedulePage);
  };

  const updatePaymentScheduleMultiTableDueDate = (
    rowIndex: number,
    newDueDate: Date
  ) => {
    const atomPaymentScheduleTable =
      getAtom()?.policyQuoteInformation?.policyQuote?.bindInstructions
        ?.scheduleMultiTable;
    const updatedMultiTableGroups = getMultiTableGroups(
      rowIndex,
      newDueDate,
      atomPaymentScheduleTable
    );
    setPaymentScheduleTable({
      ...paymentScheduleTable,
      tableData: {
        ...paymentScheduleTable?.tableData,
        groups: updatedMultiTableGroups,
      },
    } as PolicyPaymentScheduleMultiTablePage);
  };

  const getPaymentSchedulePaymentDataByReadOnlyMode = (
    bindInstructions?: PolicyBindInstructionsBlob | null
  ) => {
    if (readOnly) {
      return isBillByLocation
        ? {
            tableData: bindInstructions?.scheduleMultiTable ?? null,
            rowChildren: bindInstructions?.multiTableRowChildren ?? null,
          }
        : {
            tableData: bindInstructions?.scheduleTable ?? null,
          };
    } else {
      return isBillByLocation
        ? (bindInstructionUI?.paymentScheduleMultiTable as PolicyPaymentScheduleMultiTablePage)
        : (bindInstructionUI?.paymentScheduleTable as PolicyPaymentSchedulePage);
    }
  };

  useEffect(() => {
    const atomValue = getAtom();
    const quote = getPolicyQuote(atomValue);
    const hasPayPlanChange =
      bindInstructionUI?.payPlan?.payPlanID !== quote?.payPlan?.payPlanID;
    const hasDepositTypeChange =
      bindInstructionUI?.depositTypeForDepositOverride !==
      quote?.bindInstructions?.depositTypeForDepositOverride;
    const hasDepositRateChange =
      bindInstructionUI?.depositRate !== quote?.bindInstructions?.depositRate;
    const hasBillByLocationChange =
      bindInstructionUI?.billByLocation !==
      quote?.bindInstructions?.billByLocation;
    if (
      !readOnly &&
      (hasPayPlanChange ||
        hasDepositTypeChange ||
        hasDepositRateChange ||
        hasBillByLocationChange)
    ) {
      setPaymentScheduleTable(null);
      setPaymentScheduleParametersToRequestTable();
    } else {
      setPaymentScheduleTable(
        getPaymentSchedulePaymentDataByReadOnlyMode(quote?.bindInstructions)
      );
    }
  }, [bindInstructionUI?.billByLocation]);

  useEffect(() => {
    if (!readOnly && !isEmptyValue(paymentScheduleUI)) scheduleTableDispatch();
  }, [paymentScheduleUI]);

  useEffect(() => {
    if (
      isAPITotallyComplete(scheduleTableResponse) &&
      !isEmptyValue(scheduleTableResponse?.axiosResponse?.data)
    ) {
      setPaymentScheduleTable(scheduleTableResponse?.axiosResponse?.data);
      setShowTable(false);
      setPolicyQuoteTriggers(["paymentScheduleTableComponent"]);
    }
  }, [scheduleTableResponse]);

  useEffect(() => {
    if (!isEmptyValue(paymentScheduleTable)) {
      const atomValue = getAtom();
      setAtom({
        ...atomValue,
        policyQuoteInformation: {
          ...atomValue?.policyQuoteInformation,
          policyQuote: {
            ...atomValue?.policyQuoteInformation?.policyQuote,
            invoices: getScheduleTableAsInvoicesForRegularQuote(
              readOnly,
              atomValue,
              paymentScheduleTable
            ),
            bindInstructions: {
              ...atomValue?.policyQuoteInformation?.policyQuote
                ?.bindInstructions,
              scheduleTable: isBillByLocation
                ? atomValue?.policyQuoteInformation?.policyQuote
                    ?.bindInstructions?.scheduleTable
                : (paymentScheduleTable as PolicyPaymentSchedulePage)
                    ?.tableData,
              scheduleMultiTable: isBillByLocation
                ? (paymentScheduleTable as PolicyPaymentScheduleMultiTablePage)
                    ?.tableData
                : undefined,
              multiTableRowChildren: isBillByLocation
                ? (paymentScheduleTable as PolicyPaymentScheduleMultiTablePage)
                    ?.rowChildren
                : undefined,
            },
          },
        },
      });
    }
  }, [paymentScheduleTable]);

  useEffect(() => {
    const atomValue = getAtom();
    const bindInstructions =
      atomValue?.policyQuoteInformation?.policyQuote?.bindInstructions;
    if (
      bindInstructions !== undefined &&
      bindInstructions !== null &&
      bindInstructionUI?.billByLocation === true
    ) {
      setPaymentScheduleTable({
        tableData: bindInstructions.scheduleMultiTable ?? null,
        rowChildren: bindInstructions.multiTableRowChildren ?? null,
      } as PolicyPaymentScheduleMultiTablePage);
    }
    if (
      bindInstructions !== undefined &&
      bindInstructions !== null &&
      !bindInstructions.billByLocation
    ) {
      setPaymentScheduleTable({
        tableData: bindInstructions.scheduleTable,
      } as PolicyPaymentSchedulePage);
    }
  }, []);

  useEffect(() => {
    setShowTable(false);
  }, [readOnly]);

  useEffect(() => {
    if (showTable === false) setShowTable(true);
  }, [showTable]);

  return hasBilledDepositAmount(bindInstructionUI) &&
    !hasBilledMethodReporting(bindInstructionUI) ? (
    <>
      <Row
        {...rowWithNoMarginNorGutter}
        horizontalGutter="15px"
        horizontalAlign={"flex-start"}
        numberOfColumns={24}
        rowDirection="column"
      >
        <Col
          {...colWithNoMarginNorGutter}
          horizontalGutter="5px"
          horizontalAlign="flex-start"
          breakpoints={{ md: 4, lg: 4, xl: 4 }}
        >
          <Font
            className={`payment-schedule-table-title${
              readOnly ? "" : "-compact"
            }`}
          >
            PAYMENT SCHEDULE
          </Font>
        </Col>
        <Col
          {...colWithNoMarginNorGutter}
          horizontalGutter="5px"
          horizontalAlign="flex-start"
        >
          {!isEmptyValue(paymentScheduleTable) && showTable && (
            <PaymentScheduleTable
              tabKey={tabKey}
              insuredId={insuredId}
              paymentScheduleTable={paymentScheduleTable}
              paymentScheduleUI={paymentScheduleUI}
              bindInstructionUI={bindInstructionUI}
              updatePaymentScheduleTableDueDate={
                updatePaymentScheduleTableDueDate
              }
              updatePaymentScheduleMultiTableDueDate={
                updatePaymentScheduleMultiTableDueDate
              }
              readOnly={readOnly}
            />
          )}
        </Col>
      </Row>
    </>
  ) : null;
};

export default PaymentSchedule;
