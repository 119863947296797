import { FC, useEffect, useState } from "react";
import { Modal } from "../../../../../../TrueUI";
import { ModalRefundPaymentProps } from "./TypesModalRefundPayment";
import FormRefundPayment from "./FormRefundPayment";
import { SelectOptions } from "../../../../../../../dtos/select-options";
import { useApiGet, useApiPost } from "../../../../../../../hooks";
import { PaymentDto } from "../../../../../../../dtos/payment-dto";
import { isAPITotallyComplete } from "../../../../../../../utilities/apiFunctions";
import { useAtomFamily } from "../../../../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../../../../InsuredAtoms";
import { INSURED_ATOM_KEY } from "../../../../../../../utilities/queryStringsHash";

const ModalRefundPayment: FC<ModalRefundPaymentProps> = ({
  claimId,
  isVoidedRefundOrMoveModalConfig,
  setIsVoidedRefundOrMoveModalConfig,
  reservesInformation,
  tabKey,
}) => {
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { setComponentTriggers } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );
  const [refundReasonSelectOptions, setRefundReasonSelectOptions] = useState<
    SelectOptions[] | null
  >(null);
  const [refundPayment, setRefundPayment] = useState<
    PaymentDto | Partial<PaymentDto> | null
  >(null);

  const [errorDetails, setErrorDetails] = useState<any>();

  const { responsePost, dispatchPost, validatorErrorResponse } = useApiPost<
    PaymentDto | Partial<PaymentDto>
  >(`api/Payment/SaveRefundPayment`, {
    ...refundPayment,
    claimId: claimId,
  });

  const closeModal = (refreshTable = false) => {
    setIsVoidedRefundOrMoveModalConfig?.({
      ...isVoidedRefundOrMoveModalConfig,
      refundPaymentData: null,
      refund: false,
      voided: false,
      refreshTable,
    });
  };

  useEffect(() => {
    if (isAPITotallyComplete(responsePost)) {
      closeModal(true);
      setComponentTriggers(["claimBanner"]);
    }
  }, [responsePost]);

  useEffect(() => {
    setErrorDetails(validatorErrorResponse?.errorDetails);
  }, [validatorErrorResponse]);

  useEffect(() => {
    if (isVoidedRefundOrMoveModalConfig.refundPaymentData !== null) {
      setRefundPayment({
        ...(isVoidedRefundOrMoveModalConfig?.refundPaymentData ?? null),
        paymentMethod: null,
        referenceNo: null,
        paidFromDate: null,
        paidToDate: null,
        comments: null,
        paymentDate: new Date(),
      });
    }
  }, [isVoidedRefundOrMoveModalConfig?.refundPaymentData]);

  const _refundModalProps = {
    id: "claim-financial-refund-payment",
    title: "Add Refund",
    showCloseButton: true,
    saveEvent: () => dispatchPost(),
    closeEvent: () => closeModal(),
    deleteEvent: undefined,
    open: isVoidedRefundOrMoveModalConfig?.refund,
    cancelEvent: () => closeModal(),
  };

  const { responseGet, dispatchGet } = useApiGet<SelectOptions[]>(
    `api/Payment/GetRefundReasonOptions`
  );

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setRefundReasonSelectOptions(responseGet?.responseData ?? []);
    }
  }, [responseGet]);

  useEffect(() => {
    if (refundPayment !== null && refundReasonSelectOptions === null) {
      dispatchGet();
    }
  }, [refundPayment]);

  return (
    <>
      <Modal {..._refundModalProps}>
        <FormRefundPayment
          reserveTypeOptions={isVoidedRefundOrMoveModalConfig.reserveTypes}
          reservesInformation={reservesInformation}
          refundPayment={refundPayment}
          setRefundPayment={setRefundPayment}
          refundReasonOptions={refundReasonSelectOptions ?? []}
          errorDetails={errorDetails}
        />
      </Modal>
    </>
  );
};

export default ModalRefundPayment;
