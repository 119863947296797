import { FC, useEffect, useState } from "react";
import { Button, Col, Input, Row, Switch } from "../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import StyleBox from "../../../TrueUI/StyleBox/StyleBox";
import { inputConfiguration } from "../../../../utilities/inputPropsConfigurationFunctions";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import {
  getAllTemplateNamesToPrint,
  getDefaultFileName,
} from "./EndorsementDocumentsUtils";
import { useFormRequest } from "../../../../hooks/useFileStorage";
import { FormTypeEnum } from "../../../../dtos/form-type-enum";
import DialogConfirmation, {
  DialogConfirmationProps,
} from "../../../TrueUI/Dialogs/DialogConfirmation";
import { ProgramEndorsementBlob } from "../../../../dtos/program-endorsement-blob";
import {
  getJSONDataWithoutConfigurations,
  getPolicyQuote,
} from "../PolicyQuoteForm/PolicyQuoteUtils";
import { isEmptyValue } from "../../../../utilities/conditionalSupportFunctions";
import { isJsonString } from "../../../../utilities/stringFunctions";
import {
  getActivityLogCategory,
  getDocumentFileCategory,
} from "../SharedComponents/Documents/DocumentsUtils";
import { PolicyFormTypeEnum } from "../../../../dtos/policy-form-type-enum";

const columnProps = (
  columns: number,
  equalWidth: boolean,
  displayFlex: boolean
) => ({
  equalWidth: equalWidth,
  displayFlex: displayFlex,
  breakpoints: {
    xs: columns,
    sm: columns,
    md: columns,
    lg: columns,
    xl: columns,
  },
});

export type EndorsementDocumentFileOptionsProps = {
  insuredId: number;
  tabKey: string;
  fileName: string;
  customJSONToPrint: string;
  setFileName: (name: string) => void;
  endorsementDocuments?: any[];
  selectedDocuments?: ProgramEndorsementBlob[];
};

const EndorsementDocumentFileOptions: FC<
  EndorsementDocumentFileOptionsProps
> = ({
  insuredId,
  tabKey,
  fileName,
  customJSONToPrint,
  setFileName,
  selectedDocuments,
  endorsementDocuments,
}) => {
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom } = useAtomFamily(GlobalInsuredAtomFamily(insuredIdAtomKey));
  const { sendMergeFormRequest } = useFormRequest();
  const [saveFile, setSaveFile] = useState<boolean>(false);
  const [dialogConfiguration, setDialogConfiguration] =
    useState<DialogConfirmationProps | null>(null);

  useEffect(() => {
    const atomValue = getAtom();
    const policyQuote = getPolicyQuote(atomValue);
    const insuredName = policyQuote?.insured?.insuredName ?? "";
    const defaultFileName = getDefaultFileName(insuredName, selectedDocuments);
    setFileName(defaultFileName);
  }, [selectedDocuments]);

  const evaluatePrintingConfigurations = () => {
    const { hasInvalidTemplateNames, allTemplateWithDocSourceNames } =
      getAllTemplateNamesToPrint(selectedDocuments, endorsementDocuments);

    if (hasInvalidTemplateNames) {
      setDialogConfiguration({
        open: true,
        dialogDescriptionText: "No print template(s) found.",
      });

      return { isFullyConfigured: false, allTemplateWithDocSourceNames };
    }

    if (
      allTemplateWithDocSourceNames.find(
        (templateWithDocSource) =>
          templateWithDocSource.mergeFieldSource === FormTypeEnum.CUSTOM_FORM
      ) !== undefined &&
      customJSONToPrint === ""
    ) {
      setDialogConfiguration({
        open: true,
        dialogDescriptionText:
          "There are documents selected requesting the usage of the custom JSON for printing but there is no custom JSON set up for the current policy period.",
      });

      return { isFullyConfigured: false, allTemplateWithDocSourceNames };
    }

    return { isFullyConfigured: true, allTemplateWithDocSourceNames };
  };

  const printDocuments = (downloadOrMail: "download" | "mail") => {
    const policyMergeFields = getAtom()?.policyQuoteInformation?.policyQuote;
    if (policyMergeFields !== undefined && policyMergeFields !== null) {
      const { isFullyConfigured, allTemplateWithDocSourceNames } =
        evaluatePrintingConfigurations();

      if (isFullyConfigured) {
        const fileCategory = getDocumentFileCategory(
          PolicyFormTypeEnum.ENDORSEMENT_FORM
        );
        sendMergeFormRequest(
          {
            formType: FormTypeEnum.POLICY_FORM,
            policyMergeFields: getJSONDataWithoutConfigurations(
              getAtom()?.policyQuoteInformation
            ),
            customMergeFields: isJsonString(customJSONToPrint)
              ? JSON.parse(customJSONToPrint)
              : null,
            templateNameWithSpecificSourceMergeFieldsList:
              allTemplateWithDocSourceNames,
            customFileName: fileName,
            configurationToSaveFile: {
              saveFileToInsured: saveFile,
              fileCategory,
              insuredId: insuredId,
              claimId: null,
              policyId: policyMergeFields.policyID ?? -1,
              activityLogCategory: getActivityLogCategory(
                PolicyFormTypeEnum.ENDORSEMENT_FORM
              ),
              activityLogDescription: !isEmptyValue(
                policyMergeFields.policyNumber
              )
                ? `The ${fileCategory.toLocaleLowerCase()} for policy number ${
                    policyMergeFields.policyNumber
                  } were generated`
                : `The ${fileCategory.toLocaleLowerCase()} for policy ID ${
                    policyMergeFields.policyID
                  } were generated`,
            },
          },
          downloadOrMail,
          true
        );
      }
    }
  };

  return (
    <StyleBox margin={0} padding={0} width={"100%"} showBorder>
      <Row
        {...rowWithNoMarginNorGutter}
        horizontalAlign="flex-start"
        numberOfColumns={24}
      >
        <Col {...columnProps(3, true, false)} verticalAlignSelf="center">
          <Switch
            {...inputConfiguration("save-file", "saveFile", "Save File")}
            control="checkbox"
            isChecked={saveFile}
            onChangeIsChecked={setSaveFile}
          />
        </Col>
        <Col {...columnProps(17, true, false)} verticalAlignSelf="center">
          <Input
            {...inputConfiguration("file-name", "fileName", "File Name", 100)}
            value={fileName}
            onChangeRawValue={setFileName}
            labelFontType={"BOLD_CAPTION"}
            labelPosition="start"
            variant="filled"
            fileNameValidation
          />
        </Col>
        <Col {...columnProps(4, false, true)}>
          <Button
            id="policy-doc-email"
            name="email"
            onClick={() => printDocuments("mail")}
          >
            EMAIL
          </Button>
          <Button
            id="policy-doc-print"
            name="print"
            onClick={() => printDocuments("download")}
          >
            PRINT
          </Button>
        </Col>
      </Row>

      <DialogConfirmation
        {...dialogConfiguration}
        id="id-dialog-confirmation-endorsement-document-file-options"
        optionYesOverrideLabel="OK"
        onOptionYesEvent={() => setDialogConfiguration(null)}
        onCloseEvent={() => setDialogConfiguration(null)}
      />
    </StyleBox>
  );
};

export default EndorsementDocumentFileOptions;
