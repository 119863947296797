import { atom } from "recoil";
import { ClaimFinancialDto } from "../../../../../../dtos/claim-financial-dto";
import { ClaimFlagsDataDto } from "../../../../../../dtos/claim-flags-data-dto";
import { ServiceFeeScheduleDto } from "../../../../../../dtos/service-fee-schedule-dto";
import { PaymentEOBDetailDto } from "../../../../../../dtos/payment-eob-detail-dto";
import { PaymentDto } from "../../../../../../dtos/payment-dto";
import { DiagnosisCodeDto } from "../../../../../../dtos/diagnosis-code-dto";

export const ClaimFinancialGetAPIRequesterAtom = atom<{
  isRequested: boolean;
  isCompleted: boolean;
}>({
  key: "ClaimFinancialGetAPIRequesterAtom",
  default: {
    isRequested: true,
    isCompleted: false,
  },
});

export const ClaimFinancialDataAtom = atom<ClaimFinancialDto | null>({
  key: "ClaimFinancialDataAtom",
  default: null,
});

export const ClaimFlagsDataAtom = atom<ClaimFlagsDataDto | null>({
  key: "ClaimFlagsDataAtom",
  default: null,
});

export const ReloadPaymentsAndReservesGridAtom = atom<boolean>({
  key: "ReloadPaymentsAndReservesGridAtom",
  default: false,
});

export const ReloadReservesSubGridAtom = atom<boolean>({
  key: "ReloadReservesSubGridAtom",
  default: false,
});

type PaymentBillDetailsProps = {
  individualBillDetails: Partial<PaymentEOBDetailDto> | null;
  paymentServiceFeeSchedule: Partial<ServiceFeeScheduleDto> | null;
};

type ClaimFinancialPaymentBillDetailsAtomProps = {
  paymentBillDetails?: PaymentBillDetailsProps[] | null;
  diagnosisCode1?: Partial<DiagnosisCodeDto> | null;
  diagnosisCode2?: Partial<DiagnosisCodeDto> | null;
  diagnosisCode3?: Partial<DiagnosisCodeDto> | null;
  diagnosisCode4?: Partial<DiagnosisCodeDto> | null;
  paymentData?: PaymentDto | Partial<PaymentDto> | null;
  paymentBillDetailsToDelete?: number[] | null;
  totalCharge?: number | null;
  totalPaid?: number | null;
  isModalForBillDetailsOpen?: boolean | null;
  clearPaymentBillDetails?: boolean | null;
  isDeletingPaymentBillDetails?: boolean | null;
};
export const ClaimFinancialPaymentBillDetailsAtom =
  atom<ClaimFinancialPaymentBillDetailsAtomProps | null>({
    key: "ClaimFinancialPaymentBillDetails",
    default: null,
  });
