import { FC, useEffect } from "react";
import { EndorsementGeneralProps } from "../EndorsementForm/EndorsementTypes";
import AuditResults from "./AuditResults";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import {
  SaveQuoteAtom,
  usePolicyQuoteTriggerComponent,
} from "../hooks/usePolicyQuoteTriggerComponent";
import { PolicyBlob } from "../../../../dtos/policy-blob";
import { useApiPost } from "../../../../hooks";
import { AuditSectionEnum } from "../../../../dtos/audit-section-enum";
import {
  CONTINUE_ACTION,
  SAVE_ONLY_ACTION,
  getAtomUpdatedAfterSaveEndorsement,
} from "../EndorsementForm/EndorsementUtils";
import { useRecoilState } from "recoil";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import style from "../AuditForm/Audit.module.css";
import { updatePolicyQuoteInformationMultiTarget } from "../updatesPolicyQuoteFunctions";
import PolicyChangeStatusWrapper from "../PolicyChangeStatusModal/PolicyChangeStatusWrapper";
import Documents from "../SharedComponents/Documents/Documents";
import { PolicyFormTypeEnum } from "../../../../dtos/policy-form-type-enum";

const AuditResultsAndDocs: FC<EndorsementGeneralProps> = (props) => {
  const atomKey = `${INSURED_ATOM_KEY} ${props.tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(GlobalInsuredAtomFamily(atomKey));
  const { setPolicyQuoteTriggers } = usePolicyQuoteTriggerComponent();
  const { responsePost, dispatchPost } = useApiPost<PolicyBlob>(
    `api/PolicyAudit/SavePolicyFinalAudit?auditSection=${AuditSectionEnum.AUDIT_RESULTS_AND_DOCS}`,
    getAtom()?.policyQuoteInformation?.policyQuote
  );
  const { responsePost: responseBind, dispatchPost: dispatchBind } =
    useApiPost<PolicyBlob>(
      `api/PolicyAudit/BindFinalAudit`,
      getAtom()?.policyQuoteInformation?.policyQuote
    );

  //#region Save Logic
  const [saveQuoteAtom, setSaveQuoteAtom] = useRecoilState(SaveQuoteAtom);

  const updateAtomAfterSaveFinalAudit = (policyJSON: PolicyBlob) => {
    const atomValue = getAtom();
    const breadcrumbTargetSection =
      atomValue?.policyQuoteInformation?.breadcrumbTargetSectionAfterSave;

    const newAtomValue = getAtomUpdatedAfterSaveEndorsement(
      CONTINUE_ACTION,
      atomValue,
      policyJSON,
      breadcrumbTargetSection ?? AuditSectionEnum.EXPOSURE_AND_PREMIUM
    );

    setAtom(newAtomValue);
    setPolicyQuoteTriggers(["auditSectionChangedComponent"]);
    setSaveQuoteAtom(null);
  };

  useEffect(() => {
    if (
      saveQuoteAtom !== null &&
      saveQuoteAtom.saveAction === CONTINUE_ACTION
    ) {
      dispatchPost();
    }
    if (
      saveQuoteAtom !== null &&
      saveQuoteAtom.saveAction === SAVE_ONLY_ACTION
    ) {
      dispatchBind();
    }
  }, [saveQuoteAtom]);

  useEffect(() => {
    if (
      isAPITotallyComplete(responsePost) &&
      conditionHasValue(responsePost.axiosResponse) &&
      saveQuoteAtom !== null
    ) {
      updateAtomAfterSaveFinalAudit(responsePost.axiosResponse.data);
    }
  }, [responsePost]);

  useEffect(() => {
    if (isAPITotallyComplete(responseBind)) {
      const atomValue = getAtom();
      const policyJSON = responseBind.axiosResponse?.data ?? null;
      const newAtomValue = updatePolicyQuoteInformationMultiTarget(
        atomValue,
        ["policyQuote", "readOnly"],
        [policyJSON, true]
      );
      setAtom(newAtomValue);
      setPolicyQuoteTriggers(["policyQuoteReadOnlyComponent"]);
    }
  }, [responseBind]);
  //#endregion

  return (
    <div
      className={`${style.audit_scrollable_body_container} ${style.audit_results_and_docs_container}`}
    >
      <AuditResults {...props} />
      <Documents {...props} policyArea={PolicyFormTypeEnum.FINAL_AUDIT_FORM} />
      <PolicyChangeStatusWrapper
        tabKey={props.tabKey}
        insuredId={props.insuredId}
      />
    </div>
  );
};

export default AuditResultsAndDocs;
