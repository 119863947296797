import { SelectOptions } from "../../../../../../dtos/select-options";
import { ColProps } from "../../../../../TrueUI/Grids/Col";
import { PaymentDto } from "../../../../../../dtos/payment-dto";
import { AddPaymentSelectsOptionsDto } from "../../../../../../dtos/add-payment-selects-options-dto";
import { UpdatedPaymentRowData } from "./ClaimFinancialUtil";
import { ClaimLandingClaimantDto } from "../../../../../../dtos/claim-landing-claimant-dto";
import { PaymentFrequencyEnums } from "../../../../../../dtos/payment-frequency-enums";
import { ItemProperties } from "../../../../../TrueUI/Buttons/SplitButton";
import { PaymentStatusEnum } from "../../../../../../dtos/payment-status-enum";
import { ClaimFlagsDataDto } from "../../../../../../dtos/claim-flags-data-dto";
import { VoidedOrRefundModalOpenProps } from "./RefundComponents/TypesModalRefundPayment";
import { GettingDateWithoutTime } from "../../../../../../utilities/dateFunctions";
import { ReservesInformationDto } from "../../../../../../dtos/reserves-information-dto";

export type PaymentType =
  | "add-payment"
  | "manual-payment"
  | "view-payment"
  | "add-recovery";

export type FinancialModalProps = {
  id?: string;
  title: string;
  claimId?: number;
  open?: boolean;
  option: PaymentType;
  refreshTable: boolean;
  isCopying: boolean;
};

export type PaymentsModalProps = {
  claimId?: number;
  insuredId?: number;
  tabKey?: string;
  modalProps: FinancialModalProps | Partial<FinancialModalProps>;
  closeModal: (isOpen: boolean) => void;
  paymentId?: string | null;
  rowKey?: string;
  setNewPaymentRowData?: (
    payment: PaymentDto[] | (Partial<PaymentDto> & PaymentTableRow)[] | null
  ) => void;
  setUpdatedPaymentRowData?: (
    paymentData?: UpdatedPaymentRowData | null
  ) => void;
  setDeletedPaymentRowData?: (paymentData: DeletedPaymentProps) => void;
  setIsVoidedModalOpen?: (isVoidedModalOpen: boolean) => void;
  claimLandingClaimant?: ClaimLandingClaimantDto;
  paymentSelectsOptions: AddPaymentSelectsOptionsDto;
  claimFlagsData?: ClaimFlagsDataDto;
};

export type ModalVoidPaymentProps = {
  claimId?: number;
  paymentId?: number | null;
  rowKey?: string;
  setUpdatedPaymentRowData?: (
    paymentData?: UpdatedPaymentRowData | null
  ) => void;
  setNewPaymentRowData?: (
    paymentData: PaymentDto[] | (Partial<PaymentDto> & PaymentTableRow)[] | null
  ) => void;
  isVoidedRefundOrMoveModalConfig: VoidedOrRefundModalOpenProps;
  setIsVoidedRefundOrMoveModalConfig?: (
    isVoidedRefundOrMoveModalConfig: VoidedOrRefundModalOpenProps
  ) => void;
  tabKey: string;
};
export const colProps: ColProps = {
  horizontalMargin: "0px",
  verticalGutter: "5px",
  horizontalGutter: "5px",
};

export const splitButtonOptions: ItemProperties[] = [
  {
    option: "add-payment",
    dropdownText: "ADD PAYMENT",
    action: () => alert("This feature is coming in a future story"),
  },
  {
    option: "manual-payment",
    dropdownText: "ADD MANUAL",
    action: () => alert("This feature is coming in a future story"),
  },
  {
    option: "add-recovery",
    dropdownText: "ADD RECOVERY",
    action: () => alert("This feature is coming in a future story"),
  },
];

export const getSplitButtonOptions = (
  buttonFunctions: { option: string; action: () => void }[]
) => {
  splitButtonOptions.map((buttonOption) => {
    const buttonFunction = buttonFunctions.find(
      (buttonFunction) => buttonFunction.option === buttonOption.option
    ) ?? {
      action: () => {},
    };
    buttonOption.action = buttonFunction?.action;

    return buttonOption;
  });
  return splitButtonOptions;
};

export type ReserveTypeAndPaymentCategoryProps = {
  reserveTypeOptions: SelectOptions[] | Partial<SelectOptions>[] | [];
  reservesInformation: ReservesInformationDto | null | undefined;
  setPaymentData: (
    paymentData: PaymentDto | Partial<PaymentDto> | null
  ) => void;
  paymentData: PaymentDto | Partial<PaymentDto> | null;
  errorDetails: any;
  readOnly?: boolean;
  setPaymentCategories: (
    paymentCategories: SelectOptions[] | Partial<SelectOptions>[] | []
  ) => void;
  showTotalReserve?: boolean;
  claimLandingClaimant?: ClaimLandingClaimantDto;
};

export type SearchPayeeProps = {
  setPaymentData: (paymentData: PaymentDto | Partial<PaymentDto>) => void;
  paymentData: PaymentDto | Partial<PaymentDto>;
  errorDetails: any;
};
export type TabsClaimFinancialProps = {
  claimId?: number;
  insuredId?: number;
  paymentId?: string | null;
  tabKey?: string;
  closeModal: (isOpen: boolean, refreshTable?: boolean) => void;
  setIsVoidedRefundOrMoveModalConfig?: (
    isVoidedRefundOrMoveModalConfig: VoidedOrRefundModalOpenProps
  ) => void;
  paymentFormSelectsData?: Partial<AddPaymentSelectsOptionsDto> | null;
  paymentType?: PaymentType;
  setNewPaymentRowData?: (
    payment: PaymentDto[] | (Partial<PaymentDto> & PaymentTableRow)[] | null
  ) => void;
  setUpdatedPaymentRowData?: (payment: UpdatedPaymentRowData | null) => void;
  rowKey?: string;
  setDeletedPaymentRowData?: (paymentData: DeletedPaymentProps) => void;
  claimLandingClaimant?: ClaimLandingClaimantDto;
  paymentModalProps: Partial<FinancialModalProps>;
  setPaymentModalProps: (
    paymentModalProps: Partial<FinancialModalProps>
  ) => void;
};
export type PaymentTableRow = {
  PaymentStatus?: string;
  PaymentCategory?: string;
  PaidAmount?: string;
  ServiceDate?: string;
  PaymentDate?: string;
  InvoiceDate?: string;
};
export type AddPaymentFormProps = {
  paymentFormSelectsData?: Partial<AddPaymentSelectsOptionsDto> | null;
  claimId?: number;
  insuredId?: number;
  paymentType?: PaymentType;
  rowKey?: string;
  paymentFormData: PaymentDto | Partial<PaymentDto> | null;
  claimLandingClaimant?: ClaimLandingClaimantDto;
  tabKey?: string;
  externalErrorDetails: any;
  paymentResponse: PaymentDto[] | Partial<PaymentDto>[] | null;
  isReadOnly: boolean;
  currentPaymentStatus?: number;
  closeModal: (isOpen: boolean, refreshTable?: boolean) => void;
  setIsVoidedRefundOrMoveModalConfig?: (
    isVoidedRefundOrMoveModalConfig: VoidedOrRefundModalOpenProps
  ) => void;
  setNewPaymentRowData?: (
    payment: PaymentDto[] | (Partial<PaymentDto> & PaymentTableRow)[] | null
  ) => void;
  setUpdatedPaymentRowData?: (payment: UpdatedPaymentRowData | null) => void;
  setDeletedPaymentRowData?: (paymentData: DeletedPaymentProps) => void;
  setPaymentFormData: (
    currentPaymentData: PaymentDto | Partial<PaymentDto> | null
  ) => void;
  setExternalSave: (save: boolean) => void;
  paymentModalProps: Partial<FinancialModalProps>;
  setPaymentModalProps: (
    paymentModalProps: Partial<FinancialModalProps>
  ) => void;
};

export type DeletedPaymentProps = {
  deletedPayment: PaymentDto | (Partial<PaymentDto> & PaymentTableRow) | null;
  rowKey?: string;
};

export const defaultPaymentData = (
  defaultBankAccountId: number
): PaymentDto | Partial<PaymentDto> => ({
  paymentId: 0,
  paymentStatus: PaymentStatusEnum.PENDING,
  paymentStatusName: "Pending",
  paymentDate: GettingDateWithoutTime(new Date()),
  payToClaimant: false,
  invoiceAmount: 0,
  discountAmount: 0,
  paidAmount: 0,
  voided: false,
  _1099type: -1,
  updateBy: "",
  recurring: false,
  numberOfPayments: null,
  frequency: PaymentFrequencyEnums.DAILY,
  startDate: GettingDateWithoutTime(new Date()),
  bankAccountId: defaultBankAccountId,
});

export const defaultRecoveryData: Partial<PaymentDto> = {
  paymentId: 0,
  paymentDate: new Date(),
  paidAmount: 0,
  invoiceAmount: 0,
  discountAmount: 0,
  paymentStatus: PaymentStatusEnum.PAID,
  comments: "",
  recovery: true,
  recurring: false,
  payToClaimant: false,
  voided: false,
};

export const defaultSelectsOptions: AddPaymentFormSelectOptionsProps = {
  ten99Types: [],
  paymentStatuses: [],
  reserveTypes: [],
};
export type AddPaymentFormSelectOptionsProps = {
  ten99Types: SelectOptions[] | Partial<SelectOptions>[] | [];
  paymentStatuses: SelectOptions[] | Partial<SelectOptions>[] | [];
  reserveTypes: SelectOptions[] | Partial<SelectOptions>[] | [];
};
export type PayeeNameListProps = {
  payeeName: string;
  payeeId: number;
};
export const API_PATH = "api/Payment";

export type AddPayeeFormProps = {
  paymentFormData: PaymentDto | Partial<PaymentDto> | null;
  setPaymentFormData: (
    currentPaymentData: PaymentDto | Partial<PaymentDto> | null
  ) => void;
  closeModal: (isOpen: boolean, refreshTable?: boolean) => void;
  setCurrentTab: any;
  setExternalSave: (save: boolean) => void;
  externalErrorDetails: any;
  claimLandingClaimant?: ClaimLandingClaimantDto;
};
