import { AccountTableDto } from "../../../../../../dtos/account-table-dto";
import { BaseTableDisplayOptions } from "../../../../../../dtos/base-table-display-options";
import { BillingTransactionDataDto } from "../../../../../../dtos/billing-transaction-data-dto";
import { ConfigurationNewPaymentDto } from "../../../../../../dtos/configuration-new-payment-dto";
import { NewPaymentDto } from "../../../../../../dtos/new-payment-dto";
import { ProcessorTypeEnum } from "../../../../../../dtos/processor-type-enum";
import { currencyFormat } from "../../../../../../utilities/currencyFunctions";
import { GettingDateWithoutTime } from "../../../../../../utilities/dateFunctions";
import { Col, FontBold, Row } from "../../../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Row";

export const defaultPaymentData = (
  insuredId: number,
  defaultBankAccountId?: number,
  defaultDate?: Date | null
) => {
  return {
    insuredId: insuredId,
    bankAccountId: defaultBankAccountId,
    paymentTypeId: 1,
    paymentDate: defaultDate ? GettingDateWithoutTime(defaultDate) : undefined,
    dateReceived: defaultDate ? GettingDateWithoutTime(defaultDate) : undefined,
    postmarkDate: defaultDate ? GettingDateWithoutTime(defaultDate) : undefined,
    referenceNumber: "",
    billingTransactionDataList: [],
  };
};

export type ModalAddPaymentProps = {
  insuredId: number;
  hasAch: boolean;
  hasCreditCard: boolean;
  showModal: boolean;
  isOpenModal: (close: boolean) => void;
  accountTableData: AccountTableDto;
};

export type ModalAddPaymentContentProps = {
  insuredId: number;
  newPaymentData?: NewPaymentDto;
  setNewPaymentData?: (value: NewPaymentDto) => void;
  paymentFormSelectsData?: ConfigurationNewPaymentDto;
  errorMessages?: any;
  isReadyToRenderTable: boolean;
  errorDetails?: any;
  setBillingTransactionData?: (value: BillingTransactionDataDto[]) => void;
  accountTableData: AccountTableDto;
  hasAch: boolean;
  hasCreditCard: boolean;
};

export type AccountAndBalanceSectionProps = {
  insuredId?: number;
  errorDetails?: any;
  tranAccountOptions?: BaseTableDisplayOptions[];
  setBillingTransactionData?: (value: BillingTransactionDataDto[]) => void;
  processorType?: ProcessorTypeEnum;
  hideToolbar?: boolean;
  accountTableData: AccountTableDto;
};

export const CustomFooterComponent = (rows: any) => {
  const totalValue = rows
    ?.map((row) => row?.Payment ?? 0)
    ?.reduce((x: any, y: any) => {
      return Number(x) + Number(y?.toString()?.replaceAll(",", ""));
    }, 0);
  return (
    <Row {...rowWithNoMarginNorGutter} numberOfColumns={50} rowHeight="45px">
      <Col breakpoints={{ md: 44, lg: 44, xl: 44 }} />
      <Col
        horizontalMargin="0px"
        breakpoints={{ md: 5.2, lg: 5.2, xl: 5.2 }}
        horizontalAlign="flex-end"
        verticalAlign="center"
      >
        <FontBold>{currencyFormat(totalValue, false, 2, true)}</FontBold>
      </Col>{" "}
      <Col breakpoints={{ md: 0.8, lg: 0.8, xl: 0.8 }} />
    </Row>
  );
};

export const getPaymentFromBalanceAndAmount = (
  amount: number,
  row,
  balanceIndex
) => {
  const balance = Number(row[balanceIndex]?.replace(",", "") ?? "0");

  if (balance === 0) return 0;
  if (amount >= balance) {
    return balance;
  }

  return amount;
};

export const getDataWithRowRecalculatedByNewAmount = (
  data: any[][],
  dataIndex: number,
  amount: number,
  balanceIndex: number,
  paymentIndex: number
) => {
  if (dataIndex >= data.length) return data;

  const row = data[dataIndex];
  const payment = getPaymentFromBalanceAndAmount(amount, row, balanceIndex);
  const amountToPay = amount - payment;
  const newAmount = amountToPay < 0 ? 0 : amountToPay;

  const rowWithPaymentUpdated = row.map((cellValue, index) => {
    if (index === paymentIndex) return currencyFormat(payment, false, 2);
    return cellValue;
  });

  const dataUpdated = data.map((row, index) => {
    if (index === dataIndex) return rowWithPaymentUpdated;
    return row;
  });

  return getDataWithRowRecalculatedByNewAmount(
    dataUpdated,
    dataIndex + 1,
    newAmount,
    balanceIndex,
    paymentIndex
  );
};

export const getDataWithRowRecalculatedByNewAmountToExactBalance = (
  data: any[][],
  dataIndex: number,
  amount: number,
  balanceIndex: number,
  paymentIndex: number
) => {
  if (dataIndex >= data.length) return data;

  const row = data[dataIndex];
  const balance = Number(row[balanceIndex]?.replace(",", "") ?? "0");

  const rowWithPaymentUpdated = row.map((cellValue, colIndex) => {
    if (colIndex === paymentIndex && amount === balance)
      return currencyFormat(amount, false, 2);
    return cellValue;
  });

  const dataUpdated = data.map((row, rowIndex) => {
    if (rowIndex === dataIndex) return rowWithPaymentUpdated;
    return row;
  });
  const newAmount = balance === amount ? 0 : amount;

  return getDataWithRowRecalculatedByNewAmountToExactBalance(
    dataUpdated,
    dataIndex + 1,
    newAmount,
    balanceIndex,
    paymentIndex
  );
};

export const getGetDataWithExactPayment = (
  data: any[][],
  balanceIndex: number,
  paymentIndex: number,
  amount: number
) => {
  const reversedDataWithExactPayment =
    getDataWithRowRecalculatedByNewAmountToExactBalance(
      data,
      0,
      amount,
      balanceIndex,
      paymentIndex
    );

  const dataUpdated = [...reversedDataWithExactPayment]?.reverse() ?? [];
  return {
    dataUpdated: dataUpdated ?? [],
    hasExactPayment:
      dataUpdated.find(
        (row) => Number(row[paymentIndex]?.replace(",", "") ?? "0") === amount
      ) !== undefined,
  };
};
