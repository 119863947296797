import { FC, useEffect, useState } from "react";
import { Col, Input, Row, Select } from "../../../../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../../../../TrueUI/Grids/Row";
import {
  inputConfiguration,
  selectConfiguration,
} from "../../../../../../../utilities/inputPropsConfigurationFunctions";
import { colWithNoMarginNorGutter } from "../../../../../../TrueUI/Grids/Col";
import {
  ReserveTypeAndPaymentCategoryProps,
  SelectedOptionProps,
} from "./TypesModalRefundPayment";

const RefundReserveTypeAndPaymentCategory: FC<
  ReserveTypeAndPaymentCategoryProps
> = ({
  reserveTypeOptions,
  reservesInformation,
  setPaymentData,
  paymentData,
  errorDetails,
  readOnly,
  setPaymentCategories,
}) => {
  const [selectedReserve, setSelectedReserve] = useState<SelectedOptionProps>({
    reserveTypeId: paymentData?.reserveTypeId ?? -1,
    reserveAmount: null,
    paymentCategorySelectOptions: [],
    paymentCategorySelectKey: crypto.randomUUID(),
  });

  const getReserve = (reserveTypeId) =>
    reservesInformation?.reserves?.find(
      (x) => x?.reserveTypeId === reserveTypeId
    );

  useEffect(() => {
    const reserve = getReserve(paymentData?.reserveTypeId);

    setSelectedReserve({
      ...selectedReserve,
      reserveTypeId: reserve?.reserveTypeId,
      reserveAmount: reserve?.reserveAmount,
      paymentCategorySelectOptions: reserve?.paymentCategoryList ?? [],
    });
  }, [reservesInformation]);

  const onReserveTypeChange = (value: number) => {
    const reserve = getReserve(value);
    const options = reserve?.paymentCategoryList ?? [];
    setPaymentCategories?.(options);
    setPaymentData({
      ...paymentData,
      reserveTypeId: value,
      paymentCategoryId: options?.length > 0 ? options[0]?.intValue ?? -1 : -1,
      outstandingReserves: reserve?.reserveAmount,
    });
  };

  useEffect(() => {
    if (
      paymentData?.reserveTypeId !== null &&
      paymentData?.reserveTypeId !== undefined &&
      (reservesInformation?.reserves?.length ?? 0) > 0
    ) {
      const reserve = getReserve(paymentData?.reserveTypeId);

      setSelectedReserve({
        ...selectedReserve,
        reserveTypeId: paymentData?.reserveTypeId,
        reserveAmount: reserve?.reserveAmount,
        paymentCategorySelectOptions: reserve?.paymentCategoryList ?? [],
      });
    }
  }, [paymentData]);

  useEffect(() => {
    if (selectedReserve?.paymentCategorySelectOptions?.length > 0) {
      setSelectedReserve({
        ...selectedReserve,
        paymentCategorySelectKey: crypto.randomUUID(),
      });
    }
  }, [selectedReserve?.paymentCategorySelectOptions]);

  const onPaymentCategoryChange = (value: number) => {
    setPaymentData({
      ...paymentData,
      paymentCategoryId: value,
    });
  };

  return (
    <Col {...colWithNoMarginNorGutter} colDirection="column">
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 6, lg: 6, xl: 6 }}>
          {readOnly ? (
            <Input
              {...inputConfiguration(
                "id-reserve-type-readonly",
                "paymentFormReadOnlyReserveType",
                "Reserve Type",
                100
              )}
              value={paymentData?.reserveType}
              readOnly
              labelFontType={"BOLD_CAPTION"}
            />
          ) : (
            <Select
              tabIndex={1}
              {...selectConfiguration(
                "id-reserve-type",
                "paymentFormReserveType",
                "Reserve Type"
              )}
              options={reserveTypeOptions ?? []}
              value={paymentData?.reserveTypeId ?? 0}
              onChange={(value) => {
                onReserveTypeChange(value);
              }}
              firstOptionAsDefault={false}
              errorMessage={errorDetails?.reserveTypeId}
              labelFontType={"BOLD_CAPTION"}
              readOnly={readOnly}
            />
          )}
        </Col>

        <Col breakpoints={{ md: 6, lg: 6, xl: 6 }}>
          {readOnly ? (
            <Input
              {...inputConfiguration(
                "id-payment-category-readonly",
                "paymentFormReadOnlyPaymentCategory",
                "Payment Category",
                100
              )}
              value={paymentData?.paymentCategory}
              readOnly
              labelFontType={"BOLD_CAPTION"}
            />
          ) : (
            <Select
              tabIndex={2}
              {...selectConfiguration(
                "id-payment-category",
                "paymentFormPaymentCategory",
                "Payment Category"
              )}
              options={selectedReserve?.paymentCategorySelectOptions ?? []}
              value={paymentData?.paymentCategoryId}
              onChange={(value) => {
                onPaymentCategoryChange(value);
              }}
              firstOptionAsDefault={true}
              errorMessage={errorDetails?.paymentCategoryId}
              labelFontType={"BOLD_CAPTION"}
              key={selectedReserve?.paymentCategorySelectKey}
            />
          )}
        </Col>
      </Row>
    </Col>
  );
};

export default RefundReserveTypeAndPaymentCategory;
