import { Divider, InputDate, Modal } from "../../../../../TrueUI";
import { FC, useEffect, useState } from "react";
import {
  ContactModalFormProps,
  defaultClaimContactData,
  getDtoDataFromRowData,
} from "./ContactModalConstants/ContactModalConstants";
import {
  selectConfiguration,
  inputConfiguration,
} from "../../../../../../utilities/inputPropsConfigurationFunctions";
import {
  Row,
  Col,
  Input,
  Select,
  InputAddress,
  InputPhone,
} from "../../../../../TrueUI";
import { useApiPost } from "../../../../../../hooks";
import { rowWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Row";
import { colWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Col";
import { ClaimContactDto } from "../../../../../../dtos/claim-contact-dto";
import { StatusEnums } from "../../../../../../dtos/status-enums";
import DialogConfirmation from "../../../../../TrueUI/Dialogs/DialogConfirmation";
import { isAPITotallyComplete } from "../../../../../../utilities/apiFunctions";

const ModalClaimContact: FC<ContactModalFormProps> = ({
  modalConfiguration,
  closeModal,
  contactFormReferences,
  claimContactData,
  claimId,
  refreshTable,
}) => {
  const [isDialogConfirmationOpen, setIsDialogConfirmationOpen] =
    useState<boolean>(false);
  const [isDialogContactConfirmationOpen, setIsDialogContactConfirmationOpen] =
    useState<boolean>(false);
  const [errorDetails, setErrorDetails] = useState<any>();
  const [contactValues, setContactValues] = useState<
    ClaimContactDto | Partial<ClaimContactDto>
  >(claimContactData);

  const { responsePost, dispatchPost, validatorErrorResponse } = useApiPost<
    ClaimContactDto | Partial<ClaimContactDto>
  >("api/ClaimContact/SaveClaimContact", {
    ...contactValues,
    claimId: claimId,
    emailAddress:
      contactValues?.emailAddress === "" ? null : contactValues?.emailAddress,
  });

  useEffect(() => {
    if (claimContactData)
      setContactValues(getDtoDataFromRowData(claimContactData));
  }, [claimContactData?.claimContactId]);

  useEffect(() => {
    if (isAPITotallyComplete(responsePost)) {
      setErrorDetails(undefined);
      refreshTable?.();
      setContactValues(defaultClaimContactData);
      closeModal(false);
    }
  }, [responsePost]);

  useEffect(() => {
    if (contactValues?.saveRepeated === true) {
      dispatchPost();
    }
  }, [contactValues?.saveRepeated]);

  useEffect(() => {
    if (errorDetails?.contactRepeated) {
      setIsDialogContactConfirmationOpen(true);
    }
  }, [errorDetails]);

  useEffect(() => {
    if (validatorErrorResponse) {
      setErrorDetails(validatorErrorResponse?.errorDetails);
      setContactValues({ ...contactValues, saveRepeated: false });
    }
  }, [validatorErrorResponse]);

  useEffect(() => {
    setContactValues({
      ...contactValues,
      contactStatus: contactFormReferences?.statuses?.[0]?.intValue ?? 1,
    });
  }, [contactFormReferences]);

  const dialogConfirmationYes = (close) => {
    setContactValues({
      ...contactValues,
      contactStatus: StatusEnums.DELETED,
    });
    dispatchPost();
    setIsDialogConfirmationOpen(close);
  };

  const modalProps = {
    id: "add-edit-claim-contactValues",
    title: modalConfiguration?.title ?? "",
    open: modalConfiguration.add || modalConfiguration.edit,
    closeEvent: (e) => {
      setErrorDetails(undefined);
      closeModal(e);
    },
    saveEvent: () => {
      dispatchPost();
    },
    deleteEvent: modalConfiguration.edit
      ? () => {
          setIsDialogConfirmationOpen(true);
        }
      : undefined,
    showCloseButton: true,
    showCancelTextButton: true,
  };

  return (
    <Modal {...modalProps}>
      <Divider height={20} />
      <Row {...rowWithNoMarginNorGutter} verticalAlign="flex-start">
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 8, lg: 8, xl: 8 }}
        >
          <Row {...rowWithNoMarginNorGutter} rowDirection="column">
            <Col>
              <Input
                tabIndex={1}
                {...inputConfiguration("id-name", "contactName", "Name", 100)}
                value={contactValues.contactName}
                onChangeRawValue={(value) =>
                  setContactValues({
                    ...contactValues,
                    contactName: value === null ? "" : value,
                  })
                }
                errorMessage={errorDetails?.contactName}
                labelFontType={"BOLD_CAPTION"}
              />
            </Col>
            <Col>
              <Input
                tabIndex={2}
                {...inputConfiguration(
                  "id-company",
                  "contactCompany",
                  "Company",
                  100
                )}
                value={contactValues.company}
                onChangeRawValue={(value) =>
                  setContactValues({ ...contactValues, company: value })
                }
                labelFontType={"BOLD_CAPTION"}
                errorMessage={errorDetails?.company}
              />
            </Col>
            <Col {...colWithNoMarginNorGutter}>
              <InputAddress
                tabIndex={3}
                id="id-contactValues-location-address-input"
                name="contactValuesAddress"
                label="Address"
                initValueAddress1={contactValues.address1 ?? ""}
                initValueAddress2={contactValues?.address2}
                initValueCity={contactValues?.city ?? ""}
                initValueStateCode={contactValues?.state ?? ""}
                initValuePostCode={contactValues?.postalCode ?? ""}
                address1ErrorMessage={errorDetails?.address1}
                address2ErrorMessage={errorDetails?.address2}
                cityErrorMessage={errorDetails?.city}
                stateCodeErrorMessage={errorDetails?.state}
                postCodeErrorMessage={undefined}
                showSingleLabel={false}
                isFirstStateCodeAsDefault={false}
                onChangeValueAddress1={(value) =>
                  setContactValues({
                    ...contactValues,
                    address1: value,
                  })
                }
                onChangeValueAddress2={(value) =>
                  setContactValues({
                    ...contactValues,
                    address2: value,
                  })
                }
                onChangeValueCity={(value) =>
                  setContactValues({
                    ...contactValues,
                    city: value,
                  })
                }
                onChangeStateCode={(value) => {
                  setContactValues({
                    ...contactValues,
                    state: value,
                  });
                }}
                onChangeValuePostCode={(value) =>
                  setContactValues({
                    ...contactValues,
                    postalCode: value,
                  })
                }
                readOnly={false}
                labelFontType={"BOLD_CAPTION"}
              />
            </Col>
            <Row {...rowWithNoMarginNorGutter}>
              <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
                <InputPhone
                  tabIndex={9}
                  id="id-phone1"
                  name="contactPhone1"
                  phoneLabel="Phone 1"
                  phoneValue={contactValues?.phone1}
                  errorMessagePhone={errorDetails?.phone1}
                  onChangePhoneValue={(value) =>
                    setContactValues({
                      ...contactValues,
                      phone1: value,
                    })
                  }
                  labelFontType={"BOLD_CAPTION"}
                />
              </Col>
              <Col
                breakpoints={{ md: 2, lg: 2, xl: 2 }}
                verticalAlignSelf="flex-end"
              >
                <Select
                  tabIndex={10}
                  {...selectConfiguration(
                    "id-phone1-type",
                    "contactPhone1Type",
                    ""
                  )}
                  options={contactFormReferences?.phoneTypes ?? []}
                  value={contactValues?.phone1Type}
                  onChange={(value) => {
                    setContactValues({
                      ...contactValues,
                      phone1Type: value,
                    });
                  }}
                  labelFontType={"BOLD_CAPTION"}
                />
              </Col>
              <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
                <InputPhone
                  tabIndex={11}
                  id="id-phone2"
                  name="contactPhone2"
                  phoneLabel="Phone 2"
                  phoneValue={contactValues?.phone2}
                  errorMessagePhone={errorDetails?.phone2}
                  onChangePhoneValue={(value) =>
                    setContactValues({
                      ...contactValues,
                      phone2: value,
                    })
                  }
                  labelFontType={"BOLD_CAPTION"}
                />
              </Col>
              <Col
                breakpoints={{ md: 2, lg: 2, xl: 2 }}
                verticalAlignSelf="flex-end"
              >
                <Select
                  tabIndex={12}
                  {...selectConfiguration(
                    "id-phone2-type",
                    "contactPhone2Type",
                    ""
                  )}
                  options={contactFormReferences?.phoneTypes ?? []}
                  value={contactValues?.phone2Type}
                  onChange={(value) =>
                    setContactValues({
                      ...contactValues,
                      phone2Type: value,
                    })
                  }
                  labelFontType={"BOLD_CAPTION"}
                />
              </Col>
            </Row>
            <Col
              breakpoints={{ md: 6, lg: 6, xl: 6 }}
              horizontalAlignSelf="flex-start"
            >
              <Input
                tabIndex={13}
                {...inputConfiguration(
                  "id-email",
                  "contactEmail",
                  "Email",
                  100
                )}
                value={contactValues.emailAddress}
                onChangeRawValue={(value) =>
                  setContactValues({
                    ...contactValues,
                    emailAddress: value,
                  })
                }
                labelFontType={"BOLD_CAPTION"}
                errorMessage={errorDetails?.emailAddress}
              />
            </Col>
          </Row>
        </Col>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 4, lg: 4, xl: 4 }}
        >
          <Row {...rowWithNoMarginNorGutter} rowDirection="column">
            <Col>
              <Select
                tabIndex={14}
                {...selectConfiguration("id-status", "contactStatus", "Status")}
                options={contactFormReferences?.statuses}
                value={contactValues?.contactStatus}
                errorMessage={errorDetails?.contactStatus}
                onChange={(value) =>
                  setContactValues({
                    ...contactValues,
                    contactStatus: value,
                  })
                }
                labelFontType={"BOLD_CAPTION"}
              />
            </Col>
            <Col>
              <Input
                tabIndex={15}
                {...inputConfiguration(
                  "id-assistant-name",
                  "contactAssistantName",
                  "Assistant Name",
                  20
                )}
                value={contactValues.assistantName}
                onChangeRawValue={(value) =>
                  setContactValues({ ...contactValues, assistantName: value })
                }
                labelFontType={"BOLD_CAPTION"}
                errorMessage={errorDetails?.assistantName}
              />
            </Col>
            <Col>
              <Input
                tabIndex={16}
                {...inputConfiguration(
                  "id-relationship-to-claimant",
                  "contactRelationshipToClaimant",
                  "Relationship to Claimant",
                  20
                )}
                value={contactValues.relationshipToClaimant}
                onChangeRawValue={(value) =>
                  setContactValues({
                    ...contactValues,
                    relationshipToClaimant: value,
                  })
                }
                labelFontType={"BOLD_CAPTION"}
                errorMessage={errorDetails?.relationshipToClaimant}
              />
            </Col>
            <Col>
              <InputDate
                tabIndex={17}
                id="id-date-of-birth"
                name="contactDateOfBirth"
                label="Date of Birth"
                value={contactValues?.dateOfBirth}
                onChangeRawValue={(value) =>
                  setContactValues({
                    ...contactValues,
                    dateOfBirth: value,
                  })
                }
                labelFontType={"BOLD_CAPTION"}
              />
            </Col>
            <Col>
              <Select
                tabIndex={18}
                {...selectConfiguration(
                  "id-contactValues-type",
                  "contactType",
                  "Type"
                )}
                firstOptionAsDefault={false}
                options={contactFormReferences.types}
                value={contactValues.contactType}
                errorMessage={errorDetails?.contactType}
                onChange={(value) =>
                  setContactValues({
                    ...contactValues,
                    contactType: value,
                  })
                }
                labelFontType={"BOLD_CAPTION"}
              />
            </Col>
            <Col>
              <InputPhone
                tabIndex={19}
                id="id-fax"
                name="contactFax"
                phoneLabel="Fax"
                phoneValue={contactValues?.fax}
                errorMessagePhone={errorDetails?.fax}
                onChangePhoneValue={(value) =>
                  setContactValues({
                    ...contactValues,
                    fax: value,
                  })
                }
                labelFontType={"BOLD_CAPTION"}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider height={20} />
      <DialogConfirmation
        id="delete-contact-confirmation"
        open={isDialogConfirmationOpen}
        dialogDescriptionText="Are you sure you want to delete this contact?"
        onCloseEvent={(close) => {
          setIsDialogConfirmationOpen(close);
        }}
        onOptionNoEvent={(close) => {
          setIsDialogConfirmationOpen(close);
        }}
        onOptionYesEvent={(close) => {
          dialogConfirmationYes(close);
        }}
      />

      <DialogConfirmation
        id="save-possible-repeated-contact-confirmation"
        open={isDialogContactConfirmationOpen}
        dialogDescriptionText="This could be a duplicate contact. Do you want to proceed?"
        onCloseEvent={(close) => {
          setIsDialogContactConfirmationOpen(close);
        }}
        onOptionNoEvent={(close) => {
          setIsDialogContactConfirmationOpen(close);
        }}
        onOptionYesEvent={(close) => {
          if (
            modalConfiguration.add === true ||
            modalConfiguration.edit === true
          ) {
            setContactValues({ ...contactValues, saveRepeated: true });
            setIsDialogContactConfirmationOpen(close);
          }
        }}
      />
    </Modal>
  );
};

export default ModalClaimContact;
