import { FC, useEffect, useState } from "react";
import { useApiGet, useApiPost } from "../../../../hooks/useApi";
import { Splitter, SplitterOnChangeEvent } from "@progress/kendo-react-layout";
import {
  GlobalInsuredAtomFamily,
  GlobalInsuredAtomProperties,
} from "../../InsuredAtoms";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { getPolicyQuote } from "../PolicyQuoteForm/PolicyQuoteUtils";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import OptionalForms from "./OptionalForms";
import RequiredForms from "./RequiredForms";
import {
  getAtomUpdatedByEndorsementDocumentChecked,
  getEndorsementFormsFromResponse,
} from "./EndorsementDocumentsUtils";
import { EndorsementGeneralProps } from "../EndorsementForm/EndorsementTypes";
import "../EndorsementForm/Endorsement.module.css";
import { ProgramPolicyEndorsementDocPage } from "../../../../dtos/program-policy-endorsement-doc-page";
import { FormattingDate } from "../../../../utilities/dateFunctions";
import { ProgramEndorsementBlob } from "../../../../dtos/program-endorsement-blob";
import {
  conditionHasValue,
  isEmptyValue,
} from "../../../../utilities/conditionalSupportFunctions";
import {
  getAtomUpdatedByEndorsementTypeListChange,
  getUpdatedEndorsementSelectedList,
} from "../EndorsementInformation/EndorsementInformationUtils";
import { EndorsementTypeEnum } from "../../../../dtos/endorsement-type-enum";
import EndorsementDocumentFileOptions from "./EndorsementDocumentFileOptions";
import { ProgramPolicyEndorsementBlob } from "../../../../dtos/program-policy-endorsement-blob";
import {
  SaveQuoteAtom,
  usePolicyQuoteTriggerComponent,
} from "../hooks/usePolicyQuoteTriggerComponent";
import { PolicyBlob } from "../../../../dtos/policy-blob";
import { EndorsementSectionEnum } from "../../../../dtos/endorsement-section-enum";
import {
  CONTINUE_ACTION,
  SAVE_ONLY_ACTION,
  getAtomUpdatedAfterSaveEndorsement,
} from "../EndorsementForm/EndorsementUtils";
import { useRecoilState } from "recoil";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";

const EndorsementDocuments: FC<EndorsementGeneralProps> = ({
  insuredId,
  tabKey,
  readonly,
}) => {
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );
  const { setPolicyQuoteTriggers } = usePolicyQuoteTriggerComponent();
  const {
    responsePost: responseSaveEndorsement,
    dispatchPost: dispatchSaveEndorsement,
  } = useApiPost<PolicyBlob>(
    `api/PolicyEndorsement/SavePolicyEndorsement?endorsementSection=${EndorsementSectionEnum.POLICY_DOCS}`,
    getAtom()?.policyQuoteInformation?.policyQuote
  );

  const [fileName, setFileName] = useState<string>("");
  const [leftForms, setLeftForms] = useState<ProgramPolicyEndorsementBlob[]>();
  const [rightForms, setRightForms] = useState<ProgramEndorsementBlob[]>();
  const [selectedRightForms, setSelectedRightForms] =
    useState<ProgramEndorsementBlob[]>();

  const [panes, setPanes] = useState<Array<any>>([
    { size: "40%", min: "30%", max: "70%" },
  ]);

  const getPolicyId = () => {
    const atomValue = getAtom();
    return atomValue?.policyQuoteInformation?.policyId;
  };

  const getQuoteId = () => {
    const atomValue = getAtom();
    return atomValue?.policyQuoteInformation?.quoteId;
  };

  const getEffectiveDate = () => {
    const atomValue = getAtom();
    const policyQuote = getPolicyQuote(atomValue);
    return FormattingDate(policyQuote?.effectiveDate);
  };

  const { responseGet, dispatchGet } =
    useApiGet<ProgramPolicyEndorsementDocPage>(
      `api/QuotePolicy/GetPolicyEndorsement?policyId=${getPolicyId()}&quoteId=${getQuoteId()}&effectiveDate=${getEffectiveDate()}`
    );

  const onChange = (event: SplitterOnChangeEvent) => {
    setPanes(event.newState);
  };

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (responseGet?.axiosResponse?.data && !responseGet?.isLoading) {
      const atomValue = getAtom();
      const policyQuote = getPolicyQuote(atomValue);
      const responseData = responseGet?.axiosResponse?.data;
      const forms = getEndorsementFormsFromResponse(responseData, policyQuote);
      setLeftForms(forms.leftForms);
      setRightForms(forms.rightForms);
    }
  }, [responseGet]);

  useEffect(() => {
    if (conditionHasValue(selectedRightForms)) {
      const atomValue = getAtom();
      const policyQuote = getPolicyQuote(atomValue);
      const newEndorsementTypeSelectedList = getUpdatedEndorsementSelectedList(
        !isEmptyValue(selectedRightForms),
        {
          value: EndorsementTypeEnum.ITEM_3_D_ENDORSEMENT_NUMBERS,
        },
        policyQuote?.quote?.endorsementTypeList ?? []
      );
      const newAtomValue = getAtomUpdatedByEndorsementTypeListChange(
        newEndorsementTypeSelectedList,
        atomValue
      ) as GlobalInsuredAtomProperties;
      const updatedAtomValue = getAtomUpdatedByEndorsementDocumentChecked(
        newAtomValue,
        selectedRightForms
      ) as GlobalInsuredAtomProperties;
      setAtom(updatedAtomValue);
    }
  }, [selectedRightForms]);

  //#region Save Logic

  const [saveQuoteAtom, setSaveQuoteAtom] = useRecoilState(SaveQuoteAtom);

  const updateAtomAfterSaveEndorsement = (
    policyJSON: PolicyBlob,
    saveAction: string
  ) => {
    const atomValue = getAtom();
    const newAtomValue = getAtomUpdatedAfterSaveEndorsement(
      saveAction,
      atomValue,
      policyJSON,
      EndorsementSectionEnum.POLICY_DOCS
    );
    setAtom(newAtomValue);
    if (saveAction === CONTINUE_ACTION)
      setPolicyQuoteTriggers(["endorsementComponent"]);

    setSaveQuoteAtom(null);
  };

  useEffect(() => {
    if (
      saveQuoteAtom?.saveAction === CONTINUE_ACTION ||
      saveQuoteAtom?.saveAction === SAVE_ONLY_ACTION
    ) {
      dispatchSaveEndorsement();
    }
  }, [saveQuoteAtom]);

  useEffect(() => {
    if (
      isAPITotallyComplete(responseSaveEndorsement) &&
      responseSaveEndorsement.axiosResponse !== null &&
      responseSaveEndorsement.axiosResponse !== undefined &&
      (saveQuoteAtom?.saveAction === CONTINUE_ACTION ||
        saveQuoteAtom?.saveAction === SAVE_ONLY_ACTION)
    ) {
      updateAtomAfterSaveEndorsement(
        responseSaveEndorsement.axiosResponse.data,
        saveQuoteAtom.saveAction
      );
    }
  }, [responseSaveEndorsement]);

  //#endregion

  return (
    <div className="endorsement_documents_main_container">
      <div className="endorsement_splitter_body_container">
        <Splitter panes={panes} onChange={onChange}>
          <RequiredForms
            tabKey={tabKey}
            forms={leftForms}
            fileName={fileName}
            customJSONToPrint={
              responseGet.axiosResponse?.data.programPacket?.customPolicyJSON ??
              ""
            }
          />
          <OptionalForms
            tabKey={tabKey}
            insuredId={insuredId}
            forms={rightForms}
            customJSONToPrint={
              responseGet.axiosResponse?.data.programPacket?.customPolicyJSON ??
              ""
            }
            setSelectedOptionalForms={setSelectedRightForms}
            readonly={readonly}
          />
        </Splitter>
        <EndorsementDocumentFileOptions
          insuredId={insuredId}
          tabKey={tabKey}
          fileName={fileName}
          setFileName={setFileName}
          customJSONToPrint={
            responseGet.axiosResponse?.data.programPacket?.customPolicyJSON ??
            ""
          }
          endorsementDocuments={leftForms}
          selectedDocuments={selectedRightForms}
        />
      </div>
    </div>
  );
};

export default EndorsementDocuments;
