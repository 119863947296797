import { FC, useEffect } from "react";
import InsuredLanding from "./InsuredLanding";
import InsuredInformation from "./Information/InsuredInformation";
import { Box } from "@mui/material";
import { SubSideNavItemDestinationsEnum } from "../../dtos/sub-side-nav-item-destinations-enum";
import { GlobalInsuredAtomFamily } from "./InsuredAtoms";
import Incident from "./Claim/Incident/Incident";
import ClaimLandingMain from "./Claim/ClaimLanding/ClaimLandingMain";
import { useAtomFamily } from "../../hooks/useAtomFamily";
import {
  CLAIM_NUMBER,
  INSURED_ATOM_KEY,
  INSURED_BODY_SECTION,
  INSURED_CHILD_ITEM,
  INSURED_PARENT_ITEM,
  OPEN_CLAIM_INSIDE_INSURED,
} from "../../utilities/queryStringsHash";
import InsuredPanel from "./InsuredPanel/InsuredPanel";
import {
  addQueryStrings,
  getQueryValue,
} from "../../utilities/URLUtilities_OBSOLETE";
import AlertsContainer from "./AlertsContainer";
import { ContextEnums } from "../../dtos/context-enums";
import {
  getChildItemByInsuredSection,
  getParentItemByInsuredSection,
} from "../../utilities/staticDataFunctions";
import BillingActivityTabs from "./Billing/Activity/BillingActivityTabs";
import IncidentTable from "./Claim/Incident/IncidentTable/IncidentTable";
import BillingPayrollReports from "./Billing/PayrollReport/PayrollReports";
// import PayrollReportWrapper from "./Billing/PayrollReport/PayrollReportWrapper";
import PolicyQuote from "./Policy/PolicyQuoteForm/PolicyQuote";
import PolicyHistoryTableWrapper from "./Policy/PolicyHistoryTableWrapper";
import PolicyCancellationWrapper from "./Policy/PolicyCancellations/PolicyCancellationWrapper";
import AlertsAtomContainer from "./AlertsAtomContainer";
import { TabDto } from "./Insured";
import {
  getOpenTabByTabKey,
  removeClaimNumberFromTab,
} from "../../utilities/localStorageFunctions";
import BillingAccountsWrapper from "./Billing/Accounts/BillingAccountsWrapper";
import Endorsement from "./Policy/EndorsementForm/Endorsement";
import ScheduledWrapper from "./Billing/Scheduled/ScheduledWrapper";
import style from "./InsuredInstanceWrapper.module.css";
import ClaimsTableWrapper from "./Claim/ClaimTable/ClaimsTableWrapper";
import PolicyCertificates from "./Policy/PolicyCertificates/PolicyCertificates";
import InsuredUsersWrapper from "./Users/InsuredUsersWrapper";
import Audit from "./Policy/AuditForm/Audit";
import PolicyWaiversWrapper from "./Policy/Waivers/PolicyWaiversWrapper";
// import { removeElementTab } from "../../utilities/localStorageFunctions";

type InsuredBodyWrapperProps = {
  insuredTabContent: TabDto;
};

const PlaceHolderElement = () => (
  <Box sx={{ marginTop: 10 }}>Nav module In Development...</Box>
);

const renderInsuredBody = (
  param: number | undefined,
  insuredTabObj: TabDto
) => {
  const insuredInfo = insuredTabObj?.information?.insuredInformation;
  const insuredId = insuredTabObj?.information?.insuredInformation?.insuredId;
  const tabKey = insuredTabObj?.tabKey;
  switch (param) {
    case SubSideNavItemDestinationsEnum.MAIN:
      return (
        <InsuredLanding
          insuredObject={insuredTabObj?.information}
          tabKey={tabKey}
        />
      );
    case SubSideNavItemDestinationsEnum.INFORMATION:
      return <InsuredInformation insuredId={insuredId} tabKey={tabKey} />;
    case SubSideNavItemDestinationsEnum.INCIDENTS:
      return <IncidentTable insuredId={insuredId} tabKey={tabKey} />;
    case SubSideNavItemDestinationsEnum.INCIDENTS_INCIDENT_SECTIONS:
      return <Incident insuredId={insuredId} tabKey={tabKey} />;
    case SubSideNavItemDestinationsEnum.HISTORY:
      return (
        <PolicyHistoryTableWrapper insuredId={insuredId} tabKey={tabKey} />
      );
    case SubSideNavItemDestinationsEnum.POLICY_QUOTE_SECTIONS:
      return <PolicyQuote insuredId={insuredId} tabKey={tabKey} />;
    case SubSideNavItemDestinationsEnum.ENDORSEMENT_SECTIONS:
      return <Endorsement insuredId={insuredId} tabKey={tabKey} />;
    case SubSideNavItemDestinationsEnum.AUDIT_SECTIONS:
      return <Audit insuredId={insuredId} tabKey={tabKey} />;
    case SubSideNavItemDestinationsEnum.CLAIMS:
      return <ClaimsTableWrapper insuredId={insuredId} />;
    case SubSideNavItemDestinationsEnum.CLAIMS_LANDING:
      return <ClaimLandingMain insuredId={insuredId} tabKey={tabKey} />;
    case SubSideNavItemDestinationsEnum.BILLING_ACTIVITY:
      return (
        <BillingActivityTabs
          insuredId={insuredId}
          insuredInfo={insuredInfo}
          tabKey={tabKey}
        />
      );
    case SubSideNavItemDestinationsEnum.BILLING_PAYROLL_REPORTS:
      return <BillingPayrollReports insuredId={insuredId} tabKey={tabKey} />;
    // case SubSideNavItemDestinationsEnum.BILLING_PAYROLL_REPORT_DETAILS:
    //   return <PayrollReportWrapper insuredId={insuredId} tabKey={tabKey} />;
    case SubSideNavItemDestinationsEnum.POLICY_CANCELLATION:
      return (
        <PolicyCancellationWrapper insuredId={insuredId} tabKey={tabKey} />
      );
    case SubSideNavItemDestinationsEnum.CERTIFICATES:
      return <PolicyCertificates insuredId={insuredId} />;
    case SubSideNavItemDestinationsEnum.BILLING_ACCOUNTS:
      return <BillingAccountsWrapper insuredId={insuredId} tabKey={tabKey} />;
    case SubSideNavItemDestinationsEnum.BILLING_SCHEDULED_TRANSACTIONS:
      return <ScheduledWrapper insuredId={insuredId} tabKey={tabKey} />;
    case SubSideNavItemDestinationsEnum.USERS:
      return (
        <InsuredUsersWrapper
          insuredId={insuredId}
          insuredName={insuredInfo?.insuredName}
        />
      );
    case SubSideNavItemDestinationsEnum.POLICY_WAIVERS:
      return <PolicyWaiversWrapper insuredId={insuredId} tabKey={tabKey} />;
    default:
      return <PlaceHolderElement />;
  }
};

export const getContext = (section): number => {
  switch (section) {
    case SubSideNavItemDestinationsEnum.CLAIMS_LANDING:
      return ContextEnums.CLAIMS;
    default:
      return ContextEnums.INSURED;
  }
};

const getClaimNumberWhenNewClaim = (claimNumber) => {
  if (getQueryValue(OPEN_CLAIM_INSIDE_INSURED) === "1") {
    return getQueryValue(CLAIM_NUMBER);
  }
  return claimNumber;
};

const InsuredBodyWrapper: FC<InsuredBodyWrapperProps> = ({
  insuredTabContent,
}) => {
  const tabKey = insuredTabContent?.tabKey;
  const selectedTab = getOpenTabByTabKey(tabKey);
  const claimNumber = getClaimNumberWhenNewClaim(selectedTab?.claimNumber);
  const insuredId = selectedTab?.insuredId;
  const { getAtom, setAtom, setComponentTriggers } = useAtomFamily(
    GlobalInsuredAtomFamily(`${INSURED_ATOM_KEY} ${tabKey}`)
  );

  const getInsuredSection = () => {
    if (getQueryValue(INSURED_BODY_SECTION) != "") {
      return parseInt(getQueryValue(INSURED_BODY_SECTION));
    }
    if (claimNumber && claimNumber != "") {
      return SubSideNavItemDestinationsEnum.CLAIMS_LANDING;
    }

    return getAtom()?.insuredBodySection ?? 1;
  };

  const insuredSection = getInsuredSection();
  const context = getContext(insuredSection);

  const addQueryStringsByInsuredSection = (insuredSection: number) => {
    addQueryStrings([
      {
        nameOfHash: INSURED_BODY_SECTION,
        valueOfHash: insuredSection,
      },
      {
        nameOfHash: INSURED_PARENT_ITEM,
        valueOfHash: getParentItemByInsuredSection(insuredSection),
      },
      {
        nameOfHash: INSURED_CHILD_ITEM,
        valueOfHash: getChildItemByInsuredSection(insuredSection),
      },
    ]);
  };

  useEffect(() => {
    addQueryStringsByInsuredSection(insuredSection);
    setAtom({
      ...getAtom(),
      insuredBodySection: insuredSection,
      contextDrawer: context,
      currentClaim: {
        ...getAtom()?.currentClaim,
        claimNumber: claimNumber,
      },
    });

    // If exist one open claim, it should re-render the
    // body Wrapper to get the claimNumber correctly.
    if (claimNumber && claimNumber != "") {
      setComponentTriggers(["claimLandingPageComponent", "navComponent"]);
      if (insuredSection != SubSideNavItemDestinationsEnum.CLAIMS_LANDING) {
        const tabToRemove = {
          tabKey: tabKey,
          claimNumber: claimNumber,
          insuredId: insuredId,
        };
        removeClaimNumberFromTab(tabToRemove);
        setComponentTriggers(["tabTitleComponent"]);
      }
    }
  }, [insuredSection]);
  return (
    <>
      <div style={{ position: "sticky", zIndex: 3, top: 0 }}>
        <AlertsContainer
          insuredId={insuredId}
          tabKey={tabKey}
          context={context}
          claimNumber={claimNumber}
        />
      </div>
      {context === ContextEnums.INSURED && (
        <div style={{ position: "sticky", zIndex: 3, top: 0 }}>
          <AlertsAtomContainer
            insuredId={insuredId}
            tabKey={tabKey}
            atomTrigger="alertAtomComponent"
            atomPath={["policyQuoteInformation", "policyValidations"]}
          />
          <InsuredPanel
            tabKey={tabKey}
            insuredTab={insuredTabContent?.information}
            insuredBodySection={insuredSection}
          />
        </div>
      )}
      <div className={style.insured_body_components}>
        {renderInsuredBody(insuredSection, insuredTabContent)}
      </div>
    </>
  );
};

export default InsuredBodyWrapper;
