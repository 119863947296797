import { FC, useEffect, useState } from "react";
import { Modal } from "../../../../TrueUI";
import { ModalWaiverProps, PolicyWaiverCompProps } from "../TypesPolicyWaivers";
import { useApiGet, useApiPost } from "../../../../../hooks";
import { isAPITotallyComplete } from "../../../../../utilities/apiFunctions";
import FormPolicyWaiver from "./FormPolicyWaiver";
import { PolicyWaiverDto } from "../../../../../dtos/policy-waiver-dto";
import { StatusEnums } from "../../../../../dtos/status-enums";
import RibbonButtonsPolicyWaiver from "./RibbonButtonsPolicyWaiver";
import { PolicyWaiverFormInformationDto } from "../../../../../dtos/policy-waiver-form-information-dto";

const defaultPolicyWaiver: PolicyWaiverDto = {
  waiverId: 0,
  policyId: 0,
  policyQuoteId: null,
  issueDate: new Date(),
  autoRenew: 0,
  address: null,
  address2: null,
  city: null,
  state: null,
  postalCode: null,
  phoneNumber: null,
  contactName: null,
  location: null,
  description: null,
  companyName: "",
  status: StatusEnums.ACTIVE,
};

const ModalWaiverWrapper: FC<ModalWaiverProps> = ({
  modalWaiverConfig,
  setModalWaiverConfig,
}) => {
  const [policyWaiverComp, setPolicyWaiverComp] =
    useState<PolicyWaiverCompProps>({
      policySelectOptions: [],
      policyWaiver: {
        ...defaultPolicyWaiver,
        waiverId: modalWaiverConfig?.waiverId ?? 0,
      },
    });
  const [errorDetails, setErrorDetails] = useState<any>(null);
  const { dispatchGet, responseGet } =
    useApiGet<PolicyWaiverFormInformationDto>(
      `api/PolicyWaiver/GetPolicyWaiverFormInformation?insuredId=${modalWaiverConfig?.insuredId}&waiverId=${policyWaiverComp?.policyWaiver?.waiverId}`
    );
  const { dispatchPost, responsePost, validatorErrorResponse } = useApiPost<
    Partial<PolicyWaiverDto>
  >(`api/PolicyWaiver/SavePolicyWaiver`, policyWaiverComp?.policyWaiver);

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responsePost)) {
      if (policyWaiverComp?.policyWaiver?.status === StatusEnums.DELETED) {
        setModalWaiverConfig({
          ...modalWaiverConfig,
          isOpen: false,
          refreshTable: true,
        });
      } else {
        setModalWaiverConfig({
          ...modalWaiverConfig,
          refreshTable: true,
        });

        setPolicyWaiverComp({
          ...policyWaiverComp,
          policyWaiver: responsePost?.responseData ?? defaultPolicyWaiver,
        });
      }

      setErrorDetails(null);
    }
  }, [responsePost]);

  useEffect(() => {
    setErrorDetails(validatorErrorResponse?.errorDetails);
  }, [validatorErrorResponse]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      const responseGetData = responseGet?.responseData;
      setPolicyWaiverComp({
        ...responseGetData,
        policyWaiver: responseGetData?.policyWaiver ?? defaultPolicyWaiver,
      });
    }
  }, [responseGet]);

  useEffect(() => {
    if (policyWaiverComp?.dispatchSave) {
      dispatchPost();
    }
  }, [policyWaiverComp?.dispatchSave]);

  return (
    <Modal
      id="waiver-of-subrogation"
      title="Waiver of Subrogation"
      open={modalWaiverConfig.isOpen}
      cancelEvent={() => {
        setModalWaiverConfig({ ...modalWaiverConfig, isOpen: false });
      }}
      saveEvent={() => {
        dispatchPost();
      }}
      size={"sm"}
      cancelButtonWithConfirmation
      showCloseButton
      showCancelTextButton
      cancelButtonConfirmationText={
        "Are you sure you want to cancel? Your work will be lost."
      }
      buttonRibbonNode={
        <RibbonButtonsPolicyWaiver
          {...{
            policyWaiverComp,
            setPolicyWaiverComp,
          }}
        />
      }
    >
      <FormPolicyWaiver
        policies={policyWaiverComp?.policySelectOptions ?? []}
        policyWaiver={policyWaiverComp?.policyWaiver ?? {}}
        setPolicyWaiver={(policyWaiver) =>
          setPolicyWaiverComp({
            ...policyWaiverComp,
            policyWaiver: policyWaiver,
          })
        }
        errorDetails={errorDetails}
      />
    </Modal>
  );
};

export default ModalWaiverWrapper;
