import { FC } from "react";
import { Modal } from "../../../TrueUI";
import PayrollReportDetailModalContent from "./PayrollReportDetailModalContent";
import { PayrollReportDetailModalConfig } from "./PayrollReportDetailsConstants";

type PayrollReportDetailModalProps = {
  modalConfig: PayrollReportDetailModalConfig;
  setModalConfig: (config: PayrollReportDetailModalConfig) => void;
};
const PayrollReportDetailModal: FC<PayrollReportDetailModalProps> = ({
  modalConfig,
  setModalConfig,
}) => {
  const setIsOpen = (open: boolean) => {
    setModalConfig({ ...modalConfig, isOpen: open });
  };

  const setTitle = (title: string) => {
    setModalConfig({ ...modalConfig, title: title });
  };
  return (
    <Modal
      id="payrollReportDetailModal"
      title={`Payroll Report Form - ${modalConfig.title}`}
      open={modalConfig.isOpen}
      size="xl"
      closeEvent={() => setIsOpen(false)}
      cancelEvent={() => setIsOpen(false)}
    >
      <PayrollReportDetailModalContent
        insuredId={modalConfig.insuredId}
        payrollReportId={modalConfig.payrollReportId}
        isOpen={modalConfig.isOpen}
        setIsOpen={setIsOpen}
        setTitle={setTitle}
      />
    </Modal>
  );
};

export default PayrollReportDetailModal;
