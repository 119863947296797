import { FC, useEffect, useState } from "react";
import {
  Col,
  Input,
  InputDate,
  Row,
  Select,
  SelectWithIcon,
} from "../../../../TrueUI";
import { isDateAfterDate } from "../../../../../utilities/dateFunctions";
import { useAtomFamily } from "../../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../../InsuredAtoms";
import {
  PolicyInformationProps,
  PolicyInformationUIProps,
} from "../../PolicyQuoteForm/PolicyQuoteTypes";
import {
  getAtomUpdatedByPolicyInformationUI,
  getNewAtomValueByEffectiveDate,
  getNewAtomValueByExpirationDate,
  getNewAtomValueByNullEffectiveDate,
  getNewAtomValueByNullExpirationDate,
  selectedPolicyLimitItem,
  updatePolicyInformationUIByEffectiveDate,
  updatePolicyInformationUIByExpirationDate,
  updatePolicyInformationUIByPolicyBlob,
  minValidDate,
  getPolicyEnumValueCodeAndDescriptionAsOptions,
  getAtomUpdatedByPolicyInformationUIAssignment,
} from "./InformationUtils";
import { rowWithNoMarginNorGutter } from "../../../../TrueUI/Grids/Row";
import { usePolicyQuoteTriggerComponent } from "../../hooks/usePolicyQuoteTriggerComponent";
import { INSURED_ATOM_KEY } from "../../../../../utilities/queryStringsHash";
import { SelectOptions } from "../../../../../dtos/select-options";
import { useRecoilState } from "recoil";
import { InitOptionsDto } from "../../../../../dtos/init-options-dto";
import { globalOptions } from "../../../../../GlobalAtoms";

export const Information: FC<PolicyInformationProps> = ({
  tabKey,
  assignedTo,
  errorDetails,
  readOnly = false,
}) => {
  const [localOptions] = useRecoilState<InitOptionsDto>(globalOptions);
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const [policyInformationUI, setPolicyInformationUI] =
    useState<PolicyInformationUIProps>();
  const [readyToRender, setReadyToRender] = useState<boolean>(false);

  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );
  const { setPolicyQuoteTriggers } = usePolicyQuoteTriggerComponent();

  const setPolicyInformationByChangedProperty = (
    targetProperty:
      | "quoteName"
      | "employeeLeasingType"
      | "retrospectiveRating"
      | "experienceRating"
      | "customerPolicyType"
      | "policyLimits",
    targetValue: any
  ) => {
    const atomValue = getAtom();

    const newAtomValue = getAtomUpdatedByPolicyInformationUI(
      targetProperty,
      targetValue,
      atomValue
    );

    setAtom(newAtomValue);
    setPolicyInformationUI({
      ...policyInformationUI,
      [targetProperty]: targetValue,
    });
    setPolicyQuoteTriggers(["policyQuoteHeaderComponent"]);
  };

  const setPolicyInformationByChangedAssignment = (
    value: any,
    list: SelectOptions[]
  ) => {
    const atomValue = getAtom();
    const newAtomValue = getAtomUpdatedByPolicyInformationUIAssignment(
      value,
      list,
      atomValue
    );
    setAtom(newAtomValue);
    setPolicyInformationUI({
      ...policyInformationUI,
      underwriterID: value,
    });
  };

  const onEffectiveDateChanged = (effectiveDate: Date) => {
    const atomValue = getAtom();
    if (
      effectiveDate !== null &&
      isDateAfterDate(effectiveDate, minValidDate)
    ) {
      const newPolicyInformationUI = updatePolicyInformationUIByEffectiveDate(
        effectiveDate,
        policyInformationUI,
        atomValue?.policyQuoteInformation?.policyQuote?.program?.programName
      );

      const newAtomValue = getNewAtomValueByEffectiveDate(
        atomValue,
        newPolicyInformationUI
      );

      setAtom(newAtomValue);
      setPolicyInformationUI(newPolicyInformationUI);
      setPolicyQuoteTriggers([
        "policyQuoteHeaderComponent",
        "agencyInformationTableComponent",
      ]);
    } else if (effectiveDate === null) {
      const newAtomValue = getNewAtomValueByNullEffectiveDate(atomValue);
      setAtom(newAtomValue);
      setPolicyInformationUI({
        ...policyInformationUI,
        policyQuoteEffectiveDate: null,
        policyQuoteExpirationDate: null,
      });
      setPolicyQuoteTriggers([
        "policyQuoteHeaderComponent",
        "agencyInformationTableComponent",
      ]);
    } else {
      setPolicyInformationUI({
        ...policyInformationUI,
        policyQuoteEffectiveDate: effectiveDate,
      });
    }
  };

  const onExpirationDateChanged = (expirationDate: Date) => {
    const atomValue = getAtom();
    if (
      expirationDate !== null &&
      isDateAfterDate(expirationDate, minValidDate)
    ) {
      if (expirationDate) {
        const newPolicyInformationUI =
          updatePolicyInformationUIByExpirationDate(
            policyInformationUI,
            expirationDate
          );

        const newAtomValue = getNewAtomValueByExpirationDate(
          atomValue,
          newPolicyInformationUI
        );

        setAtom(newAtomValue);
        setPolicyInformationUI(newPolicyInformationUI);
        setPolicyQuoteTriggers([
          "policyQuoteHeaderComponent",
          "agencyInformationTableComponent",
        ]);
      }
    } else if (expirationDate === null) {
      const newAtomValue = getNewAtomValueByNullExpirationDate(atomValue);

      setAtom(newAtomValue);
      setPolicyInformationUI({
        ...policyInformationUI,
        policyQuoteExpirationDate: null,
      });
      setPolicyQuoteTriggers([
        "policyQuoteHeaderComponent",
        "agencyInformationTableComponent",
      ]);
    } else {
      setPolicyInformationUI({
        ...policyInformationUI,
        policyQuoteExpirationDate: expirationDate,
      });
    }
  };

  const setInitialValuesForInformationForm = () => {
    const atomValue = getAtom();
    const currentAssigneeByUserId = assignedTo.find(
      (assigned) => assigned.intValue === localOptions.userId
    );
    const defaultUnderwriterIdForNewQuote =
      (currentAssigneeByUserId ?? 0) !== 0
        ? currentAssigneeByUserId?.intValue
        : 0;

    const newPolicyInformationUI = updatePolicyInformationUIByPolicyBlob(
      defaultUnderwriterIdForNewQuote ?? 0,
      atomValue?.policyQuoteInformation?.policyQuote
    );

    setPolicyInformationUI(newPolicyInformationUI);
  };

  useEffect(() => {
    setInitialValuesForInformationForm();
    setPolicyQuoteTriggers(["policyQuoteHeaderComponent"]);
  }, []);

  useEffect(() => {
    if (policyInformationUI !== undefined && policyInformationUI !== null) {
      setReadyToRender(true);
    }
  }, [policyInformationUI]);

  return readyToRender ? (
    <Row
      {...rowWithNoMarginNorGutter}
      rowDirection="column"
      verticalAlign="flex-start"
      rowWidth="80%"
    >
      <Row {...rowWithNoMarginNorGutter}>
        <Col>
          <InputDate
            id="id-effective-date"
            name="effectiveDate"
            label="Effective Date"
            readOnly={readOnly}
            value={policyInformationUI?.policyQuoteEffectiveDate}
            onChangeRawValue={(newValue) => onEffectiveDateChanged(newValue)}
            errorMessage={errorDetails?.effectiveDate}
          />
        </Col>
        <Col>
          <InputDate
            id="id-expiration-date"
            name="expirationDate"
            label="Expiration Date"
            readOnly={readOnly}
            value={policyInformationUI?.policyQuoteExpirationDate}
            onChangeRawValue={(newValue) => onExpirationDateChanged(newValue)}
            errorMessage={errorDetails?.expirationDate}
          />
        </Col>
        <Col breakpoints={{ md: 2, lg: 2, xl: 2 }}>
          <Input
            id="id-policy-period"
            name="policyPeriod"
            label="Policy Period"
            readOnly
            className="policy_info_disabled"
            value={policyInformationUI?.policyPeriod?.displayValue}
          />
        </Col>
        <Col>
          <SelectWithIcon
            id="id-policy-assigned-to"
            name="policyAssignedTo"
            label="Assigned To"
            options={assignedTo}
            readOnly={readOnly}
            firstOptionAsDefault={false}
            value={policyInformationUI?.underwriterID}
            onChange={(value) =>
              setPolicyInformationByChangedAssignment(value, assignedTo)
            }
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col>
          <Input
            id="id-quote-name"
            name="quoteName"
            label="Quote Name"
            readOnly={readOnly}
            value={policyInformationUI?.quoteName}
            maxLength={100}
            onChangeRawValue={(newValue) =>
              setPolicyInformationByChangedProperty("quoteName", newValue)
            }
            errorMessage={errorDetails?.quoteName}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col>
          <Input
            id="id-policy-type"
            name="policyType"
            label="Policy Type"
            readOnly
            className="policy_info_disabled"
            value={policyInformationUI?.policyType?.description}
          />
        </Col>
        <Col>
          <Input
            id="id-prior-policy-number"
            name="priorPolicyNumber"
            label="Prior Policy Number"
            readOnly
            className="policy_info_disabled"
            value={policyInformationUI?.priorPolicyNumber ?? ""}
          />
        </Col>
        <Col>
          <Input
            id="id-policy-term"
            name="policyTerm"
            label="Policy Term"
            readOnly
            className="policy_info_disabled"
            value={policyInformationUI?.policyTerm?.description}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col>
          <Select
            id="information-employee-leasing"
            name="employeeLeasing"
            label="Employee Leasing"
            readOnly={readOnly}
            value={policyInformationUI?.employeeLeasingType?.value}
            options={getPolicyEnumValueCodeAndDescriptionAsOptions(
              policyInformationUI?.employeeLeasingList ?? []
            )}
            onChange={(value) =>
              setPolicyInformationByChangedProperty(
                "employeeLeasingType",
                policyInformationUI?.employeeLeasingList?.find(
                  (item) => item.value === value
                )
              )
            }
          />
        </Col>
        <Col>
          <Select
            id="information-restrospective-rating"
            name="retrospectiveRating"
            label="Retrospective Rating"
            readOnly={readOnly}
            value={policyInformationUI?.retrospectiveRating?.value}
            options={getPolicyEnumValueCodeAndDescriptionAsOptions(
              policyInformationUI?.retrospectiveRatingList ?? []
            )}
            onChange={(value) =>
              setPolicyInformationByChangedProperty(
                "retrospectiveRating",
                policyInformationUI?.retrospectiveRatingList?.find(
                  (item) => item.value === value
                )
              )
            }
          />
        </Col>
        <Col>
          <Select
            id="information-experience-rating"
            name="experienceRating"
            label="Experience Rating"
            readOnly={readOnly}
            value={policyInformationUI?.experienceRating?.value}
            options={getPolicyEnumValueCodeAndDescriptionAsOptions(
              policyInformationUI?.experienceRatingList ?? []
            )}
            onChange={(value) =>
              setPolicyInformationByChangedProperty(
                "experienceRating",
                policyInformationUI?.experienceRatingList?.find(
                  (item) => item.value === value
                )
              )
            }
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter} horizontalAlign="flex-start">
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <Select
            id="information-customer-policy-type"
            name="customerPolicyType"
            label={
              policyInformationUI?.customerPolicyTypeLabel ??
              "customerPolicyType"
            }
            readOnly={readOnly}
            value={policyInformationUI?.customerPolicyType?.value}
            options={policyInformationUI?.customerPolicyList ?? []}
            onChange={(value) =>
              setPolicyInformationByChangedProperty("customerPolicyType", {
                value: value,
                label: policyInformationUI?.customerPolicyTypeLabel,
                description:
                  policyInformationUI?.customerPolicyList?.find(
                    (item) => item.intValue === value
                  )?.displayName ?? "",
              })
            }
          />
        </Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <Select
            id="information-policyt-limits"
            name="policyLimits"
            label={"Policy Limits"}
            readOnly={readOnly}
            value={policyInformationUI?.policyLimits?.id}
            options={policyInformationUI?.policyPeriodListAsOptions ?? []}
            onChange={(value) =>
              setPolicyInformationByChangedProperty(
                "policyLimits",
                selectedPolicyLimitItem(
                  policyInformationUI?.policyLimitList ?? [],
                  value
                )
              )
            }
          />
        </Col>
      </Row>
    </Row>
  ) : (
    <div>LOADING...</div>
  );
};

export default Information;
