import { FC, useEffect, useState } from "react";
import BaseGrid from "../../BaseGrid/BaseGrid";
import { useBaseGrid } from "../../BaseGrid/Hooks/useBaseGrid";
import { BaseGridProperties } from "../../BaseGrid/BaseGridProperties";
import {
  StatementsDetailsTableWrapperProps,
  tableName,
} from "./StatementsUtil";
import PrintIcon from "@mui/icons-material/Print";

import { Box, IconButton } from "@mui/material";
import { OptionsContextActionParameterProperties } from "../../TrueUI/Tables/BaseTable2/TableProperties";
import DialogConfirmation, {
  DialogConfirmationProps,
} from "../../TrueUI/Dialogs/DialogConfirmation";
import BaseGridReadOnlyInput from "../../BaseGrid/BaseGridCommonFilters/BaseGridReadOnlyInput";
import { Button, Col, Row } from "../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../TrueUI/Grids/Row";
import CustomJSONPrintManager from "../../CustomJSONPrint/CustomJSONPrintManager";
import { useSetRecoilState } from "recoil";
import { customJSONPrintManagerAtom } from "../../CustomJSONPrint/CustomJSONPrintManagerAtoms";
import { CustomJSONTypeEnum } from "../../../dtos/custom-json-type-enum";
import { useApiGet } from "../../../hooks";
import { CustomPrintConfigurationsDto } from "../../../dtos/custom-print-configurations-dto";
import { isAPITotallyComplete } from "../../../utilities/apiFunctions";

const StatementsDetailsTableWrapper: FC<StatementsDetailsTableWrapperProps> = ({
  statementBatchId,
  statementDate,
  onClickReturnToBatchTable,
}) => {
  const setCustomJSONPrintManagerAtom = useSetRecoilState(
    customJSONPrintManagerAtom
  );
  const [invoiceId, setInvoiceId] = useState<number | null>(null);
  const {
    responseGet: responseGetGetStatementDetailsConfigurationsToPrint,
    dispatchGet: dispatchGetGetStatementDetailsConfigurationsToPrint,
  } = useApiGet<CustomPrintConfigurationsDto>(
    `api/Invoice/GetStatementDetailsConfigurationsToPrint?invoiceId=${invoiceId}`
  );
  const [dialogConfiguration, setDialogConfiguration] =
    useState<DialogConfirmationProps | null>(null);

  const actionsForRows = (
    actionOptions: OptionsContextActionParameterProperties<any>
  ) => {
    return (
      <Box display={"flex"} justifyContent={"flex-end"}>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => {
            const newInvoiceId = actionOptions.row.InvoiceId;
            setInvoiceId(newInvoiceId);
          }}
        >
          <PrintIcon fontSize="small" />
        </IconButton>
      </Box>
    );
  };

  useEffect(() => {
    if (invoiceId !== null) {
      dispatchGetGetStatementDetailsConfigurationsToPrint();
    }
  }, [invoiceId]);

  const tableIncidentsConfiguration = () => {
    return {
      name: tableName,
      getURL: `api/StatementDetail/GetStatementDetails?statementBatchId=${statementBatchId}`,
      columnOptions: [
        { fieldName: "Insured", width: 50 },
        { fieldName: "InvoiceNumber", width: 20 },
        { fieldName: "BillingMethod", width: 10 },
        { fieldName: "IsAutoPay", width: 10 },
        { fieldName: "Amount", width: 10, align: "right" },
        { fieldName: "OPTIONS", width: 3 },
      ],
      orderDirection: "asc",
      orderByColumnIndex: 1,
      toolbarOptions: {
        showEditButton: false,
        showSaveButton: false,
        showSortFilter: false,
        showImportButton: false,
        showAddButton: true,
        addButtonText: "Print Batch",
      },
      filterOptions: [
        () =>
          BaseGridReadOnlyInput({
            id: "statement-batch-date",
            defaultValue: statementDate ?? "",
            labelText: "Statement Batch Date:",
            inputWidth: "320px",
            labelFontType: "BODY",
            inputFontType: "BODY",
          }),
      ],

      advancedOptions: {
        optionsContextActions: {
          contextComponent: actionsForRows,
        },
        optionsColumnConfiguration: {
          isHidden: false,
          optionType: "component",
        },
      },
      events: {
        addEventOverrideCallback: () => {
          console.log("TODO: Print Batch");
        },
      },
    } as BaseGridProperties;
  };

  const requestPrint = () => {
    const configurations =
      responseGetGetStatementDetailsConfigurationsToPrint.axiosResponse?.data;

    if (configurations !== undefined && invoiceId !== null) {
      setCustomJSONPrintManagerAtom({
        customJSONType: CustomJSONTypeEnum.INVOICE,
        sourceIDToBuildJSON_1: invoiceId,
        configurationRequest: {
          templateNameWithExtensionList: [configurations.templateName ?? ""],
          customFileName: `${configurations.insuredName} Invoice`,
        },
      });
      setInvoiceId(null);
    }
  };

  useEffect(() => {
    if (
      isAPITotallyComplete(responseGetGetStatementDetailsConfigurationsToPrint)
    ) {
      requestPrint();
    }
  }, [responseGetGetStatementDetailsConfigurationsToPrint]);

  useBaseGrid(tableIncidentsConfiguration());

  return (
    <>
      <Row
        {...rowWithNoMarginNorGutter}
        numberOfColumns={24}
        horizontalAlign={"flex-end"}
        verticalGutter={"5px"}
      >
        <Col breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          <Button
            onClick={onClickReturnToBatchTable}
            variantStyle={"outlined"}
            fullWidth
          >
            Return
          </Button>
        </Col>
      </Row>
      <BaseGrid name={tableName} />
      <DialogConfirmation
        id="insuredInvoiceDialogConfirmation"
        {...dialogConfiguration}
        onCloseEvent={() => {
          setDialogConfiguration(null);
        }}
      />
      <CustomJSONPrintManager />
    </>
  );
};

export default StatementsDetailsTableWrapper;
