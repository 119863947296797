import { FC, useEffect, useState } from "react";

import { TableWrapperProceduresServicesSuppliesProps } from "../TypesPaymentBillDetails";
import { useRecoilState } from "recoil";
import { ClaimFinancialPaymentBillDetailsAtom } from "../../ClaimFinancialAtoms";
import { FormattingDate } from "../../../../../../../../utilities/dateFunctions";
import { currencyFormat } from "../../../../../../../../utilities/currencyFunctions";
import { useApiGet } from "../../../../../../../../hooks";
import { isAPITotallyComplete } from "../../../../../../../../utilities/apiFunctions";
import TableProceduresServicesSupplies from "./TableProceduresServicesSupplies";

const TableWrapperProceduresServicesSupplies: FC<
  TableWrapperProceduresServicesSuppliesProps
> = ({ paymentId }) => {
  const [tableKey, setTableKey] = useState(crypto.randomUUID());
  const [paymentBillDetailsAtom, setPaymentBillDetailsAtom] = useRecoilState(
    ClaimFinancialPaymentBillDetailsAtom
  );
  const [tableData, setTableData] = useState<any>(null);
  const { dispatchGet, responseGet } = useApiGet<{
    tableData: { columns: any; data: any };
  }>(
    `api/PaymentEOB/GetPaymentEOBDetailsForPaymentBillDetailsModalTable?paymentId=${paymentId}`
  );
  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      const columns = responseGet?.responseData?.tableData?.columns ?? [];
      const data = responseGet?.responseData?.tableData?.data ?? [];
      getTableData(columns, data);
    }
  }, [responseGet]);

  const getRowForTableFromAtom = (atomRowData) => {
    const diagnosisCodes = { A: 1, B: 2, C: 3, D: 4 };
    const diagnosisCodeCharacter = Object.keys(diagnosisCodes).find(
      (diagnosisCodeKey) =>
        diagnosisCodes[diagnosisCodeKey] ===
        atomRowData?.individualBillDetails?.diagnosisCodeId
    );
    const newRowData = {
      AmountAllowed: currencyFormat(
        atomRowData?.individualBillDetails?.amountAllowed,
        false,
        2
      ),
      AmountBilled: currencyFormat(
        atomRowData?.individualBillDetails?.amountBilled,
        false,
        2
      ),
      AmountPaid: currencyFormat(
        atomRowData?.individualBillDetails?.amountPaid,
        false,
        2
      ),
      DiagnosisCode: diagnosisCodeCharacter ?? "",
      EOBCode: atomRowData?.paymentServiceFeeSchedule?.eobCode ?? "",
      PaymentId: "0",
      PaymentDetailId: "0",
      Quantity: currencyFormat(
        atomRowData?.individualBillDetails?.quantity,
        false,
        2
      ),
      ServiceCode: atomRowData?.paymentServiceFeeSchedule?.serviceCode,
      ServiceFromDate: FormattingDate(
        atomRowData?.individualBillDetails?.serviceFromDate
      ),
      ServiceToDate: FormattingDate(
        atomRowData?.individualBillDetails?.serviceToDate
      ),
      ShortDescription:
        atomRowData?.paymentServiceFeeSchedule?.shortDescription,
    };
    return (atomRowData ?? 0) !== 0 ? newRowData : null;
  };

  const getTableData = (columns, data) => {
    const savedBillDetails = paymentBillDetailsAtom?.paymentBillDetails ?? [];
    const rowsFromAtom = savedBillDetails.map((billDetail) => {
      const newRowData = getRowForTableFromAtom(billDetail);
      const rowFromAtomData =
        newRowData !== null
          ? columns.map((column) => {
              const key = column?.fieldName;
              return newRowData?.[key];
            })
          : [];
      return rowFromAtomData;
    });
    const paymentDetailIdIndex = columns.findIndex(
      (item) => item.fieldName === "PaymentDetailId"
    );
    if ((paymentBillDetailsAtom?.paymentBillDetailsToDelete?.length ?? 0) > 0) {
      data = data?.filter(
        (row) =>
          paymentBillDetailsAtom?.paymentBillDetailsToDelete?.includes(
            parseInt(row[paymentDetailIdIndex])
          ) === false
      );
    }

    setTableData({
      columns: columns,
      data: [...rowsFromAtom, ...data],
    });
    setTableKey(crypto.randomUUID());
  };

  const addRowToTable = () => {
    const atomRowData = paymentBillDetailsAtom?.paymentBillDetails?.[0];
    const newRowData = getRowForTableFromAtom(atomRowData);
    const rowFromAtomData = tableData?.columns.map((column) => {
      const key = column?.fieldName;
      return newRowData?.[key];
    });
    const dataWithNonDeletedRowsOnly =
      (paymentBillDetailsAtom?.paymentBillDetailsToDelete?.length ?? 0) > 0
        ? tableData?.data.filter((row) => {
            const paymentDetailIdIndex = tableData?.columns?.findIndex(
              (item) => item.fieldName === "PaymentDetailId"
            );
            return (
              paymentBillDetailsAtom?.paymentBillDetailsToDelete?.includes(
                parseInt(row[paymentDetailIdIndex])
              ) === false
            );
          })
        : tableData?.data;
    setTableData({
      ...tableData,
      data: [rowFromAtomData, ...(dataWithNonDeletedRowsOnly ?? [])],
    });
    setTableKey(crypto.randomUUID());
  };

  useEffect(() => {
    if (
      (paymentBillDetailsAtom?.paymentBillDetails?.length ?? 0) > 0 &&
      (paymentBillDetailsAtom?.isDeletingPaymentBillDetails ?? false) === false
    ) {
      addRowToTable();
    }
    if (paymentBillDetailsAtom?.isDeletingPaymentBillDetails === true) {
      setPaymentBillDetailsAtom({
        ...paymentBillDetailsAtom,
        isDeletingPaymentBillDetails: false,
      });
    }
  }, [paymentBillDetailsAtom?.paymentBillDetails]);

  return (
    <>
      {tableData?.columns && (
        <TableProceduresServicesSupplies tableData={tableData} key={tableKey} />
      )}
    </>
  );
};

export default TableWrapperProceduresServicesSupplies;
