import { FC, useEffect, useState } from "react";
import {
  BaseTable2,
  Button,
  Col,
  Divider,
  Input,
  Row,
  SaveButton,
  Switch,
} from "../../../TrueUI";
import DialogConfirmation, {
  DialogConfirmationProps,
} from "../../../TrueUI/Dialogs/DialogConfirmation";
import { BaseTable2Properties } from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import BaseTableGridForm from "../../../TrueUI/Tables/ComponentPatterns/BaseTableGridForm";
import MultiHeader from "../../../TrueUI/Tables/MultiTable/MultiHeader";
import { useBaseTable } from "../../../../hooks/useBaseTable";
import { useApiGet, useApiPost } from "../../../../hooks/useApi";
import {
  isAPITotallyComplete,
  isAPITotallyCompleteNoContentResponse,
} from "../../../../utilities/apiFunctions";
import { Typography } from "@mui/material";
import { EditedPayrollReportDetailsDto } from "../../../../dtos/edited-payroll-report-details-dto";
import ModalAddClassCode from "./ClassCode/ModalAddClassCode";
import { useFormRequest, usePermissions } from "../../../../hooks";
import { PayrollReportStatusEnum } from "../../../../dtos/payroll-report-status-enum";
import {
  detailsTableColumnOptions,
  errorMessageStyle,
  getDataForDetailRow,
  getDetailRowsFromTemp,
  getDetailsColumns,
  getEditedPayrollReportDetail,
} from "./PayrollReportDetailsConstants";
import themes from "../../../../media/TrueTheme";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../../../GlobalAtoms";
import {
  conditionHasValue,
  isEmptyValue,
} from "../../../../utilities/conditionalSupportFunctions";
import { checkUpdated, columnOptionsProps } from "./PayrollReportDetailUtils";
import { PayrollReportPayInlineDto } from "../../../../dtos/payroll-report-pay-inline-dto";
import { SavePayrollReportDetailResponseDto } from "../../../../dtos/save-payroll-report-detail-response-dto";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { colWithNoMarginNorGutter } from "../../../TrueUI/Grids/Col";
import { FormTypeEnum } from "../../../../dtos/form-type-enum";
import {
  CustomFooterComponentDetail,
  CustomFooterComponentGeneralPayrollReport,
} from "./CustomFooterComponentsPayrollReportDetails";
import { AllPayrollReportJSON } from "../../../../dtos/all-payroll-report-json";
import { PayrollReportDetailRowDto } from "../../../../dtos/payroll-report-detail-row-dto";
import { PayrollReportDetailInfoPage } from "../../../../dtos/payroll-report-detail-info-page";
import { PermissionsEnums } from "../../../../dtos/permissions-enums";

type PayrollReportDetailProps = {
  insuredId: number;
  payrollReportId: number;
  isOpen: boolean;
  setIsOpen: (open) => void;
  setTitle: (title) => void;
};

const tableName = "payroll_report_detail_multi_table";

const PayrollReportDetailModalContent: FC<PayrollReportDetailProps> = ({
  insuredId,
  payrollReportId,
  isOpen,
  setIsOpen,
  setTitle,
}) => {
  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];
  const hasPermission = usePermissions([
    PermissionsEnums.BILLING_PAYMENT,
    PermissionsEnums.BILLING_USER,
    PermissionsEnums.BILLING_ADMIN,
  ]);
  const [editedDetailRows, setEditedDetailRows] =
    useState<Partial<EditedPayrollReportDetailsDto> | null>();
  const [detailsRowsTemp, setDetailsRowsTemp] = useState<
    PayrollReportDetailRowDto[]
  >([]);
  const [dialogConfiguration, setDialogConfiguration] =
    useState<DialogConfirmationProps | null>(null);
  const [isReadOnly, setIsReadOnly] = useState<boolean>(
    hasPermission.hasPermission === true ? false : true
  );
  const [requiredFlagChecked, setRequiredFlagChecked] = useState<
    boolean | null
  >(null);

  const [payInlineData, setPayInlineData] =
    useState<PayrollReportPayInlineDto | null>(null);
  const [payrollReportInfo, setPayrollReportInfo] =
    useState<Partial<PayrollReportDetailInfoPage> | null>(null);
  const { responseGet, dispatchGet } = useApiGet<PayrollReportDetailInfoPage>(
    `api/PayrollReportDetail/GetPayrollReportDetailInfo?payrollReportId=${payrollReportId}`
  );
  const [uuid, setUUID] = useState<string>(crypto.randomUUID());

  const {
    responsePost: responseSaveDetailRows,
    dispatchPost: saveDetailRows,
    validatorErrorResponse: errorResponseSaveDetailRows,
  } = useApiPost<SavePayrollReportDetailResponseDto>(
    `api/PayrollReportDetail/SavePayrollReportDetailInfoRows`,
    editedDetailRows ?? []
  );

  const { responsePost: responseUncomplete, dispatchPost: dispatchUncomplete } =
    useApiPost(
      `api/PayrollReportDetail/UncompletePayrollReport?payrollReportId=${payrollReportId}`
    );

  const {
    responseGet: responseGetPayrollToPrint,
    dispatchGet: dispatchGetPayrollToPrint,
  } = useApiGet<AllPayrollReportJSON>(
    `api/PayrollReportDetail/GetPayrollReportToPrint?payrollReportId=${payrollReportId}`
  );
  const { sendMergeFormRequest } = useFormRequest();

  const [errorDetails, setErrorDetails] = useState<any>(null);

  const [refresh, setRefresh] = useState<boolean>(true);

  useEffect(() => {
    dispatchGet();
    setRefresh(false);
  }, [uuid]);

  useEffect(() => {
    if (errorResponseSaveDetailRows) {
      setErrorDetails({
        ...errorResponseSaveDetailRows?.errorDetails,
      });
    }
  }, [errorResponseSaveDetailRows]);

  useEffect(() => {
    if (isAPITotallyComplete(responseSaveDetailRows)) {
      if (
        responseSaveDetailRows?.axiosResponse?.data.isSaveAsComplete === true
      ) {
        setIsOpen(false);
      }
      setEditedDetailRows({ ...editedDetailRows, detailsRows: [] });
    }
  }, [responseSaveDetailRows]);

  const [openModalAddClassCode, setOpenModalAddClassCode] =
    useState<boolean>(false);

  const isCheckPaymentDisabled = () =>
    (editedDetailRows?.notRequiredFlag ?? false) ||
    (payrollReportInfo?.isEverCompleted ?? false);

  const isCompleted = () =>
    payrollReportInfo?.statusId === PayrollReportStatusEnum.COMPLETE ||
    payrollReportInfo?.statusId === PayrollReportStatusEnum.COMPLETE_ZERO;

  const onChangeAdjustment = (
    updatedValue: any,
    parameterName: string,
    adjustmentId: number
  ) => {
    const updatedAdjustments = editedDetailRows?.adjustmentsRows?.map(
      (adjustment) =>
        adjustment.payrollReportAjustmentId === adjustmentId
          ? { ...adjustment, [parameterName]: updatedValue, isUpdated: true }
          : adjustment
    );
    setEditedDetailRows((prev) => ({
      ...prev,
      adjustmentsRows: updatedAdjustments,
    }));
  };

  const PayrollReportDetailConfig: BaseTable2Properties = {
    name: tableName,
    getURL: `api/PayrollReportDetail/GetPayrollReportDetailMultiTable?payrollReportId=${payrollReportId}&insuredId=${insuredId}&uuid=${uuid}`,
    tableType: "multi",
    toolbarOptions: {
      addButtonText: "ADD CLASS CODE",
      addButtonIsDisabled: isReadOnly,
      showEditButton: false,
      showSaveButton: false,
      showSortFilter: false,
      showImportButton: false,
    },
    events: {
      addEventOverrideCallback: () => {
        setOpenModalAddClassCode(true);
      },
    },
    columnOptions: columnOptionsProps,
    advancedOptions: {
      paginate: false,
      multiTableProperties: {
        allRowsExpanded: true,
        MultiTablePatternComponent: (e) => {
          setErrorDetails(null);
          return (
            <BaseTableGridForm
              columnOptions={[
                ...columnOptionsProps,
                ...detailsTableColumnOptions,
              ]}
              obj={{
                ...e,
                IsEdit: !isReadOnly,
              }}
              columnsAndData={{
                columns:
                  getDetailsColumns(payrollReportInfo, requiredFlagChecked) ??
                  [],
                data: getDataForDetailRow(
                  payrollReportInfo?.detailsRowsData ?? [],
                  e,
                  editedDetailRows,
                  payrollReportInfo?.detailsColumns ?? []
                ),
              }}
              parameters={[
                {
                  paramaterKey: "payrollReportId",
                  parameterPropertyValue: "PayrollReportId",
                },
                { paramaterKey: "nameId", parameterPropertyValue: "NameId" },
                { paramaterKey: "isEdit", parameterPropertyValue: "IsEdit" },
              ]}
              isEditMode={isCompleted() === false}
              onDataChange={(data) =>
                setDetailsRowsTemp(getEditedPayrollReportDetail(data))
              }
              customFooterComponent={(rows) =>
                CustomFooterComponentDetail(
                  rows,
                  getDetailsColumns(payrollReportInfo, requiredFlagChecked) ??
                    []
                )
              }
            />
          );
        },
        MultiTableHeaderPatternComponent: (params) => {
          const titleParams = params ?? [];
          return <MultiHeader headerParams={titleParams} />;
        },
      },
    },
    footerOptions: {
      CustomFooterComponent: (rows) =>
        CustomFooterComponentGeneralPayrollReport(
          rows,
          isCheckPaymentDisabled(),
          payrollReportInfo,
          editedDetailRows ?? null,
          onChangeAdjustment,
          payInlineData,
          setPayInlineData,
          errorDetails
        ),
    },
  };

  useEffect(() => {
    setEditedDetailRows({
      ...editedDetailRows,
      payrollReportId: payrollReportInfo?.payrollReportId ?? 0,
      eeRequired: (payrollReportInfo?.eeRequired ?? 0) === 1 ? true : false,
      detailsRows: getDetailRowsFromTemp(
        detailsRowsTemp,
        editedDetailRows?.detailsRows ?? []
      ),
    });
  }, [detailsRowsTemp]);

  useBaseTable(PayrollReportDetailConfig);

  useEffect(() => {
    if (isOpen && payrollReportId !== 0) dispatchGet();
  }, [isOpen]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setPayrollReportInfo(responseGet.responseData ?? null);
      setTitle(responseGet.responseData?.insuredName);
      const statusId = responseGet.responseData?.statusId;
      if (statusId === PayrollReportStatusEnum.COMPLETE_ZERO) {
        setRequiredFlagChecked(true);
        setIsReadOnly(true);
      } else if (statusId === PayrollReportStatusEnum.COMPLETE) {
        setRequiredFlagChecked(false);
        setIsReadOnly(true);
      } else {
        setRequiredFlagChecked(false);
      }
      setEditedDetailRows({
        ...editedDetailRows,
        eeRequired: responseGet?.responseData?.eeRequired === 1 ? true : false,
        notRequiredFlag: statusId === PayrollReportStatusEnum.COMPLETE_ZERO,
        payrollReportId: responseGet?.responseData?.payrollReportId ?? 0,
        totalPaid: responseGet?.responseData?.amountPaid ?? 0,
        detailsRows: [],
        adjustmentsRows: responseGet?.responseData?.payrollReportAdjustments,
      });
      setRefresh(true);
    }
  }, [responseGet]);

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responseUncomplete)) {
      setIsReadOnly(false);
      setUUID(crypto.randomUUID());
      dispatchGet();
    }
  }, [responseUncomplete]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGetPayrollToPrint))
      printPayrollReport(responseGetPayrollToPrint?.axiosResponse?.data);
  }, [responseGetPayrollToPrint]);

  const customErrorMessage = (errorDetails) => {
    if (isEmptyValue(errorDetails)) {
      return <></>;
    }
    const errorList: { errors: string[] } = { errors: [] };
    Object.keys(errorDetails).forEach((key) => {
      const errorsByRow = errorDetails[key]?.errorsByCell;
      if (errorsByRow) {
        Object.keys(errorsByRow).forEach((errorKey) => {
          if (errorList.errors.includes(errorsByRow[errorKey][0]) === false) {
            errorList.errors = [...errorList.errors, errorsByRow[errorKey][0]];
          }
        });
      }
    });
    return errorList.errors.length > 0 ? (
      <>
        <Divider height={10}></Divider>
        {errorList.errors.map((error) => (
          <Typography sx={errorMessageStyle(theme)}>{error}</Typography>
        ))}
      </>
    ) : (
      <></>
    );
  };

  const saveButtonClicked = () => {
    if (checkUpdated(editedDetailRows)) {
      setEditedDetailRows({
        ...editedDetailRows,
        notRequiredFlag: null,
      });
      saveDetailRows();
    }
    setErrorDetails(null);
  };

  const saveAsCompletedButtonClicked = () => {
    setEditedDetailRows({
      ...editedDetailRows,
      notRequiredFlag: requiredFlagChecked,
      payrollReportPayInlineDto: payInlineData,
    });
    saveDetailRows();
    setErrorDetails(null);
  };

  const notRequiredFlagCheckboxChange = (value) => {
    setEditedDetailRows({
      ...editedDetailRows,
      notRequiredFlag: value,
      detailsRows: conditionHasValue(editedDetailRows?.detailsRows)
        ? editedDetailRows?.detailsRows
        : [],
    });
    setErrorDetails(null);
    setRequiredFlagChecked(value);
  };

  const uncompletePayrollReport = () => {
    dispatchUncomplete();
  };
  const printPayrollReport = (allPayrollReportJSON?: AllPayrollReportJSON) => {
    if (
      allPayrollReportJSON !== undefined &&
      allPayrollReportJSON !== null &&
      !isEmptyValue(allPayrollReportJSON.templateName)
    ) {
      sendMergeFormRequest({
        formType: FormTypeEnum.PAYROLL_REPORT_FORM,
        allPayrollReportMergeFields: allPayrollReportJSON,
        templateNameWithExtensionList: [
          allPayrollReportJSON.templateName ?? "",
        ],
        customFileName: `Payroll Report ${payrollReportInfo?.insuredName} ${payrollReportInfo?.policyNumber}`,
      });
    }
    if (isEmptyValue(allPayrollReportJSON?.templateName)) {
      setDialogConfiguration({
        dialogDescriptionText: "No print template found.",
        open: true,
        optionYesOverrideLabel: "OK",
        onOptionYesEvent: () => setDialogConfiguration(null),
      });
    }
  };

  return (
    <div className="payroll-report-detail-container" style={{ width: "100%" }}>
      <div
        className="payroll-report-detail-header"
        style={{ display: "flex", flexDirection: "column", width: "100%" }}
      >
        <div
          className="payroll-report-detail-header-info"
          style={{ display: "flex", width: "100%", marginBottom: "20px" }}
        >
          <div>
            <Input
              id="policy-number"
              name="policy-number"
              readOnly
              label="Policy Number"
              labelFontType="BOLD_CAPTION"
              labelPosition="top"
              value={payrollReportInfo?.policyNumber}
            />
          </div>
          <div>
            <Input
              id="reported-from"
              name="reported-from"
              readOnly
              label="For PayrollReported From"
              labelFontType="BOLD_CAPTION"
              labelPosition="top"
              value={payrollReportInfo?.reportFrom}
            />
          </div>
          <div>
            <Input
              id="reported-to"
              name="reported-to"
              readOnly
              label="To"
              labelFontType="BOLD_CAPTION"
              labelPosition="top"
              value={payrollReportInfo?.reportTo}
            />
          </div>
          <div style={{ width: "50%" }}>
            <Input
              id="status"
              name="status"
              readOnly
              label="Status"
              labelFontType="BOLD_CAPTION"
              labelPosition="top"
              value={payrollReportInfo?.status}
            />
          </div>
        </div>
        <div
          className="payroll-report-detail-header-actions"
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            marginBottom: "5px",
          }}
        >
          <Switch
            labelFontType="BODY"
            label="Flag this payroll form as not required"
            control="checkbox"
            readOnly={isReadOnly}
            isChecked={requiredFlagChecked ?? false}
            onChangeIsChecked={(value) => notRequiredFlagCheckboxChange(value)}
          />
          <div style={{ marginRight: "6px" }}>
            <Button
              variantStyle="outlined"
              sx={{ width: "max-content" }}
              onClick={() => dispatchGetPayrollToPrint()}
            >
              PRINT FORM
            </Button>
          </div>
        </div>
      </div>
      <div>
        {requiredFlagChecked !== null && (
          <div key={Number(requiredFlagChecked)}>
            {refresh && <BaseTable2 name={tableName} />}
            {customErrorMessage(errorDetails)}
          </div>
        )}
      </div>
      <Row {...rowWithNoMarginNorGutter} verticalMargin="10px">
        <Col {...colWithNoMarginNorGutter} horizontalAlign="flex-start">
          {isCompleted() && (
            <Button variantStyle="outlined" onClick={uncompletePayrollReport}>
              UNCOMPLETE
            </Button>
          )}
        </Col>
        <Col {...colWithNoMarginNorGutter} horizontalAlign="flex-end">
          <SaveButton
            disabled={requiredFlagChecked || isReadOnly}
            onClick={() => saveButtonClicked()}
            sx={{ marginRight: "10px" }}
          />
          <SaveButton
            text="SAVE AS COMPLETE"
            onClick={() => saveAsCompletedButtonClicked()}
            disabled={
              payrollReportInfo?.statusId ===
                PayrollReportStatusEnum.COMPLETE ||
              payrollReportInfo?.statusId ===
                PayrollReportStatusEnum.COMPLETE_ZERO
                ? true
                : isReadOnly
            }
            sx={{ marginRight: "10px" }}
          />
          <Button
            variantStyle="outlined"
            onClick={() => {
              hasPermission.hasPermission === true
                ? setDialogConfiguration({
                    dialogDescriptionText:
                      "Are you sure you want to cancel, all your work will be lost?",
                    open: true,
                    onOptionNoEvent: () => setDialogConfiguration(null),
                    onOptionYesEvent: () => {
                      setEditedDetailRows(null);
                      setErrorDetails(null);
                      setDialogConfiguration(null);
                      setIsOpen(false);
                    },
                  })
                : setIsOpen(false);
            }}
          >
            CANCEL
          </Button>
        </Col>
      </Row>
      <ModalAddClassCode
        insuredId={insuredId}
        payrollReportId={payrollReportId}
        openModalAddClassCode={openModalAddClassCode}
        setOpenModalAddClassCode={setOpenModalAddClassCode}
        setUUID={setUUID}
      />
      <DialogConfirmation
        id="payrollDetailsCancel"
        {...dialogConfiguration}
        onCloseEvent={() => setDialogConfiguration(null)}
      />
    </div>
  );
};

export default PayrollReportDetailModalContent;
