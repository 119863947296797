import { ClaimLandingClaimantDto } from "../../../../../../dtos/claim-landing-claimant-dto";
import { PaymentDto } from "../../../../../../dtos/payment-dto";
import { getFormattedValue } from "../../../../../TrueUI/Search/SearchTextUtil";
import { getPayeeData } from "./ClaimFinancialUtil";

export const getPayeeDataByPayToClaimant = (
  paymentFormData: PaymentDto | Partial<PaymentDto> | null,
  claimLandingClaimant?: ClaimLandingClaimantDto
) => {
  if (
    paymentFormData?.payToClaimant === true &&
    paymentFormData.overridePayee === false
  ) {
    return {
      ...paymentFormData,
      payeeName: claimLandingClaimant?.fullName,
      payeeAddress1: claimLandingClaimant?.address,
      payeeAddress2: claimLandingClaimant?.address2,
      payeeCity: claimLandingClaimant?.city,
      payeeZip: claimLandingClaimant?.zip,
      payeeStateCode: claimLandingClaimant?.state,
    };
  } else {
    return {
      ...paymentFormData,
      payeeName: paymentFormData?.payeeName ?? "",
      payeeAddress1: paymentFormData?.payeeAddress1 ?? "",
      payeeAddress2: paymentFormData?.payeeAddress2 ?? "",
      payeeCity: paymentFormData?.payeeCity ?? "",
      payeeZip: paymentFormData?.payeeZip ?? "",
      payeeStateCode: paymentFormData?.payeeStateCode ?? "",
    };
  }
};

export const getPayeeDataByPayToClaimantOnChange = (
  payToClaimant: boolean,
  paymentFormData: PaymentDto | Partial<PaymentDto> | null,
  claimLandingClaimant?: ClaimLandingClaimantDto
) => {
  if (payToClaimant === true) {
    return {
      ...paymentFormData,
      payeeName: claimLandingClaimant?.fullName,
      payeeCompanyName: claimLandingClaimant?.fullName,
      payeeAddress1: claimLandingClaimant?.address,
      payeeAddress2: claimLandingClaimant?.address2,
      payeeCity: claimLandingClaimant?.city,
      payeeZip: claimLandingClaimant?.zip,
      payeeStateCode: claimLandingClaimant?.state,
      overridePayee: false,
      payeeNameAndAddress: getPayeeData(claimLandingClaimant),
    };
  } else {
    return {
      ...paymentFormData,
      payeeName: paymentFormData?.payeeName ?? "",
      payeeAddress1: paymentFormData?.payeeAddress1 ?? "",
      payeeAddress2: paymentFormData?.payeeAddress2 ?? "",
      payeeCity: paymentFormData?.payeeCity ?? "",
      payeeZip: paymentFormData?.payeeZip ?? "",
      payeeStateCode: paymentFormData?.payeeStateCode ?? "",
      payeeNameAndAddress: "",
      payeeCompanyName: "",
    };
  }
};

export const getPaymentFormDataFromPayee = (
  paymentFormData,
  payee,
  payeeSearchTextKeys,
  payeeSearchTextKeysToShowExtraDataIntoInput
) => {
  return {
    ...paymentFormData,
    payeeId: payee?.payeeId && payee?.payeeId !== "" ? payee?.payeeId : null,
    payeeName:
      payee?.payeeName && payee?.payeeName !== "" ? payee?.payeeName : null,
    payeeNameAndAddress: getFormattedValue(
      payee,
      payeeSearchTextKeys,
      payeeSearchTextKeysToShowExtraDataIntoInput
    ),
    payeeAddress1: payee?.address,
    payeeAddress2: null,
    payeeCity: payee?.city,
    payeeStateCode: payee?.state,
    payeeZip: payee?.zip,
    payToClaimant: false,
    payeeCompanyName: null,
  };
};

export const getPaymentFormSelectsData = (paymentFormSelectsData) => {
  return {
    ten99Types: paymentFormSelectsData?.ten99TypeSelectValues
      ? [
          {
            displayName: "",
            intValue: -1,
          },
          ...paymentFormSelectsData?.ten99TypeSelectValues,
        ]
      : [],
    paymentStatuses: paymentFormSelectsData?.paymentStatusesSelectValues ?? [],
    reserveTypes: paymentFormSelectsData?.reserveTypeSelectValues ?? [],
  };
};
