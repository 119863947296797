import { FC, useEffect, useState } from "react";
import { Box } from "@mui/material";
import {
  BaseTable2,
  Col,
  Font,
  Header,
  InputDate,
  Row,
  Switch,
} from "../../../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Row";
import { BaseTable2Properties } from "../../../../../TrueUI/Tables/BaseTable2/TableProperties";
import { SpecificWaiverFormProps } from "../../../PolicyQuoteForm/PolicyQuoteTypes";
import {
  getSpecificWaiverTableData,
  getTotalWaiverCharge,
  getTotalWaiverPayroll,
  onWaiverPayrollChange,
  setSpecificWaiverTarget,
  updatePremiumCharge,
  updateTotalsByTableChanged,
} from "./ModalSpecificWaiverFormUtils";
import { getConfigurationForColumn } from "../PremiumTableRows/PremiumTableRowsUtils";
import {
  CustomFooterComponent,
  CustomHeaderComponent,
  specificWaiverTableColumns,
} from "./ModalSpecificWaiverFormConfiguration";
import {
  modalSpecificWaiverErrorMessageStyles,
  modalSpecificWaiverFormStyles,
} from "./ModalSpecificWaiverStyles";
import SpecificWaiverForm from "./SpecificWaiverForm";
import { useBaseTable } from "../../../../../../hooks/useBaseTable";
import { INSURED_ATOM_KEY } from "../../../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../../../InsuredAtoms";
import { BaseTableColumn } from "../../../../../../dtos/base-table-column";
import { GettingDateWithoutTime } from "../../../../../../utilities/dateFunctions";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../../../../../GlobalAtoms";
import themes from "../../../../../../media/TrueTheme";

const WaiverForm: FC<SpecificWaiverFormProps> = ({
  tabKey,
  specificWaiverUI,
  specificWaiverFormUI,
  setSpecificWaiverUI,
  errorDetails,
}) => {
  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom } = useAtomFamily(GlobalInsuredAtomFamily(insuredIdAtomKey));

  const [readyToCompute, setReadyToCompute] = useState<{
    data: string[][];
    columns: BaseTableColumn[];
    isChanged: boolean;
  }>({ data: [[]], columns: [], isChanged: false });

  const tableData = getSpecificWaiverTableData(
    getAtom(),
    specificWaiverFormUI
  ) as any[][];

  const TABLE_NAME = `policy_specific_waiver_modal_form_${specificWaiverFormUI?.insuredId}`;
  const tableConfiguration: BaseTable2Properties = {
    name: TABLE_NAME,
    columnsAndData: {
      columns: specificWaiverTableColumns,
      data: tableData,
    },
    advancedOptions: {
      paginate: false,
      disableOrderBy: true,
      isEditMode: true,
      tableStyle: {
        height: "auto",
      },
      CustomHeaderComponent: () =>
        CustomHeaderComponent(
          [{ displayName: "", intValue: -1 } as any].concat(
            specificWaiverFormUI?.insuredAddress
          ),
          specificWaiverUI,
          setSpecificWaiverUI
        ),
    },
    columnOptions: [
      {
        fieldName: "ClassCode",
        width: "60",
      },
      {
        fieldName: "Rate",
        width: "50",
        align: "right",
      },
      {
        fieldName: "PolicyPayroll",
        width: "80",
        align: "right",
      },
      {
        fieldName: "WaiverPayroll",
        width: "80",
        align: "right",
        computeOnChange: {
          conditionForCell: [(options) => onWaiverPayrollChange(options)],
        },
      },
      {
        fieldName: "PremiumCharge",
        width: "150",
        align: "right",
        computeOnChange: {
          conditionForCell: [
            (options) => updatePremiumCharge(options, specificWaiverFormUI),
          ],
        },
      },
    ],
    toolbarOptions: { hideToolbar: true },
    events: {
      onDataChange: (data, columns) =>
        setReadyToCompute({ data, columns, isChanged: true }),
    },
  };

  useBaseTable(tableConfiguration);

  useEffect(() => {
    if (readyToCompute.isChanged) {
      updateTotalsByTableChanged(
        readyToCompute.data,
        readyToCompute.columns,
        specificWaiverUI,
        setSpecificWaiverUI
      );
      setReadyToCompute({ ...readyToCompute, isChanged: false });
    }
  }, [readyToCompute.isChanged]);

  return (
    <Row {...rowWithNoMarginNorGutter}>
      <Col {...getConfigurationForColumn(8, "flex-start", "center")}>
        <div>
          <Row {...rowWithNoMarginNorGutter}>
            <Col {...getConfigurationForColumn(4, "flex-start", "center")}>
              <InputDate
                id="issue-date"
                name="name-issue-date"
                variant="filled"
                label="Issue Date"
                labelFontType="BOLD_CAPTION"
                value={specificWaiverUI?.issueDate}
                onChangeRawValue={(value) =>
                  setSpecificWaiverTarget(
                    "issueDate",
                    GettingDateWithoutTime(value),
                    specificWaiverUI,
                    setSpecificWaiverUI
                  )
                }
              />
            </Col>
            <Col
              {...getConfigurationForColumn(8, "flex-start", "center")}
              horizontalGutter="25px"
            >
              <Switch
                label={"Auto renew?"}
                labelFontType="BODY"
                labelStyle={{ marginTop: "15px" }}
                control="checkbox"
                isChecked={
                  (specificWaiverUI?.autoRenew ?? 0) === 1 ? true : false
                }
                onChangeIsChecked={(value) =>
                  setSpecificWaiverTarget(
                    "autoRenew",
                    value ? 1 : 0,
                    specificWaiverUI,
                    setSpecificWaiverUI
                  )
                }
              />
            </Col>
          </Row>
          <Row {...rowWithNoMarginNorGutter}>
            <Col horizontalAlign="flex-start" verticalGutter="0px">
              <Font>
                {`Waiver Rate ${specificWaiverUI?.elementRate} (${specificWaiverUI?.minimumValue} minimum per waiver / ${specificWaiverUI?.maximumValue} maximum
                    for policy)`}
              </Font>
            </Col>
          </Row>
          <Row {...rowWithNoMarginNorGutter}>
            <Col verticalGutter="25px">
              <Box sx={modalSpecificWaiverFormStyles()}>
                <BaseTable2 name={TABLE_NAME} />
                {tableData.length > 0 ? (
                  CustomFooterComponent(
                    theme,
                    getTotalWaiverPayroll(specificWaiverUI),
                    getTotalWaiverCharge(
                      specificWaiverUI,
                      specificWaiverFormUI?.rounding
                    )
                  )
                ) : (
                  <Box sx={modalSpecificWaiverErrorMessageStyles(theme)}>
                    <Header
                      className="modal-waiver-error"
                      size="h6"
                      marginTop="20px"
                    >
                      There aren't valid Class Codes for performing the
                      calculate
                    </Header>
                  </Box>
                )}
              </Box>
            </Col>
          </Row>
        </div>
      </Col>
      <Col {...getConfigurationForColumn(4, "flex-start", "center")}>
        <SpecificWaiverForm
          tabKey={tabKey}
          specificWaiverUI={specificWaiverUI}
          setSpecificWaiverUI={setSpecificWaiverUI}
          errorDetails={errorDetails}
        />
      </Col>
    </Row>
  );
};

export default WaiverForm;
