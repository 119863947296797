import { FC, useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import OpenIcon from "@mui/icons-material/Launch";
import {
  ConfigModalWaiverProps,
  PolicyWaiverTableProps,
} from "./TypesPolicyWaivers";
import { PermissionsEnums } from "../../../../dtos/permissions-enums";
import { usePermissions } from "../../../../hooks";
import { BaseGridProperties } from "../../../BaseGrid/BaseGridProperties";
import { useBaseGrid } from "../../../BaseGrid/Hooks/useBaseGrid";
import BaseGrid from "../../../BaseGrid/BaseGrid";
import BaseGridDropDownFilter from "../../../BaseGrid/BaseGridCommonFilters/BaseGridDropDownFilter";
import { addAllOption } from "../../../../utilities/selectFunctions";
import ModalWaiverWrapper from "./ModalWaiver/ModalWaiverWrapper";

const PolicyWaiversTable: FC<PolicyWaiverTableProps> = ({
  insuredId,
  programAllowsNoChargeWaivers,
  policies,
}) => {
  const [modalWaiverConfig, setModalWaiverConfig] =
    useState<ConfigModalWaiverProps>({
      isOpen: false,
      refreshTable: false,
      insuredId: insuredId,
      waiverId: 0,
    });
  const [selectedPolicy, setSelectedPolicy] = useState("all");
  const { hasPermission: hasPermissionToAddWaive } = usePermissions([
    PermissionsEnums.UNDERWRITING_QUOTE,
    PermissionsEnums.UNDERWRITTER,
    PermissionsEnums.PROGRAM_ADMIN,
  ]);
  const name = `policy_waivers_grid_${insuredId}`;
  const getURL = `api/PolicyWaiver/GetPolicyWaiversTableData?insuredId=${insuredId}`;

  useEffect(() => {
    if (modalWaiverConfig?.refreshTable === true) {
      manuallyReloadParameters();
      setModalWaiverConfig({ ...modalWaiverConfig, refreshTable: false });
    }
  }, [modalWaiverConfig?.refreshTable]);

  const openWaiverModalLink = (rowData?: any) => {
    return (
      <IconButton
        aria-label="save"
        size="small"
        onClick={() => {
          setModalWaiverConfig({
            ...modalWaiverConfig,
            isOpen: true,
            waiverId: rowData?.WaiverId ?? 0,
          });
        }}
      >
        <OpenIcon fontSize="small" />
      </IconButton>
    );
  };

  const gridConfig: BaseGridProperties = {
    name,
    getURL,
    useManuallyReloadParameters: true,
    toolbarOptions: {
      showEditButton: false,
      showSaveButton: false,
      showImportButton: false,
      showSortFilter: false,
      addButtonText: "NEW WAIVER",
      showAddButton: hasPermissionToAddWaive && programAllowsNoChargeWaivers,
    },
    columnOptions: [
      { fieldName: "IssueDate", width: 8 },
      { fieldName: "Status", width: 10 },
      { fieldName: "CompanyName", width: 30 },
      { fieldName: "WaiverId", width: 8 },
      { fieldName: "AutoReNew", width: 8 },
      { fieldName: "PolicyQuote", width: 16 },
      { fieldName: "IssuedBy", width: 20 },
    ],
    filterOptions: [
      (options) =>
        BaseGridDropDownFilter({
          options,
          id: "policies_filter",
          columnNames: ["PolicyId"],
          optionsOverride: addAllOption(policies),
          labelText: "Policy:",
          labelPosition: "start",
          defaultValue: selectedPolicy,
          onChange: (value) => {
            setSelectedPolicy(value);
          },
        }),
    ],
    events: {
      addEventOverrideCallback: () =>
        setModalWaiverConfig({
          ...modalWaiverConfig,
          isOpen: true,
          waiverId: 0,
        }),
    },
    advancedOptions: {
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: (actionOptions) =>
          openWaiverModalLink(actionOptions.row),
      },
    },
  };

  const { manuallyReloadParameters } = useBaseGrid(gridConfig);

  return (
    <div>
      <BaseGrid name={name} />
      {modalWaiverConfig.isOpen === true && (
        <ModalWaiverWrapper
          modalWaiverConfig={modalWaiverConfig}
          setModalWaiverConfig={setModalWaiverConfig}
        />
      )}
    </div>
  );
};

export default PolicyWaiversTable;
