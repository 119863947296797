import { FC } from "react";
import { BaseTable2Properties } from "../../../../TrueUI/Tables/BaseTable2/TableProperties";
import { useBaseTable } from "../../../../../hooks/useBaseTable";
import Box from "@mui/material/Box";
import { BaseTable2 } from "../../../../TrueUI";
import { BaseTableColumn } from "../../../../../dtos/base-table-column";
import { AccountAndBalanceTableStyles } from "./AccountAndBalanceSectionStyles";
import { getDataIndexByColumnName } from "../../../../TrueUI/Tables/tableFunctions";
import {
  AccountAndBalanceSectionProps,
  CustomFooterComponent,
} from "./CheckPayment/ModalAddPaymentUtil";
import {
  accountCellOnChangeCondition,
  descriptionCellOnChangeCondition,
  getTextOfSelectedItem,
  getRowValueByIndex,
  paymentCellIOnChangeCondition,
  paymentCellInitCondition,
  policyCellInitCondition,
  policyIdCellOnChangeCondition,
  policyNumberCellOnChangeCondition,
  tranAccountCellInitCondition,
  tranAccountIdCellOnChangeCondition,
} from "./AccountAndBalanceSectionUtil";

const AccountAndBalanceSection: FC<AccountAndBalanceSectionProps> = ({
  errorDetails,
  tranAccountOptions,
  setBillingTransactionData,
  hideToolbar = false,
  accountTableData,
}) => {
  const onDataChangeEvent = (data: string[][], columns: BaseTableColumn[]) => {
    const accountIdIndex = getDataIndexByColumnName(columns, "AccountId");
    const policyIdIndex = getDataIndexByColumnName(columns, "PolicyId");
    const invoiceIdIndex = getDataIndexByColumnName(columns, "InvoiceId");
    const policyNumberIndex = getDataIndexByColumnName(columns, "PolicyNumber");
    const descriptionIndex = getDataIndexByColumnName(columns, "Description");
    const paymentIndex = getDataIndexByColumnName(columns, "Payment");
    const baseTableRowKeyIndex = getDataIndexByColumnName(columns, "_row_key");
    const tranAccountIdIndex = getDataIndexByColumnName(
      columns,
      "TranAccountId"
    );

    const originalItemsWithPaymentData = data
      .filter(
        (row) => row[paymentIndex] !== "" && Number(row[paymentIndex]) !== 0
      )
      .map((row: string[]) => {
        const policyId = getRowValueByIndex(row, policyIdIndex);
        const invoiceId = getRowValueByIndex(row, invoiceIdIndex);
        const tranAccountId = getRowValueByIndex(row, tranAccountIdIndex);
        const policyNumber = getTextOfSelectedItem(
          policyId,
          columns[policyNumberIndex]?.displayOptions
        );

        return {
          accountId: Number(row[accountIdIndex]),
          policyId: policyId === 0 ? null : policyId,
          tranAccountId: tranAccountId === 0 ? null : tranAccountId,
          policyNumber: policyNumber,
          description: row[descriptionIndex],
          payment: getRowValueByIndex(row, paymentIndex) ?? 0,
          isBaseTableRowDeleted: false,
          baseTableRowKey: row[baseTableRowKeyIndex],
          invoiceId: invoiceId === 0 ? null : invoiceId,
        };
      });
    setBillingTransactionData?.(originalItemsWithPaymentData);
  };

  const tableName = "AccountAndBalanceTable";
  const tableConfiguration: BaseTable2Properties = {
    name: tableName,
    columnsAndData: {
      columns: accountTableData?.tableData.columns,
      data: accountTableData?.tableData.data,
    },
    columnOptions: [
      {
        fieldName: "PolicyNumber",
        width: 20,
        computeOnInit: {
          conditionForCell: [(options) => policyCellInitCondition(options)],
        },
        computeOnChange: {
          conditionForCell: [
            (options) => policyNumberCellOnChangeCondition(options),
          ],
        },
      },
      {
        fieldName: "PolicyId",
        computeOnChange: {
          conditionForCell: [
            (options) => policyIdCellOnChangeCondition(options),
          ],
        },
      },
      {
        fieldName: "Account",
        width: 20,
        selectMaxHeight: "200px",
        align: "right",
        computeOnInit: {
          conditionForCell: [
            (options) => tranAccountCellInitCondition(options),
          ],
        },
        computeOnChange: {
          conditionForCell: [
            (options) => accountCellOnChangeCondition(options),
          ],
        },
      },
      {
        fieldName: "TranAccountId",
        computeOnChange: {
          conditionForCell: [
            (options) => tranAccountIdCellOnChangeCondition(options),
          ],
        },
      },
      {
        fieldName: "Description",
        width: 20,
        computeOnChange: {
          conditionForCell: [
            (options) =>
              descriptionCellOnChangeCondition(
                options,
                tranAccountOptions ?? []
              ),
          ],
        },
      },
      { fieldName: "Balance", width: 20, align: "right" },
      {
        fieldName: "Payment",
        width: 20,
        allowNegatives: false,
        align: "right",
        computeOnInit: {
          conditionForCell: [(options) => paymentCellInitCondition(options)],
        },
        computeOnChange: {
          conditionForCell: [
            (options) => paymentCellIOnChangeCondition(options, false),
          ],
        },
      },
    ],
    toolbarOptions: {
      hideToolbar: hideToolbar,
      toolBarType: "compact",
      showEditButton: false,
      showSaveButton: false,
      showAddButton: false,
      showExcelButton: false,
      showImportButton: false,
      showPDFButton: false,
      showSortFilter: false,
    },
    advancedOptions: {
      paginate: false,
      isEditMode: true,
      disableOrderBy: true,
      tableStyle: {
        height: "auto",
        headerStyle: "transparent",
      },
      tableErrors: errorDetails,
    },
    events: {
      onDataChange: (data, columns) => onDataChangeEvent(data, columns),
    },
    footerOptions: {
      CustomFooterComponent: (rows) => CustomFooterComponent(rows),
    },
  };

  useBaseTable([tableConfiguration]);

  return (
    <>
      <Box
        id={"account-and-balance-table-wrapper"}
        sx={AccountAndBalanceTableStyles()}
      >
        <BaseTable2 name={tableName} />
      </Box>
    </>
  );
};

export default AccountAndBalanceSection;
