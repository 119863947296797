import { ActivityCategoryEnums } from "../../../../../dtos/activity-category-enums";
import { DocSourceEnum } from "../../../../../dtos/doc-source-enum";
import { FormTypeEnum } from "../../../../../dtos/form-type-enum";
import { PolicyFormTypeEnum } from "../../../../../dtos/policy-form-type-enum";
import { ProgramPacketDto } from "../../../../../dtos/program-packet-dto";
import { ProgramPolicyDocumentDto } from "../../../../../dtos/program-policy-document-dto";
import { TemplateNameAndSourceDto } from "../../../../../dtos/template-name-and-source-dto";
import { GlobalInsuredAtomProperties } from "../../../InsuredAtoms";
import { getJSONDataWithoutConfigurations } from "../../PolicyQuoteForm/PolicyQuoteUtils";
import { DocumentsCommandsUIProps, DocumentsUIProps } from "./DocumentsTypes";

export const ERROR_TEMPLATE_NAME = "_ERROR_TEMPLATE_NO_FOUND_";
export const QUOTE_POLICY_API_PATH = "api/QuotePolicy";

export const defaultDocumentsCommandsUI: DocumentsCommandsUIProps = {
  saveFile: false,
  fileName: "",
};

export const defaultDocumentsUI: DocumentsUIProps = {
  allDocuments: null,
  selectedPackets: [],
  selectedPacketDocuments: [],
  selectedDocuments: [],
};

export const getActivityLogCategory = (policyArea: PolicyFormTypeEnum) => {
  switch (policyArea) {
    case PolicyFormTypeEnum.QUOTE_FORM:
      return ActivityCategoryEnums.POLICY_POLICY_DOC_PACKET_PRINTED;
    case PolicyFormTypeEnum.FINAL_AUDIT_FORM:
      return ActivityCategoryEnums.AUDIT_DOCUMENTS_PRINTED;
    default:
      return ActivityCategoryEnums.POLICY_POLICY_DOC_PACKET_PRINTED;
  }
};

export const getDocumentFileCategory = (policyArea: PolicyFormTypeEnum) => {
  switch (policyArea) {
    case PolicyFormTypeEnum.QUOTE_FORM:
      return "Policy Documents";
    case PolicyFormTypeEnum.FINAL_AUDIT_FORM:
      return "Audit Documents";
    default:
      return "Policy Documents";
  }
};

export const getDefaultFileName = (
  selectedPackets: ProgramPacketDto[],
  selectedDocuments: ProgramPolicyDocumentDto[],
  insuredName: string,
  policyArea: PolicyFormTypeEnum
) => {
  if (selectedPackets.length === 1 && selectedDocuments.length === 0) {
    const packetName = selectedPackets[0].packetName;
    return `${insuredName} ${packetName}`;
  }
  if (selectedPackets.length === 0 && selectedDocuments.length === 1) {
    const documentName = selectedDocuments[0].docName;
    return `${insuredName} ${documentName}`;
  }
  return `${insuredName} ${getDocumentFileCategory(policyArea)}`;
};

export const getTemplateNameAndSource = (
  selectedPacketDocument: ProgramPolicyDocumentDto
): TemplateNameAndSourceDto => {
  const formType =
    selectedPacketDocument.docSource === DocSourceEnum.CUSTOM_JSON
      ? FormTypeEnum.CUSTOM_FORM
      : FormTypeEnum.POLICY_FORM;
  return {
    templateName: selectedPacketDocument.templateName ?? ERROR_TEMPLATE_NAME,
    mergeFieldSource: formType,
  };
};

export const getSourceJSONFromDocSourceForIndividualPrinting = (
  docSource: number,
  customJSONToPrint: string,
  atomValue: GlobalInsuredAtomProperties | null
) => {
  const formType =
    docSource === DocSourceEnum.CUSTOM_JSON
      ? FormTypeEnum.CUSTOM_FORM
      : FormTypeEnum.POLICY_FORM;
  const mergeFieldPropName =
    docSource === DocSourceEnum.CUSTOM_JSON
      ? "customMergeFields"
      : "policyMergeFields";
  const mergeFieldsToUse =
    docSource === DocSourceEnum.CUSTOM_JSON
      ? JSON.parse(customJSONToPrint)
      : getJSONDataWithoutConfigurations(atomValue?.policyQuoteInformation);

  return {
    formType,
    [mergeFieldPropName]: mergeFieldsToUse,
  };
};
