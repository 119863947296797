import { Col, FontBold, Row } from "../../../TrueUI";
import { PolicyPaymentSchedulePage } from "../../../../dtos/policy-payment-schedule-page";
import { PolicyPaymentScheduleMultiTablePage } from "../../../../dtos/policy-payment-schedule-multi-table-page";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { PolicyPaymentScheduleTableRequestDto } from "../../../../dtos/policy-payment-schedule-table-request-dto";
import { currencyFormat } from "../../../../utilities/currencyFunctions";
import {
  BaseTable2AdvancedProperties,
  BaseTableChildrenData,
} from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import { PolicyBindInstructionUIProps } from "../PolicyQuoteForm/PolicyQuoteTypes";
import MultiTableChildrenComponent from "./MultiTableChildrenComponent";
import MultiTableParentComponent from "./MultiTableParentComponent";
import { BaseTableColumn } from "../../../../dtos/base-table-column";
import {
  castToExternalColumnsDataTypeOnly,
  getDataIndexByColumnNameWithoutExternal,
} from "../../../TrueUI/Tables/tableFunctions";
import { BaseTableInputType } from "../../../../dtos/base-table-input-type";
import {
  FormattingDate,
  GettingDateWithoutTime,
} from "../../../../utilities/dateFunctions";
import { BaseTableMetaData } from "../../../../dtos/base-table-meta-data";
import { MultiBaseTable } from "../../../../dtos/multi-base-table";
import { PolicyInvoiceBlob } from "../../../../dtos/policy-invoice-blob";
import { StatusEnums } from "../../../../dtos/status-enums";
import { GlobalInsuredAtomProperties } from "../../InsuredAtoms";
import { PolicyInvoiceSubBlob } from "../../../../dtos/policy-invoice-sub-blob";
import { PolicyInvoiceDetailBlob } from "../../../../dtos/policy-invoice-detail-blob";
import { unFormatNegativeLocalString } from "../../../../utilities/stringFunctions";
import { InvoiceTypeDto } from "../../../../dtos/invoice-type-dto";
import { PolicyPayPlanBlob } from "../../../../dtos/policy-pay-plan-blob";
import { PolicyInsuredAddressBlob } from "../../../../dtos/policy-insured-address-blob";

const getDueDateByHeaderParam = (headerParams?: BaseTableMetaData[]) => {
  const param = headerParams?.find(
    (p) => p.inputType === BaseTableInputType.DATE_PICKER
  );
  return param?.value ?? null;
};

export const getAdvancedTableOptions = (
  isBillByLocation?: boolean,
  paymentScheduleTable?:
    | PolicyPaymentSchedulePage
    | PolicyPaymentScheduleMultiTablePage
    | null,
  updatePaymentScheduleMultiTableDueDate?: (
    rowIndex: number,
    newDueDate: Date
  ) => void,
  readOnly: boolean = false
): BaseTable2AdvancedProperties => {
  return isBillByLocation
    ? {
        headerExportType: "aligned",
        tableStyle: { height: "fit-content" },
        paginate: false,
        disableOrderBy: true,
        multiTableProperties: {
          MultiTablePatternComponent: (e) => (
            <MultiTableChildrenComponent
              detailData={e}
              scheduleTable={
                paymentScheduleTable as PolicyPaymentScheduleMultiTablePage
              }
            />
          ),
          MultiTableHeaderPatternComponent: (params, obj) => (
            <MultiTableParentComponent
              readOnly={readOnly}
              metaData={obj}
              paramDate={getDueDateByHeaderParam(params)}
              headerParams={params ?? []}
              columnOptions={[
                {
                  fieldName: "DueDate",
                  width: "22",
                },
                {
                  fieldName: "Description",
                  width: "38",
                },
                {
                  fieldName: "Fees",
                  width: "30",
                  align: "right",
                },
                {
                  fieldName: "Premium",
                  width: "30",
                  align: "right",
                },
                {
                  fieldName: "Total",
                  width: "30",
                  align: "right",
                },
              ]}
              updatePaymentScheduleMultiTableDueDate={
                updatePaymentScheduleMultiTableDueDate
              }
            />
          ),
        },
      }
    : {
        tableStyle: { height: "fit-content" },
        paginate: false,
        disableOrderBy: true,
        isEditMode: !readOnly,
      };
};

export const getColumnsAndDataBaseTable = (
  paymentScheduleTable?: PolicyPaymentSchedulePage
) => ({
  columns: paymentScheduleTable?.tableData?.columns ?? [],
  data: paymentScheduleTable?.tableData?.data ?? [],
});

export const getColumnsAndDataMultiTable = (
  paymentScheduleTable?: PolicyPaymentScheduleMultiTablePage
) => ({
  columns: paymentScheduleTable?.tableData?.columns ?? [],
  groups: paymentScheduleTable?.tableData?.groups ?? [],
});

const CustomTableFooterComponent = (
  bindInstructionUI?: PolicyBindInstructionUIProps
) => {
  const isNonEarning = bindInstructionUI?.nonEarningAmount ?? false;
  const totalPremium =
    (bindInstructionUI?.billedDepositAmount ?? 0) +
    (bindInstructionUI?.installmentPaymentAmount ?? 0) *
      ((bindInstructionUI?.numberOfPayments ?? 0) - 1);
  const total = totalPremium + (bindInstructionUI?.billedFees ?? 0);

  const columnsWidth = isNonEarning
    ? {
        numberOfColumns: 138,
        date: { md: 20, lg: 20, xl: 20 },
        label: { md: 31, lg: 31, xl: 31 },
        columns: { md: 21, lg: 21, xl: 21 },
      }
    : {
        numberOfColumns: 104,
        date: { md: 16, lg: 16, xl: 16 },
        label: { md: 26, lg: 26, xl: 26 },
        columns: { md: 20, lg: 20, xl: 20 },
      };
  return (
    <Row
      {...rowWithNoMarginNorGutter}
      numberOfColumns={columnsWidth.numberOfColumns}
      rowHeight="41px"
    >
      <Col breakpoints={columnsWidth.date} />
      <Col breakpoints={columnsWidth.label} horizontalAlign="flex-start">
        <FontBold>Total of all payments</FontBold>
      </Col>
      {isNonEarning && (
        <>
          <Col
            horizontalMargin="0px"
            breakpoints={columnsWidth.columns}
            horizontalAlign="flex-end"
            verticalAlign="center"
            horizontalGutter="0px"
          >
            <FontBold>
              {currencyFormat(bindInstructionUI?.nonEarningAmount ?? 0)}
            </FontBold>
          </Col>
          <Col breakpoints={{ md: 1, lg: 1, xl: 1 }} />
        </>
      )}
      <Col
        horizontalMargin="0px"
        breakpoints={columnsWidth.columns}
        horizontalAlign="flex-end"
        verticalAlign="center"
        horizontalGutter="0px"
      >
        <FontBold>
          {currencyFormat(bindInstructionUI?.billedFees, false, 0)}
        </FontBold>
      </Col>
      <Col breakpoints={{ md: 1, lg: 1, xl: 1 }} />
      <Col
        horizontalMargin="0px"
        breakpoints={columnsWidth.columns}
        horizontalAlign="flex-end"
        verticalAlign="center"
        horizontalGutter="0px"
      >
        <FontBold>{currencyFormat(totalPremium, false, 0)}</FontBold>
      </Col>
      <Col breakpoints={{ md: 1, lg: 1, xl: 1 }} />
      <Col
        horizontalMargin="0px"
        breakpoints={columnsWidth.columns}
        horizontalAlign="flex-end"
        verticalAlign="center"
        horizontalGutter="10px"
      >
        <FontBold>{currencyFormat(total, false, 0)}</FontBold>
      </Col>
    </Row>
  );
};

const CustomMultiTableFooterComponent = (
  paymentScheduleUI?: PolicyPaymentScheduleTableRequestDto
) => {
  const totalPremium =
    (paymentScheduleUI?.billedDepositAmount ?? 0) +
    (paymentScheduleUI?.installmentPaymentAmount ?? 0) *
      ((paymentScheduleUI?.numberOfPayments ?? 0) - 1);
  const total = totalPremium + (paymentScheduleUI?.billedFeesAmount ?? 0);
  return (
    <Row {...rowWithNoMarginNorGutter} numberOfColumns={104} rowHeight="41px">
      <Col breakpoints={{ md: 16, lg: 16, xl: 16 }} />
      <Col
        breakpoints={{ md: 25, lg: 25, xl: 25 }}
        horizontalAlign="flex-start"
      >
        <FontBold>Total of all payments</FontBold>
      </Col>
      <Col
        horizontalMargin="0px"
        horizontalGutter="0px"
        breakpoints={{ md: 20, lg: 20, xl: 20 }}
        horizontalAlign="flex-end"
        verticalAlign="center"
      >
        <FontBold>
          {currencyFormat(paymentScheduleUI?.billedFeesAmount, false, 0)}
        </FontBold>
      </Col>
      <Col breakpoints={{ md: 1, lg: 1, xl: 1 }} />
      <Col
        horizontalMargin="0px"
        horizontalGutter="0px"
        breakpoints={{ md: 19, lg: 19, xl: 19 }}
        horizontalAlign="flex-end"
        verticalAlign="center"
      >
        <FontBold>{currencyFormat(totalPremium, false, 0)}</FontBold>
      </Col>
      <Col breakpoints={{ md: 1, lg: 1, xl: 1 }} />
      <Col
        horizontalMargin="0px"
        horizontalGutter="0px"
        breakpoints={{ md: 20, lg: 20, xl: 20 }}
        horizontalAlign="flex-end"
        verticalAlign="center"
      >
        <FontBold>{currencyFormat(total, false, 0)}</FontBold>
      </Col>
      <Col breakpoints={{ md: 2, lg: 2, xl: 2 }} />
    </Row>
  );
};

const getChildrenData = (
  paymentScheduleTable?: PolicyPaymentScheduleMultiTablePage
): BaseTableChildrenData[] =>
  paymentScheduleTable?.rowChildren?.map(
    (children) =>
      ({
        groupId: children.id,
        columns: paymentScheduleTable?.tableData?.columns,
        data: children.rowData.map((row) => [
          "",
          row[0],
          row[1],
          row[2],
          row[3],
        ]),
      } as BaseTableChildrenData)
  ) ?? [];

export const getBaseTableProperties = (
  tableName: string,
  isBillByLocation?: boolean,
  paymentScheduleTable?:
    | PolicyPaymentSchedulePage
    | PolicyPaymentScheduleMultiTablePage
    | null,
  paymentScheduleUI?: PolicyPaymentScheduleTableRequestDto,
  bindInstructionUI?: PolicyBindInstructionUIProps,
  updatePaymentScheduleTableDueDate?: (newData: string[][]) => void,
  updatePaymentScheduleMultiTableDueDate?: (
    rowIndex: number,
    newDueDate: Date
  ) => void,
  readOnly?: boolean
) => ({
  name: tableName,
  tableType: isBillByLocation ? "multi" : "standard",
  advancedOptions: getAdvancedTableOptions(
    isBillByLocation,
    paymentScheduleTable,
    updatePaymentScheduleMultiTableDueDate,
    readOnly
  ),
  childrenData: isBillByLocation
    ? getChildrenData(
        paymentScheduleTable as PolicyPaymentScheduleMultiTablePage
      )
    : null,
  parentMetaData: (paymentScheduleTable as PolicyPaymentScheduleMultiTablePage)
    ?.tableData?.parentMetaData,
  columnOptions: [
    {
      fieldName: "DueDate",
      width: "15",
    },
    {
      fieldName: "Description",
      width: "25",
    },
    {
      fieldName: "NonEarning",
      align: "right",
    },
    {
      fieldName: "Fees",
      align: "right",
    },
    {
      fieldName: "Premium",
      align: "right",
    },
    {
      fieldName: "Total",
      align: "right",
    },
  ],
  toolbarOptions: {
    showSaveButton: false,
    showAddButton: false,
    showEditButton: false,
    showSortFilter: false,
    showImportButton: false,
    showExcelButton: readOnly,
    showPDFButton: readOnly,
  },
  events: {
    onDataChange: (data: string[][], columns: BaseTableColumn[]) =>
      updatePaymentScheduleTableDueDate?.(
        castToExternalColumnsDataTypeOnly(columns, data)
      ),
  },
  footerOptions: {
    CustomFooterComponent: () =>
      isBillByLocation
        ? CustomMultiTableFooterComponent(paymentScheduleUI)
        : CustomTableFooterComponent(bindInstructionUI),
  },
});

export const getMultiTableGroups = (
  groupRowIndex: number,
  newDueDate: Date,
  paymentScheduleTable?: MultiBaseTable | null
) =>
  paymentScheduleTable?.groups.map((group, groupIdx) => {
    return groupIdx === groupRowIndex
      ? {
          ...group,
          header: group.header.map((header) => {
            return header.inputType === BaseTableInputType.DATE_PICKER
              ? { ...header, value: FormattingDate(newDueDate) }
              : header;
          }),
        }
      : group;
  });

const getInvoiceDetails = (
  isDeposit: boolean,
  description: string,
  fees: number,
  premium: number,
  premiumTranAccountId: number,
  feesTranAccountId: number
) => {
  if (isDeposit) {
    return [
      {
        invoiceDetailID: 0,
        invoiceSubID: 0,
        detailStatus: StatusEnums.ACTIVE,
        lineNumber: 2,
        tranAccountID: feesTranAccountId,
        quantity: 1,
        description,
        priceEach: fees,
        extended: fees,
      },
      {
        invoiceDetailID: 0,
        invoiceSubID: 0,
        detailStatus: StatusEnums.ACTIVE,
        lineNumber: 1,
        tranAccountID: premiumTranAccountId,
        quantity: 1,
        description,
        priceEach: premium,
        extended: premium,
      },
    ] as PolicyInvoiceDetailBlob[];
  } else {
    return [
      {
        invoiceDetailID: 0,
        invoiceSubID: 0,
        detailStatus: StatusEnums.ACTIVE,
        lineNumber: 1,
        tranAccountID: premiumTranAccountId,
        quantity: 1,
        description,
        priceEach: premium,
        extended: premium,
      },
    ] as PolicyInvoiceDetailBlob[];
  }
};

const getInvoiceSubs = (
  isDeposit: boolean,
  description: string,
  sequenceNumber: number,
  fees: number,
  premium: number,
  premiumTranAccountId: number,
  feesTranAccountId: number,
  childData: string[][] | null,
  primaryAddress?: PolicyInsuredAddressBlob | null,
  addresses?: PolicyInsuredAddressBlob[] | null
) => {
  if (childData !== null) {
    return childData.map((row, index) => {
      const locationNameIndex = 0;
      const feesIndex = 1;
      const premiumIndex = 2;
      const locationAndStateArray = row[locationNameIndex].split(" ");
      const addressFound = addresses?.find(
        (address) =>
          address.locationNumber?.toString() === locationAndStateArray[0]
      );
      const fees =
        row[feesIndex] !== "" &&
        row[feesIndex] !== undefined &&
        row[feesIndex] !== null
          ? unFormatNegativeLocalString(row[feesIndex])
          : 0;
      const premium =
        row[premiumIndex] !== "" &&
        row[premiumIndex] !== undefined &&
        row[premiumIndex] !== null
          ? unFormatNegativeLocalString(row[premiumIndex])
          : 0;
      const invoiceDetailDescription = `${row[locationNameIndex]} ${description}`;

      return {
        invoiceSubID: 0,
        invoiceID: 0,
        nameID: addressFound?.nameID ?? primaryAddress?.nameID ?? null,
        addressID: addressFound?.addressID ?? primaryAddress?.addressID ?? null,
        sequence: (sequenceNumber + (index + 1)).toString(),
        invoiceDetails: getInvoiceDetails(
          isDeposit,
          invoiceDetailDescription,
          fees,
          premium,
          premiumTranAccountId,
          feesTranAccountId
        ),
      } as PolicyInvoiceSubBlob;
    });
  }

  return [
    {
      invoiceSubID: 0,
      invoiceID: 0,
      nameID: primaryAddress?.nameID ?? null,
      addressID: primaryAddress?.addressID ?? null,
      sequence: (sequenceNumber + 1).toString(),
      invoiceDetails: getInvoiceDetails(
        isDeposit,
        description,
        fees,
        premium,
        premiumTranAccountId,
        feesTranAccountId
      ),
    },
  ] as PolicyInvoiceSubBlob[];
};

const getInvoiceJSON = (
  isDeposit: boolean,
  invoiceTypeID: number,
  payPlanId: number,
  dueDate: Date,
  description: string,
  sequenceNumber: number,
  fees: number,
  premium: number,
  premiumTranAccountId: number,
  feesTranAccountId: number,
  childData: string[][] | null,
  primaryAddress?: PolicyInsuredAddressBlob | null,
  addresses?: PolicyInsuredAddressBlob[] | null
) =>
  ({
    invoiceID: 0,
    invoiceNumber: null,
    invoiceTypeID,
    invoiceStatus: StatusEnums.ACTIVE,
    payPlanID: payPlanId,
    invoiceDate: dueDate,
    dueDate: dueDate,
    invoiceDescription: description,
    invoiceTotal: fees + premium,
    invoiceSubs: getInvoiceSubs(
      isDeposit,
      description,
      sequenceNumber,
      fees,
      premium,
      premiumTranAccountId,
      feesTranAccountId,
      childData,
      primaryAddress,
      addresses
    ),
  } as PolicyInvoiceBlob);

const getInvoicesFromStandardScheduleTable = (
  scheduleTable: PolicyPaymentSchedulePage,
  invoiceTypeDeposit?: InvoiceTypeDto,
  invoiceTypePremium?: InvoiceTypeDto,
  payPlan?: PolicyPayPlanBlob | null,
  primaryAddress?: PolicyInsuredAddressBlob | null,
  addresses?: PolicyInsuredAddressBlob[] | null
) => {
  const regularScheduleTable = scheduleTable.tableData;
  const columns = regularScheduleTable?.columns ?? [];

  const dueDateIndex = getDataIndexByColumnNameWithoutExternal(
    columns,
    "DueDate"
  );
  const descriptionIndex = getDataIndexByColumnNameWithoutExternal(
    columns,
    "Description"
  );
  const feesIndex = getDataIndexByColumnNameWithoutExternal(columns, "Fees");
  const premiumIndex = getDataIndexByColumnNameWithoutExternal(
    columns,
    "Premium"
  );

  const data = regularScheduleTable?.data ?? [];

  const newInvoices = data.map((row, index) => {
    const dueDate = GettingDateWithoutTime(new Date(row[dueDateIndex] ?? ""));
    const invoiceTypeID =
      index === 0
        ? invoiceTypeDeposit?.invoiceTypeId ?? null
        : invoiceTypePremium?.invoiceTypeId ?? null;
    const fees =
      row[feesIndex] !== "" &&
      row[feesIndex] !== undefined &&
      row[feesIndex] !== null
        ? unFormatNegativeLocalString(row[feesIndex])
        : 0;
    const premium =
      row[premiumIndex] !== "" &&
      row[premiumIndex] !== undefined &&
      row[premiumIndex] !== null
        ? unFormatNegativeLocalString(row[premiumIndex])
        : 0;

    return getInvoiceJSON(
      index === 0,
      invoiceTypeID ?? 0,
      payPlan?.payPlanID ?? 0,
      dueDate,
      row[descriptionIndex] ?? "",
      index,
      fees,
      premium,
      payPlan?.premiumTranAccountId ?? 0,
      payPlan?.assessmentTranAccountID ?? 0,
      null,
      primaryAddress,
      addresses
    );
  });

  return newInvoices;
};

const getInvoicesFromMultiTableSchedule = (
  multiTableSchedulePage: PolicyPaymentScheduleMultiTablePage,
  invoiceTypeDeposit?: InvoiceTypeDto,
  invoiceTypePremium?: InvoiceTypeDto,
  payPlan?: PolicyPayPlanBlob | null,
  primaryAddress?: PolicyInsuredAddressBlob | null,
  addresses?: PolicyInsuredAddressBlob[] | null
) => {
  const multiTableSchedule = multiTableSchedulePage.tableData;
  const groups = multiTableSchedule?.groups ?? [];

  const newInvoices = groups.map((group, index) => {
    const dueDateInHeader = group.header.find(
      (header) => header.name === "DueDate"
    )?.value;
    const feesInHeader = group.header.find(
      (header) => header.name === "Fees"
    )?.value;
    const premiumInHeader = group.header.find(
      (header) => header.name === "Premium"
    )?.value;
    const descriptionInHeader =
      group.header.find((header) => header.name === "Description")?.value ?? "";

    const dueDate = GettingDateWithoutTime(new Date(dueDateInHeader ?? ""));
    const invoiceTypeID =
      index === 0
        ? invoiceTypeDeposit?.invoiceTypeId ?? null
        : invoiceTypePremium?.invoiceTypeId ?? null;
    const fees =
      feesInHeader !== "" && feesInHeader !== undefined && feesInHeader !== null
        ? unFormatNegativeLocalString(feesInHeader)
        : 0;
    const premium =
      premiumInHeader !== "" &&
      premiumInHeader !== undefined &&
      premiumInHeader !== null
        ? unFormatNegativeLocalString(premiumInHeader)
        : 0;

    const childData =
      multiTableSchedulePage.rowChildren?.find(
        (child) => child.id.toString() === group.groupUiidKey
      )?.rowData ?? [];

    const sequenceNumber = index * childData.length;

    return getInvoiceJSON(
      index === 0,
      invoiceTypeID ?? 0,
      payPlan?.payPlanID ?? 0,
      dueDate,
      descriptionInHeader,
      sequenceNumber,
      fees,
      premium,
      payPlan?.premiumTranAccountId ?? 0,
      payPlan?.assessmentTranAccountID ?? 0,
      childData,
      primaryAddress,
      addresses
    );
  });

  return newInvoices;
};

export const getScheduleTableAsInvoicesForRegularQuote = (
  readOnly: boolean,
  atomValue: GlobalInsuredAtomProperties | null,
  scheduleTable?:
    | PolicyPaymentSchedulePage
    | PolicyPaymentScheduleMultiTablePage
    | null
) => {
  const policyJSON = atomValue?.policyQuoteInformation?.policyQuote;
  const billByLocation = policyJSON?.bindInstructions?.billByLocation ?? false;
  const invoices = policyJSON?.invoices;
  const invoiceTypeList = policyJSON?.configuration?.invoiceTypeList ?? [];
  const invoiceTypeDeposit = invoiceTypeList.find(
    (it) => it.description === "Deposit"
  );
  const invoiceTypePremium = invoiceTypeList.find(
    (it) => it.description === "Premium"
  );
  const payPlan = policyJSON?.payPlan;
  const primaryAddress = policyJSON?.insured?.primaryAddress;
  const addresses = policyJSON?.insured?.addresses;

  if (
    scheduleTable !== undefined &&
    scheduleTable !== null &&
    !readOnly &&
    !billByLocation
  ) {
    return getInvoicesFromStandardScheduleTable(
      scheduleTable as PolicyPaymentSchedulePage,
      invoiceTypeDeposit,
      invoiceTypePremium,
      payPlan,
      primaryAddress,
      addresses
    );
  }
  if (scheduleTable !== undefined && scheduleTable !== null && !readOnly) {
    return getInvoicesFromMultiTableSchedule(
      scheduleTable as PolicyPaymentScheduleMultiTablePage,
      invoiceTypeDeposit,
      invoiceTypePremium,
      payPlan,
      primaryAddress,
      addresses
    );
  }

  return invoices;
};
