import { FC } from "react";
import { BaseTable2, Header } from "../../../../../TrueUI";
import {
  BaseTable2Properties,
  ComputeForCellParameters,
  ComputeForColumnParameters,
  ConditionForCellResponse,
  ConditionForColumnResponse,
} from "../../../../../TrueUI/Tables/BaseTable2/TableProperties";
import { SelectOptions } from "../../../../../../dtos/select-options";
import {
  GlobalInsuredAtomFamily,
  TriggerComponentUpdateAtom,
} from "../../../../InsuredAtoms";
import { useBaseTable } from "../../../../../../hooks/useBaseTable";
import { useAtomFamily } from "../../../../../../hooks/useAtomFamily";
import { spreadChangedBodyParts } from "../spreadClaimTabsFunctions";
import { Stack } from "@mui/material";
import { useRecoilValue } from "recoil";
import { ClaimBodyPartDto } from "../../../../../../dtos/claim-body-part-dto";
import { getColorWithOpacityHexaDecimal } from "../../../../../../media/themeConstants";
import themes from "../../../../../../media/TrueTheme";
import { globalOptions } from "../../../../../../GlobalAtoms";
import StyleBox from "../../../../../TrueUI/StyleBox/StyleBox";
import Link from "../../../../../TrueUI/Typography/Link";
import { OPTION_COLUMN_FIELD_NAME } from "../../../../../TrueUI/Tables/TableConstants";

const tableName = "claim_body_parts_container_table";

type ClaimBodyPartsProps = {
  insuredIdAtomKey: string;
  claimId?: number;
  allBodyParts?: SelectOptions[];
  errorDetails?: any;
  hasClaimsManagementPermissions: boolean;
};

const ClaimBodyPartsContainer: FC<ClaimBodyPartsProps> = ({
  claimId,
  allBodyParts,
  insuredIdAtomKey,
  errorDetails,
  hasClaimsManagementPermissions,
}) => {
  const { setAtom, getAtom, setComponentTriggers } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );
  useRecoilValue(TriggerComponentUpdateAtom("bodyPartsComponent"));

  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];

  const onBodyPartChange = (): ConditionForCellResponse | void => {
    const conditionResponse = {
      triggerComputeFieldNames: ["Description"],
    } as ConditionForCellResponse;
    return conditionResponse;
  };
  const onDescriptionChange = (
    options: ComputeForCellParameters<ClaimBodyPartDto>
  ): ConditionForCellResponse | void => {
    const description =
      allBodyParts?.find(
        (x) => x.stringValue === options.row?.BodyPartId?.toString()
      )?.displayName ?? "";
    const conditionResponse = {
      value: description,
    } as ConditionForCellResponse;
    return conditionResponse;
  };

  const onPrimaryChange = (
    options: ComputeForColumnParameters
  ): ConditionForColumnResponse => {
    if (options.rowValue === "1") {
      const updateTargetFields = options.columnData.map((data, index) => {
        if (options.rowEventIndex === index) {
          return {
            ...data,
            value: "1",
          };
        }
        return { ...data, value: "0" };
      });

      return {
        targetFields: updateTargetFields,
      } as ConditionForColumnResponse;
    }
    const updateTargetFields = options.columnData.map((data, index) => {
      if (options.rowEventIndex === index) {
        return {
          ...data,
          value: options.rowValue,
        };
      }
      return data;
    });
    return {
      targetFields: updateTargetFields,
    } as ConditionForColumnResponse;
  };

  const isTableEditMode =
    getAtom()?.claimTabsInformation?.claimDetailsTab
      ?.detailsContainersInformation?.bodyPartContainerData?.isTableOnEditMode;

  const tableConfiguration: BaseTable2Properties<ClaimBodyPartDto> = {
    name: tableName,
    getURL: `api/claims/GetClaimDetailsBodyPartsData?claimId=${claimId}`,
    toolbarOptions: {
      toolBarType: "compact",
      showAddButton: hasClaimsManagementPermissions,
      showEditButton: false,
      showExcelButton: false,
      showImportButton: false,
      showPDFButton: false,
      showSaveButton: false,
      showSortFilter: false,
    },
    columnOptions: [
      {
        fieldName: "PrimaryInjury",
        width: 20,
        computeOnChange: {
          conditionForColumn: [(options) => onPrimaryChange(options)],
        },
      },
      { fieldName: "Side", width: 20 },
      {
        fieldName: "BodyPartId",
        width: 30,
        selectMaxHeight: "200px",
        computeOnChange: {
          conditionForCell: [() => onBodyPartChange()],
        },
      },
      {
        fieldName: "Description",
        width: 30,
        computeOnChange: {
          conditionForCell: [(options) => onDescriptionChange(options)],
        },
      },
      { fieldName: OPTION_COLUMN_FIELD_NAME, width: 10 },
    ],
    advancedOptions: {
      paginate: false,
      isEditMode: isTableEditMode,
      optionsColumnConfiguration: {
        isHidden: !hasClaimsManagementPermissions,
        optionType: "edit_delete",
      },
      tableStyle: {
        headerStyle: "transparent",
        height: "200px",
      },
      tableErrors: errorDetails ?? [],
      disableOrderBy: true,
    },
    events: {
      onChangeDataListener: (changedData) => {
        const atomValue = getAtom();
        setAtom(spreadChangedBodyParts(atomValue, changedData));
        setComponentTriggers(["buttonComponent"]);
      },
    },
  };

  useBaseTable(tableConfiguration);

  return (
    <StyleBox
      showBorder
      borderRadius={5}
      overrideBorderColor={getColorWithOpacityHexaDecimal(
        theme?.BORDER_COLOR,
        100
      )}
      padding={10}
      margin={0}
      width={"100%"}
      height={"100%"}
      verticalAlign={"start"}
      marginBottom={5}
    >
      <Stack width={"100%"}>
        <Header marginBottom="10px">
          <Link
            linkFontType={"BOLD_TITLE"}
            displayValue={"Body Part"}
            name="bodyPartsCollapseTitleLink"
          ></Link>
        </Header>
        <BaseTable2 name={tableName} />
      </Stack>
    </StyleBox>
  );
};

export default ClaimBodyPartsContainer;
