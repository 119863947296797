import { FC, useEffect, useState } from "react";
import { useApiGet } from "../../../../hooks";
import { Col, Input, Row, Select, Switch } from "../../../TrueUI";
import PaymentSchedule from "./PaymentSchedule";
import {
  BindInstructionPremiumBreakdownProp,
  BindInstructionPaymentPlanUIProps,
} from "../PolicyQuoteForm/PolicyQuoteTypes";
import {
  calculatedBilledDepositAmount,
  colWithHorizontalGutterOnly,
  constants,
  defaultPaymentPlanOption,
  getBilledDepositAmountByPlanTypeOrDepositOverride,
  getLongevityThreshold,
  getPaymentPlanDtoAsJSON,
  getPaymentPlanOption,
  getSelectOptionsFromResponse,
  isDepositOverride,
  paymentPlanDepositTypeOptions,
} from "./BindInstructionUtils";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { ProgramPayPlanDto } from "../../../../dtos/program-pay-plan-dto";
import { addDays } from "../../../../utilities/dateFunctions";
import {
  conditionHasValue,
  isEmptyValue,
} from "../../../../utilities/conditionalSupportFunctions";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import { getPolicyQuote } from "../PolicyQuoteForm/PolicyQuoteUtils";
import { PolicyTypeEnum } from "../../../../dtos/policy-type-enum";

const PaymentPlan: FC<BindInstructionPremiumBreakdownProp> = ({
  insuredId,
  tabKey,
  bindInstructionUI,
  setBindInstructionUI,
  readOnly,
}) => {
  const [paymentPlanUI, setPaymentPlanUI] =
    useState<BindInstructionPaymentPlanUIProps>();

  const isMultiTable = paymentPlanUI?.billByLocation ?? false;
  const billedPremiumAmount = bindInstructionUI?.billedPremium ?? 0;

  const [tableURL, setTableURL] = useState("");

  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom } = useAtomFamily(GlobalInsuredAtomFamily(insuredIdAtomKey));

  const onChangePaymentPlan = (paymentPlanSelected: number) => {
    const atomValue = getAtom();
    const payPlanInAtom =
      atomValue?.policyQuoteInformation?.policyQuote?.payPlan;

    if (paymentPlanSelected !== payPlanInAtom?.payPlanID) {
      const paymentOption = getPaymentPlanOption(
        paymentPlanUI?.paymentPlans,
        paymentPlanSelected
      );

      const depositOverride = isDepositOverride(
        paymentOption?.payPlanId ?? -1,
        paymentPlanUI?.paymentPlans ?? []
      );

      const depositType = depositOverride
        ? bindInstructionUI?.depositTypeForDepositOverride ?? constants.PERCENT
        : paymentOption?.depositType ?? "";

      const { billedDepositAmount, installmentPayment, nonEarningAmount } =
        calculatedBilledDepositAmount(
          paymentOption?.depositAmount ?? 0,
          billedPremiumAmount,
          paymentOption?.numberOfPayments ?? 0,
          paymentOption?.depositType === constants.NON_EARNING,
          paymentOption?.allEqual ?? false,
          depositType === constants.NON_EARNING
            ? constants.PERCENT
            : depositType
        );

      const billedDepositAmountResult =
        getBilledDepositAmountByPlanTypeOrDepositOverride(
          billedDepositAmount,
          billedPremiumAmount,
          paymentOption,
          paymentPlanUI
        );
      setPaymentPlanUI({
        ...paymentPlanUI,
        nonEarningAmount,
        dueOnDay: paymentOption?.dueOnDay ?? 0,
        billingMethod:
          paymentOption?.planType === constants.INSTALL
            ? "Installment"
            : paymentOption?.planType ?? "",
        paymentPlan: paymentPlanSelected,
        depositType: depositType,
        depositRate: paymentOption?.depositAmount,
        numberOfPayments: paymentOption?.numberOfPayments,
        billedDepositAmount: billedDepositAmountResult,
        billByLocation: isEmptyValue(nonEarningAmount)
          ? paymentPlanUI?.billByLocation ??
            bindInstructionUI?.billByLocation ??
            false
          : false,
      });

      setBindInstructionUI?.({
        ...bindInstructionUI,
        nonEarningAmount,
        dueOnDay: paymentOption?.dueOnDay ?? 0,
        billedDepositAmount: billedDepositAmount,
        installmentPaymentAmount: installmentPayment,
        numberOfPayments: paymentOption?.numberOfPayments,
        payPlan: paymentOption ? getPaymentPlanDtoAsJSON(paymentOption) : null,
        depositRate: paymentOption?.depositAmount,
        depositTypeForDepositOverride: depositOverride ? depositType : null,
        billingMethod:
          paymentOption?.planType === constants.INSTALL
            ? "Installment"
            : paymentOption?.planType ?? "",
        billByLocation: isEmptyValue(nonEarningAmount)
          ? paymentPlanUI?.billByLocation ??
            bindInstructionUI?.billByLocation ??
            false
          : false,
      });

      setTableURL("");
    }
  };

  const onChangeDepositRateInput = (depositRate: number) => {
    const paymentOption = getPaymentPlanOption(
      paymentPlanUI?.paymentPlans,
      paymentPlanUI?.paymentPlan
    );
    const { billedDepositAmount, installmentPayment, nonEarningAmount } =
      calculatedBilledDepositAmount(
        depositRate,
        billedPremiumAmount,
        paymentPlanUI?.numberOfPayments ?? 0,
        paymentPlanUI?.nonEarningAmount !== null,
        paymentOption?.allEqual ?? false,
        paymentPlanUI?.depositType ?? constants.PERCENT
      );
    setPaymentPlanUI({
      ...paymentPlanUI,
      nonEarningAmount,
      depositRate: depositRate,
      billedDepositAmount: billedDepositAmount,
    });
    setBindInstructionUI?.({
      ...bindInstructionUI,
      nonEarningAmount,
      billedDepositAmount: billedDepositAmount,
      installmentPaymentAmount: installmentPayment,
      numberOfPayments: paymentPlanUI?.numberOfPayments,
      depositRate: depositRate,
      payPlan: {
        ...bindInstructionUI?.payPlan,
        depositRate: depositRate,
      },
    });

    setTableURL("");
  };

  const onChangeDepositTypeSelect = (type: string) => {
    const paymentOption = getPaymentPlanOption(
      paymentPlanUI?.paymentPlans,
      paymentPlanUI?.paymentPlan
    );
    const { billedDepositAmount, installmentPayment, nonEarningAmount } =
      calculatedBilledDepositAmount(
        paymentPlanUI?.depositRate ?? 0,
        billedPremiumAmount,
        paymentPlanUI?.numberOfPayments ?? 0,
        paymentPlanUI?.nonEarningAmount !== null,
        paymentOption?.allEqual ?? false,
        type === null ? constants.PERCENT : type
      );
    setPaymentPlanUI({
      ...paymentPlanUI,
      nonEarningAmount,
      depositType: type,
      billByLocation: false,
      billedDepositAmount: billedDepositAmount,
    });
    setBindInstructionUI?.({
      ...bindInstructionUI,
      nonEarningAmount,
      billedDepositAmount: billedDepositAmount,
      installmentPaymentAmount: installmentPayment,
      numberOfPayments: paymentPlanUI?.numberOfPayments,
      depositRate: paymentPlanUI?.depositRate,
      depositTypeForDepositOverride: type,
      billByLocation: false,
    });

    setTableURL("");
  };

  const onChangeBillByLocationCheckbox = (isChecked: boolean) => {
    setTableURL("");
    setPaymentPlanUI({
      ...paymentPlanUI,
      billByLocation: isChecked,
    });
    setBindInstructionUI?.({
      ...bindInstructionUI,
      billByLocation: isChecked,
    });
  };

  const getTextAlign = () =>
    !isDepositOverride(
      paymentPlanUI?.paymentPlan ?? -1,
      paymentPlanUI?.paymentPlans ?? []
    )
      ? "right"
      : "left";

  const getLabelAlign = () =>
    !isDepositOverride(
      paymentPlanUI?.paymentPlan ?? -1,
      paymentPlanUI?.paymentPlans ?? []
    )
      ? "end"
      : "start";

  const { responseGet, dispatchGet, validatorErrorResponse } = useApiGet<
    ProgramPayPlanDto[]
  >(
    `api/QuotePolicy/GetProgramPayPlan?billedPremiumAmount=${billedPremiumAmount}&longevityThreshold=${getLongevityThreshold(
      bindInstructionUI
    )}`
  );

  useEffect(() => {
    if (readOnly && conditionHasValue(paymentPlanUI)) {
      const atomValue = getAtom();
      const policyQuote = getPolicyQuote(atomValue);
      const payPlan = policyQuote?.payPlan;
      onChangePaymentPlan(payPlan?.payPlanID ?? -1);
      setPaymentPlanUI({
        ...paymentPlanUI,
        paymentPlan: payPlan?.payPlanID,
      });
    }
    if (!readOnly && conditionHasValue(paymentPlanUI)) {
      onChangePaymentPlan(paymentPlanUI.paymentPlan ?? 0);
    }
  }, [readOnly, paymentPlanUI?.paymentPlanOptions]);

  useEffect(() => {
    dispatchGet();
  }, [billedPremiumAmount]);

  useEffect(() => {
    if (
      validatorErrorResponse === null &&
      responseGet?.requestInstanceSuccessful === true
    ) {
      const paymentOptions = getSelectOptionsFromResponse(
        responseGet?.axiosResponse?.data ?? []
      );
      setPaymentPlanUI({
        ...paymentPlanUI,
        paymentPlan: bindInstructionUI?.payPlan?.payPlanID ?? null,
        depositType:
          bindInstructionUI?.depositTypeForDepositOverride ??
          bindInstructionUI?.payPlan?.depositType ??
          null,
        depositRate: bindInstructionUI?.payPlan?.depositRate ?? null,
        billingMethod: bindInstructionUI?.billingMethod ?? null,
        billByLocation: bindInstructionUI?.billByLocation ?? null,
        dueOnDay: bindInstructionUI?.payPlan?.dueOnDay ?? null,
        numberOfPayments: bindInstructionUI?.payPlan?.numberOfPayment ?? null,
        nonEarningAmount: bindInstructionUI?.nonEarningAmount ?? null,
        billedDepositAmount: bindInstructionUI?.billedDepositAmount ?? null,
        paymentPlans: responseGet?.axiosResponse?.data ?? [],
        paymentPlanOptions: [defaultPaymentPlanOption, ...paymentOptions],
      });
    }
  }, [responseGet]);

  useEffect(() => {
    if (conditionHasValue(paymentPlanUI?.billByLocation)) {
      const atomValue = getAtom();
      const isRenewal =
        atomValue?.policyQuoteInformation?.policyQuote?.policyType?.value ===
        PolicyTypeEnum.RENEWAL_POLICY;
      const effectiveDateValidated = isRenewal
        ? addDays(
            bindInstructionUI?.effectiveDate ?? new Date(),
            bindInstructionUI?.payPlan?.renewalFirstOffset ?? 0
          )
        : bindInstructionUI?.effectiveDate;
      if (
        effectiveDateValidated !== undefined &&
        effectiveDateValidated !== null
      ) {
        setTableURL(
          isMultiTable
            ? `api/QuotePolicy/GetProgramPaymentScheduleMultiTable`
            : `api/QuotePolicy/GetProgramPaymentSchedule`
        );
      }
    }
  }, [bindInstructionUI, paymentPlanUI?.billByLocation]);

  useEffect(() => {
    if (
      conditionHasValue(paymentPlanUI?.billedDepositAmount) &&
      bindInstructionUI?.billedPremium !== paymentPlanUI?.billedDepositAmount
    ) {
      setPaymentPlanUI({
        ...paymentPlanUI,
        paymentPlan: 1,
        billedDepositAmount: bindInstructionUI?.billedPremium,
      });
      onChangePaymentPlan(1);
    }
  }, [bindInstructionUI?.billedPremium]);

  return (
    <>
      <Row
        {...rowWithNoMarginNorGutter}
        horizontalAlign={"flex-start"}
        numberOfColumns={28}
      >
        <Col
          {...colWithHorizontalGutterOnly}
          breakpoints={{ md: 6, lg: 6, xl: 6 }}
        >
          <Select
            id="id-payment-plan-input"
            name="paymentPlanInput"
            label="Payment Plan"
            labelFontType="BOLD_CAPTION"
            inputFontType="BODY"
            variant="filled"
            value={paymentPlanUI?.paymentPlan}
            options={paymentPlanUI?.paymentPlanOptions ?? []}
            onChange={(value) => onChangePaymentPlan(value)}
            readOnly={readOnly}
          />
        </Col>
        <Col
          {...colWithHorizontalGutterOnly}
          breakpoints={{ md: 3, lg: 3, xl: 3 }}
        >
          <Input
            id="id-billing-method-input"
            name="billingMethodInput"
            label="Billing Method"
            labelFontType="BOLD_CAPTION"
            value={paymentPlanUI?.billingMethod}
            readOnly
            align="right"
            labelAlign="end"
          />
        </Col>
        <Col
          {...colWithHorizontalGutterOnly}
          breakpoints={{ md: 3, lg: 3, xl: 3 }}
        >
          {isDepositOverride(
            paymentPlanUI?.paymentPlan ?? -1,
            paymentPlanUI?.paymentPlans ?? []
          ) ? (
            <Select
              id="id-deposit-type-input"
              name="depositTypeInput"
              label="Deposit Type"
              labelFontType="BOLD_CAPTION"
              inputFontType="BODY"
              variant="filled"
              value={paymentPlanUI?.depositType}
              options={paymentPlanDepositTypeOptions}
              onChange={(value) => onChangeDepositTypeSelect(value)}
              readOnly={readOnly}
            />
          ) : (
            <Input
              id="id-deposit-type-input"
              name="depositTypeInput"
              label="Deposit Type"
              labelFontType="BOLD_CAPTION"
              value={paymentPlanUI?.depositType}
              readOnly
            />
          )}
        </Col>
        <Col
          {...colWithHorizontalGutterOnly}
          breakpoints={{ md: 3, lg: 3, xl: 3 }}
        >
          <Input
            id="id-deposit-rate-input"
            name="depositRateInput"
            label="Deposit Rate"
            labelFontType="BOLD_CAPTION"
            prefix=""
            value={paymentPlanUI?.depositRate}
            type="fixedCurrency"
            readOnly={
              readOnly ||
              !isDepositOverride(
                paymentPlanUI?.paymentPlan ?? -1,
                paymentPlanUI?.paymentPlans ?? []
              )
            }
            align={getTextAlign()}
            labelAlign={getLabelAlign()}
            variant={
              !isDepositOverride(
                paymentPlanUI?.paymentPlan ?? -1,
                paymentPlanUI?.paymentPlans ?? []
              )
                ? "standard"
                : "filled"
            }
            onChangeRawValue={(value) => onChangeDepositRateInput(value ?? 0)}
          />
        </Col>
        <Col
          {...colWithHorizontalGutterOnly}
          breakpoints={{ md: 5, lg: 5, xl: 5 }}
        >
          <Input
            id="id-billed-deposit-amount-input"
            name="billedDepositAmountInput"
            label="Billed Deposit Amount"
            labelFontType="BOLD_CAPTION"
            prefix=""
            value={paymentPlanUI?.billedDepositAmount}
            type="fixedCurrency"
            align="right"
            labelAlign="end"
            readOnly
            inputWidth="110px"
          />
        </Col>
        <Col
          {...colWithHorizontalGutterOnly}
          breakpoints={{ md: 4, lg: 4, xl: 4 }}
        >
          <Input
            id="id-number-payments-input"
            name="numberPaymentsInput"
            label="Number of Payments"
            labelFontType="BOLD_CAPTION"
            value={paymentPlanUI?.numberOfPayments}
            type="number"
            readOnly
          />
        </Col>
        <Col
          {...colWithHorizontalGutterOnly}
          breakpoints={{ md: 3, lg: 3, xl: 3 }}
        >
          {paymentPlanUI?.billingMethod === "Installment" &&
            paymentPlanUI.nonEarningAmount === null && (
              <Switch
                id="id-bill-by-location-input"
                name="billByLocationInput"
                label="Bill by Location"
                labelFontType="BODY"
                labelPlacement="end"
                isChecked={paymentPlanUI.billByLocation ?? false}
                control={"checkbox"}
                onChangeIsChecked={onChangeBillByLocationCheckbox}
                readOnly={readOnly}
              />
            )}
        </Col>
      </Row>
      {tableURL !== "" || readOnly === true ? (
        <Row
          {...rowWithNoMarginNorGutter}
          rowDirection="column"
          rowWidth="95%"
          selfAlign="flex-start"
        >
          <PaymentSchedule
            tabKey={tabKey}
            tableURL={tableURL}
            insuredId={insuredId}
            bindInstructionUI={bindInstructionUI}
            readOnly={readOnly}
          />
        </Row>
      ) : null}
    </>
  );
};

export default PaymentPlan;
