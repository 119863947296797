import { FC } from "react";
import { getEnumValue } from "../../../utilities/enumFunctions";
import { InsuredStatusEnum } from "../../../dtos/insured-status-enum";
import Font from "../../TrueUI/Typography/Font";
import FontError from "../../TrueUI/Typography/FontError";

const InsuredStatus: FC<{
  insuredStatus: number;
  terminationDate: string | null;
}> = ({ insuredStatus, terminationDate }) => {
  const getStatusFontToDisplay = (status: number) => {
    const insuredEnumValue = getEnumValue(
      insuredStatus.toString(),
      InsuredStatusEnum
    );
    switch (status) {
      case InsuredStatusEnum.INACTIVE:
        const terminationDateToDisplay =
          terminationDate !== null ? ` (${terminationDate})` : "";
        return (
          <FontError name="insured-status">
            <FontError fontType={"BOLD_SMALL_TITLE"}>
              {insuredEnumValue}
            </FontError>
            {`${terminationDateToDisplay}`}
          </FontError>
        );
      case InsuredStatusEnum.ACTIVE:
      default:
        return <Font name="insured-status">{insuredEnumValue}</Font>;
    }
  };

  return <div>{getStatusFontToDisplay(insuredStatus)}</div>;
};
export default InsuredStatus;
