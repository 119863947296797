import { FC, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useRecoilValue } from "recoil";
import themes from "../../../../media/TrueTheme";
import { globalOptions } from "../../../../GlobalAtoms";
import { useApiGet, useApiPost } from "../../../../hooks/useApi";
import { ProgramEndorsementBlob } from "../../../../dtos/program-endorsement-blob";
import { PolicyQuoteInsuredAndActiveSetterProps } from "../PolicyQuoteForm/PolicyQuoteTypes";
import PolicyQuoteBottomButtons from "../PolicyQuoteForm/PolicyQuoteBottomButtons";
import { Splitter, SplitterOnChangeEvent } from "@progress/kendo-react-layout";
import OptionalForms from "./OptionalForms";
import { endorsementFormsSplitterStyles } from "./EndorsementFormStyles";
import RequiredForms from "./RequiredForms";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { getPolicyQuote } from "../PolicyQuoteForm/PolicyQuoteUtils";
import DialogConfirmation from "../../../TrueUI/Dialogs/DialogConfirmation";
import { getRequiredAndOptionalFormsFromResponse } from "./EndorsementFormsUtils";
import { updatePolicyQuoteInformation } from "../updatesPolicyQuoteFunctions";
import { PolicyBlob } from "../../../../dtos/policy-blob";
import { QuotePolicySectionEnum } from "../../../../dtos/quote-policy-section-enum";
import { validatePreviousSections } from "../PolicyQuoteForm/PolicyQuoteValidationUtils";
import { getValidatedDateToPost } from "../../../../utilities/dateFunctions";
import { ProgramEndorsementFormWithCustomPolicyJSON } from "../../../../dtos/program-endorsement-form-with-custom-policy-json";

const EndorsementFormsMain: FC<PolicyQuoteInsuredAndActiveSetterProps> = ({
  insuredId,
  tabKey,
  quoteStatus,
  setActiveSection,
}) => {
  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];

  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [readyToSave, setReadyToSave] = useState<boolean>(false);
  const { responsePost, dispatchPost } = useApiPost<PolicyBlob>(
    `api/QuotePolicy/SaveQuoteJSON?insuredId=${insuredId}&section=${QuotePolicySectionEnum.ENDORSEMENT_FORMS}`,
    getAtom()?.policyQuoteInformation?.policyQuote
  );

  const [optionalForms, setOptionalForms] = useState<any>();
  const [selectedOptionalForms, setSelectedOptionalForms] =
    useState<ProgramEndorsementBlob[]>();
  const [requiredForms, setRequiredForms] = useState<any>();

  const [panes, setPanes] = useState<Array<any>>([
    { size: "50%", min: "30%", max: "70%" },
  ]);
  const getPolicyId = () => getAtom()?.policyQuoteInformation?.policyId ?? 0;

  const getQuoteId = () => getAtom()?.policyQuoteInformation?.quoteId ?? 0;

  const getPolicyEffectiveDate = () =>
    getValidatedDateToPost(
      getAtom()?.policyQuoteInformation?.policyQuote?.effectiveDate
    );

  const { responseGet, dispatchGet } =
    useApiGet<ProgramEndorsementFormWithCustomPolicyJSON>(
      `api/QuotePolicy/GetProgramEndorsement?policyId=${getPolicyId()}&quoteId=${getQuoteId()}&effectiveDate=${getPolicyEffectiveDate()}`
    );

  const onChange = (event: SplitterOnChangeEvent) => {
    setPanes(event.newState);
  };

  useEffect(() => {
    const atomValue = getAtom();
    const activeSection =
      atomValue?.policyQuoteInformation?.activeSection ??
      QuotePolicySectionEnum.INFORMATION;
    const isValid = validatePreviousSections(atomValue, activeSection);
    if (isValid) dispatchGet();
    else {
      const newAtomValue = updatePolicyQuoteInformation(
        atomValue,
        "activeSection",
        QuotePolicySectionEnum.INFORMATION
      );
      setAtom(newAtomValue);
      setActiveSection?.(QuotePolicySectionEnum.INFORMATION);
    }
  }, []);

  useEffect(() => {
    if (responseGet?.axiosResponse?.data && !responseGet?.isLoading) {
      const atomValue = getAtom();
      const policyQuote = getPolicyQuote(atomValue);
      const responseData = responseGet.axiosResponse.data;

      const forms = getRequiredAndOptionalFormsFromResponse(
        responseData.programEndorsementForms,
        policyQuote
      );
      setRequiredForms(forms.requiredForms);
      setOptionalForms(forms.optionalForms);
    }
  }, [responseGet]);

  const saveEndorsements = () => {
    const atomValue = getAtom();
    const castedRequiredForms = Object.values(
      requiredForms
    ).flat() as ProgramEndorsementBlob[];
    setAtom({
      ...atomValue,
      policyQuoteInformation: {
        ...atomValue?.policyQuoteInformation,
        policyQuote: {
          ...atomValue?.policyQuoteInformation?.policyQuote,
          endorsementForms: castedRequiredForms.concat(
            selectedOptionalForms ?? []
          ),
        },
      },
    });
    setReadyToSave(true);
  };

  useEffect(() => {
    if (readyToSave) dispatchPost();
  }, [readyToSave]);

  useEffect(() => {
    if (responsePost.requestInstanceSuccessful) {
      const atomValue = getAtom();
      const newAtomValue = updatePolicyQuoteInformation(
        atomValue,
        "policyQuote",
        responsePost.axiosResponse?.data
      );
      setAtom(newAtomValue);
      setDialogOpen(true);
      setReadyToSave(false);
    }
  }, [responsePost]);

  return (
    <div
      id="endorsement-form-main-container"
      style={{ width: "100%", display: "flex", flexDirection: "column" }}
    >
      <div
        className="policy-scrollable-body-container"
        style={{ overflow: "hidden" }}
      >
        <Box sx={endorsementFormsSplitterStyles(theme)}>
          <Splitter panes={panes} onChange={onChange}>
            <RequiredForms insuredId={insuredId} forms={requiredForms} />
            <OptionalForms
              tabKey={tabKey}
              insuredId={insuredId}
              forms={optionalForms}
              setSelectedOptionalForms={setSelectedOptionalForms}
            />
          </Splitter>
        </Box>
      </div>
      <PolicyQuoteBottomButtons
        tabKey={tabKey}
        insuredId={insuredId}
        policySection={QuotePolicySectionEnum.ENDORSEMENT_FORMS}
        quoteStatus={quoteStatus}
        saveEvent={saveEndorsements}
      />
      <DialogConfirmation
        dialogDescriptionText="Endorsement forms saved"
        open={dialogOpen}
        optionYesOverrideLabel="OK"
        onOptionYesEvent={setDialogOpen}
        onCloseEvent={setDialogOpen}
      />
    </div>
  );
};

export default EndorsementFormsMain;
