import { conditionHasValue, isEmptyValue } from "./conditionalSupportFunctions";

export function truncateString(
  value?: string,
  maxLength?: number,
  addEllipsis: boolean = false
) {
  var truncatedValue =
    value !== null && value !== undefined ? value.substring(0, maxLength) : "";
  return truncatedValue !== "" && addEllipsis && value!!.length > maxLength!!
    ? truncatedValue + "..."
    : truncatedValue;
}

export function getSafeStringToBool(value: string): boolean {
  return value?.toLocaleLowerCase() === "true";
}

export function getSafeBoolToString(value: boolean): string {
  return String(value);
}

export function formatNumber(number: number, length) {
  var len = length - ("" + number).length;
  return (len > 0 ? new Array(++len).join("0") : "") + number;
}

export function toCamelCase(val: string) {
  return (val.slice(0, 1).toLowerCase() + val.slice(1))
    .replace(/([-_ ]){1,}/g, " ")
    .split(/[-_ ]/)
    .reduce((cur, acc) => {
      return cur + acc[0].toUpperCase() + acc.substring(1);
    });
}

export function getCorrectURL(url) {
  let formattedURL = url;
  if (url != null && url.indexOf("http") === -1) {
    formattedURL = "https://".concat(url);
  }
  return formattedURL;
}

export function ssnFormat(ssn: string | undefined) {
  return ssn ? ssn.replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3") ?? "" : "";
}

export function ssnMask(ssn: string) {
  const valueHidden = ssn ? ssn.replace(/[\d]/g, "X") : "";
  return valueHidden.length <= 3
    ? valueHidden
    : valueHidden.length <= 5
    ? valueHidden.slice(0, 3) + "-" + valueHidden.slice(3, 5)
    : valueHidden.slice(0, 3) +
      "-" +
      valueHidden.slice(3, 5) +
      "-" +
      ssn.substr(5);
}

export function einFormat(ein: string | undefined) {
  return ein ? ein.replace(/(\d{2})(\d{7})/, "$1-$2") ?? "" : "";
}

export function einMask(ein: string) {
  return ein ? ein.replace(/(\d{5})(\d{4})/, "XX-XXX$2") : "";
}

export function phoneMask(phone: string) {
  return phone ? phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3") : "";
}

export function customRound(value: string, decimalPlaces: number) {
  // const result = Number(parseFloat(value).toFixed(decimalPlaces)); TODO - Remove once fully tested though the system
  const factor = Math.pow(10, decimalPlaces);
  const result =
    Math.round((parseFloat(value) + Number.EPSILON) * factor) / factor;
  return result === 0 ? Math.abs(result) : result;
}

export function unFormatLocalString(formatted: string) {
  return parseFloat(formatted?.replaceAll(/[^0-9.]/g, ""));
}

export function unFormatNegativeLocalString(formatted: string) {
  return parseFloat(formatted?.replaceAll(/[^-0-9.]/g, ""));
}

export function isJsonString(str) {
  try {
    if (!isNaN(str)) {
      return false;
    } else {
      JSON.parse(str);
    }
  } catch (e) {
    return false;
  }
  return true;
}

export function removeHTMLTagsByRegex(str: string) {
  const strWithLineBreaks = str.replace(/<\/?p>/gi, "\n");
  const removeTagsRegex = /(<([^>]+)>)/gi;
  return strWithLineBreaks.replace(removeTagsRegex, "");
}

export const getNumberAsStringWithComas = (value: number) =>
  value.toLocaleString("en-US", {
    style: "decimal",
    minimumFractionDigits: 0,
    maximumFractionDigits: 20,
  });

export const getNumberAsStringWithComasWithDecimals = (
  value: number,
  decimals: number
) =>
  value.toLocaleString("en-US", {
    style: "decimal",
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });

export const formatToCurrency = (amount) => {
  return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

export const splitPhoneNumberAndExtension = (value: string) => {
  if (isEmptyValue(value)) {
    return null;
  }
  const pattern = /^(\d{10})(\d+)?/;
  return value.match(pattern);
};

export const formatNegativeNumbers = (amount: number) =>
  amount < 0
    ? `(${getNumberAsStringWithComasWithDecimals(amount * -1, 2)})`
    : getNumberAsStringWithComasWithDecimals(amount, 2);

export const getFileNameWithoutExtension = (
  filename: string,
  fileExt: string
) => {
  var fileNameWithoutExtension = filename?.replace(fileExt ?? "", "");
  return fileNameWithoutExtension;
};
export function getFileExtension(filename: string) {
  // Use a regular expression to match the file extension
  let match = /\.([0-9a-z]+)$/i.exec(filename);

  // Check if there is a match and return the file extension (converted to lowercase)
  return match ? `.${match[1].toLowerCase()}` : "";
}

export const capitalizeText = (text: string) =>
  text.length >= 0
    ? text.charAt(0).toUpperCase() + text.slice(1).toLocaleLowerCase()
    : "";

export const parseFloatValueIfExist = (value) =>
  conditionHasValue(value) && value !== "" ? parseFloat(value) : null;

export const castStringToTags = (string) => {
  return string?.trim()?.replaceAll(" ", "-")?.toLowerCase();
};

export const escapeTextForRegex = (text: string) =>
  text.replace(/[/\-\\^$*+?.()|[\]{}]/g, "\\$&");

export const addThousandsSeparator = (amount) => {
  return amount.replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

export function toCamelCaseWhenAllCaps(val: string) {
  const camelCaseValue = toCamelCase(val);
  let isFirstCamelCase = true;
  const trueCamelCaseValue = camelCaseValue.split("")?.map((character) => {
    if (isFirstCamelCase === true && /[A-Z]/.test(character) === true) {
      isFirstCamelCase = false;
      return character;
    }
    return character.toLowerCase();
  });
  return trueCamelCaseValue.join("");
}

export function removeASCIIFromText(val?: string | null) {
  return val?.replace(/&#[0-9]+;/g, " ");
}

export const getNumberFromCurrencyFormat = (val: string) => {
  const parsedValue = parseFloat(val.replace(/[(),]/g, ""));
  if (isNaN(parsedValue)) return 0;

  // If it starts with parentheses, make it negative
  if (val.startsWith("(")) return parsedValue * -1;

  return parsedValue;
};

export const parenthesesCurrencyFormat = (amount) => {
  return `(${formatToCurrency(Math.abs(amount))})`;
};

export const getJSONAsStringWithCorrectSyntax = (jsonString: string) =>
  jsonString
    .trim()
    .replace(/(\r|\n|\\)/g, "")
    .replace(/"{/g, "{")
    .replace(/}"/g, "}");
