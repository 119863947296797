import { FC } from "react";
import { FormRefundPaymentProps } from "./TypesModalRefundPayment";
import RefundReserveTypeAndPaymentCategory from "./RefundReserveTypeAndPaymentCategory";
import {
  Col,
  Input,
  InputDate,
  InputMemo,
  Row,
  Select,
} from "../../../../../../TrueUI";
import SearchText from "../../../../../../TrueUI/Search/SearchText";
import {
  getPaymentFormDataFromPayee,
  payeeSearchTextKeys,
  payeeSearchTextKeysToShowExtraDataIntoInput,
} from "./RefundPaymentUtils";
import { rowWithNoMarginNorGutter } from "../../../../../../TrueUI/Grids/Row";
import { colWithNoMarginNorGutter } from "../../../../../../TrueUI/Grids/Col";
import {
  inputConfiguration,
  selectConfiguration,
} from "../../../../../../../utilities/inputPropsConfigurationFunctions";

const FormRefundPayment: FC<FormRefundPaymentProps> = ({
  reserveTypeOptions,
  reservesInformation,
  refundPayment,
  setRefundPayment,
  readOnly = false,
  refundReasonOptions,
  errorDetails,
}) => {
  const savePayeeData = (payee) => {
    setRefundPayment({
      ...getPaymentFormDataFromPayee(
        refundPayment,
        payee,
        payeeSearchTextKeys,
        payeeSearchTextKeysToShowExtraDataIntoInput
      ),
      payToClaimant: false,
    });
  };

  return (
    <>
      <Row {...rowWithNoMarginNorGutter} verticalGutter={"10px"} allowWrap>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 7, lg: 7, xl: 7 }}
        >
          <RefundReserveTypeAndPaymentCategory
            reserveTypeOptions={reserveTypeOptions}
            reservesInformation={reservesInformation}
            setPaymentData={setRefundPayment}
            paymentData={refundPayment}
            errorDetails={null}
          />
        </Col>
        <Col
          {...colWithNoMarginNorGutter}
          breakpoints={{ md: 5, lg: 5, xl: 5 }}
        />
        <Col breakpoints={{ md: 12, lg: 12, xl: 12 }} displayFlex={false}>
          <SearchText
            tabIndex={3}
            saveSelectedResult={savePayeeData}
            searchTextKeys={payeeSearchTextKeys}
            searchTextKeysToShowExtraDataIntoInput={
              payeeSearchTextKeysToShowExtraDataIntoInput
            }
            value={refundPayment?.payeeNameAndAddress ?? ""}
            labelText={"Payee"}
            placeholderText={"Search for a payee"}
            url={"api/Payee/GetPayeeList?searchPayeeValue="}
            errorMessage={errorDetails?.payeeName}
            labelFontType={"BOLD_CAPTION"}
            key={`payee-search-${refundPayment?.payeeNameAndAddress}`}
            name={"payeeName"}
          />
        </Col>
        <Col displayFlex={false} breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          <Input
            tabIndex={4}
            {...inputConfiguration(
              "id-paid-amount",
              "paymentFormPaidAmount",
              "Paid Amount",
              20
            )}
            type={"fixedCurrency"}
            prefix={""}
            align={readOnly ? "left" : "right"}
            value={refundPayment?.paidAmount ?? null}
            onChangeRawValue={(value) =>
              setRefundPayment?.({
                ...refundPayment,
                paidAmount: value,
              })
            }
            errorMessage={errorDetails?.paidAmount}
            labelFontType={"BOLD_CAPTION"}
          />
        </Col>
        <Col displayFlex={false} breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          <Input
            tabIndex={5}
            {...inputConfiguration(
              "id-payment-method",
              "paymentFormPaymentMethod",
              "Payment Method",
              20
            )}
            value={refundPayment?.paymentMethod ?? null}
            onChangeRawValue={(value) =>
              setRefundPayment?.({
                ...refundPayment,
                paymentMethod: value,
              })
            }
            labelFontType={"BOLD_CAPTION"}
            errorMessage={errorDetails?.paymentMethod}
          />
        </Col>
        <Col displayFlex={false} breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          <Input
            tabIndex={6}
            {...inputConfiguration(
              "id-reference-number",
              "paymentFormReferenceNumber",
              "Reference Number",
              30
            )}
            value={refundPayment?.referenceNo ?? null}
            onChangeRawValue={(value) =>
              setRefundPayment?.({
                ...refundPayment,
                referenceNo: value,
              })
            }
            labelFontType={"BOLD_CAPTION"}
            errorMessage={errorDetails?.referenceNo}
          />
        </Col>
        <Col displayFlex={false} breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          <Input
            tabIndex={7}
            {...inputConfiguration(
              "id-payment-status-readonly",
              "paymentFormReadOnlyPaymentStatus",
              "Payment Status",
              30
            )}
            value={refundPayment?.paymentStatusName}
            readOnly
            labelFontType={"BOLD_CAPTION"}
          />
        </Col>
        <Col displayFlex={false} breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          <InputDate
            tabIndex={8}
            id="id-payment-date"
            name={"paymentFormPaymentDate"}
            label="Payment Date"
            value={refundPayment?.paymentDate ?? new Date()}
            onChangeRawValue={(value) =>
              setRefundPayment?.({
                ...refundPayment,
                paymentDate: value,
              })
            }
            errorMessage={errorDetails?.paymentDate}
            labelFontType={"BOLD_CAPTION"}
          />
        </Col>
        <Col breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          <InputDate
            tabIndex={9}
            id="id-paid-from-date"
            name={"paymentFormPaidFromDate"}
            label="Paid From"
            value={refundPayment?.paidFromDate}
            onChangeRawValue={(value) =>
              setRefundPayment?.({
                ...refundPayment,
                paidFromDate: value,
              })
            }
            labelFontType={"BOLD_CAPTION"}
          />
        </Col>
        <Col breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          <InputDate
            tabIndex={10}
            id="id-paid-to-date"
            name={"paymentFormPaidToDate"}
            label="Paid To"
            value={refundPayment?.paidToDate}
            onChangeRawValue={(value) =>
              setRefundPayment?.({
                ...refundPayment,
                paidToDate: value,
              })
            }
            labelFontType={"BOLD_CAPTION"}
          />
        </Col>
        <Col breakpoints={{ md: 3, lg: 3, xl: 3 }}>
          <Select
            tabIndex={1}
            {...selectConfiguration(
              "id-refund-reason",
              "paymentRefundReason",
              "Refund Reason"
            )}
            options={refundReasonOptions ?? []}
            value={refundPayment?.refundReason ?? 0}
            onChange={(value) =>
              setRefundPayment?.({
                ...refundPayment,
                refundReason: value,
              })
            }
            firstOptionAsDefault={false}
            errorMessage={errorDetails?.refundReason}
            labelFontType={"BOLD_CAPTION"}
            readOnly={readOnly}
          />
        </Col>
        <Col displayFlex={false} breakpoints={{ md: 12, lg: 12, xl: 12 }}>
          <InputMemo
            tabIndex={11}
            {...inputConfiguration(
              "id-payment-comments",
              "paymentFormPaymentComments",
              "Comments",
              200
            )}
            value={refundPayment?.comments ?? null}
            onChangeRawValue={(value) =>
              setRefundPayment?.({
                ...refundPayment,
                comments: value,
              })
            }
            errorMessage={errorDetails?.comments}
            labelFontType={"BOLD_CAPTION"}
            rows={3}
          />
        </Col>
      </Row>
    </>
  );
};

export default FormRefundPayment;
