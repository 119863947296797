import { Box } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../../../../../GlobalAtoms";
import { usePermissions } from "../../../../../../hooks";
import themes from "../../../../../../media/TrueTheme";
import {
  Col,
  Font,
  SmallTitleBold,
  Input,
  Modal,
  Row,
  FontBold,
} from "../../../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../../../TrueUI/Grids/Row";
import {
  ModalScheduleRatingProps,
  ScheduleRateItemsUIProps,
} from "../../../PolicyQuoteForm/PolicyQuoteTypes";
import {
  getRateItemErrors,
  getTotalMinMaxRange,
  getTotalPreviousAndValue,
} from "../../../PolicyQuoteExposurePremium/ExposurePremiumUtils";
import {
  modalScheduleRatingHeaderStyles,
  modalScheduleRatingRowStyles,
} from "./ModalScheduleRatingStyles";
import { getConfigurationForColumn } from "../PremiumTableRows/PremiumTableRowsUtils";
import FontError from "../../../../../TrueUI/Typography/FontError";

const getLimitValidation = (value: number) => 1 + value / 100;

const TABLE_NAME = "schedule-rating-table";

const ModalScheduleRating: FC<ModalScheduleRatingProps> = ({
  state,
  scheduleFormUI,
  saveEvent,
  closeEvent,
  readOnly,
}) => {
  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];
  const hasPermission = usePermissions([1, 2, 3]); // TODO: @elara Update until Antonio's PR will be ready);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [localModalUI, setLocalModalUI] = useState<
    ScheduleRateItemsUIProps[] | undefined | null
  >();

  const onSaveModal = () => {
    if (errorMessage === "") {
      saveEvent?.(localModalUI);
    }
  };

  const updateRateItem = (value: string, rateIndex: number, target: string) => {
    const updatedLocal = localModalUI?.map((item, _rateIndex) =>
      rateIndex === _rateIndex ? { ...item, [target]: value ?? "" } : item
    );
    setLocalModalUI(updatedLocal);
    setErrorMessage(getRateItemErrors(updatedLocal));
  };

  useEffect(() => {
    setErrorMessage("");
    setLocalModalUI(scheduleFormUI?.scheduleRating?.scheduleRateItems ?? []);
  }, [scheduleFormUI]);

  return (
    <Modal
      size="lg"
      id={"modal-schedule-rating-worksheet"}
      title={`Schedule Rating Worksheet (${state})`}
      open={scheduleFormUI?.isModalOpen}
      showCloseButton={true}
      showCancelTextButton={true}
      saveEvent={onSaveModal}
      cancelEvent={closeEvent}
      saveDisabled={readOnly === true ? true : errorMessage !== ""}
    >
      <Box sx={modalScheduleRatingHeaderStyles(theme)}>
        <Row {...rowWithNoMarginNorGutter} numberOfColumns={24}>
          <Col {...getConfigurationForColumn(9, "flex-start", "center")}>
            <SmallTitleBold>Risk Characteristics</SmallTitleBold>
          </Col>
          <Col {...getConfigurationForColumn(4, "flex-start", "center")}>
            <SmallTitleBold>Range (%)</SmallTitleBold>
          </Col>
          <Col {...getConfigurationForColumn(2, "flex-start", "center")}>
            <SmallTitleBold>Previous</SmallTitleBold>
          </Col>
          <Col {...getConfigurationForColumn(2, "flex-start", "center")}>
            <SmallTitleBold>Value</SmallTitleBold>
          </Col>
          <Col {...getConfigurationForColumn(7, "flex-start", "center")}>
            <SmallTitleBold>Reason/Basis</SmallTitleBold>
          </Col>
        </Row>
      </Box>
      {localModalUI &&
        localModalUI?.length > 0 &&
        localModalUI?.map((item, index) => (
          <Box
            key={`${TABLE_NAME}-row-${index}`}
            sx={modalScheduleRatingRowStyles(theme)}
          >
            <Row
              {...rowWithNoMarginNorGutter}
              numberOfColumns={24}
              verticalMargin="5px"
            >
              <Col {...getConfigurationForColumn(9, "flex-start", "center")}>
                <Font className="schedule-rating-modal-text">
                  {item.description}
                </Font>
              </Col>
              <Col {...getConfigurationForColumn(4, "flex-start", "center")}>
                <Font className="schedule-rating-modal-text">{`+${item.maxValue}/${item.minValue}%`}</Font>
              </Col>
              <Col {...getConfigurationForColumn(2, "flex-start", "center")}>
                <Font className="schedule-rating-modal-text">1.00</Font>
              </Col>
              <Col {...getConfigurationForColumn(2, "flex-start", "center")}>
                <Input
                  prefix=""
                  inputWidth="80px"
                  variant={"filled"}
                  inputFontType="BODY"
                  allowNegatives={true}
                  type={"fixedCurrency"}
                  readOnly={!hasPermission || readOnly}
                  value={localModalUI[index].value ?? 1}
                  onChangeRawValue={(value) =>
                    updateRateItem(value, index, "value")
                  }
                  sx={{ marginTop: "-4px", paddingBottom: 0 }}
                  id={`id-schedule-worksheet-value-${index}`}
                  name={`name-schedule-worksheet-value-${index}`}
                  minNumericValue={getLimitValidation(item.minValue)}
                  maxNumericValue={getLimitValidation(item.maxValue)}
                />
              </Col>
              <Col {...getConfigurationForColumn(7, "flex-start", "center")}>
                <Input
                  variant={"filled"}
                  inputFontType="BODY"
                  allowNegatives={false}
                  readOnly={!hasPermission || readOnly}
                  value={localModalUI[index].reason}
                  onChangeRawValue={(value) =>
                    updateRateItem(value, index, "reason")
                  }
                  maxLength={300}
                  sx={{ marginTop: "-4px", paddingBottom: 0 }}
                  id={`id-schedule-worksheet-reason-${index}`}
                  name={`name-schedule-worksheet-reason-${index}`}
                />
              </Col>
            </Row>
          </Box>
        ))}
      <Row {...rowWithNoMarginNorGutter} numberOfColumns={24}>
        <Col {...getConfigurationForColumn(9, "flex-start", "center")}>
          <FontBold>Total</FontBold>
        </Col>
        <Col {...getConfigurationForColumn(4, "flex-start", "center")}>
          <FontBold>
            {getTotalMinMaxRange(scheduleFormUI?.scheduleRating)}
          </FontBold>
        </Col>
        <Col {...getConfigurationForColumn(2, "flex-start", "center")}>
          <FontBold>1.00</FontBold>
        </Col>
        <Col
          {...getConfigurationForColumn(2, "flex-start", "center")}
          horizontalGutter="15px"
        >
          <FontBold>{getTotalPreviousAndValue(localModalUI)}</FontBold>
        </Col>
        <Col {...getConfigurationForColumn(7, "flex-start", "flex-start")}>
          <FontError>{errorMessage}</FontError>
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalScheduleRating;
