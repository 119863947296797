import { FC, useEffect, useState } from "react";
import Modal from "../../../TrueUI/Modal/Modal";
import {
  ModalAddCancellationProps,
  SAVE_AS_CANCELLED_ACTION,
  SAVE_ONLY_ACTION,
} from "./CancellationUtil";
import ModalAddCancellationContent from "./ModalAddCancellationContent";
import { CancellationDataDto } from "../../../../dtos/cancellation-data-dto";
import DialogConfirmation from "../../../TrueUI/Dialogs/DialogConfirmation";
import { useApiPost } from "../../../../hooks";
import { isAPITotallyCompleteNoContentResponse } from "../../../../utilities/apiFunctions";
import { CancellationStatusEnum } from "../../../../dtos/cancellation-status-enum";
import { useSetRecoilState } from "recoil";
import { RefreshInsuredHeaderAtom } from "../../InsuredAtoms";
import { StatusEnums } from "../../../../dtos/status-enums";

type ConfirmationDialogProps = {
  text: string;
  isOpen: boolean;
  action: "close" | "delete" | "rescindReinstate";
};
const ModalAddCancellation: FC<ModalAddCancellationProps> = ({
  cancellationModalConfig,
  setCancellationModalConfig,
  updateRowIntoTable,
}) => {
  const [cancellationData, setCancellationData] = useState<
    CancellationDataDto | Partial<CancellationDataDto> | null
  >();
  const [confirmationDialogConfig, setConfirmationDialogConfig] =
    useState<ConfirmationDialogProps>({
      text: "",
      isOpen: false,
      action: "close",
    });
  const [errorMessages, setErrorMessages] = useState<any>();

  const {
    responsePost: responsePostSaveOnly,
    dispatchPost: dispatchPostSaveOnly,
    validatorErrorResponse: errorResponseSaveOnly,
  } = useApiPost<any>(
    "api/Cancellation/SaveOnlyCancellation",
    cancellationData
  );
  const {
    responsePost: responsePostSaveAsCancelled,
    dispatchPost: dispatchPostSaveAsCancelled,
    validatorErrorResponse: errorResponseSaveAsCancelled,
  } = useApiPost<any>(
    "api/Cancellation/SaveCancellationAsCancelled",
    cancellationData
  );

  const { responsePost: responseDelete, dispatchPost: dispatchDelete } =
    useApiPost(
      `api/Cancellation/DeleteCancellation?cancellationId=${cancellationData?.cancellationId}`
    );
  const setRefreshInsuredHeader = useSetRecoilState(RefreshInsuredHeaderAtom);

  const getRescindOrReinstateUpdateObj = () => {
    return {
      cancellationId: cancellationData?.cancellationId,
      rescindReinstateReason: cancellationData?.rescindOrReinstateByReason,
      isRescindOrReinstate: cancellationData?.isRescindOrReinstate,
    };
  };
  const {
    responsePost: responseReinstateOrRescind,
    dispatchPost: dispatchReinstateOrRescind,
  } = useApiPost<any>(
    `api/Cancellation/ReinstateOrRescindCancellation`,
    getRescindOrReinstateUpdateObj()
  );

  const modalTitle =
    cancellationModalConfig.cancellationId === 0
      ? "New Cancellation"
      : cancellationModalConfig.type === "insured"
      ? "Cancellation"
      : `Cancellation - ${cancellationData?.insuredName}`;

  const onSaveCancellationEvent = (saveAction: string) => {
    if (saveAction === SAVE_AS_CANCELLED_ACTION) {
      dispatchPostSaveAsCancelled();
    }
    if (saveAction === SAVE_ONLY_ACTION) {
      dispatchPostSaveOnly();
    }
  };

  const onReinstateOrRescindEvent = () => {
    setConfirmationDialogConfig({
      ...confirmationDialogConfig,
      isOpen: true,
      text: `Are you sure you want to ${cancellationData?.isRescindOrReinstate}?`,
      action: "rescindReinstate",
    });
  };

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responsePostSaveAsCancelled)) {
      updateRowIntoTable(
        cancellationModalConfig.action,
        responsePostSaveAsCancelled.axiosResponse?.data
      );
      setCancellationModalConfig({
        ...cancellationModalConfig,
        openCancellationModal: false,
      });
      setCancellationData(null);
      setRefreshInsuredHeader({
        insuredStatus: StatusEnums.INACTIVE,
        insuredId: cancellationData?.insuredId,
      });
    }
  }, [responsePostSaveAsCancelled]);

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responsePostSaveOnly)) {
      updateRowIntoTable(
        cancellationModalConfig.action,
        responsePostSaveOnly.axiosResponse?.data
      );
      setCancellationModalConfig({
        ...cancellationModalConfig,
        openCancellationModal: false,
      });
      setCancellationData(null);
    }
  }, [responsePostSaveOnly]);

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responseDelete)) {
      updateRowIntoTable("delete", null);
      setErrorMessages(null);
      setCancellationData(null);
      setConfirmationDialogConfig({
        ...confirmationDialogConfig,
        isOpen: false,
      });

      setCancellationModalConfig({
        ...cancellationModalConfig,
        openCancellationModal: false,
      });
    }
  }, [responseDelete]);

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responseReinstateOrRescind)) {
      updateRowIntoTable(
        cancellationModalConfig.action,
        responseReinstateOrRescind.axiosResponse?.data
      );
      setErrorMessages(null);
      setCancellationData(null);
      setConfirmationDialogConfig({
        ...confirmationDialogConfig,
        isOpen: false,
      });

      setCancellationModalConfig({
        ...cancellationModalConfig,
        openCancellationModal: false,
      });
      setRefreshInsuredHeader({
        insuredStatus: StatusEnums.ACTIVE,
        insuredId: cancellationData?.insuredId,
      });
    }
  }, [responseReinstateOrRescind]);

  useEffect(() => {
    setErrorMessages(errorResponseSaveAsCancelled?.errorDetails);
  }, [errorResponseSaveAsCancelled]);

  useEffect(() => {
    setErrorMessages(errorResponseSaveOnly?.errorDetails);
  }, [errorResponseSaveOnly]);

  const onYesConfirmationEvent = () => {
    if (confirmationDialogConfig.action === "close") {
      setConfirmationDialogConfig({
        ...confirmationDialogConfig,
        isOpen: false,
      });
      setErrorMessages(null);
      setCancellationData(null);

      setCancellationModalConfig({
        ...cancellationModalConfig,
        openCancellationModal: false,
      });
    }
    if (confirmationDialogConfig.action === "delete") {
      dispatchDelete();
    }
    if (confirmationDialogConfig.action === "rescindReinstate") {
      dispatchReinstateOrRescind();
    }
  };

  const onCancelEvent = () => {
    if (
      cancellationData?.cancellationStatusId !==
        CancellationStatusEnum.REINSTATED &&
      cancellationData?.cancellationStatusId !==
        CancellationStatusEnum.RESCINDED
    ) {
      setConfirmationDialogConfig({
        text: "Are you sure you want to cancel?",
        isOpen: true,
        action: "close",
      });
    } else {
      setCancellationModalConfig({
        ...cancellationModalConfig,
        openCancellationModal: false,
      });
      setErrorMessages(null);
      setCancellationData(null);
    }
  };

  return (
    <>
      <Modal
        id="policy-cancellation"
        title={modalTitle}
        size="md"
        open={cancellationModalConfig.openCancellationModal}
        cancelEvent={onCancelEvent}
      >
        <ModalAddCancellationContent
          insuredId={cancellationModalConfig.insuredId}
          cancellationId={cancellationModalConfig.cancellationId}
          onSaveCancellationEvent={onSaveCancellationEvent}
          cancellationData={cancellationData}
          onReinstateOrRescindEvent={onReinstateOrRescindEvent}
          setCancellationData={setCancellationData}
          errorMessages={errorMessages}
          onCancelEvent={onCancelEvent}
          onDeleteEvent={() => {
            setConfirmationDialogConfig({
              text: "Are you sure you want to delete?",
              isOpen: true,
              action: "delete",
            });
          }}
        />
      </Modal>
      <DialogConfirmation
        id="cancellation-confirmation"
        open={confirmationDialogConfig.isOpen}
        dialogDescriptionText={confirmationDialogConfig.text}
        optionYesOverrideLabel="OK"
        optionNoOverrideLabel={"Cancel"}
        onOptionYesEvent={onYesConfirmationEvent}
        onOptionNoEvent={() => {
          setConfirmationDialogConfig({
            ...confirmationDialogConfig,
            isOpen: false,
          });
        }}
      />
    </>
  );
};

export default ModalAddCancellation;
