import { FC, useState } from "react";
import { INSURED_ATOM_KEY } from "../../../../../utilities/queryStringsHash";
import DialogConfirmation, {
  DialogConfirmationProps,
} from "../../../../TrueUI/Dialogs/DialogConfirmation";
import { DocSourceEnum } from "../../../../../dtos/doc-source-enum";
import { ProgramPolicyDocumentDto } from "../../../../../dtos/program-policy-document-dto";
import { Font } from "../../../../TrueUI";
import {
  ERROR_TEMPLATE_NAME,
  getDocumentFileCategory,
  getSourceJSONFromDocSourceForIndividualPrinting,
} from "./DocumentsUtils";
import { useAtomFamily } from "../../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../../InsuredAtoms";
import { useFormRequest } from "../../../../../hooks";
import Link from "../../../../TrueUI/Typography/Link";
import { DocumentSelectedPacketsProps } from "./DocumentsTypes";
import style from "./DocumentsStyles.module.css";

const DocumentSelectedPackets: FC<DocumentSelectedPacketsProps> = ({
  tabKey,
  policyArea,
  selectedPacketDocuments,
  customJSONToPrint,
}) => {
  const atomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom } = useAtomFamily(GlobalInsuredAtomFamily(atomKey));
  const { sendMergeFormRequest } = useFormRequest();
  const [dialogConfiguration, setDialogConfiguration] =
    useState<DialogConfirmationProps | null>(null);

  const evaluatePrintingConfigurations = (
    document: ProgramPolicyDocumentDto,
    templateNames: string[]
  ) => {
    if (templateNames.includes(ERROR_TEMPLATE_NAME)) {
      setDialogConfiguration({
        open: true,
        dialogDescriptionText: "No print template found.",
      });

      return { isFullyConfigured: false };
    }

    if (
      document.docSource === DocSourceEnum.CUSTOM_JSON &&
      customJSONToPrint === ""
    ) {
      setDialogConfiguration({
        open: true,
        dialogDescriptionText:
          "There is no custom JSON set up for the current policy period.",
      });

      return { isFullyConfigured: false };
    }

    return { isFullyConfigured: true };
  };

  const printIndividualDocument = (document: ProgramPolicyDocumentDto) => {
    const policyMergeFields = getAtom()?.policyQuoteInformation?.policyQuote;
    const templateNames =
      document.fileId !== null
        ? [document.templateName ?? ERROR_TEMPLATE_NAME]
        : policyMergeFields?.endorsementForms?.map((form) => {
            return form.templateName ?? ERROR_TEMPLATE_NAME;
          }) ?? [];
    const { isFullyConfigured } = evaluatePrintingConfigurations(
      document,
      templateNames
    );

    if (isFullyConfigured) {
      sendMergeFormRequest({
        ...getSourceJSONFromDocSourceForIndividualPrinting(
          document.docSource,
          customJSONToPrint,
          getAtom()
        ),
        templateNameWithExtensionList: templateNames,
        customFileName: `${policyMergeFields?.insured?.insuredName ?? ""} ${
          document.docName ?? ""
        }`,
      });
    }
  };

  return (
    <div
      id="document-selected-packets-container-id"
      className={style.document_divider_section_container}
    >
      <div className={style.divider_section_title_container}>
        <Font whiteSpace="normal" textAlign="start">
          PACKET {getDocumentFileCategory(policyArea).toLocaleUpperCase()}
        </Font>
      </div>
      {selectedPacketDocuments.map((packetDocument, index) => (
        <div
          key={`packet-selected-document-container-${packetDocument.programDocId}-${index}`}
        >
          <Link
            key={`packet-selected-document-id-${packetDocument.programDocId}-${index}`}
            id={`packet-selected-document-id-${packetDocument.programDocId}-${index}`}
            linkFontType="BODY"
            displayValue={packetDocument.docName}
            underline="hover"
            onClick={() => printIndividualDocument(packetDocument)}
          />
        </div>
      ))}
      <DialogConfirmation
        {...dialogConfiguration}
        id="id-dialog-confirmation-document-packet"
        optionYesOverrideLabel="OK"
        onOptionYesEvent={() => setDialogConfiguration(null)}
        onCloseEvent={() => setDialogConfiguration(null)}
      />
    </div>
  );
};

export default DocumentSelectedPackets;
