import { Box } from "@mui/material";
import { FC, useEffect, useState } from "react";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import { useApiPost, useApiGet } from "../../../hooks";
import { RecentItemDto } from "../../../dtos/recent-item-dto";
import { InsuredTabDto } from "../../../dtos/insured-tab-dto";
import { useRecoilValue } from "recoil";
import { Font, Heading4 } from "../../TrueUI";
import { globalOptions } from "../../../GlobalAtoms";
import themes from "../../../media/TrueTheme";
import { insuredPanelStyles } from "./InsuredPanelStyles";
import InsuredPanelSubHeaderSelector from "./InsuredPanelSubHeaderSelector";
import { RefreshInsuredHeaderAtom } from "../InsuredAtoms";
import { FormattingDate } from "../../../utilities/dateFunctions";
import InsuredStatus from "./InsuredStatus";
import { InsuredStatusEnum } from "../../../dtos/insured-status-enum";

export type InsuredPanelProps = {
  insuredTab: InsuredTabDto;
  insuredBodySection: number | undefined;
  tabKey: string;
};

type InsuredInfoProps = {
  insuredId: number;
  insuredCode: string;
  insuredStatus: number;
  terminationDate: string | null;
};

const InsuredPanel: FC<InsuredPanelProps> = ({
  insuredTab,
  tabKey,
  insuredBodySection,
}) => {
  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];
  const [pinned, setPinned] = useState<any>(null);
  const [insuredInfo, setInsuredInfo] = useState<InsuredInfoProps>({
    insuredId: insuredTab?.insuredInformation?.insuredId,
    insuredCode: insuredTab?.insuredInformation?.insuredCode,
    insuredStatus: insuredTab?.insuredInformation?.insuredStatus,
    terminationDate:
      (insuredTab?.insuredInformation?.terminationDate ?? null) !== null
        ? FormattingDate(insuredTab?.insuredInformation?.terminationDate)
        : null,
  });
  const refreshInsuredHeader = useRecoilValue(RefreshInsuredHeaderAtom);

  const { dispatchPost } = useApiPost<RecentItemDto>(
    "api/recents/SaveRecentInsuredPage",
    { id: insuredInfo.insuredId, pinned: pinned }
  );
  const { responseGet, dispatchGet } = useApiGet<any>(
    `api/recents/GetRecentByInsuredPage?insuredId=${insuredInfo.insuredId}`
  );

  const checkRecentIsPinned = (response: any) => {
    return response?.responseData?.isPinned ?? false;
  };

  useEffect(() => {
    if (responseGet?.requestInstanceSuccessful === true) {
      setPinned(checkRecentIsPinned(responseGet));
      setRotatePin(checkRecentIsPinned(responseGet));
    }
    dispatchPost();
  }, [responseGet]);

  useEffect(() => {
    dispatchGet();
    if (
      insuredTab?.insuredInformation?.insuredId !== null &&
      insuredTab?.insuredInformation?.insuredId !== undefined &&
      insuredTab?.insuredInformation?.insuredId > 0
    ) {
      setInsuredInfo({
        ...insuredInfo,
        insuredId: insuredTab?.insuredInformation?.insuredId * 1,
      });
    }
  }, []);

  useEffect(() => {
    if (
      refreshInsuredHeader !== null &&
      insuredInfo.insuredId === refreshInsuredHeader?.insuredId
    ) {
      setInsuredInfo({
        ...insuredInfo,
        insuredCode:
          refreshInsuredHeader.insuredCode ?? insuredInfo.insuredCode,
        insuredStatus: refreshInsuredHeader.insuredStatus ?? -1,
      });
    }
  }, [refreshInsuredHeader]);

  const [rotatePin, setRotatePin] = useState(false);
  const handleRotate = () => {
    setRotatePin(!rotatePin);
    setPinned(!rotatePin);
    dispatchPost();
  };

  const getLeftContainerClass = (status) => {
    const inactiveClass =
      status === InsuredStatusEnum.INACTIVE ? "inactive-styles" : "";
    return `left-container ${inactiveClass}`;
  };

  return (
    <Box
      className={"insured_panel_inner_wrapper"}
      sx={insuredPanelStyles(theme)}
    >
      <div className={getLeftContainerClass(insuredInfo?.insuredStatus)}>
        <div className={"top-row"}>
          <PushPinOutlinedIcon
            onClick={handleRotate}
            className={rotatePin ? "pinned" : ""}
            true-element="true-element-pin-insured"
          />
          <Font name="insured-code" primaryColor>
            {insuredInfo?.insuredCode ?? "unassigned"}
          </Font>
        </div>

        <InsuredStatus
          insuredStatus={insuredInfo?.insuredStatus}
          terminationDate={insuredInfo?.terminationDate ?? null}
        />
        <div>
          <Font name="insured-program">
            {insuredTab?.insuredInformation.insuredProgram}
          </Font>
        </div>
      </div>
      <div className={"right-container"}>
        <Heading4
          className={"insured-name"}
          whiteSpace="nowrap"
          textAlign="start"
          trueElement="true-element-label-insured-name"
          display={"block"}
        >
          {insuredTab?.insuredInformation?.insuredName}
        </Heading4>
        <InsuredPanelSubHeaderSelector
          insuredTab={insuredTab}
          tabKey={tabKey}
          insuredBodySection={insuredBodySection}
        />
      </div>
    </Box>
  );
};

export default InsuredPanel;
