import { FC } from "react";
import { EndorsementGeneralProps } from "./EndorsementTypes";
import { EndorsementSectionEnum } from "../../../../dtos/endorsement-section-enum";
import EndorsementInformation from "../EndorsementInformation/EndorsementInformation";
import ExposureAndPremium from "../EndorsementExposurePremium/ExposureAndPremium";
import EndorsementBindInstructions from "../EndorsementBindInstructions/EndorsementBindInstructions";
import EndorsementDocuments from "../EndorsementDocuments/EndorsementDocuments";
import { PolicyFormTypeEnum } from "../../../../dtos/policy-form-type-enum";

const EndorsementBodySelector: FC<EndorsementGeneralProps> = (props) => {
  switch (props.activeSection) {
    case EndorsementSectionEnum.ENDORSEMENT_INFORMATION:
      return <EndorsementInformation {...props} />;
    case EndorsementSectionEnum.EXPOSURE_AND_PREMIUM:
      return (
        <ExposureAndPremium
          {...props}
          policyFormType={PolicyFormTypeEnum.ENDORSEMENT_FORM}
        />
      );
    case EndorsementSectionEnum.BIND_INSTRUCTIONS:
      return <EndorsementBindInstructions {...props} />;
    case EndorsementSectionEnum.POLICY_DOCS:
      return <EndorsementDocuments {...props} />;
    default:
      return <EndorsementInformation {...props} />;
  }
};

export default EndorsementBodySelector;
