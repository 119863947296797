import { FC, useEffect, useState } from "react";
import { Font, Switch } from "../../../../TrueUI";
import Link from "../../../../TrueUI/Typography/Link";
import { ProgramPolicyDocumentDto } from "../../../../../dtos/program-policy-document-dto";
import { INSURED_ATOM_KEY } from "../../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../../InsuredAtoms";
import { useFormRequest } from "../../../../../hooks";
import style from "./DocumentsStyles.module.css";
import DialogConfirmation, {
  DialogConfirmationProps,
} from "../../../../TrueUI/Dialogs/DialogConfirmation";
import { DocSourceEnum } from "../../../../../dtos/doc-source-enum";
import { DocumentsIndividualProps } from "./DocumentsTypes";
import { ERROR_TEMPLATE_NAME, getDocumentFileCategory, getSourceJSONFromDocSourceForIndividualPrinting } from "./DocumentsUtils";

const DocumentsIndividual: FC<DocumentsIndividualProps> = ({
  tabKey,
  policyArea,
  allProgramPolicyDocuments,
  selectedPacketDocuments,
  selectedDocuments,
  customJSONToPrint,
  onChangeIndividualDocumentSelected,
}) => {
  const [
    documentsFilteredBySelectedPackets,
    setDocumentsFilteredBySelectedPackets,
  ] = useState<ProgramPolicyDocumentDto[]>(allProgramPolicyDocuments);
  const atomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom } = useAtomFamily(GlobalInsuredAtomFamily(atomKey));
  const { sendMergeFormRequest } = useFormRequest();
  const [dialogConfiguration, setDialogConfiguration] =
    useState<DialogConfirmationProps | null>(null);

  const evaluatePrintingConfigurations = (
    document: ProgramPolicyDocumentDto,
    templateNames: string[]
  ) => {
    if (templateNames.includes(ERROR_TEMPLATE_NAME)) {
      setDialogConfiguration({
        open: true,
        dialogDescriptionText: "No print template found.",
      });

      return { isFullyConfigured: false };
    }

    if (
      document.docSource === DocSourceEnum.CUSTOM_JSON &&
      customJSONToPrint === ""
    ) {
      setDialogConfiguration({
        open: true,
        dialogDescriptionText:
          "There is no custom JSON set up for the current policy period.",
      });

      return { isFullyConfigured: false };
    }

    return { isFullyConfigured: true };
  };

  const printIndividualDocument = (document: ProgramPolicyDocumentDto) => {
    const policyMergeFields = getAtom()?.policyQuoteInformation?.policyQuote;
    const templateNames =
      document.fileId !== null
        ? [document.templateName ?? ERROR_TEMPLATE_NAME]
        : policyMergeFields?.endorsementForms?.map((form) => {
            return form.templateName ?? ERROR_TEMPLATE_NAME;
          }) ?? [];
    const { isFullyConfigured } = evaluatePrintingConfigurations(
      document,
      templateNames
    );

    if (isFullyConfigured) {
      sendMergeFormRequest({
        ...getSourceJSONFromDocSourceForIndividualPrinting(
          document.docSource,
          customJSONToPrint,
          getAtom()
        ),
        templateNameWithExtensionList: templateNames,
        customFileName: `${policyMergeFields?.insured?.insuredName ?? ""} ${
          document.docName ?? ""
        }`,
      });
    }
  };

  const getCheckedValueForIndividualDocument = (
    programPolicyDocumentId: number
  ) =>
    selectedDocuments.find(
      (selectedDocument) =>
        selectedDocument.programDocId === programPolicyDocumentId
    ) !== undefined;

  useEffect(() => {
    const filteredDocuments = allProgramPolicyDocuments.filter(
      (programPolicyDocument) =>
        selectedPacketDocuments.find(
          (selectedPacketDocument) =>
            selectedPacketDocument.programDocId ===
            programPolicyDocument.programDocId
        ) === undefined
    );

    setDocumentsFilteredBySelectedPackets(filteredDocuments);
  }, [selectedPacketDocuments]);

  return (
    <div
      id="document-packets-container-id"
      className={style.document_divider_section_container}
    >
      <div className={style.divider_section_title_container}>
        <Font whiteSpace="normal" textAlign="start">
          INDIVIDUAL {getDocumentFileCategory(policyArea).toLocaleUpperCase()}
        </Font>
      </div>
      {documentsFilteredBySelectedPackets.map(
        (programPolicyDocument, index) => (
          <div
            key={`program-policy-document-container-${programPolicyDocument.programDocId}`}
            className={style.single_document_container}
          >
            <Switch
              inputWidth="auto"
              key={`program-policy-document-checkbox-${programPolicyDocument.programDocId}`}
              id={`program-policy-document-checkbox-${programPolicyDocument.programDocId}`}
              tabIndex={index}
              control="checkbox"
              isChecked={getCheckedValueForIndividualDocument(
                programPolicyDocument.programDocId
              )}
              onChangeIsChecked={(checked) =>
                onChangeIndividualDocumentSelected(
                  programPolicyDocument,
                  checked
                )
              }
            />
            <Link
              key={`program-policy-document-link-${programPolicyDocument.programDocId}`}
              id={`program-policy-document-link-${programPolicyDocument.programDocId}`}
              linkFontType="BODY"
              displayValue={`${programPolicyDocument.docName}`}
              underline="hover"
              onClick={() => printIndividualDocument(programPolicyDocument)}
            />
          </div>
        )
      )}
      <DialogConfirmation
        {...dialogConfiguration}
        id="id-dialog-confirmation-document-individual"
        optionYesOverrideLabel="OK"
        onOptionYesEvent={() => setDialogConfiguration(null)}
        onCloseEvent={() => setDialogConfiguration(null)}
      />
    </div>
  );
};

export default DocumentsIndividual;
