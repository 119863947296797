import { RecoilState, useRecoilCallback, useRecoilValue } from "recoil";
import {
  BaseGridAccessorProperties,
  BaseGridInstanceMethods,
  BaseGridInternalProperties,
  BaseGridProperties,
  BaseGridToolbarConfigurationProperties,
  DeleteRowParameters,
  GridDataRow,
  HydratedData,
  UpdateRowParameters,
} from "../BaseGridProperties";
import {
  BaseGridIsReadyTrackerAtomFamily,
  BaseGridKeyNameMapAtom,
  BaseGridKeyNameMapProperty,
  GridInstanceAtomFamily,
} from "../BaseGridAtoms";
import { useEffect } from "react";
import { BaseTableColumn } from "../../../dtos/base-table-column";
import { getExternalColumnsOnly } from "../SupportFunctions/OLD_baseGridFunctions";
import {
  createNewDataItemWithDefaults,
  hydrateDataSingle,
} from "../SupportFunctions/baseGridFunctions";
import { useGridInstance } from "./useGridInstance";
import { NotImplementedError } from "../../../utilities/errorHandlerHelper";
import { BaseGridFilterParameters } from "./useBaseGridFilters";

export function useBaseGrid(
  config: BaseGridProperties
): BaseGridInstanceMethods {
  const getMappedInstanceState = useRecoilCallback(
    ({ snapshot }) =>
      (tableName) => {
        const instance =
          (snapshot.getLoadable(BaseGridKeyNameMapAtom)
            .contents as BaseGridKeyNameMapProperty[]) ?? null;

        const keyNameMap = instance?.find((z) => z.name === tableName) ?? null;

        return {
          ...keyNameMap,
        };
      }
  );

  const { instanceInternalSelector } = useGridInstance(
    getMappedInstanceState(config.name ?? "").uiid ?? "",
    "useBaseGrid"
  );

  const instanceInternalAccessors = useRecoilValue(
    instanceInternalSelector("accessors")
  );

  const setInitialTableInstance = useRecoilCallback(
    ({ set }) =>
      (tableInstance: {
        BaseGridProperties: BaseGridProperties;
        BaseGridInternalProperties?: BaseGridInternalProperties;
      }) => {
        set(
          GridInstanceAtomFamily(
            tableInstance?.BaseGridProperties?.uiid ?? "NO_UIID_FOUND"
          ),
          tableInstance
        );

        set(
          BaseGridIsReadyTrackerAtomFamily(
            tableInstance?.BaseGridProperties?.name ?? "NO_NAME_FOUND"
          ),
          tableInstance.BaseGridProperties.uiid ?? "NO_UIID_FOUND"
        );
      },
    []
  );

  const registerNewKeyNameMapInstance = (
    gridName: string,
    set: <T>(
      recoilVal: RecoilState<T>,
      valOrUpdater: T | ((currVal: T) => T)
    ) => void
  ) => {
    const keyNameMap = {
      uiid: crypto.randomUUID(),
      name: gridName,
      disableInitialize: false,
      hasHookRegistered: "unregistered",
    } as BaseGridKeyNameMapProperty;
    set(BaseGridKeyNameMapAtom, (keyName) => [...keyName, keyNameMap]);
  };

  const nameKeySynchronizer = useRecoilCallback(
    ({ snapshot, set }) =>
      (gridName: string): BaseGridKeyNameMapProperty | null => {
        const instance = snapshot.getLoadable(BaseGridKeyNameMapAtom)
          .contents as BaseGridKeyNameMapProperty[];

        if (instance.length > 0) {
          const foundGridKeyRegisteration =
            instance.find((z) => z.name === gridName) ?? null;

          if (foundGridKeyRegisteration !== null) {
            return foundGridKeyRegisteration;
          } else {
            registerNewKeyNameMapInstance(config?.name ?? "NO_NAME_FOUND", set);
          }
        } else {
          registerNewKeyNameMapInstance(config?.name ?? "NO_NAME_FOUND", set);
        }
        return null;
      },
    []
  );

  const updateKeyMap = useRecoilCallback(
    ({ snapshot, set }) =>
      () => {
        const instance = snapshot.getLoadable(BaseGridKeyNameMapAtom)
          .contents as BaseGridKeyNameMapProperty[];
        const updatedInstance = instance.map((keyMap) => {
          if (keyMap?.name === (config?.name ?? "NO_NAME_FOUND")) {
            return {
              ...keyMap,
              hasHookRegistered: "registered",
            } as BaseGridKeyNameMapProperty;
          }
          return keyMap as BaseGridKeyNameMapProperty;
        });
        set(BaseGridKeyNameMapAtom, updatedInstance);
      },
    []
  );

  const initalizeGrid = () => {
    const nameKeySynchronizeredHook = nameKeySynchronizer(
      config?.name ?? "NO_GRID_NAME_FOUND"
    );

    const toolbarConfiguration: BaseGridToolbarConfigurationProperties = {
      hideToolbar: false,
      toolBarType: "standard",
      showAddButton: true,
      addButtonText: "ADD",
      addButtonIsDisabled: false,
      showEditButton: true,
      editButtonText: "EDIT",
      editButtonPrimary: true,
      showSaveButton: true,
      saveButtonText: "SAVE",
      showSortFilter: true,
      showImportButton: true,
      showExcelButton: true,
      showPDFButton: true,
      showExportButton: true,
      showSettingsIcon: true,
      compactTitle: "COMPACT",
      ...config?.toolbarOptions,
    };

    const gridInstance: {
      BaseGridProperties: BaseGridProperties;
      BaseGridInternalProperties: BaseGridInternalProperties;
    } = {
      BaseGridProperties: {
        uiid: nameKeySynchronizeredHook?.uiid,
        name: nameKeySynchronizeredHook?.name,
        getURL: config?.getURL ?? null,
        postURL: config?.postURL ?? null,
        postTarget: config?.postTarget ?? "changedTableData",
        columns: [] as BaseTableColumn[],
        data: [] as GridDataRow[],
        columnsAndData: config?.columnsAndData ?? null,
        changedData: [],
        // computes: [],
        orderByColumnIndex: 1,
        orderDirection: "asc",
        tableType: "standard",
        deletedData: [],
        events: config.events ?? null,
        methods: config.methods ?? null,
        advancedOptions: config?.advancedOptions ?? null,
        permissions: config?.permissions ?? null,
        hasToggledSave: false,
        hasToggledEdit: false,
        hasToggleExportExcel: false,
        hasToggleExportPDF: false,
        hasSelectedAllRows: false,
        columnOptions: config?.columnOptions ?? null,
        filterOptions: config.filterOptions ?? [],
        toolbarOptions: toolbarConfiguration,
        optionsConfiguration: config?.optionsConfiguration ?? null,
        useManuallyReloadParameters:
          config.useManuallyReloadParameters ?? false,
        disableInitialize: config.disableInitialize ?? false,
        styling: {
          rowStyles: config?.styling?.rowStyles ?? null,
          cellStyles: config?.styling?.cellStyles ?? null,
        },
      },
      BaseGridInternalProperties: {
        dataSetKey: crypto.randomUUID(), // See note about the dataSetKey property
        rowKeys: [],
        // isGridReadyToRender: false,
        totalNumberOfInitComputes: 0,
        unprocessedMountedCellManagers: [],
        inProcessCellManagerOperations: [],
        filterParameters: [],
        sortedAndFilteredData: [],
        hasSortedAndFilteredProcessingCompleted: false,
        selectedEditRows: null,
        currentlyRenderedRowKeys: [],
        recentlyUpdatedData: [],
        recentlyAddedRowKey: [],
        newestRowKey: null,
        selectedRows: [],
        validationErrors: null,
        inProcessComputesQueue: [],
        initalInProcessComputesQueueStarted: false,
        inProcessCell: null,
        lastOriginComputeChainCellKey: null,
        lastOnChangeInitiatorCellKey: null,
        inProcessAPIResponse: null,
        computeAPIData: [],
        lastMountAction: "mount",
        debug: null,
        isReadyToRender: false,
        refreshAfterAddRow: false,
        selectedRow: null,
        selectedCell: null,
        selectedRowKeys: [],
        hasScrollBar: false,
        accessors: {
          _reloadDataSources: true,
          _manuallyReloadParameters: false,
          _hasInitalizedDefaultFilterValues: false,
        },
        hasGridInitialConfigurationCompleted: true,
        hasInitialFilterParametersProcessingCompleted: false,
      },
    };

    setInitialTableInstance({ ...gridInstance });

    updateKeyMap();
  };

  const getFilteredParameterResultsForOnManuallyReload = (
    filterParameters: BaseGridFilterParameters[]
  ): BaseGridFilterParameters[] => {
    const updatedFilterParameters = filterParameters.filter(
      (fp) => fp.resetOnManuallyReload !== true
    );
    return updatedFilterParameters;
  };

  useEffect(() => {
    const nameKeySynchronizeredHook = nameKeySynchronizer(
      config?.name ?? "NO_GRID_NAME_FOUND"
    );
    if (!nameKeySynchronizeredHook?.disableInitialize) {
      initalizeGrid();
    }
  }, []);

  useEffect(() => {
    const nameKeySynchronizeredHook = nameKeySynchronizer(
      config?.name ?? "NO_GRID_NAME_FOUND"
    );
    if (nameKeySynchronizeredHook !== null) {
      const instance = getGridInstance(nameKeySynchronizeredHook?.uiid);
      if (
        instance !== null &&
        instance.BaseGridInternalProperties
          .hasGridInitialConfigurationCompleted &&
        !instance.BaseGridProperties.useManuallyReloadParameters
      ) {
        updateGridInstance({
          BaseGridProperties: {
            ...instance.BaseGridProperties,
            ...config,
            columnsAndData: config?.columnsAndData ?? null,
            toolbarOptions: {
              ...instance.BaseGridProperties.toolbarOptions,
              ...config.toolbarOptions,
            },
            filterOptions: config?.filterOptions ?? [],
          },
          BaseGridInternalProperties: {
            ...instance.BaseGridInternalProperties,
          },
        });
      }
    }
  }, [config]);

  useEffect(() => {
    if (instanceInternalAccessors?._reloadDataSources === true) {
      const nameKeySynchronizeredHook = nameKeySynchronizer(
        config?.name ?? "NO_GRID_NAME_FOUND"
      );
      if (nameKeySynchronizeredHook !== null) {
        const instance = getGridInstance(nameKeySynchronizeredHook?.uiid);
        if (
          (instance !== null &&
            instance.BaseGridProperties.useManuallyReloadParameters ===
              undefined) ||
          instance.BaseGridProperties.useManuallyReloadParameters === null ||
          !instance.BaseGridProperties.useManuallyReloadParameters
        ) {
          updateGridInstance({
            BaseGridProperties: {
              ...instance.BaseGridProperties,
              ...config,
              columnsAndData: config?.columnsAndData ?? null,
              toolbarOptions: {
                ...instance.BaseGridProperties.toolbarOptions,
                ...config.toolbarOptions,
              },
            },
            BaseGridInternalProperties: {
              ...instance.BaseGridInternalProperties,
              dataSetKey: crypto.randomUUID(),
              sortedAndFilteredData: [],
              accessors: {
                ...instance.BaseGridInternalProperties.accessors,
                _manuallyReloadParameters: false,
                _reloadDataSources: true,
              },
            },
          });
        }
      }
    }
  }, [instanceInternalAccessors?._reloadDataSources]);

  useEffect(() => {
    const nameKeySynchronizeredHook = nameKeySynchronizer(
      config?.name ?? "NO_GRID_NAME_FOUND"
    );
    if (nameKeySynchronizeredHook !== null) {
      const instance = getGridInstance(nameKeySynchronizeredHook.uiid);
      if (
        instance !== null &&
        instance.BaseGridProperties.useManuallyReloadParameters &&
        instanceInternalAccessors?._manuallyReloadParameters
      ) {
        updateGridInstance({
          BaseGridProperties: {
            ...instance.BaseGridProperties,
            ...config,
            columnsAndData: config?.columnsAndData ?? null,
            columns: config?.columns ?? [],
            data: config?.data ?? [],
            toolbarOptions: {
              ...instance.BaseGridProperties.toolbarOptions,
              ...config.toolbarOptions,
            },
            filterOptions: config?.filterOptions ?? [],
          },
          BaseGridInternalProperties: {
            ...instance.BaseGridInternalProperties,
            dataSetKey: crypto.randomUUID(),
            sortedAndFilteredData: [],
            filterParameters: getFilteredParameterResultsForOnManuallyReload(
              instance?.BaseGridInternalProperties?.filterParameters ?? []
            ),
            accessors: {
              ...instance.BaseGridInternalProperties.accessors,
              _manuallyReloadParameters: false,
              _reloadDataSources: true,
            },
          },
        });
      }
    }
  }, [instanceInternalAccessors?._manuallyReloadParameters]);

  const updateGridInstance = useRecoilCallback(
    ({ set }) =>
      (entireInstance: {
        BaseGridProperties: BaseGridProperties;
        BaseGridInternalProperties: BaseGridInternalProperties;
      }) => {
        set(
          GridInstanceAtomFamily(
            entireInstance.BaseGridProperties.uiid ?? "NO_UIID_FOUND"
          ),
          entireInstance
        );
      },
    []
  );

  const getGridInstance = useRecoilCallback(
    ({ snapshot }) =>
      (uiid: string) => {
        return (
          (snapshot.getLoadable(GridInstanceAtomFamily(uiid)).contents as {
            BaseGridProperties: BaseGridProperties;
            BaseGridInternalProperties: BaseGridInternalProperties;
          }) ?? null
        );
      },
    []
  );

  const getTableInstanceByName = (
    tableName: string
  ): {
    BaseGridProperties: BaseGridProperties;
    BaseGridInternalProperties: BaseGridInternalProperties;
  } => {
    const nameKeySynchronizeredHook = nameKeySynchronizer(tableName);
    const instance = getGridInstance(
      nameKeySynchronizeredHook?.uiid ?? "NO_UIID_FOUND"
    );
    return instance;
  };

  const updateInternalInstanceMethodEvents = (
    tableName: string,
    updatedAccessorHooks: BaseGridAccessorProperties
  ) => {
    const instance = getTableInstanceByName(tableName);
    // BASETABLE TODO - configure this method correctly. the final product is targeted toward _accessors which might be fine for
    // a separate standalone function but we are unable to target properties outside of the _accessors namespace.
    // and are forced to do hacky things like the "resetURL" object.

    const updatedInstance = {
      BaseGridProperties: {
        ...instance.BaseGridProperties,
      } as BaseGridProperties,
      BaseGridInternalProperties: {
        ...instance.BaseGridInternalProperties,
        accessors: {
          ...instance.BaseGridInternalProperties.accessors,
          ...updatedAccessorHooks,
        },
      } as BaseGridInternalProperties,
    };

    updateGridInstance(updatedInstance);
    // });
  };

  const addRow = (
    tableName: string,
    callback: (hydratedDataDefault: HydratedData) => void
  ) => {
    const instance = getTableInstanceByName(tableName);
    const externalColumnsOnly = getExternalColumnsOnly(
      instance?.BaseGridProperties?.columns ?? []
    );
    const defaults = createNewDataItemWithDefaults(externalColumnsOnly);
    const hydratedBaseObject = hydrateDataSingle(externalColumnsOnly, defaults);
    const hydratedData = callback(hydratedBaseObject);
    const newRowKey = crypto.randomUUID();
    updateInternalInstanceMethodEvents(tableName, {
      _addRowExternal: { hydratedData, newRowKey },
    });
  };

  const updateRow = (tableName: string, hydratedData: UpdateRowParameters) => {
    updateInternalInstanceMethodEvents(tableName, {
      _updateRowExternal: hydratedData,
    });
  };

  const updateRowAndReload = (
    tableName: string,
    hydratedData: UpdateRowParameters
  ) => {
    updateInternalInstanceMethodEvents(tableName, {
      _updateRowExternal: hydratedData,
    });
  };

  const updateRowAndSave = (
    _tableName: string,
    _hydratedData: UpdateRowParameters
  ) => {
    // updateInternalInstanceMethodEvents(tableName, {
    //   _updateRowExternal: hydratedData,
    //   _reloadDataSources: true,
    // });
    throw NotImplementedError("Not yet ready to use.");
  };

  const deleteRow = (tableName: string, deleteAction: DeleteRowParameters) => {
    updateInternalInstanceMethodEvents(tableName, {
      _deleteRowExternal: deleteAction,
    });
  };

  const deleteRowAndReload = (
    _tableName: string,
    _deleteAction: DeleteRowParameters
  ) => {
    throw NotImplementedError("Not yet ready to use.");
  };

  const deleteRowAndSave = (
    _tableName: string,
    _deleteAction: DeleteRowParameters
  ) => {
    // updateInternalInstanceMethodEvents(tableName, {
    //   _deleteRowExternal: deleteAction,
    //   _reloadDataSources: true,
    // });
    throw NotImplementedError("Not yet ready to use.");
  };

  const reloadGridDataSource = (tableName: string) => {
    updateInternalInstanceMethodEvents(tableName, { _reloadDataSources: true });
  };

  const manuallyReloadParameters = (gridName: string) => {
    updateInternalInstanceMethodEvents(gridName, {
      _manuallyReloadParameters: true,
    });
  };

  const mountGrid = () => {
    initalizeGrid();
  };

  const unmountGrid = useRecoilCallback(
    ({ snapshot, set }) =>
      () => {
        const nameKeySynchronizeredHook = nameKeySynchronizer(
          config?.name ?? "NO_GRID_NAME_FOUND"
        );
        if (nameKeySynchronizeredHook !== null) {
          const keyMapInstance = snapshot.getLoadable(BaseGridKeyNameMapAtom)
            .contents as BaseGridKeyNameMapProperty[];
          const updatedKeyMapInstance = keyMapInstance.map((keyMap) => {
            if (keyMap?.name === (config?.name ?? "NO_NAME_FOUND")) {
              return {
                ...keyMap,
                hasHookRegistered: "unregistered",
              } as BaseGridKeyNameMapProperty;
            }
            return keyMap as BaseGridKeyNameMapProperty;
          });

          set(BaseGridKeyNameMapAtom, updatedKeyMapInstance);

          const gridInstance = getGridInstance(
            nameKeySynchronizeredHook?.uiid ?? "NO_UIID_FOUND"
          );
          if (gridInstance !== null) {
            const updatedGridInstance = {
              BaseGridProperties: {
                ...gridInstance.BaseGridProperties,
              } as BaseGridProperties,
              BaseGridInternalProperties: {
                ...gridInstance.BaseGridInternalProperties,
                lastMountAction: "unmount",
              } as BaseGridInternalProperties,
            };
            updateGridInstance(updatedGridInstance);
          }
        }
      },
    []
  );

  return {
    addRow: (callback: (hydratedDataDefault: HydratedData) => void) => {
      addRow(config?.name ?? "NO_TABLE_NAME_FOUND", callback);
    },
    updateRow: (hydratedData: UpdateRowParameters) => {
      updateRow(config?.name ?? "NO_TABLE_NAME_FOUND", hydratedData);
    },
    updateRowAndSave: (hydratedData: UpdateRowParameters) => {
      updateRowAndSave(config?.name ?? "NO_TABLE_NAME_FOUND", hydratedData);
    },
    updateRowAndReload: (hydratedData: UpdateRowParameters) => {
      updateRowAndReload(config?.name ?? "NO_TABLE_NAME_FOUND", hydratedData);
    },
    deleteRow: (deleteAction: DeleteRowParameters) => {
      deleteRow(config?.name ?? "NO_TABLE_NAME_FOUND", deleteAction);
    },
    deleteRowAndSave: (deleteAction: DeleteRowParameters) => {
      deleteRowAndSave(config?.name ?? "NO_TABLE_NAME_FOUND", deleteAction);
    },
    deleteRowAndReload: (deleteAction: DeleteRowParameters) => {
      deleteRowAndReload(config?.name ?? "NO_TABLE_NAME_FOUND", deleteAction);
    },
    reloadGridDataSource: () => {
      reloadGridDataSource(config.name ?? "NO_TABLE_NAME_FOUND");
    },
    manuallyReloadParameters: () => {
      manuallyReloadParameters(config.name ?? "NO_TABLE_NAME_FOUND");
    },
    mountGrid: () => {
      mountGrid();
    },
    unmountGrid: () => {
      unmountGrid();
    },
  } as BaseGridInstanceMethods;
}
