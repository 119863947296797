import { FC, useEffect, useState } from "react";
import { DocumentsProps, DocumentsUIProps } from "./DocumentsTypes";
import { INSURED_ATOM_KEY } from "../../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../../InsuredAtoms";
import { FormattingDate } from "../../../../../utilities/dateFunctions";
import { useApiGet } from "../../../../../hooks";
import { ProgramPacketPage } from "../../../../../dtos/program-packet-page";
import { defaultDocumentsUI, QUOTE_POLICY_API_PATH } from "./DocumentsUtils";
import { isAPITotallyComplete } from "../../../../../utilities/apiFunctions";
import DocumentsCommands from "./DocumentsCommands";
import style from "./DocumentsStyles.module.css";
import DocumentsSplitter from "./DocumentsSplitter";

const Documents: FC<DocumentsProps> = ({ tabKey, policyArea }) => {
  const atomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom } = useAtomFamily(GlobalInsuredAtomFamily(atomKey));

  const getPolicyId = () => {
    const atomValue = getAtom();
    return atomValue?.policyQuoteInformation?.policyId;
  };
  const getQuoteId = () => {
    const atomValue = getAtom();
    return atomValue?.policyQuoteInformation?.quoteId;
  };
  const getEffectiveDate = () =>
    FormattingDate(
      getAtom()?.policyQuoteInformation?.policyQuote?.effectiveDate
    );
  const { responseGet, dispatchGet } = useApiGet<ProgramPacketPage>(
    `${QUOTE_POLICY_API_PATH}/GetProgramPacketList?policyId=${getPolicyId()}&quoteId=${getQuoteId()}&effectiveDate=${getEffectiveDate()}&formType=${policyArea}`
  );
  const [documentsUI, setDocumentsUI] =
    useState<DocumentsUIProps>(defaultDocumentsUI);

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (
      isAPITotallyComplete(responseGet) &&
      responseGet.axiosResponse?.data !== undefined
    ) {
      setDocumentsUI({
        ...documentsUI,
        allDocuments: responseGet.axiosResponse.data,
      });
    }
  }, [responseGet]);

  return documentsUI.allDocuments !== null ? (
    <div id="documents-container-id" className={style.documents_container}>
      <DocumentsSplitter
        tabKey={tabKey}
        documentsUI={documentsUI}
        setDocumentsUI={setDocumentsUI}
        policyArea={policyArea}
      />
      <DocumentsCommands
        tabKey={tabKey}
        selectedPackets={documentsUI.selectedPackets}
        selectedPacketDocuments={documentsUI.selectedPacketDocuments}
        selectedDocuments={documentsUI.selectedDocuments}
        customJSONToPrint={documentsUI.allDocuments?.customPolicyJSON}
        policyArea={policyArea}
      />
    </div>
  ) : null;
};

export default Documents;
