import { FC, useEffect, useState } from "react";
import { updatePolicyQuoteInformation } from "../updatesPolicyQuoteFunctions";
import { QuotePolicySectionEnum } from "../../../../dtos/quote-policy-section-enum";
import { validatePreviousSections } from "../PolicyQuoteForm/PolicyQuoteValidationUtils";
import { PolicyQuoteInsuredAndActiveSetterProps } from "../PolicyQuoteForm/PolicyQuoteTypes";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import Documents from "../SharedComponents/Documents/Documents";
import { PolicyFormTypeEnum } from "../../../../dtos/policy-form-type-enum";
import PolicyQuoteBottomButtons from "../PolicyQuoteForm/PolicyQuoteBottomButtons";

const PolicyDocuments: FC<PolicyQuoteInsuredAndActiveSetterProps> = ({
  insuredId,
  quoteStatus,
  tabKey,
  setActiveSection,
}) => {
  const [renderDocuments, setRenderDocuments] = useState<boolean>(false);

  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );

  useEffect(() => {
    const atomValue = getAtom();
    const activeSection =
      atomValue?.policyQuoteInformation?.activeSection ??
      QuotePolicySectionEnum.INFORMATION;
    const isValid = validatePreviousSections(atomValue, activeSection);
    if (isValid) setRenderDocuments(true);
    else {
      const newAtomValue = updatePolicyQuoteInformation(
        atomValue,
        "activeSection",
        QuotePolicySectionEnum.INFORMATION
      );
      setAtom(newAtomValue);
      setActiveSection?.(QuotePolicySectionEnum.INFORMATION);
    }
  }, []);

  return renderDocuments ? (
    <div
      id="policy-documents-main-container"
      style={{ width: "100%", display: "flex", flexDirection: "column" }}
    >
      <Documents tabKey={tabKey} policyArea={PolicyFormTypeEnum.QUOTE_FORM} />
      <PolicyQuoteBottomButtons
        tabKey={tabKey}
        insuredId={insuredId}
        policySection={QuotePolicySectionEnum.POLICY_DOCS}
        quoteStatus={quoteStatus}
        setActiveSection={setActiveSection}
      />
    </div>
  ) : null;
};

export default PolicyDocuments;
